import React, { useRef } from 'react'
import Header from './home/Header'
import Banner from './home/Banner'
import KeyFeatures from './home/KeyFeatures'
import BusinessPlan from './business-plan/BusinessPlan'
import ContactForm from './business-plan/ContactForm'
import Footer from './footer/Footer'

const HomePage = () => {
  const contactFormRef = useRef<any>(null);

  const scrollToContactForm = () => {
    contactFormRef?.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="client overflow-hidden">
      <div className="client-banner relative">
        <div className="client-container w-[90%]">
          <Header />
          <Banner scrollToContactForm={scrollToContactForm} />
        </div>
      </div>
      <div className="client-container relative h-[400px] w-[80%] ">
        <div className="client-banner-video flex justify-center absolute">
          <video autoPlay={true} loop={true} preload="auto" muted width={"75%"} className='rounded-2xl'>
            <source src="https://storage.googleapis.com/all-aboard-staging/promo.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="client-container pt-[200px] w-[90%]">
        <div className="client-content pb-40 pl-[5%]">
          <KeyFeatures />
        </div>
      </div>
      <div className="client-business-plan">
        <div className="client-container w-[80%] business-plan pb-40">
          <BusinessPlan />
        </div>
      </div>
      <div className="client-contact-form" ref={contactFormRef} >
        <ContactForm />
      </div>
      <div className="client-container w-[80%]">
        <Footer />
      </div>
    </div>
  )
}

export default HomePage
