import React, { useEffect, useState } from 'react';
import { Tabs, Modal } from 'antd';
import PersonalDetail from './PersonalDetails';
import OtherDetails from './OtherDetails'
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
const { TabPane } = Tabs;

const EditEmployeeDetailsModal = (props: any) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [modalOpen, setOpen] = useState(false);
  const [loader, setLoader] = useState<any>(false)

  useEffect(() => {
    setOpen(props?.open);
    return (() => {
      setOpen(false);
    })
  }, [props?.open])

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleCloseModal = () => {
    setOpen(false);
    props?.setModalOpen(false)
  }
  const items = [
    {
      key: '1',
      label: 'EMPLOYEE DETAILS',
      children: <PersonalDetail setLoader={setLoader} handleCloseModal={handleCloseModal} handleTabChange={handleTabChange} />
    },
    {
      key: '2',
      label: 'OTHER DETAILS',
      children: <OtherDetails handleCloseModal={handleCloseModal} setLoader={setLoader} />
    },
  ]

  return (
    <Modal className='salary-modal relative' centered open={modalOpen} onCancel={handleCloseModal} footer={null} maskClosable={false}>
      {loader && <ModalLoader />}
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        {items.map(item => (
          <TabPane key={item.key} tab={item.label}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </Modal >
  )
}

export default EditEmployeeDetailsModal