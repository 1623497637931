import React from 'react'
import addSalary from "../../../../../../assets/images/employee/add-salary.png"

const SalaryComponent = (props: any) => {
  const { showNull, salaryParticulars, employeeContribution, grossSalary, costToCompany, netAmount, combineEmployerArray } = props;
  const changeNumberToIndianCurrency = (numberValue: any) => {

    return Number(numberValue).toLocaleString('en-IN');
  }

  return (
    <>
      {showNull !== null && salaryParticulars.length > 0 && combineEmployerArray.length > 0 || employeeContribution.length > 0 ? (
        <div className="salary_prticulars-container pl-0 ">
          <div className="salary_prticulars-list overflow-y-auto mt-4">
            <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
              <div className="particulars">
                <p className='_header mb-5'>Salary Particulars</p>
                <ul>
                  {salaryParticulars
                    .filter((item: any) => !(item.salaryComponentId === 132 && item?.name === 'LTA' && grossSalary?.grossAmountMonthly <= 36803))
                    .map((item: any) => (
                      <li key={item?.salaryComponentId}>{item?.name}</li>
                    ))}
                </ul>
                <p className='salary-gross'>Gross Salary</p>
              </div>
              <div className="monthly_details">
                <p className='_monthly'>Monthly</p>
                <ul>
                  {salaryParticulars
                    .filter((item: any) => !(item.salaryComponentId === 132 && item?.name === 'LTA' && grossSalary?.grossAmountMonthly <= 36803))
                    .map((item: any) => (
                      <li key={item?.salaryComponentId}>
                        &#8377;{item?.monthly !== '' ? changeNumberToIndianCurrency(item?.monthly) : 0}
                      </li>
                    ))}
                </ul>
                <div className='form-item basicForm-fields' >

                  <p className='font-bold'> &#8377; {changeNumberToIndianCurrency(grossSalary?.grossAmountMonthly)}</p>
                </div>
              </div>
              <div className="annual_details">
                <p className='_annual'>Annual </p>
                <ul>
                  {salaryParticulars
                    .filter((item: any) => !(item.salaryComponentId === 132 && item?.name === 'LTA' && grossSalary?.grossAmountMonthly <= 36803))
                    .map((item: any) => (
                      <li key={item?.salaryComponentId}>
                        &#8377;{item?.yearly !== '' ? changeNumberToIndianCurrency(item?.yearly) : 0}
                      </li>
                    ))}
                </ul>
                <div className='form-item basicForm-fields' >

                  <p className='font-bold'> &#8377; {changeNumberToIndianCurrency(grossSalary?.grossAmountAnnual)}</p>
                </div>
              </div>
            </div>

            <div className='_header mb-5'>Employer Contribution</div>
            <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
              <div className="particulars">
                <ul>
                  {combineEmployerArray.map((item: any) => (
                    <li key={item?.salaryComponentId}>{item?.name}</li>
                  ))}
                </ul>
                <p className='salary-gross'>Cost To Company</p>

              </div>
              <div className="particulars">
                <ul>
                  {combineEmployerArray.map((item: any) => (
                    <li key={item?.salaryComponentId}>&#8377;{changeNumberToIndianCurrency(item?.monthly)}</li>
                  ))}
                </ul>
                <p className='salary-gross'>&#8377; {changeNumberToIndianCurrency(costToCompany?.ctcMonthly)}</p>
              </div>
              <div className="particulars">
                <ul>
                  {combineEmployerArray.map((item: any) => (
                    <li key={item?.salaryComponentId}>&#8377;{changeNumberToIndianCurrency(item?.yearly)}</li>
                  ))}
                </ul>
                <p className='salary-gross' >&#8377;{changeNumberToIndianCurrency(Math.round(costToCompany?.ctcYearly))} </p>
              </div>
            </div>
            <div className='_header mb-5'>Employee Contribution</div>
            <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
              <div className="particulars">
                <ul>
                  {employeeContribution.map((item: any) => (
                    <li key={item?.salaryComponentId}>{item?.name}</li>
                  ))}
                </ul>
                <p className='salary-gross'>Net Salary</p>
              </div>
              <div className="particulars">
                <ul>
                  {employeeContribution.map((item: any) => (
                    <li key={item?.salaryComponentId}>&#8377;{changeNumberToIndianCurrency(item?.monthly)}</li>
                  ))}

                </ul>
                <p className='salary-gross'>&#8377; {changeNumberToIndianCurrency(netAmount?.monthly)}</p>
              </div>
              <div className="particulars">
                <ul>
                  {employeeContribution.map((item: any) => (
                    <li key={item?.salaryComponentId}>&#8377;{changeNumberToIndianCurrency(item?.yearly)}</li>
                  ))}
                </ul>
                <p className='salary-gross' >&#8377; {changeNumberToIndianCurrency(netAmount?.yearly)}</p>
              </div>
            </div>
          </div>
        </div>) : (
        <div className='h-[60vh] flex justify-center items-center'>
          <img src={addSalary} alt="" />
        </div>
      )}
    </>
  )
}

export default SalaryComponent
