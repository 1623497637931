import React from 'react'
import logo from "../../../assets/images/logo.png"
import "./Banner.scss"
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const handelLogin = () => {
    navigate("/")
  }
  const handelSignUp = () => {
    navigate("/signup?key=free")
  }

  return (
    <header>
      <div className="client-header pt-8 flex justify-between ">
        <div className="w-2/5 flex items-center">
          <img src={logo} alt="logo " />
          <p className='text-[#ffffff] font-rubik text-[30px]'>All-Aboard </p>
        </div>
        <div className="flex items-center">
          <p className=' font-rubik text-[20px] font-medium text-[#ffffff] w-[100px] cursor-pointer ' onClick={() => handelLogin()}>
            Login
          </p>
          <button className='addButton register w-[150px] font-medium text-[20px]' onClick={() => handelSignUp()}>
            SignUp
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
