import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  leaveReportPayload: any;
}

const initialState: AuthState = {
  leaveReportPayload: {},
};

const leaveReportPayloadSlice = createSlice({
  name: 'leaveReportPayload',
  initialState,
  reducers: {
    setLeaveReportPayload: (state, action: PayloadAction<any>) => {
      state.leaveReportPayload = action.payload;
    },
    clearLeaveReportPayload: state => {
      state.leaveReportPayload = {};
    },
  },
});

export const { setLeaveReportPayload, clearLeaveReportPayload } = leaveReportPayloadSlice.actions;
export default leaveReportPayloadSlice.reducer;