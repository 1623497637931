import React from 'react';
import ImageUpload from '../../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload';
import UploadImages from './UploadImages';
import { Input } from 'antd';
import { ErrorMessage } from 'formik';

const ArrangeInOrder = ({ values, setFieldValue, arrangeInOrderState, setArrangeInOrderState, showError, setMediaData, mediaData }: any) => {
  const handleImageDelete = (index: any) => (file: File) => {
    setArrangeInOrderState((prevState: any) => {
      const newState = [...prevState];
      newState[index] = { imageUrl: '', selectedFileName: index, file: null, check: false };
      return newState;
    });
  };

  const handleImageUpload = (index: any) => (file: File) => {
    setArrangeInOrderState((prevState: any) => {
      const newState = [...prevState];
      newState[index] = { imageUrl: '', selectedFileName: index, file, check: true };
      return newState;
    });
  }

  return (
    <div>
      <div className="flex">
        <div className="w-3/5 flex multiple-upload flex-col">
          <div className="addcourse-field pt-5">
            <p>Question</p>
            <Input.TextArea
              name="question"
              value={values?.question}
              placeholder="Question"
              onChange={(e: any) => setFieldValue('question', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="question" component="div" className="error-message" />
          </div>
          <div className='flex'>
            {arrangeInOrderState.map((imageState: any, index: any) => (
              <div key={`${index + 1}`} className='m-2'>
                <ImageUpload
                  onImageUpload={handleImageUpload(index)}
                  onImageDelete={handleImageDelete(index)}
                  maxFileSize={1024 * 1024 * 10}
                  imageUrl={imageState?.imageUrl}
                  allowedTypes={['image/jpeg', 'image/png']}
                />
                {(showError && !imageState?.check) && <p className="error-message">Please Select Image</p>}
              </div>
            ))}</div>
        </div>
        <div className='w-2/5 pt-5 pl-8 upload-error-tooltip arrange-ino multi-ques'>
          <label className='addcourse-lable pl-2 arrange-in-order' htmlFor="firstName">Question Image</label>
          <UploadImages setMediaData={setMediaData} mediaData={mediaData} />
        </div>
      </div>
    </div>
  )
}

export default ArrangeInOrder
