import React, { useEffect, useState } from 'react'
import { Input, Modal } from 'antd'
import { useSelector } from 'react-redux';
import { triggerNotification } from '../../common/CommonExport';
import FloatLabel from '../../components/FloatLabel';
import Excell from "../../assets/images/modal/excell.png"
import Pdf from "../../assets/images/modal/pdf.png"


const DownloadReport = (props: any) => {
  const { open, handelDocumentDownload } = props;
  const [isOpen, setIsOpen] = useState<any>(false);
  const leaveReportPayload = useSelector((state: any) => state?.leaveReportPayload?.leaveReportPayload);
  const userId = useSelector((state: any) => state?.userId?.id);

  const [reportName, setReportName] = useState('')
  useEffect(() => {
    setIsOpen(open)
  }, [open])
  const handleClose = () => {
    setIsOpen(false)
    handelDocumentDownload()
  };

  const handleExcelDownload = () => {
    let params = { ...leaveReportPayload };
    params.reportName = reportName;
    if (leaveReportPayload !== '' && leaveReportPayload !== null && leaveReportPayload !== undefined && Object.keys(leaveReportPayload).length > 0) {
      if (reportName !== '') {
        const baseURL = `/admin/leave-management/v1/leave-requests/download?`;
        const objectToQueryString = (obj: Record<string, any>): string => {
          return Object.keys(obj)
            .map(
              (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
            )
            .join('&');
        };

        const queryString = objectToQueryString(leaveReportPayload);
        const bucketUrl = process.env.REACT_APP_ELMS_BASEURL + baseURL + queryString + `&reportName=${reportName}&loggedInUserId=${userId}`
        handleClose()
        window.open(bucketUrl, '_blank');
      } else {
        triggerNotification('warning', '', 'Please Enter the report name', 'topRight');
      }
    } else {
      triggerNotification('warning', '', 'Report cannot be download now', 'topRight');
    }

  }
  return (
    <div>
      <Modal title="Download as" centered open={isOpen} onCancel={handleClose} footer={null} maskClosable={false} >
        <div className="'appraisal-increment mt-4">
          <div className='pr-10 my-2'>
            <FloatLabel label='Report Name *' name='employeeDto.firstName' value={reportName}>
              <Input type="text" className='float-control' name="employeeDto.firstName" value={reportName}
                onChange={(e) => setReportName(e.target.value)}
              />
            </FloatLabel>
          </div>
          <div className='flex justify-evenly   salary_add-btn mt-7'>
            <div className='card modal'>
              <p className='text-[#848484] text-[14px] cursor-pointer text-center' onClick={handleExcelDownload}>
                <img src={Excell} alt="excell" className='pl-2 pb-3' />
                Click to download <br /> Excel format
              </p>
            </div>

          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DownloadReport
