import React, { useState } from 'react';
import { Checkbox, Collapse, Switch, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import AddCourseModal from './AddCourseModal';
import { triggerNotification } from '../../common/CommonExport';

const LearningPathAccordion = (props: any) => {
  const { mainArray, setMainArray, elmsPathDetails, certificateId, pathName, setSelectedLevels, selectedLevels } = props;
  const [selectedCourseList, setSelectedCourseList] = useState<any>([]);
  // const [storingIndexes, setStoringIndexes] = useState<any>([]);
  const { token } = theme.useToken();
  const [addCourseModal, setAddCourseModal] = useState(false)
  const [indexValue, setIndexValue] = useState(1);

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const onChange = (levelData: any, mainIndex: number) => {
    const shallowArray = [...mainArray];
    shallowArray[mainIndex].learningLevel.isLevelCertificateRequired = !mainArray?.[mainIndex]?.learningLevel?.isLevelCertificateRequired;
    setMainArray(shallowArray);
  };


  const handleAddCourseModal = (arrayIndex: number) => {
    setAddCourseModal(true)
    setIndexValue(arrayIndex);
  }
  const addNewLevel = () => {
    if (pathName !== '' && pathName !== null && pathName !== undefined) {
      const sampleArray = [...mainArray];
      const lastIndexIs = sampleArray[sampleArray.length - 1]?.learningLevel?.learningLevelId === undefined ? 0 : sampleArray[sampleArray.length - 1]?.learningLevel?.learningLevelId
      const initialObject = {
        learningLevel: {
          learningLevelId: lastIndexIs + 1
        },
        learningPathLevelCourse: []
      }
      sampleArray.push(initialObject)
      setMainArray(sampleArray);
    } else {
      triggerNotification('warning', '', 'Learning Pathname is required', 'topRight');
    }
  };

  const handleCourseLevelDelete = (courseIndex: number, mainIndex: number) => {
    const sampleArray = [...mainArray];
    sampleArray[mainIndex].learningPathLevelCourse.splice(courseIndex, 1);
    setMainArray(sampleArray)
  }
  const handleLevelSelection = (index: number) => {
    const shallowCopyMainArray = [...mainArray]
    shallowCopyMainArray[index].checked = !mainArray?.[index]?.checked;
    setMainArray(shallowCopyMainArray)

    const updatedIndexes = [...selectedLevels, index];
    // setStoringIndexes(updatedIndexes);
    setSelectedLevels(updatedIndexes);
  }
  const getItems = (panelStyle: React.CSSProperties) => {
    const items = mainArray.map((levelData: any, index: number) => {
      const { learningPathLevelCourse } = levelData;
      return {
        key: `${index + 1}`,
        label: (
          <div className="flex justify-between">
            <div>
              <p className="ml-2 text-[#d34a7c] flex font-medium text-[22px]">
                <Checkbox className='' checked={levelData?.checked} onChange={() => handleLevelSelection(index)} />
                <span className="material-symbols-outlined super-dot pr-3">drag_indicator</span>
                <span>Level {index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
              </p>
            </div>
            <button className="mr-10 flex w-[170px] h-[35px] bg-[#D34A7C] text-[#ffffff] justify-center items-center rounded-lg" onClick={() => handleAddCourseModal(index)}>
              <span className="material-symbols-outlined">add</span>
              Add Course
            </button>
          </div>
        ),
        children: (
          <div>
            <div className='accordion-content'>
              {learningPathLevelCourse.map((item: any, courseIndex: number) => (
                <p className='font-rubik' key={item?.course?.courseID}>
                  <div className='flex justify-between p-[5px]'>
                    <span>{`Course ${courseIndex + 1}: ${item?.course?.name}`}</span>
                    <span className="material-symbols-outlined text-[#d34a7c] cursor-pointer" onClick={() => handleCourseLevelDelete(courseIndex, index)}>delete</span>
                  </div>
                </p>
              ))}
            </div>
            <div className='flex'>
              <Switch className='' checked={levelData?.learningLevel?.isLevelCertificateRequired} onChange={() => onChange(levelData, index)} />
              <div className="px-2 pb-1 text-[#737373] text-[18px]">Enable Level Completion Certificate</div>
            </div>
          </div>
        ),
        style: panelStyle,
      };
    });
    return items;
  };

  return (
    <>
      <div className="flex flex-col ">
        <div className="flex">

          <div className="w-full max-h-[70vh] overflow-y-auto relative">
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              items={getItems(panelStyle)}
              expandIcon={({ isActive }) => <CaretRightOutlined className="custom-icon" />}
              expandIconPosition="end"
            />
            <div className={elmsPathDetails === undefined || elmsPathDetails === null ? "add-component pointer-events-none" : "add-component pointer-events-auto"} onClick={addNewLevel}>
              <p>+ Add New level Component</p>
            </div>
          </div>
        </div>

      </div>
      {addCourseModal && <AddCourseModal certificateId={certificateId} modalOpen={addCourseModal} setAddCourseModal={setAddCourseModal} selectedCourseList={selectedCourseList} setSelectedCourseList={setSelectedCourseList} mainArray={mainArray} setMainArray={setMainArray} indexValue={indexValue} elmsPathDetails={elmsPathDetails} />}
    </>
  );
};

export default LearningPathAccordion;