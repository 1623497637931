import React from 'react'
import LearningPathTable from './LearningPathTable'

export default function AssignedEmployee() {
  return (
    <div>
      <div className='newLearningPath page-container bg-customBg'>
        <div className='assignedEmployee full-width-container mt-5'>
          <div className='lm-tab bg-white p-5'>
            <h3>Assigned Employees (35)</h3>
            <div className=' mt-6'>
              <LearningPathTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
