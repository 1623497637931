import * as Yup from 'yup';

export const newEmployeeValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(/^[\sa-zA-Z]+$/, 'First name should only contain alphabets and spaces')
        .max(20, 'First name should not exceed 20 characters')
        .required('First name is required'),
    lastName: Yup.string()
        .matches(/^[\sa-zA-Z]+$/, 'Last name should only contain alphabets ')
        .max(20, 'Last name should not exceed 20 characters')
        .required('Last name is required'),
    dateOfJoin: Yup.string().required('Date of Join is required'),
    employeeType: Yup.string().required('Employee type is required'),
    officialEmailId: Yup.string().required('Email id is required'),
});
export const newEmployeeOtherDetailsValidationSchema = Yup.object().shape({
    designation: Yup.string().required('Designation is required'),
    department: Yup.string().required('Department is required'),
    laptopConfig: Yup.string().required('Please select the configuration type'),
    deviceType: Yup.string().required('Please select the device type'),
    otherPeripherals: Yup.array().of(Yup.string()).required('Please select the peripherals'),
});
