import React, { useEffect, useState } from "react";
import AddChapterContent from "./AddChapterContent";
import { setCurrentTab } from "../../../../redux/slices/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAllChapterList, setAssessmentAssignChapterID } from "../../../../redux/slices/currentCourseDetailSlics";
import Loader from "../../../../components/Loader/Loader";
import { triggerNotification, useGetApiRequests } from "../../../../common/CommonExport";
import ModalLoader from "../../../../components/Loader/ModalLoader";

const AddNewChapterInCourseSide = ({ handleTabChange }: any) => {
  const dispatch = useDispatch()
  const chapterinTableEdit = useSelector((state: any) => state?.currentCourse?.chapterinTableEdit);
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const courseChapters = useSelector((state: any) => state?.currentCourse?.allChapterList);
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const [loader, setLoader] = useState<any>(false)
  const chapterUpdateByCourseId = useGetApiRequests('chapterListByCourseId', 'PUT');
  const chapterListByCourseId = useGetApiRequests('chapterListByCourseId', 'GET');
  const [editModal, setEditModal] = useState(false);
  const start = 0;

  const handlePutData = (payload: any) => {
    setLoader(true)
    const queryParams = { loggedInUserId: loggedInUser?.userId }
    const pathParams = { id: currentCourseId }
    chapterUpdateByCourseId(payload, queryParams, pathParams)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        getChapterListByCourseId()
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
      })
  }

  const getChapterListByCourseId = () => {
    setLoader(true)
    const pathParams = { id: currentCourseId }
    chapterListByCourseId('', {}, pathParams)
      .then((res: any) => {
        const filteredData = res?.data?.data?.find((item: any) => { return item?.chapter?.chapterId === chapterinTableEdit?.chapterId })
        const mainRes = filteredData
        const loadData: any = {
          chapterId: mainRes?.chapter?.chapterId, chaptername: mainRes?.chapter?.name, quiz: mainRes?.quiz, displaySequence: mainRes?.displaySequence, courseName: mainRes?.name,
          courseChapterAssignmentId: mainRes?.courseChapterAssignmentId
        }
        dispatch(setAssessmentAssignChapterID(loadData))
        dispatch(setAllChapterList(res?.data?.data))
        handleFinalWork()
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
      })
  }

  useEffect(() => {
    if (newChapterFlow) {
      if (Object.keys(chapterinTableEdit)?.length > 0) {
        const filteredData = courseChapters?.find((item: any) => { return item?.chapter?.chapterId === chapterinTableEdit?.chapterId })
        if (filteredData === undefined) {
          const payload = [...courseChapters, {
            course: { courseId: currentCourseId }, chapter: { chapterId: chapterinTableEdit?.chapterId },
            displaySequence: courseChapters.length + 1
          }];
          handlePutData(payload)
        }
        else handleFinalWork()
      }
    }
    setEditModal(Object.keys(chapterinTableEdit).length > 0)
  }, [chapterinTableEdit])

  const handleAssignAssessment = () => {
    dispatch(setCurrentTab(3))
  }

  const handleFinalWork = () => {
    if (!flowDirection) {
      dispatch(setCurrentTab(2))
    } else if (flowDirection) {
      handleTabChange('2')
    }
    // dispatch(clearChapterInEdit())
  }

  const handleCloseModal = () => {
    if (!newChapterFlow)
      handleFinalWork()
  }
  return (
    <>
      {loader && <ModalLoader />}
      <AddChapterContent close={handleCloseModal} getListOfChapters={handleCloseModal} isEdit={editModal} editRecord={chapterinTableEdit} start={start} />
      {(flowDirection && newChapterFlow) && <button className={`${' mr-4 ml-8 primary px-5 _square flex justify-center items-center _add-btn'}`}
        type="button"
        onClick={() => handleAssignAssessment()} > <span className="material-symbols-outlined">
          docs_add_on
        </span>Assign Chapter</button>}
    </>
  )

}

export default AddNewChapterInCourseSide