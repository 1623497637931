import { Tooltip } from 'antd'
import React from 'react'

export default function CompanyDetails(props: any) {
  const { officialEmail, designation, dateOfJoin, status, reportingManager, code } = props.employeeInformation

  return (
    <div>
      <ul>
        <li>
          <div>Employee ID :</div>
          <div>{code ?? '-'}</div>
        </li>
        <li>
          <div>Official Email : </div>
          <div className='break-all'>
            <Tooltip placement="top" title={officialEmail ?? '-'}>
              {officialEmail ?? '-'}
            </Tooltip>
          </div>
        </li>
        <li>
          <div>Grade :</div>
          <div>{designation?.grade?.name ?? '-'}</div>
        </li>
        <li>
          <div>Designation :</div>
          <div>{designation?.name ?? '-'}</div>
        </li>
        <li>
          <div>Department :</div>
          <div>{designation?.department?.description ?? '-'}</div>
        </li>
        <li>
          <div>Date of joining  :</div>
          <div>{dateOfJoin ?? '-'}</div>
        </li>
        <li>
          <div>Status :</div>
          <div>{status?.name ?? '-'}</div>
        </li>
        <li>
          <div>Reporting Manager :</div>
          <div>{reportingManager?.firstName ?? '-'}</div>
        </li>
      </ul>
    </div>
  )
}
