import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  id: any;
}

const initialState: AuthState = {
  id: null,
};

const employeeIdSlice = createSlice({
  name: 'employeeId',
  initialState,
  reducers: {
    setEmpId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    clearEmpId: state => {
      state.id = null;
    },
  },
});

export const { setEmpId, clearEmpId } = employeeIdSlice.actions;
export default employeeIdSlice.reducer;
