import { SALARY_UPDATE } from './salaryMasterType';
export const initialState: any = [];

export const salaryUpdateReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    if (type === SALARY_UPDATE) {
        return {
            data,
        };
    } else {
        return state;
    }
};
