import React, { useEffect, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox } from 'antd';
import { useGetApiRequests } from '../../../common/CommonExport';
import AddEditRender from './AddEditRender';
import { AddEditRolesProps, Role } from './AddEditInterFaces';
import ModalLoader from '../../../components/Loader/ModalLoader';

const AddEditRoles: React.FC<AddEditRolesProps> = ({ selectedRows, isEdit, getRolesList, handleCloseModal }) => {
  const rolesComponent = useGetApiRequests('rolesComponent', 'GET');
  const [initialValues, setInitialValues] = useState<any>({
    roleName: "",
    description: "",
    isFullDataAccess: false,
    permissions: [],
  });
  const [loader, setLoader] = useState(false);
  const [tableRow, setTableRow] = useState<any>([])
  const [createPayload, setCreatePayload] = useState<any>([])
  const [selectedRecordsPermissions, setSelectedRecordsPermissions] = useState<any>([]);

  useEffect(() => {
    setTableRow([]);
    if (!isEdit) {
      fetchData()
    } else {
      setInitialValues({
        description: isEdit ? selectedRows.Description : "",
        roleName: isEdit ? selectedRows.Role : "",
        isFullDataAccess: isEdit ? selectedRows.isFullDataAccess : false,
        rolePermissions: isEdit ? selectedRows.RolePermissions : []
      })
      setSelectedRecordsPermissions(selectedRows.RolePermissions)
      feedEditData()
    }

  }, [selectedRows])
  let roles: Role[] = []
  const permissions = ['Create', 'Edit', 'View', 'Delete'];

  const fetchData = async () => {
    const response: any = await rolesComponent();
    setLoader(true);
    const tempArray: any = []
    if (response.status === 200) {
      const data = response.data?.data;
      roles = data.map((permissionData: any) => ({
        key: permissionData.componentId,
        role: permissionData.name,
      }));
      for (const element of data) {
        const tempObject = {
          "isCreate": false,
          "isDelete": false,
          "isEdit": false,
          "isView": false,
          "component": {
            "name": element.name,
            "componentId": element.componentId
          }
        };
        tempArray.push(tempObject)
      }
      setLoader(false);
      setCreatePayload(tempArray)
      setTableRow(roles)
      selectedRows = [];
    }

  };
  const feedEditData = () => {
    const data: any = selectedRows.RolePermissions
    let currentValue = []

    currentValue = data.map((permissionData: any) => ({
      key: permissionData.component.name,
      role: permissionData.component.name,
    }));
    setTableRow(currentValue)
  }
  const handleCheckboxChange = (record: Role, permission: string, checked: boolean) => {

    if (isEdit) {
      setSelectedRecordsPermissions((prevCreatePayload: any) =>
        prevCreatePayload.map((item: any) => {
          if (item.component.name === record.role) {
            return {
              ...item,
              [`is${permission}`]: checked,
            };
          }
          return item;
        })
      );
    } else {
      setCreatePayload((prevCreatePayload: any) =>
        prevCreatePayload.map((item: any) => {
          if (item.component.name === record.role) {
            return {
              ...item,
              [`is${permission}`]: checked,
            };
          }
          return item;
        })
      );
    }
  };
  const columns = [
    {
      title: '',
      dataIndex: 'role',
      key: 'role',
    },
    ...permissions.map(permission => ({
      title: permission,
      dataIndex: permission,
      key: permission,
      render: (text: string, record: Role, index: number) => {
        const rolePermissionsValue = isEdit ? selectedRecordsPermissions : createPayload
        const payload = rolePermissionsValue.find((p: any) => p.component?.name === record?.role);
        const isChecked = payload?.[`is${permission}`];
        return (
          <Field type="checkbox" name={`${record.role}.${permission}`}>
            {({ field }: FieldProps) => <Checkbox {...field} checked={isChecked} onChange={(e) => handleCheckboxChange(record, permission, e.target.checked)} disabled={record.role === 'Salary History' && permission !== 'View' || record.role === 'Salary Particulars' && permission === 'Delete'} />}
          </Field>
        );

      },
    })),
  ];
  return (
    <div>
      <AddEditRender initialValues={initialValues} isEdit={isEdit} selectedRows={selectedRows} handleCloseModal={handleCloseModal} getRolesList={getRolesList} selectedRecordsPermissions={selectedRecordsPermissions} createPayload={createPayload} columns={columns} tableRow={tableRow} />
      {loader && <ModalLoader />}
    </div>
  )
};

export default AddEditRoles;
