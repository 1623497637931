import { Form } from 'antd';
import { Field, Formik } from 'formik';
import React, { useState } from 'react'
import ImageUploadElms from '../../../Dashboard/AddEmployeeModal/imageUpload/ImageUploadElms';

const CertificateUpload = () => {
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const handleImageDelete = (file: File) => setMediaData('');
  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    setMediaData(formData);

  };
  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form>
          <p className='text-[#D23B7D] font-medium text-[18px] pb-2'>Upload Certificate</p>
          <div className="chapter-field mb-5">
            <label className='addcourse-lable pl-2 text-[#737373] text-[18px]' htmlFor="firstName">Certificate Name</label>
            <Field id="firstName" name="firstName" placeholder="Enter a name" className="chapter-field-grey  " />
          </div>
          <div className='form-item'>
            <div className=' chapter-document'>
              <label className='addcourse-lable pl-2' htmlFor="firstName">Upload Certificate</label>
              <ImageUploadElms onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 10}
                allowedTypes={['image/jpeg', 'image/png', 'application/pdf']} />
            </div>
          </div>
        </Form>
      </Formik>

    </>
  )
}

export default CertificateUpload
