import { Yup } from '../../../common/CommonExport';
import { ErrorMessages } from '../../../constants/messagesAndErrors';

export const CreateDepartmentSchema = Yup.object({
    departmentName: Yup.string().required(ErrorMessages.departmentName),
    hod: Yup.string().required(ErrorMessages.hod),
    email: Yup.string().email(ErrorMessages.invalidEmail).required(ErrorMessages.departmentEmail),
    description: Yup.string().strict(true).required(ErrorMessages.departmentDescription).max(100, ErrorMessages.maxDeptDescriptionAlloed),
});
export const CreateDesignationSchema = Yup.object({
    department: Yup.string().required(ErrorMessages.departmentName),
    grade: Yup.string().required(ErrorMessages.grade),
    designation: Yup.string().required(ErrorMessages.designation),
    roles: Yup.string().strict(true).required(ErrorMessages.Roles),
});
