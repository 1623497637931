import React, { useState } from 'react';
import { Tabs } from 'antd';
import RolesTable from './tables/RolesTable';
import UsersTable from './tables/UsersTable';
import PageTitle from '../../components/title/pageTitle/PageTitle';
import "./layout/userHeader.scss"

const { TabPane } = Tabs;

const Users = () => {
  const [activeTabKey, setActiveTabKey] = useState('1');

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const items = [
    {
      key: '1',
      label: 'Roles & Permissions',
      children: <RolesTable />, // Render the Home component
    },
    {
      key: '2',
      label: 'Accounts',
      children: <UsersTable />, // Render the Organization component
    },
  ];
  return (
    <div className='page-container bg-customBg'>
      <div className="full-width-container">
        <PageTitle title="Users" />

        <div className='bg-white tab-container users-page'>
          <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
            {items.map(item => (
              <TabPane key={item.key} tab={item.label}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Users;
