import React from 'react'

const EmployeeDetails = ({ record }: any) => {
  return (
    <div className='w-full' >
      <div className="name-prefix pl-2">
        <p className='employee_name'>{record?.employeename}</p>
        <span className='employee-designation'>{record?.designation}</span>
      </div>
    </div>
  )
}

export default EmployeeDetails
