import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import EditChapterAndAssessment from "./EditChapterAndAssessment";
import { setCurrentTab } from "../../../redux/slices/courseSlice";
import { clearAssessmentAssignChapterID, clearChapterInEdit, clearCurrentAssessmentTab, clearQuizChapterMapPayload } from "../../../redux/slices/currentCourseDetailSlics";
import { questionCurrentTab } from "../../../redux/slices/multipleQuestions";
import "./AddChapterModal.scss"
const EditChapterAndAssessmentModal = (props: any) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch()
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const [isOpen, setIsOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(1);

  useEffect(() => {
    setShowComponent(reduxToken)
  }, [reduxToken]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handle = () => {
    setIsOpen(false)
    dispatch(setCurrentTab(1))
    dispatch(clearCurrentAssessmentTab())
    dispatch(questionCurrentTab(1))
    dispatch(clearChapterInEdit())
    dispatch(clearQuizChapterMapPayload())
    handleClose()
    dispatch(clearAssessmentAssignChapterID())
  }
  return (
    <Modal data-testid="modal" className='addcourse add-new-course' centered open={isOpen} footer={null} onCancel={() => handle()} maskClosable={false}>
      {(showComponent === 6 || showComponent === 9 ||
        showComponent === 10 || showComponent === 3) && <EditChapterAndAssessment handle={handle} />}
    </Modal>
  )
}

export default EditChapterAndAssessmentModal;