import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { debounce, triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import AssignChapterAndCourse from './AssignChapterAndCourse';
import AssignChapterAndCourseFooter from './AssignChapterAndCourseFooter';
import { setChapterLoad } from '../../../../redux/slices/updateProfileSlice';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const AssignChapter = () => {
  const dispatch = useDispatch()
  const courseChapterAssignmentSave = useGetApiRequests('courseChapterAssignmentSave', 'POST');
  const chapterListByCourseId = useGetApiRequests('chapterListByCourseId', 'GET');
  const chapterUpdateByCourseId = useGetApiRequests('chapterListByCourseId', 'PUT');
  const chapterList = useGetApiRequests('chapterAllList', 'POST');
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const [showComponent, setShowComponent] = useState(1);
  const [leftSide, setLeftSide] = useState<any>([]);
  const [rightSide, setRightSide] = useState<any>([]);
  const [leftSideSelected, setLeftSideSelected] = useState<any>([]);
  const [rightSideSelected, setRightSideSelected] = useState<any>([]);
  const [savedChapters, setSavedChapters] = useState<any>([]);
  const [allChapAndQuiz, setAllChapAndQuiz] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false)

  const onSearch = debounce((searchTerm: string) => {
    if (searchTerm?.length > 3 || searchTerm?.length === 0)
      getChapterList(searchTerm, true)
  }, 300);

  useEffect(() => {
    setShowComponent(reduxToken)
    setLeftSide([])
    setRightSide([])
  }, [reduxToken])

  const handleAlreadySelectedChapter = (data: any) => {
    const updatedLeftSide = data?.filter(
      (leftChapter: any) =>
        !rightSide.some((rightChapter: any) => rightChapter.chapterId === leftChapter.chapterId)
    );
    setLeftSide(updatedLeftSide)
  }

  const getChapterList = (searchTerm: any, status: any) => {
    setLoader(true)
    const payload = {
      searchChapterDto: { name: searchTerm, isActive: true, organisationId: loggedInUser?.organisationId },
      start: 0, limit: 20, sortBy: "chapterId", sortDirection: 'DESC'
    }
    chapterList(payload)
      .then((response: any) => {
        setLoader(false)
        if (status) handleAlreadySelectedChapter(response?.data?.data)
        else getChapterListByCourseId(response?.data?.data)
      })
      .catch((err) => {
        setLoader(false)
        console.error(err);
      });
  }

  const getChapterListByCourseId = (apiData: any) => {
    setLoader(true)
    const pathParams = { id: currentCourseId }
    chapterListByCourseId('', {}, pathParams)
      .then((res: any) => {
        const mainObj = res?.data?.data
        setAllChapAndQuiz(res?.data?.data)
        const extractedChapters = (mainObj || [])
          .filter((item: any) => item.chapter !== null)
          .map(({ chapter }: any) => chapter);
        setSavedChapters(extractedChapters)
        const selected = extractedChapters
        setLeftSideSelected([])
        setRightSide((prevData: any) => [...prevData, ...selected]);
        const updatedLeftSide = apiData.filter((leftChapter: any) => {
          return !selected.some((rightChapter: any) => rightChapter.chapterId === leftChapter.chapterId);
        });
        setLeftSide(updatedLeftSide)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        console.error(err)
      })
  }

  useEffect(() => {
    getChapterList('', false)
  }, [])

  const handleBack = () => {
    dispatch(setChapterLoad())
    if (!newChapterFlow)
      dispatch(setCurrentTab(2))
    else if (newChapterFlow)
      dispatch(setCurrentTab(6))
  }

  const handlePostData = async (payload: any) => {
    setLoader(true)
    const queryParams = { loggedInUserId: loggedInUser?.userId }
    try {
      const response: any = await courseChapterAssignmentSave(payload, queryParams)
      triggerNotification('success', '', response?.data?.message, 'topRight');
      // dispatch(setCurrentTab(2));
      handleBack()
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
      console.error(err)
    }
  }

  const handlePutData = (payload: any) => {
    setLoader(true)
    const queryParams = { loggedInUserId: loggedInUser?.userId }
    const pathParams = { id: currentCourseId }
    chapterUpdateByCourseId(payload, queryParams, pathParams)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        // dispatch(setCurrentTab(2));
        handleBack()
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.data, 'topRight');
      })
  }

  const constructPayload = (items: any) => {
    return items?.map((item: any, index: any) => ({
      course: { courseId: currentCourseId },
      ...(item?.chapter?.chapterId && { chapter: { chapterId: item?.chapter?.chapterId } }),
      displaySequence: item?.displaySequence,
      ...(item?.quiz !== null && { quiz: { quizId: item?.quiz?.quizId } }),
    }));
  }

  const handleSwitchToNextSection = () => {
    const chapterIds = rightSide?.map((item: any) => item.chapterId) || [];
    const payload = constructPayload(allChapAndQuiz)
    const filteredPayload = payload.filter((item: any) => {
      const chapterId = item.chapter?.chapterId;
      return !chapterId || chapterIds.includes(chapterId);
    });
    chapterIds.forEach((id: any, index: any) => {
      if (!filteredPayload.some((item: any) => item.chapter?.chapterId === id)) {
        filteredPayload.push({
          course: { courseId: currentCourseId },
          chapter: { chapterId: id },
          displaySequence: index + 1
        });
      }
    });
    if (savedChapters?.length > 0) {
      handlePutData(filteredPayload)
    } else
      handlePostData(filteredPayload)
  }

  const handleAddNewAssessment = () => {
  }

  return (
    <div className='chapter-assign p-5 edit'>
      {loader && <ModalLoader />}

      <h3 className='pb-3 chapter-title' >List of Chapters</h3>
      <AssignChapterAndCourse nameValue='name' keyValue='chapterId' onSearch={onSearch} leftSide={leftSide} leftSideSelected={leftSideSelected} setLeftSideSelected={setLeftSideSelected}
        rightSide={rightSide} rightSideSelected={rightSideSelected} setRightSideSelected={setRightSideSelected}
        setRightSide={setRightSide} setLeftSide={setLeftSide} />
      {showComponent !== 8 &&
        <div className={showComponent === 10 ? "footer-customize bg-[#ffffff]" : "bg-[#ffffff]"}>
          <AssignChapterAndCourseFooter handleBack={handleBack} handleSwitchToNextSection={handleSwitchToNextSection} handleAddNewAssessment={handleAddNewAssessment} />
        </div>
      }
    </div>
  )
}
export default AssignChapter
