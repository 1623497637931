import React from 'react'
import EmployeeManagement from "../../../assets/images/client-onboard/employee-management.png"
import Appraisal from "../../../assets/images/client-onboard/appraisal .png"
import FileManage from "../../../assets/images/client-onboard/file-management.png"
import Leave from "../../../assets/images/client-onboard/leave.png"
import Map from "../../../assets/images/client-onboard/map.png"
import OnlineLearning from "../../../assets/images/client-onboard/online-learning.png"
import "./Banner.scss"
import Dashboard from "../../../assets/images/client-onboard/dashboard.png"
import LeaveManagement from "../../../assets/images/client-onboard/leave-mgt.png"
import LearningManagement from "../../../assets/images/client-onboard/learning-mgt.png"



const KeyFeatures = () => {
  const KeyFeatureCard = ({ image, title, description }: any) => (
    <div className="w-1/2 pb-20">
      <div className="key-feature_card">
        <img src={image} alt="" />
        <h2 className='font-roboto font-medium text-[20px] text-[#091133]'>{title}</h2>
        <p className='font-roboto font-normal text-[16px] text-[#5D6970]'>{description}</p>
      </div>
    </div>
  );
  const KeyFeatureImages = () => (
    <div className="key-feature-images">
      <div className="key-image-container relative">
        <img className='key-dashboard absolute image-1' src={Dashboard} alt="" />
        <img className='key-dashboard absolute image-2' src={LeaveManagement} alt="" />
        <img className='key-dashboard absolute image-3' src={LearningManagement} alt="" />
      </div>
    </div>
  );
  return (
    <>
      <div className="key-features w-1/2 pb-20">
        <h1 className='text-[#1F1F1F] font-medium text-[48px] font-rubik pb-5'>Key Features </h1>
        <p className='text-[#4B4B4B] text-[24px] font-rubik font-light'>All Aboard enhances every aspect of your organization’s HR operations. It comes with a well thought out and diverse range of features ranging from employee onboarding to analytics to automate tasks and streamline workforce management</p>
      </div>
      <div className="flex">
        <div className='w-2/3 flex flex-wrap '>
          <KeyFeatureCard
            image={EmployeeManagement}
            title="Employee Management"
            description="All Aboard centralizes and organizes essential employee information, facilitating efficient HR administration."
          />
          <KeyFeatureCard
            image={Appraisal}
            title="Appraisal Management"
            description="All Aboard allows organizations to efficiently conduct performance evaluations, set goals, and provide constructive feedback, fostering employee development and aligning individual performance with organizational objectives."
          />
          <KeyFeatureCard
            image={OnlineLearning}
            title="Learning Management"
            description="Create, deliver, and manage educational content to foster a streamlined learning experience."
          />
          <KeyFeatureCard
            image={Leave}
            title="Leave Management"
            description="All Aboard automates and streamlines the process of requesting, approving, and tracking employee leave, reducing administrative workload."
          />
          <KeyFeatureCard
            image={FileManage}
            title="Document Management"
            description="All Aboard Document Management feature simplifies HR-related paperwork, offering a centralized system for secure storage, retrieval, and organization of employee documents, streamlining HR processes and ensuring compliance."
          />
          <KeyFeatureCard
            image={Map}
            title="Geofencing"
            description="All Aboard application enables location-based attendance tracking, ensuring accurate time and attendance records by defining virtual geographic boundaries for employees."
          />
        </div>
        <div className='w-1/3'>
          <KeyFeatureImages />
        </div>
      </div>
    </>
  )
}

export default KeyFeatures
