import React from 'react'
import { ErrorMessage } from '../../../../../../common/CommonExport';
import FloatLabel from '../../../../../../components/FloatLabel';
import { DatePicker } from 'antd';

export default function KpiCalender() {
  return (
    <div className='page-container bg-customBg'>
      <div className=' full-width-container '>
        <div className='kpi-bg bg-white mt-8 pt-7 pb-6 px-10'>
          <div className='flex justify-between'>
            <div>
              <p>BreadCrumb</p>
            </div>
            <div className='form-item edit-personal-details '>
              <FloatLabel label='End Date' name='empName' value="val">
                <DatePicker className='float-control' />
              </FloatLabel>
              <ErrorMessage message='please Enter the first name' />
            </div>
          </div>
          <div className='kpi-calender relative'>
            <div className='kpi-calender_grid grid absolute'>
              <p>01 </p>
              <p>02</p>
              <p>03</p>
              <p>04</p>
              <p>05</p>
              <p>06</p>
              <p>07</p>
              <p>08</p>
              <p>09</p>
              <p>10</p>
              <p>11</p>
              <p>12</p>
              <p>13</p>
              <p>14</p>
              <p>15</p>
              <p>16</p>
              <p>17</p>
              <p>18</p>
              <p>19</p>
              <p>20</p>
              <p>21</p>
              <p>22</p>
              <p>23</p>
              <p>24</p>
              <p>25</p>
              <p>26</p>
              <p>27</p>
              <p>28</p>
              <p>29</p>
              <p>30</p>
              <p>31</p>
            </div>
            <div className='kpi-calender_data'>
              <ul>
                <li className='text-white'>
                  <p>CNW e-Onboarding</p>
                  <div className='flex justify-between text-sm mt-2'>
                    <div>
                      <p>Total Task</p>
                    </div>
                    <div>
                      <p>5</p>
                    </div>
                  </div>
                </li>
                <li className='text-white'>
                  <p>CNW e-Onboarding</p>
                  <div className='flex justify-between text-sm mt-2'>
                    <div>
                      <p>Total Task</p>
                      <p>Total Hour</p>
                    </div>
                    <div>
                      <p>5</p>
                      <p>8.00h</p>
                    </div>
                  </div>
                </li>
                <li className='text-white'>
                  <p>CNW e-Onboarding</p>
                  <div className='flex justify-between text-sm mt-2'>
                    <div>
                      <p>Total Task</p>
                      <p>Total Hours</p>
                    </div>
                    <div>
                      <p>5</p>
                      <p>8.00h</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
