import React from 'react'
import { Input, Radio } from 'antd';
import images from "../../../../assets/images/approved_screen_banner.png"
import CertificateUpload from './CertificateUpload';
import { useDispatch } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';


const UploadCertificate = () => {
  const details = ['Certificate sample 01', 'Certificate sample 01', 'Certificate sample 01']
  const dispatch = useDispatch()
  const onChange = (e: any) => {
    console.log(`checked `);
  };
  const handleBack = () => { dispatch(setCurrentTab(3)) }
  const handleSwitchToNextSection = () => { dispatch(setCurrentTab(4)) }


  return (
    <>
      <div className="chapter-assign w-full flex  h-full">
        <div className="chapter-list w-3/5 h-full ">
          <div className="search-bar p-5 pb-0">
            <div className="search-filter search-assessment pb-3 relative w-full certificate">
              <Input
                placeholder="Search list here"
                className="search w-full bg-white"
              />
              <span className="material-symbols-outlined w-3"> search </span>
            </div>
          </div>
          <div className="w-full flex flex-row chapter-items px-5">
            <div className="w-3/5">
              <ul className='overflow-y-auto h-full'>
                {details.map((item: any, index: any) => (
                  <li key={1}><Radio

                    onChange={() => onChange(item)}
                  >
                    {item}
                  </Radio></li>
                ))}
              </ul>
            </div>
            <div className="w-2/5 pl-2">
              <p>Preview Certificate</p>
              <div className="chapter-image">
                <div className="chapter-certificate">
                  <img className='chapter-certficate-preview rounded-md' src={images} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chapter-upload w-2/5 p-5 pt-0 bg-white flex justify-center ">
          <CertificateUpload />
        </div>
      </div>
      <div className="footer">
        <div className='my-5 flex justify-between grid grid-cols-1 gap-4'>
          <div className="btnGroup flex justify-end">
            <button className='addButton mr-4 primary px-5   cancelElms' type="submit" onClick={handleBack} >Back</button>
            <button className='addButton mr-4 primary px-5 confirmElms' type="submit" onClick={handleSwitchToNextSection}  >Save & Next</button>
          </div>
        </div>
      </div>

    </>
  )
}

export default UploadCertificate
