import React from 'react'
import Visa from "../../../assets/images/client-onboard/payments/visa-card.png"
import AExpress from "../../../assets/images/client-onboard/payments/american-express.png"
import Mastero from "../../../assets/images/client-onboard/payments/mastero-card.png"
import Others from "../../../assets/images/client-onboard/payments/others.png"
import Calender from "../../../assets/images/client-onboard/payments/calender.svg"
import CardImg from "../../../assets/images/client-onboard/payments/card-img.svg"
import Cvv from "../../../assets/images/client-onboard/payments/cvv.svg"
import { Input, Radio } from 'antd'

const PaymentForm = () => {
  const handelPayment = () => { }
  return (
    <>
      <div className="w-1/2 flex justify-center flex-col ">
        <div className="card w-[720px]">
          <p className='text-[#585858] font-roboto text-[26px] font-medium pb-6'>Payment Methods</p>
          <Radio.Group className='pl-4 w-full'
          ><div className="flex justify-between w-full">
              <Radio >
                <div className='flex'>
                  <div className="payment-type text-[20px] font-roboto text-[#6C6C6C;] font-normal"> <p>Credit/Debit Cards</p>
                    <span className='text-[18px] font-roboto text-[#6C6C6C;] font-normal'>Pay with your Credit / Debit Card</span></div>
                </div>
              </Radio >
              <div className="card-type flex">
                <img src={Visa} alt="Visa" className='w-[33px] h-[33px] mr-2' />
                <img src={AExpress} alt="AExpress" className='w-[33px] h-[33px] mr-2' />
                <img src={Mastero} alt="Mastero" className='w-[33px] h-[33px]' />
              </div>
            </div>
            <div className="payments-form p-5">
              <Input size="large" placeholder="Card Number" suffix={<img src={CardImg} />} />
              <div className="flex">
                <div className="w-[60%] pt-5">
                  <Input size="large" placeholder="MM / YY" suffix={<img src={Calender} />} />
                </div>
                <div className="w-[40%] pt-5 ml-4">
                  <Input size="large" placeholder="CVV" suffix={<img src={Cvv} />} />
                </div>
              </div>
            </div>
            <Radio >
              <div className='flex'>
                <div className="payment-type text-[20px] font-roboto text-[#6C6C6C;] font-normal"> <p>Direct Bank Transfer</p>
                  <span className='text-[16px] font-roboto text-[#6C6C6C;] font-normal"'>Make payment direct through bank account</span></div>
              </div>
            </Radio>
            <div className="flex justify-between w-full pt-[35px]">
              <Radio >
                <div className='flex'>
                  <div className="payment-type text-[20px] font-roboto text-[#6C6C6C;] font-normal"> <p>Other Payments Method</p>
                    <span className='text-[16px] font-roboto text-[#6C6C6C;] font-normal"'>Make payment  through Gpay, Paypal, Paytm etc </span></div>
                </div>
              </Radio >
              <div className="card-type flex">
                <img src={Others} alt="Visa" className='h-[35px]' />
              </div>
            </div>
          </Radio.Group>
        </div>
        <button type='submit' className='addButton w-[280px] mt-8 ' onClick={handelPayment}>
          Pay <span>15.99</span>
        </button>
      </div>

    </>
  )
}

export default PaymentForm
