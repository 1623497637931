import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import ModalLoader from '../Loader/ModalLoader';

function DocumentPreviewModal(props: any) {
    const baseUrl = process.env.REACT_APP_GOOGLR_DOCUMENT_VIEW;
    const userData = useSelector((state: any) => state?.loginUserData?.userData?.data);
    const documentPreview = useGetApiRequests('documentPreview', 'POST');
    const documentDownload = useGetApiRequests('documentDownload', 'GET');
    const documentSendEmail = useGetApiRequests('documentSendMail', 'GET');
    const documentStatus = useGetApiRequests('documentStatus', 'GET');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<any>();
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const [loader, setLoader] = useState<boolean>(false);
    const imageData: any = '';

    useEffect(() => {
        setOpen(props?.open);
        initLoad(props, setData, documentPreview, handleClose, setLoader, imageData)
        setIframeUrl(`${baseUrl}${data?.data[0]?.documentPath}&embedded=true`);
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (iframeLoaded) {
                clearInterval(intervalId);
            } else {
                setIframeUrl(`${baseUrl}${data?.data[0]?.documentPath}&embedded=true&timestamp=${Date.now()}`);
                setIframeLoaded(false);
            }
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, [iframeLoaded]);


    const handleClose = () => {
        setOpen(false);
        setIframeLoaded(false);
        props?.setOpenDocumentmodal(false);
    };
    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    return (
        <div>
            <Modal title='Document Preview' centered open={open} onCancel={() => handleClose()} footer={null} maskClosable={false} className='modal-documentPreview relative' >
                {loader && <ModalLoader />}
                {iframeLoaded ? "" : <p>Document is loading...</p>}
                <iframe title='Document Preview' src={iframeUrl} width='100%' height='500px' onLoad={handleIframeLoad} />
                <div className="btn-group">
                    <button onClick={() => handleDownload(data, documentDownload)}>
                        <span className="material-symbols-outlined cursor-pointer" >
                            file_download
                        </span>
                    </button>
                    <button onClick={() => handleSendEmail(props, documentSendEmail, userData, data, documentStatus, handleClose)} className=''>
                        <span className="material-symbols-outlined">
                            send
                        </span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

const handleDownloadDocument = (downloadUrl: any) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = 'document_filename.ext';
    downloadLink.click();
};

export const initLoad = async (props: any, setData: any, documentPreview: any, handleClose: any, setLoader: any, imageData: any) => {
    try {
        setLoader(true)
        const queryParams = {
            employeeId: props?.record?.key,
            documentCode: props?.currentData?.code,
        };
        const formData = new FormData();
        formData.append('file', imageData);
        const headers = { 'Content-Type': 'multipart/form-data' };
        const res: any = await documentPreview(formData, queryParams, {}, headers);
        setData(res?.data);
        setLoader(false)
    } catch (err: any) {
        handleClose()
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message ?? err?.response?.data?.status?.message, 'topRight');
    }
};

export const handleDownload = async (data: any, documentDownload: any) => {
    const param = {
        documentId: data?.data[0]?.documentDetailId,
        end: 'download',
    };
    try {
        const res: any = await documentDownload('', {}, param);
        handleDownloadDocument(res?.data?.data);
    } catch (err) {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
    }
};

export const handleSendEmail = async (props: any, documentSendEmail: any, userData: any, data: any, documentStatus: any, handleClose: any) => {
    let employeeEmail = data?.data[0]?.employee?.officialEmail
    const queryParams = {
        employeeId: props?.record?.key,
        documentDetailId: data?.data[0]?.documentDetailId,
        emails: employeeEmail,
    };
    try {
        const res: any = await documentSendEmail('', queryParams);
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        // handleDocumentStatus(props?.record, props, documentStatus);
        props?.setCurrentStepRecord(2)
        handleClose()
    } catch (err) {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
    }
};

export const handleDocumentStatus = async (record: any, props: any, documentStatus: any) => {
    const pathParams = {
        id: record.key,
        end: 'document-status',
    };
    try {
        const res = await documentStatus('', {}, pathParams);
        if (res?.status?.success?.Success)
            props?.setCurrentStep(2);
    } catch (err) {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
    }
};

export default DocumentPreviewModal;
