import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import { employeeProfileOtherInitialValues, employeeProfileOtherSchema } from '../../../../../BasicForm/empInfoFormSchema';
import EmployeePreviousEmploymentStatus from '../../../../../BasicForm/employeePreviousEmploymentStatus';
import EmployeeDependentInfo from '../../../../../BasicForm/employeeDependentInfo';
import EmployeeBankDetails from '../../../../../BasicForm/employeeBankDetails';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const PersonalDetail = (props: any) => {
  const { id } = useParams();
  const updateEmployeeDetails = useGetApiRequests('companyEmployeeDetails', 'PUT');
  const employeeDetails = useGetApiRequests('employeeDetails', 'GET');
  const [initialvalues, setInitialvalues] = useState<any>();

  const onSubmit = (values: any) => {
    const payloadObj = values
    payloadObj.employeeAdditionalInformationDto.employeeBankDetailsDto.employeeDto = { employeeId: id }
    payloadObj.employeeAdditionalInformationDto?.employeeDependentDto?.forEach((item: any) => {
      item.employeeDto = { employeeId: id };
      delete item.key;
    });
    payloadObj.employeeAdditionalInformationDto?.employeePreviousEmployment?.forEach((item: any) => {
      item.employeeDto = { employeeId: id };
      delete item.key;
      delete item.employeeTypeId
    });
    const formData = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    updateEmployeeDetails(formData, { employeeInformationDto: values }, { id: id }, headers)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        props?.handleCloseModal()
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      })
      .finally(() => {
        props?.setLoader(false)
      })
  }

  const dataWithKeysAndEmptyStrings = (data: any) => data?.map((item: any, index: any) => {
    const modifiedItem = { ...item, key: index + 1 };
    for (const prop in modifiedItem) {
      if (modifiedItem[prop] === null) {
        modifiedItem[prop] = "";
      }
    }
    return modifiedItem;
  });

  const handleInitialValues = (res: any, dependent: any, values: any) => {
    const obj = values
    obj.employeeAdditionalInformationDto.employeeDependentDto = dataWithKeysAndEmptyStrings(dependent)
    if (res?.employeeBankDetails !== null)
      obj.employeeAdditionalInformationDto.employeeBankDetailsDto = res?.employeeBankDetails
    if (res?.employeePreviousEmployment?.length > 0)
      obj.employeeAdditionalInformationDto.employeePreviousEmployment = dataWithKeysAndEmptyStrings(res?.employeePreviousEmployment)
    setInitialvalues(obj);
  }

  const handleMarriedStatus = (status: any, values: any) => {
    let employeeDependentDto = [];
    if (status?.data?.isMarried) {
      employeeDependentDto = [{
        key: 1,
        relationship: 'Spouse',
        dependentName: '',
        dateOfBirth: '',
      }];
    } else {
      employeeDependentDto = [
        {
          key: 1,
          relationship: 'Father',
          dependentName: '',
          dateOfBirth: '',
        },
        {
          key: 2,
          relationship: 'Mother',
          dependentName: '',
          dateOfBirth: '',
        }
      ];
    }
    if (status?.data?.employeeDependent?.length > 0) {
      employeeDependentDto = status?.data?.employeeDependent
    }
    handleInitialValues(status?.data, employeeDependentDto, values)
  }


  useEffect(() => {
    props?.setLoader(true)
    employeeDetails('', {}, { id })
      .then((res: any) => {
        employeeProfileOtherInitialValues.employeeAdditionalInformationDto.employeePreviousEmployment.forEach((employment: any) => {
          employment.employeeTypeId = res?.data?.data?.employeeType?.employeeTypeId;
        });
        handleMarriedStatus(res?.data, employeeProfileOtherInitialValues)
      })
      .catch((err: any) => {
        console.error(err)
      })
      .finally(() => {
        props?.setLoader(false)
      })
  }, [])

  const handleAddRelation = (values: any) => {
    const extraObject = {
      key: values.depRelations?.length + 1,
      relationship: '',
      dependentName: '',
      dateOfBirth: '',
    }
    setInitialvalues({
      ...values,
      employeeAdditionalInformationDto: {
        ...values.employeeAdditionalInformationDto,
        employeeDependentDto: [...values.employeeAdditionalInformationDto.employeeDependentDto, extraObject],
      },
    });
  }

  const handleRowDelete = (indexVal: any, values: any) => {
    setInitialvalues((prevValues: any) => {
      const updatedEmployeeDependentDto = values.employeeAdditionalInformationDto.employeeDependentDto.filter((item: any, index: any) => index !== indexVal);
      return {
        ...values,
        employeeAdditionalInformationDto: {
          ...values.employeeAdditionalInformationDto,
          employeeDependentDto: updatedEmployeeDependentDto,
        },
      };
    });
  }

  return (
    <>
      {initialvalues && (
        <Formik
          initialValues={initialvalues}
          validationSchema={employeeProfileOtherSchema}
          onSubmit={onSubmit}
          values={initialvalues}
          enableReinitialize={true}
        >
          {({ handleSubmit, values, errors, setFieldValue, setFieldTouched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <EmployeePreviousEmploymentStatus values={values} setFieldValue={setFieldValue} />
                <EmployeeDependentInfo setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} handleRowDelete={handleRowDelete} handleAddRelation={handleAddRelation} />
                <EmployeeBankDetails setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} />
                <div className='my-5 flex justify-end'>
                  <button className='addButton mr-4 secondary' type="button" onClick={() => props?.handleCloseModal()}>Cancel</button>
                  <button className='addButton mr-4 primary' type="submit">Update</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default PersonalDetail