import { APIConfigURLs } from '../apiInterfaceType/apiInterfaceType';
const GET_API_URL: APIConfigURLs = {
    dashboard: {
        url: '/hrm/employee-dashboard/',
    },
    department: {
        url: '/hrm/department/range',
    },
    designation: {
        url: '/hrm/designation/range',
    },
    hodList: {
        url: '/hrm/employee/keyvalue/range',
    },
    getDepartment: {
        url: '/hrm/department/key-value',
    },
    getDepartmentHOD: {
        url: '/hrm/employee/keyvalue/range',
    },

    documentStatus: {
        url: '/hrm/employee',
    },
    documentPreview: {
        url: '/hrm/employee/autoDocumentUpload',
    },
    documentDownload: {
        url: '/hrm/employee/documentDetail',
    },
    documentSendMail: {
        url: '/hrm/employee/sendDocumentDetailToEmployee',
    },
    designationDepartmentList: {
        url: '/hrm/department/key-value',
    },
    designationGrade: {
        url: '/hrm/employee/designation-grade',
    },
    user: {
        url: '/hrm/user/range',
    },
    role: {
        url: '/hrm/role/listByRange',
    },
    getNotifications: {
        url: '/hrm/notification/range',
    },
    getOrganisationDetails: {
        url: '/hrm/organisation/',
    },
    roles: {
        url: '/hrm/role/',
    },
    rolesComponent: {
        url: '/hrm/role/components',
    },
    employeeTypes: {
        url: '/hrm/employee/type/range',
    },
    departmentList: {
        url: '/hrm/department/key-value',
    },
    organisationList: {
        url: '/hrm/organisation/key-value',
    },
    listByCategory: {
        url: '/hrm/master/status/listByCategory',
    },
    employeeList: {
        url: '/hrm/employee/keyvalue/range',
    },
    employeedeprovision: {
        // url: '/hrm/employeedeprovision/namefilter',
        url: '/hrm/employee/deprovision/filter',
    },
    designationList: {
        url: '/hrm/designation/key-value',
    },
    roleTypeList: {
        url: '/hrm/role/Roletypelist',
    },
    roleEmailList: {
        url: '/hrm/employee/keyvalue/range',
    },
    projectsList: {
        url: '/jira-integration/jira/project',
    },
    salaryMaster: {
        url: '/hrm/salarycomponent/list',
    },
    internSalaryMaster: {
        url: '/hrm/employee/type',
    },
    assetsDetails: {
        url: '/hrm/asset/details',
    },
    assetsAllocationStatus: {
        url: '/hrm/master/status/listByCategory',
    },
    empAssetsDetails: {
        url: '/hrm/asset/employee',
    },
    employeeDetails: {
        url: '/hrm/employee/details',
    },
    employeeType: {
        url: '/hrm/employee/type',
    },
    salaryLabelList: {
        url: '/hrm/salarycomponent/list',
    },
    getSalaryRange: {
        url: '/hrm/employee',
    },
    documentType: {
        url: '/hrm/employee/document-type/List',
    },
    employeeFootPrintDetails: {
        url: '/hrm/employee',
    },
    tokenValidation: {
        url: '/hrm/employee/additionalinfo/token-validation',
    },
    getAppraisal: {
        url: '/hrm/review',
    },
    getquestions: {
        url: '/hrm/probation',
    },
    editAssetsDetails: {
        url: '/hrm/asset',
    },
    getEmployeeCtc: {
        url: '/hrm/review',
    },
    getInternSalary: {
        url: '/hrm/employee/type',
    },
    getDocumentDetails: {
        url: '/hrm/employee/documentDetail/previewDocumentDetail',
    },
    courseListDashboard: {
        url: '/course-dashboard/',
        baseURL: '/elms-app-api/v1',
    },
    getReviewDetails: {
        url: '/hrm/review',
    },
    getBonusDetails: {
        url: '/hrm/employee-bonus',
    },
    chapterListByCourseId: {
        url: '/course-chapter-assignment/course',
        baseURL: '/elms-app-api/v1',
    },
    quizByQuizId: {
        url: '/quiz',
        baseURL: '/elms-app-api/v1',
    },
    getCourseById: {
        url: '/course',
        baseURL: '/elms-app-api/v1',
    },
    getPolicyDetails: {
        url: '/leave/policy/integration',
        baseURL: '/admin/leave-management/v1',
    },
    allEmployeeKpi: {
        url: '/jira-integration/jira/pullrequest/codequality/kpi',
    },
    userLoginDetails: {
        url: '/hrm/user/employee',
    },
    getAllCertificate: {
        url: '/certificate',
        baseURL: '/elms-app-api/v1',
    },
    assignCertificateToCourse: {
        url: '/coursecertificates',
        baseURL: '/elms-app-api/v1',
    },
    certificateDBFormFields: {
        url: '/certificate/db-fields',
        baseURL: '/elms-app-api/v1',
    },
    getLeaveReport: {
        url: '/leave-requests/view',
        baseURL: '/admin/leave-management/v1',
    },
    leaveReportDownload: {
        url: '/leave-requests/download',
        baseURL: '/admin/leave-management/v1',
    },
    leaveReportHistory: {
        url: '/leave-report/range',
        baseURL: '/admin/leave-management/v1',
    },
    getHolidayList: {
        url: '/holiday-detail/list/',
        baseURL: '/admin/leave-management/v1',
    },
    questionById: {
        url: '/questionaire',
        baseURL: '/elms-app-api/v1',
    },
    assignedEmployeeList: {
        url: '/employee/list',
        baseURL: '/elms-app-api/v1',
    },
    mapCertificateToCourse: {
        url: '/coursecertificate',
        baseURL: '/elms-app-api/v1',
    },
    getEmployeeProgresscourse: {
        url: '/employee-progress/course',
        baseURL: '/elms-app-api/v1',
    },
    getEmployeeProgressLearningPath: {
        url: '/employee-progress/learning-path',
        baseURL: '/elms-app-api/v1',
    },
    getIndustryTypeList: {
        url: `/hrm/organisation/types`,
    },
    verifyDomainName: {
        url: `/hrm/organisation/subdomain`,
    },
    salaryFormView: {
        url: `hrm/employee/salary`,
    },
    getNotificationList: {
        url: '/hrm/employee-notification-view/',
    },
    sendBasicInfoMail: {
        url: '/hrm/employee/send-basic-info-employee',
    },
    getResignationReasons: {
        url: '/hrm/master/status/listByCategory',
    },
    getDeprovisionApprovalList: {
        url: `/hrm/employee`,
    },
    getLearningPath: {
        url: '/learning-path-level/learning-path',
        baseURL: '/elms-app-api/v1',
    },
};

export default GET_API_URL;
