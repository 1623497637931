import { triggerNotification } from "../../../../../../common/CommonExport";
import { retrieveData } from '../../../../../../storage/Storage';

export const handleAppraisalsApiCall = async (tempQuestionArray: any, incrementEligible: any, id: any, createReview: any, handleCloseModal: any, setChangeCtcModal: any, getReviewsApi: any, setLoader: any, probationExtension: any, isView: any, updateReview: any, employeeReviewId: any, userId: any) => {
  setLoader(true);
  let requestPayload: any = {};
  let sendingPayload: any = {}
  let questionOptionPayload: any = [];
  let queryParams: any;
  let questionKeys: any = Object.keys(tempQuestionArray);
  questionKeys.forEach((item: any) => {
    let editedKey = item.split("_").join("").toLowerCase();
    if (editedKey === "appraiserfeedback") {
      tempQuestionArray &&
        Object.keys(tempQuestionArray[item]).forEach((data: any) => {
          questionOptionPayload.push({
            otherText: tempQuestionArray[item][data].answer,
            questionaire: {
              questionaireId: tempQuestionArray[item][data].questionaireId,
            },
          });
        });
    } else {
      tempQuestionArray?.[item]?.forEach((data: any) => {
        questionOptionPayload.push({
          performanceRatingScale: {
            performanceRatingScaleId: data?.range,
          },
          otherText: data?.answer,
          questionaire: {
            questionaireId: data?.questionaireId,
          },
        });
      });

    }
  });
  requestPayload.isEligibleForIncrement = incrementEligible;
  if (isView) {
    requestPayload.employeeReviewId = employeeReviewId
  }
  requestPayload.employeeReviewDetails = questionOptionPayload;
  requestPayload.employee = {
    employeeId: parseInt(id)
  }
  if (retrieveData('reviewtype', true) === 'Annual' || retrieveData('reviewtype', true) === 'Half Yearly') {

    requestPayload.type = retrieveData('reviewtype', true)
    sendingPayload.employeeReview = requestPayload;
    sendingPayload.userId = userId !== null ? parseInt(userId) : retrieveData('userId', true);
    queryParams = {
      employeeReviewAndDetailsDtoJson: sendingPayload
    }

  } else {
    const revObj: any = retrieveData('reviewObject', true)
    requestPayload.type = revObj.label
    if (revObj.label === 'Probation Extension') {
      requestPayload.nextProbationEndDate = probationExtension
    }
    sendingPayload.employeeReview = requestPayload;
    sendingPayload.userId = userId !== null ? parseInt(userId) : retrieveData('userId', true);
    sendingPayload.status = {
      statusId: parseInt(retrieveData('reviewtype', true))
    }

    queryParams = {
      employeeReviewAndDetailsDtoJson: sendingPayload
    }

  }

  if (!isView) {
    try {
      const res: any = await createReview(queryParams);
      if (res?.data?.status?.success === 'Success') {
        await getReviewsApi();
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setChangeCtcModal(false);
        setLoader(false);
        handleCloseModal();
      } else {
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setLoader(false);
      }
    } catch (error: any) {
      triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      setLoader(false)
    }
  } else {
    try {
      const res: any = await updateReview(queryParams);
      if (res?.data?.status?.success === 'Success') {
        await getReviewsApi();
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setChangeCtcModal(false);
        setLoader(false);
        handleCloseModal();
      } else {
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setLoader(false);
      }
    } catch (error: any) {
      triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      setLoader(false)
    }
  }

};
