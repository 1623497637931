import React, { useEffect, useState } from 'react'
import { DatePicker, Input } from 'antd'
import { useSelector } from 'react-redux';
import { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;
const LearningPathFilter = ({ setLearningPathName, learningPathName, setStartDate, setEndDate, setIsSubmit }: any) => {
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs] | null>(null);

  const currentTab = useSelector((state: any) => !state?.courseFilterSlice?.eventData);
  useEffect(() => {
    setIsFilter(currentTab)
  }, [currentTab])

  const handleStateAndEndDate = (dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]) => {
    const [startDate, endDate]: any = dates;
    setStartDate(startDate.format('YYYY-MM-DD'));
    setEndDate(endDate.format('YYYY-MM-DD'))
    setIsSubmit(false)
  };

  const handleLearningPath = (event: any) => {
    setLearningPathName(event.target.value);
    setIsSubmit(false)
  };

  const handleRemoveData = () => {
    setPickerValue(null);
    setLearningPathName('');
    setIsSubmit(true)
  };
  const handleApiCall = () => {
    setIsSubmit(true);
  };

  return (
    <div className={`grid grid-cols-6 p-4 mt-4 leave-report-input bg-[#F5F5F5] progress-report gap-5 pt-4${isFilter ? ' add-animation' : ''}`}>
      <div >
        <p className='text-[#D34A7C] font-medium' >Learning Path Name</p>
        <Input placeholder="Learning Pathname" value={learningPathName} onChange={(e: any) => handleLearningPath(e)} />
      </div>
      <div>
        <p className='text-[#D34A7C] font-medium'>Enrolled date</p>
        <RangePicker value={pickerValue}
          onChange={(dates: any, dateStrings: any) => {
            handleStateAndEndDate(dates, dateStrings);
            setPickerValue(dates);
          }} />
      </div>
      <div></div>
      <div></div>
      <div className='flex items-center justify-end pt-3'>
        <div className=' mr-5'>
          <button type='submit' className='addButton  primary pr-4 justify-center' onClick={handleApiCall} >Submit</button>
        </div>
        <div >
          <button type='submit' className='addButton  secondary' onClick={handleRemoveData} >Clear</button>
        </div>
      </div>
    </div>
  )
}
export default LearningPathFilter
