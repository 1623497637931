import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DynamicTable from '../../../../components/CommonTable/DynamicTable';
import ChapterButton from '../../../../components/AddChapterButton/ChapterButton';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import { setAllChapterList, setAssessmentAssignChapterID, setChapterInEdit } from '../../../../redux/slices/currentCourseDetailSlics';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import EditChapterAndAssessmentModal from '../../CourseDetails/EditChapterAndAssessmentModal';
import DeleteConfirmationModal from '../../../Organization/DeleteConfirmationModal';
import ModalLoader from '../../../../components/Loader/ModalLoader';
interface DataType {
  key: string;
  name: string;
  age: string;
  address: any;
  action: any
}

const ChapterTable = () => {

  const dispatch = useDispatch()
  const chapterListByCourseId = useGetApiRequests('chapterListByCourseId', 'GET');
  const chapterUpdateByCourseId = useGetApiRequests('chapterListByCourseId', 'PUT');
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const chapterReLoad = useSelector((state: any) => state?.profileReload?.chapterLoad);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const [chapterList, setChapterList]: any = useState([]);
  const [allChapter, setAllChapter]: any = useState<any>([]);
  const [loader, setLoader] = useState<any>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [deleteMessage, setDeleteMessage] = useState<any>('')
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [deleteRecord, setDeleteRecord] = useState<any>({});

  const handlePutData = async (payload: any) => {
    setLoader(true);
    try {
      const queryParams = { loggedInUserId: loggedInUser?.userId };
      const pathParams = { id: currentCourseId };
      const res: any = await chapterUpdateByCourseId(payload, queryParams, pathParams);
      if (res?.data?.status === 200)
        getChapterListByCourseId();
      triggerNotification('success', '', res?.data?.message, 'topRight');
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
      setLoader(false);
    }
  };

  const singleDeleteFunction = (record: any) => {
    if (employeeDetail?.courseStatus === 'publish') {
      setDeletePopUp(true)
      setDeleteMessage('Course Is in Publish Mode Can not able to edit')
    } else if (employeeDetail?.courseStatus !== 'publish') {
      setDeleteRecord(record)
      setDeleteMessage('Are You Sure to delete the Chapter?')
      setDeleteConfirm(true)
    }
  }

  const editRowFunction = (record: any) => {
    if (employeeDetail?.courseStatus === 'publish') {
      setDeletePopUp(true)
    } else if (employeeDetail?.courseStatus !== 'publish') {
      const payload = {
        ...record,
        name: record?.chaptername,
        key: record?.chapterId,
        chapterZipName: record?.chapterContent
      }
      dispatch(setChapterInEdit(payload))
      dispatch(setCurrentTab(6))
      if (flowDirection) {
        dispatch(setAssessmentAssignChapterID(record))
        setModalOpen(true)
      }
    }
  }
  const handleCloseDelete = () => setDeletePopUp(false);

  const handleDeleteSelected = () => {
    handleCloseDelete()
  };
  const getChapterListByCourseId = async () => {
    setLoader(true);
    try {
      const pathParams = { id: currentCourseId };
      const res: any = await chapterListByCourseId('', {}, pathParams);
      dispatch(setAllChapterList(res?.data?.data))
      setAllChapter(res?.data?.data);
      const reformedEmployeeData = (res?.data?.data || [])
        .filter((item: any) => item?.chapter !== null)
        .map((item: any, index: any) => ({
          key: index + 1,
          chapterId: item?.chapter?.chapterId,
          chaptername: item?.chapter?.name,
          chapterContent: item?.chapter?.chapterZipName,
          courseChapterAssignmentId: item?.courseChapterAssignmentId,
          isQuiz: item?.quiz !== null ? 'Yes' : 'No',
          quiz: item?.quiz,
        }));
      setLoader(false);
      setChapterList(reformedEmployeeData);
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
      setLoader(false);
    }
  };

  useEffect(() => {
    getChapterListByCourseId()
  }, [chapterReLoad])

  const columns: ColumnsType<DataType> = [
    {
      title: 'S.No',
      dataIndex: 'key',
      key: 'key',
      width: '60%',
    },
    {
      title: 'Chapter Name',
      dataIndex: 'chaptername',
      key: 'chaptername',
      width: '30%',
      render: (_: any, record: any) => (
        <div className='flex'>
          <p className='text-[#D34A7C]'>{record.chaptername}</p>
        </div>
      ),

    },
    {
      title: `${flowDirection ? 'Assessment' : 'Chapter Content'}`,
      dataIndex: `${flowDirection ? 'isQuiz' : 'chapterContent'}`,
      key: `${flowDirection ? 'isQuiz' : 'chapterContent'}`,
      width: '15%'

    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      render: (_: any, record: any) => (
        <div className='flex'>
          <Tooltip
            placement="top"
            title={
              employeeDetail?.courseStatus !== 'publish'
                ? "Edit"
                : "Edit Not Allowed"
            }
          >
            <span
              className={`material-symbols-outlined cursor-pointer hover:text-primary text-lg pr-3 ${employeeDetail?.courseStatus === 'publish' ? 'text-gray-400' : ''
                }`}
              onClick={() => {
                if (employeeDetail?.courseStatus !== 'publish') {
                  editRowFunction(record);
                }
              }}
            >
              Edit
            </span>
          </Tooltip>
          {/* <Tooltip placement="top" title="Delete">
            <span className="material-symbols-outlined cursor-pointer hover:text-primary text-lg" onClick={() => singleDeleteFunction(record)}>
              Delete
            </span>
          </Tooltip> */}
          <Tooltip
            placement="top"
            title={
              employeeDetail?.courseStatus !== 'publish'
                ? "Delete"
                : "Delete Not Allowed"
            }
          >
            <span
              className={`material-symbols-outlined cursor-pointer hover:text-primary text-lg ${employeeDetail?.courseStatus === 'publish' ? 'text-gray-400' : ''
                }`}
              onClick={() => {
                if (employeeDetail?.courseStatus !== 'publish') {
                  singleDeleteFunction(record);
                }
              }}
            >
              Delete
            </span>
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleDeleteSelectedData = () => {
    const filteredData = allChapter?.filter((item: any) => { return item?.courseChapterAssignmentId !== deleteRecord?.courseChapterAssignmentId })
    handlePutData(filteredData)
    handleCloseDeleteData()
  }

  const handleCloseDeleteData = () => setDeleteConfirm(false)

  return (
    <div className='course-table min-h-[470px] max-h[470px] overflow-y-auto   bg-[#F5F5F5] _customize p-4'>
      {loader && <ModalLoader />}
      <div className="flex flex-col bg-white _dashboard">
        <DynamicTable columns={columns} data={chapterList} />
      </div>
      {(chapterList?.length === 0 && !flowDirection) &&
        <div className="course-button">
          <ChapterButton />
        </div>
      }
      {deleteConfirm && <DeleteConfirmationModal open={deleteConfirm} handleDeleteSelected={handleDeleteSelectedData} handleCloseDelete={handleCloseDeleteData} type='Delete' deleteMessage={deleteMessage} />}
      {modalOpen && <EditChapterAndAssessmentModal open={modalOpen} handleClose={handleClose} />}
      {deletePopUp && <DeleteConfirmationModal contentChange={true} open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
    </div>
  )
}

export default ChapterTable
