import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  unassignedCourses: any, recentlyAddedCourses: any, frequentlyAssignedCourses: any
}

const initialState: AuthState = {
  unassignedCourses: [],
  recentlyAddedCourses: [],
  frequentlyAssignedCourses: []
};

const CourseListSlice = createSlice({
  name: 'courseList',
  initialState,
  reducers: {
    setUnassignedCoursesList: (state, action: PayloadAction<any>) => {
      state.unassignedCourses = action.payload;
    },
    setRecentlyAddedCoursesList: (state, action: PayloadAction<any>) => {
      state.recentlyAddedCourses = action.payload;
    },
    setFrequentlyAssignedCoursesList: (state, action: PayloadAction<any>) => {
      state.frequentlyAssignedCourses = action.payload;
    },
    clearUnassignedList: state => {
      state.recentlyAddedCourses = [];
    },
    clearRecentlyAddedCourseList: state => {
      state.unassignedCourses = [];
    },
    clearFrequentlyAssignedCoursesList: state => {
      state.frequentlyAssignedCourses = [];
    },
  },
});

export const { setUnassignedCoursesList, setRecentlyAddedCoursesList, setFrequentlyAssignedCoursesList, clearUnassignedList, clearRecentlyAddedCourseList, clearFrequentlyAssignedCoursesList } = CourseListSlice.actions;
export default CourseListSlice.reducer;
