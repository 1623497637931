import React from 'react'
import { Input } from 'antd'
import { ErrorMessage } from 'formik'
import UploadImages from './UploadImages';

const MatchTheFollowing = ({ values, setFieldValue, mediaData, setMediaData, previewImage }: any) => {
  return (
    <>
      <div className="flex">
        <div className="w-3/5">
          <div className="addcourse-field pt-5">
            <p>Question</p>
            <Input.TextArea
              name="question"
              value={values.question}
              placeholder="Question"
              onChange={(e: any) => setFieldValue('question', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="question" component="div" className="error-message" />
          </div>
          <div className="w-full flex pt-3">
            <div className="chapter-field w-1/2 pr-2">
              <Input
                type="text"
                className="chapter-field-grey  "
                name="matchingHeader1"
                placeholder="Column A Name"
                value={values?.matchingHeader1}
                onChange={(e) => setFieldValue('matchingHeader1', e.target.value)}
              />
              <ErrorMessage name="matchingHeader1" component="div" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field ml-1 w-full pl-2">
                <Input
                  type="text"
                  className="chapter-field-grey  "
                  name="matchingHeader2"
                  placeholder="Column B Name"
                  value={values?.matchingHeader2}
                  onChange={(e) => setFieldValue('matchingHeader2', e.target.value)}
                />
                <ErrorMessage name="matchingHeader2" component="div" className="error-message" />
              </div>
            </div>
          </div>

          <div className="w-full flex pt-3">
            <div className="chapter-field w-1/2 pr-2">
              <Input
                type="text"
                className="chapter-field-grey  "
                name="pair1.option"
                placeholder="Enter Choice 1"
                value={values?.pair1?.option}
                onChange={(e) => setFieldValue('pair1.option', e.target.value)}
              />
              <ErrorMessage name="pair1.option" component="div" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field ml-1 w-full pl-2">
                <Input
                  type="text"
                  className="chapter-field-grey  "
                  name="pair1.optionMatching"
                  placeholder="Enter Choice 1 pair"
                  value={values?.pair1?.optionMatching}
                  onChange={(e) => setFieldValue('pair1.optionMatching', e.target.value)}
                />
                <ErrorMessage name="pair1.optionMatching" component="div" className="error-message" />
              </div>
            </div>
          </div>

          <div className="w-full flex pt-2">
            <div className="chapter-field w-1/2 pr-2">
              <Input
                type="text"
                className="chapter-field-grey  "
                name="pair2.option"
                placeholder="Enter Choice 2"
                value={values?.pair2?.option}
                onChange={(e) => setFieldValue('pair2.option', e.target.value)}
              />
              <ErrorMessage name="pair2.option" component="div" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field ml-1 w-full pl-2">
                <Input
                  type="text"
                  className="chapter-field-grey  "
                  name="pair2.optionMatching"
                  placeholder="Enter Choice 2 pair"
                  value={values?.pair2?.optionMatching}
                  onChange={(e) => setFieldValue('pair2.optionMatching', e.target.value)}
                />
                <ErrorMessage name="pair2.optionMatching" component="div" className="error-message" />
              </div>
            </div>
          </div>

          <div className="w-full flex pt-3">
            <div className="chapter-field w-1/2 pr-2">
              <Input
                type="text"
                className="chapter-field-grey  "
                name="pair3.option"
                placeholder="Enter Choice 3"
                value={values?.pair3?.option}
                onChange={(e) => setFieldValue('pair3.option', e.target.value)}
              />
              <ErrorMessage name="pair3.option" component="div" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field ml-1 w-full pl-2">
                <Input
                  type="text"
                  className="chapter-field-grey  "
                  name="pair3.optionMatching"
                  placeholder="Enter Choice 3 pair"
                  value={values?.pair3?.optionMatching}
                  onChange={(e) => setFieldValue('pair3.optionMatching', e.target.value)}
                />
                <ErrorMessage name="pair3.optionMatching" component="div" className="error-message" />
              </div>
            </div>
          </div>

          <div className="w-full flex pt-3">
            <div className="chapter-field w-1/2 pr-2">
              <Input
                type="text"
                className="chapter-field-grey  "
                name="pair4.option"
                placeholder="Enter Choice 4"
                value={values?.pair4?.option}
                onChange={(e) => setFieldValue('pair4.option', e.target.value)}
              />
              <ErrorMessage name="pair4.option" component="div" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field ml-1 w-full pl-2">
                <Input
                  type="text"
                  className="chapter-field-grey  "
                  name="pair4.optionMatching"
                  placeholder="Enter Choice 4 pair"
                  value={values?.pair4?.optionMatching}
                  onChange={(e) => setFieldValue('pair4.optionMatching', e.target.value)}
                />
                <ErrorMessage name="pair4.optionMatching" component="div" className="error-message" />
              </div>
            </div>
          </div>
        </div>
        <div className='w-2/5 pt-5 pl-8 upload-error-tooltip multi-ques'>
          <label className='addcourse-lable pl-2 arrange-in-order' htmlFor="firstName">Question Image</label>
          <UploadImages setMediaData={setMediaData} mediaData={mediaData} previewImage={previewImage} />
        </div>
      </div>

    </>
  )
}

export default MatchTheFollowing
