import * as Yup from 'yup';

const leavePolicyValidationSchema = Yup.object().shape({
    policyName: Yup.string().required('Leave name is required'),
    employmentTypeId: Yup.string().required('Please provide employment type'),
    unit: Yup.string().required('Unit is required'),
    effectiveAfterValue: Yup.number().required('Effective after value is required'),
    effectiveAfterUnit: Yup.string().required('Effective after unit is required'),
    effectiveFrom: Yup.string().required('Effective from is required'),
    accrualUnit: Yup.string().required('Accrual unit type is required'),
    accrualUnitValue: Yup.string().required('Accrual unit value is required'),
    openingBalance: Yup.string().required('Opening balance is required'),
    maximumBalance: Yup.string().required('Maximum balance is required'),
    restriction: Yup.boolean(),
    isCarryOverAllowed: Yup.boolean(),
    maxHrPerDay: Yup.string().test('is-hours', 'Number of hours is required when unit is hours', function (value) {
        if (this.parent && this.parent.unit === 'hours') {
            return value !== undefined && value !== '';
        }
        return true;
    }),
});

export default leavePolicyValidationSchema;
