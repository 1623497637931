import React, { useState } from 'react'
import { Modal, Select, Input } from 'antd';
import ImageUploadElms from '../../../Dashboard/AddEmployeeModal/imageUpload/ImageUploadElms';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import { certificateType } from '../../../Organization/TableColumn';
import Loader from '../../../../components/Loader/Loader';
import AddCertificateFormikFields from '../AddCertificateFormikFields';
import { generateYupSchemas, generateInitialValues, clearStates } from './CertificateDynamicSchema';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const AddCertificateMain = (props: any) => {
  const dispatch = useDispatch();
  const pdfUrl = process.env.REACT_APP_ELMS_LOGOURL
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL
  const certificateFieldMapping = useGetApiRequests('createCertificate', 'POST');
  const getCertificateMappedToOrganisation = useGetApiRequests('getAllCertificate', 'GET');
  const updateCertificatePdfAndName = useGetApiRequests('getAllCertificate', 'PUT');
  const createNewCertificate = useGetApiRequests('getAllCertificate', 'POST');
  const certificateDBFormFields = useGetApiRequests('certificateDBFormFields', 'GET');
  const loggedInUserId = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const organisationId = useSelector((state: any) => state?.organisationId?.id);
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const [certificateFields, setCertificateFields] = useState<any>([])
  const [dBFormFields, setDBFormFields] = useState<any>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>()
  const [validationSchema, setValidationSchema] = useState<any>(null)
  const [allCertificates, setAllCertificates] = useState<any>()
  const [certificateName, setCertificateName] = useState<any>('')
  const [certificateTypeSelect, setCertificateTypeSelect] = useState<any>('')
  const [currentCertificateURL, setCurrentCertificateURL] = useState<any>('')
  const [currentCertificateId, setCurrentCertificateId] = useState<any>('')
  const [loader, setLoader] = useState<any>(false)
  const [preName, setPreName] = useState<any>('')

  const getDBFormFields = async () => {
    try {
      const response: any = await certificateDBFormFields()
      const outputArray = response?.data?.data?.map((item: any) => ({ value: item }));
      setDBFormFields(outputArray)
    } catch (err: any) {
      setLoader(false)
    }
  }
  const getCurrentCertificateDetails = async (id: any) => {
    setLoader(true)
    try {
      const pathParams = { id, endPoint: 'fields' }
      const response: any = await getCertificateMappedToOrganisation('', {}, pathParams)
      setLoader(false)
      const initialVals = generateInitialValues(response?.data?.data);
      setInitialValues(initialVals);
      const schema = generateYupSchemas(response?.data?.data);
      setValidationSchema(schema);
      const structure = { first: { certificateId: id }, second: response?.data?.data }
      setCertificateFields(structure)
    } catch (err: any) {
      setLoader(false)
      clearStates(dispatch, setMediaData, setInitialValues, setCertificateFields, setValidationSchema,
        setCertificateName, setCurrentCertificateURL, setCurrentCertificateId, setPreName)
    }
  }
  const filteredSetDetails = (filteredData: any) => {
    setCurrentCertificateId(filteredData?.certificateId)
    setCurrentCertificateURL(`${filteredData?.certificatePath}`)
    getCurrentCertificateDetails(filteredData?.certificateId)
    setCertificateName(filteredData?.name)
    setPreName(filteredData?.name)
  }
  const mainFilterOperation = (data: any, certiType: any) => {
    const filteredData = data?.find((item: any) => { return item?.certificateType === certiType })
    if (filteredData) {
      filteredSetDetails(filteredData)
    } else clearStates(dispatch, setMediaData, setInitialValues, setCertificateFields, setValidationSchema,
      setCertificateName, setCurrentCertificateURL, setCurrentCertificateId, setPreName)
  }
  const getOrganisationCertificate = async (certiType: any) => {
    setLoader(true)
    try {
      const response: any = await getCertificateMappedToOrganisation('', { organisationId })
      setAllCertificates(response?.data?.data)
      setCertificateTypeSelect(certiType)
      mainFilterOperation(response?.data?.data, certiType)
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
    }
  }
  const handleParticularCertificate = (value: any) => {
    mainFilterOperation(allCertificates, value)
  }
  React.useEffect(() => {
    getDBFormFields()
    getOrganisationCertificate('COURSE_CO')
    setIsOpen(props?.open)
  }, [])
  const handleGetCertificateFormMappingFields = async (formData: any, apiCall: any, sendPath: any) => {
    let pathParams = {}
    if (sendPath) pathParams = { id: currentCertificateId }
    const queryParams = { loggedInUserId: loggedInUserId?.userId }
    const payload = { name: certificateName, organisationId: organisationId, certificateType: certificateTypeSelect }
    formData.append('certificateDtoStr', JSON.stringify(payload));
    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const response: any = await apiCall(formData, queryParams, pathParams, headers)
      const initialVals = generateInitialValues(response?.data?.data?.second);
      setInitialValues(initialVals);
      const schema = generateYupSchemas(response?.data?.data?.second);
      setValidationSchema(schema);
      setCertificateFields(response?.data?.data)
    } catch (err: any) {
      clearStates(dispatch, setMediaData, setInitialValues, setCertificateFields,
        setValidationSchema, setCertificateName, setCurrentCertificateURL, setCurrentCertificateId, setPreName)
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  }
  const handleImageDelete = (file: File) => {
    setValidationSchema(null)
    setCertificateFields([])
    setMediaData('')
  };
  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('certificateFile', file);
    setMediaData(formData);
    handleGetCertificateFormMappingFields(formData, currentCertificateId ? updateCertificatePdfAndName : certificateFieldMapping, !!currentCertificateId)
  };
  const handleUpdateState = async () => {
    try {
      const response: any = await getCertificateMappedToOrganisation('', { organisationId })
      setAllCertificates(response?.data?.data)
    } catch (err: any) {
      setLoader(false)
    }
  }
  const handleCreateNewCertificate = async (result: any) => {
    setLoader(true)
    try {
      const queryParams = { loggedInUserId: loggedInUserId?.userId }
      const pathParams = { id: certificateFields?.first?.certificateId, endPoint: 'fields' }
      const response: any = await createNewCertificate(result, queryParams, pathParams)
      handleUpdateState()
      triggerNotification('success', '', response?.data?.message, 'topRight');
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  }
  const handleUpdateName = async (result: any) => {
    const empt: any = null
    const pathParams = { id: currentCertificateId }
    const payload = { name: certificateName, organisationId: organisationId, certificateType: certificateTypeSelect }
    const queryParams = { loggedInUserId: loggedInUserId?.userId }
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('certificateDtoStr', JSON.stringify(payload));
    formData.append('certificateFile', empt);
    try {
      const response: any = await updateCertificatePdfAndName(formData, queryParams, pathParams, headers)
      handleCreateNewCertificate(result)
    } catch (err: any) {
      console.log(err);
    }

  }
  const onSubmit = (values: any) => {
    const result = certificateFields?.second?.map((item: any) => ({ ...item, dbField: values[item?.pdfFormField] }));
    console.log("certificateName : ", certificateName, preName);
    if (certificateName === preName)
      handleCreateNewCertificate(result)
    else {
      console.log("call put");
      handleUpdateName(result)
    }
  };
  const handleClose = () => {
    setIsOpen(false)
    props?.setCertificateAddModal(false)
  }
  return (
    <Modal title="Add Certificate" className='addcourse w-full addCertificate-modal' centered open={isOpen} footer={null} onCancel={handleClose} maskClosable={false} >
      {loader && <ModalLoader />}
      <div className=' mb-5 mt-12 max-w-[365px] addCertificate-type'>
        <label className='text-[#737373] text-[18px] mb-2  font-rubik' htmlFor=""> Certificate Type</label>
        <Select
          className=' certificate-type-select block' value={certificateTypeSelect}
          onChange={(value) => { handleParticularCertificate(value); setCertificateTypeSelect(value) }}>
          {certificateType?.map((item: any) => (
            <Select.Option key={item?.value} value={item?.value}>
              {item?.text}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="w-full flex ">
        <div className="w-[40%]  py-2">
          <div className="w-full ">
            <div className={'w-full mt-2  '}>
              <div className=" mb-5 max-w-[354px]">
                <label className='text-[#737373] text-[18px] mb-2  font-rubik' htmlFor=""> Certificate Name</label>
                <Input
                  type="text"
                  className='rounded-md h-[50px] bg-[#f5f5f5] border-0 text-[#434343]'
                  value={certificateName}
                  onChange={(e) => setCertificateName(e.target.value)}
                />
              </div>
              <div className='form-item mb-11'>
                <div className=' chapter-document bulk_upload mt-10 certificate_upload'>
                  <label className=' text-[#737373] text-[18px] font-rubik ' htmlFor="firstName">Upload Certificate Template</label>
                  <div className="mt-2">
                    <ImageUploadElms onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} imageUrl={currentCertificateURL} maxFileSize={1024 * 1024 * 10}
                      allowedTypes={['application/pdf']} pdfUrl={currentCertificateURL} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%] add-certificate-form rounded-md">
          {validationSchema !== null ? (
            <AddCertificateFormikFields initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} certificateFields={certificateFields} dBFormFields={dBFormFields} />
          ) : (<div className='flex justify-center items-center h-full '>
            <p className='mb-24 text-[#737373] font-medium text-[20px]'>No Data found</p>
          </div>)}
        </div>
      </div>
    </Modal >
  )
}
export default AddCertificateMain