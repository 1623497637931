import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  filterValue: any;
}

const initialState: AuthState = {
  filterValue: 'All Employees',
};

const tableFilterSlice = createSlice({
  name: 'tableFilter',
  initialState,
  reducers: {
    setTableFilter: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    clearTableFilter: state => {
      state.filterValue = 'All Employees';
    },
  },
});

export const { setTableFilter, clearTableFilter } = tableFilterSlice.actions;
export default tableFilterSlice.reducer;
