import { TOGGLE_EMPLOYEE_TABLE } from './employeeTableType';
export const initialState: any = [];

export const employeeTableReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    if (type === TOGGLE_EMPLOYEE_TABLE) {
        return {
            data,
        };
    } else {
        return state;
    }
};
