import React, { useState } from 'react'
import AssignCourseModal from './AssignCourseModal'

export const AssignButton = ({ detail }: any) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleCloseModal = () => {
    setOpen(false);
  }
  const handelPopup = () => {
    setOpen(true);
  }
  return (
    <div>
      <button className='bg-primary text-white text-[14px] py-1 px-3 rounded-full' onClick={handelPopup}>Assign Course</button>
      {open && <AssignCourseModal open={open} handleCloseModal={handleCloseModal} editRecord={{ key: detail?.courseID }} courseFlow={true} />}
    </div>
  )
}
