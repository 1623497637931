import React, { useEffect, useState } from 'react'
import CourseCard from '../../../components/CommonCard/CourseCard'
import './UnassignedCourse.scss'
import { triggerNotification, useGetApiRequests, useNavigate } from '../../../common/CommonExport'
import Loader from '../../../components/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { setCourseDetails, setCurrentCourseId, setFlowDirection } from '../../../redux/slices/currentCourseDetailSlics'
import { setFrequentlyAssignedCoursesList, setRecentlyAddedCoursesList, setUnassignedCoursesList } from '../../../redux/slices/courseListPayloadSlice'
import Slider from "react-slick";
import { setCourseListData } from '../../../redux/slices/updateProfileSlice'
import noRecordFound from '../../../assets/images/empty-folder.png'
const CourseList = ({ title, courseType, keyValue, countTotal, courseSearch }: any) => {
  const courseReLoad = useSelector((state: any) => state?.profileReload?.chapterLoad);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCourseListAPI = useGetApiRequests('courseListDashboard', 'GET');
  const pageSize = 20;
  const [coursesList, setCoursesList] = useState<any>([])
  const [coursesCount, setCoursesCount] = useState<any>(-1)
  const [currentPage, setCurrentPage] = useState(0);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [loader, setLoader] = useState<boolean>(false);
  const [currentIndexOldRef, setCurrentIndexOldRef] = useState(0);
  const [currentIndexNewRef, setCurrentIndexNewRef] = useState(5);

  const handleLoadMore = () => {
    if (((currentPage + 1) * pageSize) <= roundToNearestMultipleOf5(coursesCount)) {
      setCurrentPage((prevPage) => prevPage + 1);
      getCourseList(courseType, (currentPage + 1) * pageSize, pageSize)
    }
  }

  const roundToNearestMultipleOf5 = (value: any) => Math.round(value / pageSize) * pageSize;

  const getCourseList = async (type: any, start: any, limit: any) => {
    if (start === 0)
      setLoader(true)
    try {
      const queryParams = { coursetype: type, start, limit, organisationId: orgId, search: courseSearch }
      const response: any = await getCourseListAPI('', queryParams)
      const newCourses = response?.data?.data[keyValue];
      if (newCourses.length > 0) {
        dispatch(setCourseListData(false));
      } else {
        dispatch(setCourseListData(true));
      }
      if (keyValue === 'unassignedCourses') dispatch(setUnassignedCoursesList(newCourses))
      else if (keyValue === 'recentlyAddedCourses') dispatch(setRecentlyAddedCoursesList(newCourses))
      else if (keyValue === 'frequentlyAssignedCourses') dispatch(setFrequentlyAssignedCoursesList(newCourses))
      setTimeout(() => {
        setCoursesList((prevData: any) => {
          if (newCourses) return [...prevData, ...newCourses];
          return prevData;
        });
        setLoader(false)
      }, 1000)
      setCoursesCount(response?.data?.data?.[countTotal])
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  }

  useEffect(() => {
    setCurrentIndexOldRef(0);
    setCurrentIndexNewRef(5);
    setCoursesList([])
    getCourseList(courseType, 0, pageSize)
  }, [courseReLoad, courseSearch])

  const handleNavigateToCourseDetails = (item: any) => {
    const empDetails = {
      courseStatus: item?.courseStatus,
      employeeCount: item?.employeeCount
    }
    dispatch(setCourseDetails(empDetails))
    dispatch(setFlowDirection(true))
    dispatch(setCurrentCourseId(item?.courseID))
    navigate('/home/dashboard/learning-management/courseDetails');
  }

  const settings = {
    infinite: false,
    speed: 400,
    loop: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow:
      <div>
        {(currentIndexNewRef !== coursesList?.length - 5) &&
          <span className="material-symbols-outlined" onClick={handleLoadMore}>
            arrow_forward_ios
          </span>
        }
      </div>,
    prevArrow:
      <div>
        {currentIndexOldRef > 1 &&
          <span className="material-symbols-outlined" >
            arrow_back_ios
          </span>
        }
      </div>,
    beforeChange: (oldIndex: any, newIndex: any) => {
      if (oldIndex > newIndex) {
        setCurrentIndexOldRef(oldIndex - 5);
        setCurrentIndexNewRef(newIndex);
      } else if (oldIndex < newIndex) {
        setCurrentIndexOldRef(oldIndex + 5);
        setCurrentIndexNewRef(newIndex);
      }
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>
      {/* {coursesList.length > 0 ? ( */}
      <div className='unassignedCourse'>
        {loader && <Loader />}
        <h5 data-testid="h5El" className={' text-[#696969] text-[17px] font-rubik title flex items-center pl-8 ' + title.toLowerCase()}>{title}</h5>
        <div className='w-full  '>
          <div>
            {coursesList?.length > 0 ? (
              <div>
                <Slider {...settings}>
                  {coursesList?.map((item: any) => (
                    <div key={item?.courseID} className='w-[20%] pb-14'>
                      <CourseCard detail={item} courseType={courseType} handleNavigateToCourseDetails={handleNavigateToCourseDetails} />
                    </div>
                  ))}
                </Slider></div>) : (
              <div className="no-record salary min-h-[30vh]">
                <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
                </p>
              </div>
            )
            }
          </div>
        </div>
        {/* {coursesCount !== coursesList?.length && ( */}
        {/* <button className='mt-3 text-[#D85151] text-[17px] font-rubik' onClick={() => handleLoadMore()}>Load More...</button> */}
        {/* )} */}
      </div>
      {/* ) : ( */}
      {/* <div className="no-record salary min-h-[70vh]">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
          </p>
        </div>
      )} */}

    </>

  )
}

export default CourseList;