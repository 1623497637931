import React, { useLayoutEffect, useState } from 'react'
import cloudNowLogo from '../../assets/images/cnwlogo.png'
import { Formik, Form } from 'formik';
import { State } from 'country-state-city';
import { employeeValidationSchema, initialEmpFormValues } from './empInfoFormSchema';
import EmployeeBankDetails from './employeeBankDetails';
import EmployeeDependentInfo from './employeeDependentInfo';
import EmployeePreviousEmploymentStatus from './employeePreviousEmploymentStatus';
import EmergencyContactInfo from './emergencyContactInfo';
import AddressOfEmployee from './addressOfEmployee';
import EmployeeInformation from './employeeInformation';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import dayjs from 'dayjs';

export default function BasicForm() {
  const employeeUpdateApi = useGetApiRequests('companyEmployeeDetails', 'PUT');
  const tokenValidation = useGetApiRequests('tokenValidation', 'GET');
  const [dropDownList, setDropDownList] = useState<any>({})
  const [initialValues, setInitialValues] = useState<any>();
  const [employeeId, setEmployeeId] = useState<any>()
  const [employeeApiKey, setEmployeeApiKey] = useState<any>()
  const [empDetail, setEmpDetail] = useState<any>()
  const [loader, setLoader] = useState<any>(false)

  const getAllApiData = async (urlEmployeeId: any, urlEmployeeApiKey: any) => {
    const queryParams = { employeeApiKey: urlEmployeeApiKey }
    try {
      setLoader(true)
      const [employeeTokenValidation]: any = await Promise.all([
        tokenValidation('', queryParams),
      ]);
      setEmpDetail(employeeTokenValidation?.data?.data)
      initialEmpFormValues.employeeAdditionalInformationDto.employeePreviousEmployment.forEach((employment: any) => {
        employment.employeeTypeId = employeeTokenValidation?.data?.data?.employeeType?.employeeTypeId;
      });
      initialEmpFormValues.employeeDto.firstName = employeeTokenValidation?.data?.data?.firstName
      initialEmpFormValues.employeeDto.lastName = employeeTokenValidation?.data?.data?.lastName
      setInitialValues(initialEmpFormValues)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      triggerNotification('error', '', "Seems like you have already filled the personal info", 'topRight');
      setTimeout(() => {
        window.close();
      }, 3000)
    }
  };

  useLayoutEffect(() => {
    const states = State.getStatesOfCountry('IN');
    setDropDownList({ states: states, cState: states })
    const queryParams = new URLSearchParams(window.location.search);
    const urlEmployeeId = queryParams.get('employeeId')
    const urlEmployeeApiKey = queryParams.get('employeeApiKey');
    setEmployeeId(urlEmployeeId)
    setEmployeeApiKey(urlEmployeeApiKey)
    getAllApiData(urlEmployeeId, urlEmployeeApiKey)
  }, [])

  const onSubmit = (values: any) => {
    const payloadObj = values
    payloadObj.employeeAdditionalInformationDto.employeeBankDetailsDto.employeeDto = { employeeId: employeeId }
    payloadObj.employeeAdditionalInformationDto?.employeeDependentDto?.forEach((item: any) => {
      item.employeeDto = { employeeId };
      delete item.key;
    });
    payloadObj.employeeAdditionalInformationDto?.employeePreviousEmployment?.forEach((item: any) => {
      item.employeeDto = { employeeId };
      delete item.key;
      delete item.employeeTypeId
    });
    const queryParams = {
      employeeInformationDto: payloadObj,
      employeeApiKey: employeeApiKey
    }
    const headers = { 'Content-Type': 'multipart/form-data', 'Employeeid': employeeId };
    const formData = new FormData();
    const pathParams = {
      id: employeeId
    }
    setLoader(true)
    employeeUpdateApi(formData, queryParams, pathParams, headers)
      .then((res: any) => {
        setLoader(false)
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setTimeout(() => {
          window.close();
        }, 3000)
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', err?.message, 'topRight');
        setTimeout(() => {
          window.close();
        }, 3000)
      })
  };

  const handleAddRelation = (values: any) => {
    const extraObject = {
      key: values.depRelations?.length + 1,
      relationship: '',
      dependentName: '',
      dateOfBirth: '',
    }
    setInitialValues({
      ...values,
      employeeAdditionalInformationDto: {
        ...values.employeeAdditionalInformationDto,
        employeeDependentDto: [...values.employeeAdditionalInformationDto.employeeDependentDto, extraObject],
      },
    });
  }
  const handleMarriedStatus = (status: any, values: any) => {
    let employeeDependentDto = [];
    if (status) {
      employeeDependentDto = [{
        key: 1,
        relationship: 'Spouse',
        dependentName: '',
        dateOfBirth: '',
      }];
    } else {
      employeeDependentDto = [
        {
          key: 1,
          relationship: 'Father',
          dependentName: '',
          dateOfBirth: '',
        },
        {
          key: 2,
          relationship: 'Mother',
          dependentName: '',
          dateOfBirth: '',
        }
      ];
    }
    const obj = values
    obj.employeeDto.isMarried = status
    obj.employeeAdditionalInformationDto.employeeDependentDto = employeeDependentDto
    setInitialValues(obj);
  }

  const handleRowDelete = (indexVal: any, values: any) => {
    setInitialValues((prevValues: any) => {
      const updatedEmployeeDependentDto = values.employeeAdditionalInformationDto.employeeDependentDto.filter((item: any, index: any) => index !== indexVal);
      return {
        ...values,
        employeeAdditionalInformationDto: {
          ...values.employeeAdditionalInformationDto,
          employeeDependentDto: updatedEmployeeDependentDto,
        },
      };
    });
  }

  return (
    <div className='basicForm-page'>
      {loader && <Loader />}
      {dropDownList?.states?.length !== 0 && initialValues && (
        <div className=" grid grid-cols-1 ">
          <div className='w-3/5 mx-auto bg-white my-5 py-16 px-14 basic-info-start'>
            <div className='flex justify-between  pb-14 '>
              <img src={cloudNowLogo} alt='cloudNowLogo' />
              <p>Date: {dayjs().format('DD/MM/YYYY')}</p>
            </div>
            <div>
              <h2 className='text-center text-3xl font-bold'>Employee Information Form</h2>
              <div className='grid grid-cols-2 mt-16'>
                <div>
                  <ul>
                    <li className='mb-8'>
                      <span className='w-2/5 inline-block text-xl'>Employee Name</span> {`${empDetail?.firstName} ${empDetail?.lastName}`} <span className='ml-16 text-xl'></span>
                    </li>
                    <li className='mb-8'>
                      <span className='w-2/5 inline-block text-xl'>Job Title</span> {empDetail?.designation?.name} <span className='ml-16 text-xl'></span>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li className='mb-8'>
                      <span className='w-2/5 inline-block text-xl'>Employee ID</span> {empDetail?.code} <span className='ml-16 text-xl'></span>
                    </li>
                    <li className='mb-8'>
                      <span className='w-2/5 inline-block text-xl'>Department</span> {empDetail?.department?.name} <span className='ml-16 text-xl'></span>
                    </li>
                  </ul>
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={employeeValidationSchema}
                onSubmit={onSubmit}
                values={initialValues}
                enableReinitialize={true}
              >
                {({ handleSubmit, values, setFieldValue, setFieldTouched }) => {

                  return (
                    <Form onSubmit={handleSubmit}>
                      <EmployeeInformation setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} handleMarriedStatus={handleMarriedStatus} />
                      <AddressOfEmployee setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} dropDownList={dropDownList} setDropDownList={setDropDownList} styleChange={true} />
                      <EmergencyContactInfo setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} />
                      <EmployeePreviousEmploymentStatus values={values} setFieldValue={setFieldValue} styleChange={true} />
                      <EmployeeDependentInfo setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} handleAddRelation={handleAddRelation} handleRowDelete={handleRowDelete} styleChange={true} />
                      <EmployeeBankDetails setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} styleChange={true} />
                      <div className='w-full flex justify-center'>
                        <button className='form-submit-btn text-white' type="submit">Submit Details</button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div >
      )
      }
    </div >
  )
}
