import React, { useEffect, useState } from 'react'
import { Pagination, PaginationProps, Table } from 'antd';
import { triggerNotification, useGetApiRequests, useNavigate } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import AssignCourseModal from '../LearningManagement/Course/AssignCourseModal';
import LearningPathFilter from './LearningPathFilter';

const LearningPathTable = (props: any) => {
  const getPathListApi = useGetApiRequests('learningPathGetList', "POST");
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [pathList, setPathList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState<number>();
  const [learningPathName, setLearningPathName] = useState('');
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const getLeaveType = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  const handleAssignModal = (record: any) => {
    setEditRecord(record)
    setOpenModal(true);
  }
  const columns = [
    {
      title: 'Name of the Learning path',
      dataIndex: 'nameOfLearningPath',
      render: (text: any, record: any) => (
        <div className='cursor-pointer flex'>
          <div className=' flex justify-center items-center' onClick={() => handleRowClick(record.key)}><p className='profile-initials flex justify-center items-center mr-3'>{getLeaveType(text)}</p>{text}</div>
        </div>
      ),
    },
    {
      title: 'Created on',
      dataIndex: 'createdOn',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex items-center' onClick={() => handleRowClick(record.key)}>{record?.createdOn}</div>
        </div>)
    },
    {
      title: 'No. of Levels',
      dataIndex: 'noOfLevels',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex justify-center items-center' onClick={() => handleRowClick(record.key)}>{record?.noOfLevels}</div>
        </div>)
    },
    {
      title: 'No. of Course',
      dataIndex: 'noOfCourse',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex justify-center items-center' onClick={() => handleRowClick(record.key)}>{record?.noOfCourse}</div>
        </div>)
    },
    {
      title: 'No. of Certificate',
      dataIndex: 'noOfCertificate',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex justify-center items-center' onClick={() => handleRowClick(record.key)}>{record?.noOfCertificate}</div>
        </div>)
    },
    {
      title: 'Assigned Employees',
      dataIndex: 'assignedEmployees',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex justify-center items-center' onClick={() => handleRowClick(record.key)}>{record?.assignedEmployees}</div>
        </div>)
    },
    {
      title: '',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className=' flex justify-center items-center text-[#D34A7C] py-2 ' onClick={() => handleAssignModal(record)}>{'Assign'}</div>
        </div>)
    }

  ];

  const regularDateFormat = (input_value: any) => {
    const apiDate = new Date(input_value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");
    const result = `${day}.${month}.${year}`;
    return result;
  }
  useEffect(() => {
    learningPathGetApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, isSubmit])
  const learningPathGetApi = () => {

    setLoader(true);
    const start: number = (currentPage - 1) * pageSize;;
    const payload: any = {
      "organisationId": orgId,
      "start": start,
      "limitRows": pageSize,
    }
    if (learningPathName !== '') {
      payload.searchPathName = learningPathName
    }
    if (startDate !== '') {
      payload.createdStartDate = startDate
    }
    if (endDate !== '') {
      payload.createdEndDate = endDate;
    }

    getPathListApi(payload)
      .then((response: any) => {
        const reformedPolicyList = response?.data?.data.map((item: any) => ({
          key: item?.learningPathId,
          nameOfLearningPath: item?.pathName,
          createdOn: regularDateFormat(item?.createdOn),
          noOfCourse: item?.numberOfCourses,
          noOfLevels: item.numberOfLevels,
          noOfCertificate: item?.numberOfCertificates,
          assignedEmployees: item?.numberOfEmployees
        }));
        setCount(response?.data?.meta?.totalRecords)
        setPathList(reformedPolicyList);
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handleRowClick = (learningPathId: any) => {
    navigate(`/home/dashboard/newLearningPath?isEdit=true&learningPathId=${learningPathId}`)
  };


  return (
    <div className='learning-management my-3 pb-5'>
      <LearningPathFilter learningPathName={learningPathName} setLearningPathName={setLearningPathName} setStartDate={setStartDate} setEndDate={setEndDate} setIsSubmit={setIsSubmit} />
      <Table columns={columns} dataSource={pathList} pagination={false} />
      {openModal && <AssignCourseModal open={openModal} handleCloseModal={handleCloseModal} editRecord={editRecord} courseFlow={false} />}
      {loader && <Loader />}
      <div className='pagination'>
        <Pagination className='float-right flex flex-row p-6' showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={currentPage} total={count} pageSize={pageSize} onChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  )
}

export default LearningPathTable