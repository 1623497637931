import React from 'react'
import ImageUpload from '../../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload'

const UploadImages = ({ setMediaData, mediaData, previewImage }: any) => {
  const handleImageDelete = (file: File) => setMediaData('');
  const handleImageUpload = (file: File) => {
    setMediaData(file);
  }

  return (
    <div className='form-item'>
      <div className='chapter-document'>
        <ImageUpload
          imageUrl={previewImage}
          onImageUpload={handleImageUpload}
          onImageDelete={handleImageDelete}
          maxFileSize={1024 * 1024 * 10}
          allowedTypes={['image/jpeg', 'image/png']}
        />
      </div>
    </div>
  )
}

export default UploadImages
