import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'
import { useLocation } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import EmployeeProgressLearningPath from './EmployeeProgressLearningPath';


const ChapterCard = (props: any) => {
  const { search } = useLocation();
  const empTypeId: any = new URLSearchParams(search).get("employeeId");
  const isLearningPath: any = new URLSearchParams(search).get("learningPath");
  const idOfTheCourse: any = new URLSearchParams(search).get("courseId");
  const learningPathId: any = new URLSearchParams(search).get("learningPathId");
  const getEmployeeProgressCourseApi = useGetApiRequests('getEmployeeProgresscourse', 'GET');
  const getEmployeeProgressLearningApi = useGetApiRequests('getEmployeeProgressLearningPath', 'GET');
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL;
  const [loader, setLoader] = useState(false)
  const [courseList, setCourseList] = useState([]);
  const [stepperData, setStepperData] = useState([])

  const getCourseProgress = async () => {
    setLoader(true)
    const pathParams: any = {
      id: empTypeId
    }
    if (isLearningPath === 'false') {
      const response: any = await getEmployeeProgressCourseApi('', '', pathParams)
      try {
        const responses: any = response?.data?.data;
        responses.sort((a: any, b: any) => {
          if (a.courseId === parseInt(idOfTheCourse)) {
            return -1;
          } else if (b.courseId === parseInt(idOfTheCourse)) {
            return 1;
          } else {
            return 0;
          }
        });
        setStepperData(responses?.[0]?.employeeChapterDetailDtoList)
        setCourseList(responses)
        setLoader(false)
      }
      catch (err: any) {
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      };
    } else {
      const response: any = await getEmployeeProgressLearningApi('', '', pathParams)
      try {
        const responses: any = response?.data?.data;
        responses.sort((a: any, b: any) => {
          if (a.courseId === parseInt(idOfTheCourse)) {
            return -1;
          } else if (b.courseId === parseInt(idOfTheCourse)) {
            return 1;
          } else {
            return 0;
          }
        });
        const foundIndex: number = responses.findIndex((item: any) => item?.learningPath?.learningPathId === parseInt(learningPathId));
        const combinedEmployeeLearningPathCourseDetailDtoList = responses?.[foundIndex]?.employeeLearningPathLevelProgressDtoList.map((item: any) => item.employeeLearningPathCourseDetailDtoList).flatMap((list: any) => list);  // Extracting from each object
        setStepperData(combinedEmployeeLearningPathCourseDetailDtoList?.[foundIndex]?.employeeChapterDetailDtoList)
        const sample = combinedEmployeeLearningPathCourseDetailDtoList && combinedEmployeeLearningPathCourseDetailDtoList.map((element: any, index: number) => ({
          employeeCourseStatus: element.status,
          employeeCertificatePath: element?.learningPathLevelCourseDto?.course?.logoPath,
          courseName: element?.learningPathLevelCourseDto?.course?.name,
          courseDescription: element?.learningPathLevelCourseDto?.course?.description,
          chapterCount: element?.chapterCount,
          totalDuration: element?.totalDuration,
          employeeChapterCompletedPercentage: element?.employeeChapterCompletedPercentage,
          employeeChapterDetailDtoList: element?.employeeChapterDetailDtoList,
          courseId: element?.element?.learningPathLevelCourseDto?.course?.courseId
        }));

        setCourseList(sample)
        setLoader(false)

      }
      catch (err: any) {
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      };


    }
  }
  const handleElement = (element: any) => {
    setStepperData(element)
  }
  useEffect(() => {
    getCourseProgress()
  }, [])
  return (
    <>
      <div className="flex w-full">
        < div className='w-[70%]'>
          {courseList && courseList.map((item: any) => (

            <div key={item.courseId} className='flex mb-4 bg-[#F5F5F5] items-center p-3 rounded-md listOf-course-card' onClick={() => handleElement(item?.employeeChapterDetailDtoList)}>

              <div className='mr-4 w-[15%]'>
                <img className='w-[152px] h-[126px] Courseimg' src={`${baseUrl}${item?.courseLogoPath}`} alt='coures pic' />
              </div>
              <div className='mr-4 w-[60%] listOf-course-content'>
                <div className="flex flex-col">
                  <h5 className='font-bold text-[17px]'>{item?.courseName}</h5>

                  <p className='text-[#949494] text-[15px]'>{item?.courseDescription}</p>
                </div>
                <div className="flex pt-4">
                  <p className='flex mr-8 text-[#D34A7C]'><span className="material-symbols-outlined mr-2">
                    import_contacts
                  </span>{item?.chapterCount} Chapter</p>
                  <p className='flex text-[#D34A7C]'> <span className="material-symbols-outlined mr-2">
                    schedule
                  </span>{item?.totalDuration} Duration</p>
                </div>
              </div>
              {item?.employeeCourseStatus !== 'YS' ? (
                <div className='w-[25%]'>
                  <label htmlFor="" className='text-[15px] text-[#303030]'>Completed {item?.employeeChapterCompletedPercentage}%</label>
                  <Progress className='text-primary' percent={item?.employeeChapterCompletedPercentage} />
                </div>) : (
                <div className='w-[25%]'>
                  <label htmlFor="" className='text-[15px] text-[#303030]'>Not Started</label>
                  <Progress className='text-primary' percent={0} />
                </div>
              )}
            </div>))}
        </div>
        <div className="w-[30%]">
          <div className='ml-8 mb-4 bg-[#F5F5F5] rounded-md '>
            <EmployeeProgressLearningPath stepperData={stepperData} />
          </div>

        </div>
      </div>

      {loader && <Loader />}
    </>
  )
}

export default ChapterCard
