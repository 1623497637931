import React from 'react';
import { Formik, Form } from 'formik';
import ImageUpload from '../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload';
import { triggerNotification } from '../../../../common/CommonExport';


const UploadCourseFile = (props: any) => {
  const { setMediaData, setZipFile } = props;

  const handleImageDelete = (file: File) => setMediaData('');

  const handleZipUpload = (file: File) => {
    if (!file.name.includes(' ')) {
      setZipFile(file);
      const formData = new FormData();
      formData.append('filess', file);
      setMediaData(formData);
    } else {
      triggerNotification('warning', '', 'File name should not contain spaces', 'topRight');
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form>
          <div className="chapter-url flex">
            <div className='form-item'>
              <div className=' chapter-document chapter-zip-upload'>
                <label className='addcourse-lable pl-2' htmlFor="firstName">Video Content</label>
                <div className="mt-2">
                  <ImageUpload onImageUpload={handleZipUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 50}
                    allowedTypes={['application/zip', 'application/x-zip-compressed']} />
                </div>
              </div>
            </div>
            {/* <p className='flex items-center text-lg'>or</p>
            <div className="chapter-field mb-5 pl-9 flex justify-center flex-col">
              <label className='addcourse-lable pl-2' htmlFor="firstName">Upload your video URL</label>
              <Field id="firstName" name="firstName" placeholder="Enter a name" />
            </div> */}
          </div>
        </Form>
      </Formik>

    </div>

  )
}

export default UploadCourseFile
