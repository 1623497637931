import React, { useEffect, useState } from 'react'
import img from "../../../../../../../assets/images/cnwlogo.png"
import { useLocation } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import dayjs from 'dayjs';
import { DatePicker, Select, Switch } from 'antd'
import FloatLabel from '../../../../../../../components/FloatLabel';
import { Yup, storeData, triggerNotification, useGetApiRequests } from '../../../../../../../common/CommonExport';
import Loader from '../../../../../../../components/Loader/Loader';
import EmailAppraisalContent from './EmailAppraisalContent';
export interface AppContextData {
  questionsArray: any;
  appraiserFeedback: any[];
  functionalAttributes: any[];
  keyPerformance: any[];
  organisationAttributes?: any[];
  personalAttributes?: any[];

}
export default function EmailAppraisalForm() {
  const { search } = useLocation();
  const employeeId: any = new URLSearchParams(search).get("employeeId")
  const organisationId: any = new URLSearchParams(search).get("organisationId")
  const designationIdEmail: any = new URLSearchParams(search).get("designationId")
  const employeeApiKey: any = new URLSearchParams(search).get('employeeApiKey');
  const employeeReviewId: any = new URLSearchParams(search).get('employeeReviewId');
  const userId: any = new URLSearchParams(search).get('user-id')
  const formName: any = new URLSearchParams(search).get('type')
  const dateFormat = 'YYYY-MM-DD';
  const getQuestions = useGetApiRequests('getquestions', 'GET');
  const [loader, setLoader] = useState(false);
  const [dropdownList, setDropdownList] = useState<any>([]);
  const [appraiserFeedback, setAppraiserFeedback] = useState([]);
  const [functionalAttributes, setFunctionalAttributes] = useState([]);
  const [organisationAttributes, setOrganisationAttributes] = useState([]);
  const [personalAttributes, setPersonalAttributes] = useState([]);
  const [date, setDate] = useState('');
  const [reviewtype, setReviewtype] = useState("")
  const [keyPerformance, setKeyPerformance] = useState([]);
  const [itsIntern, setItsIntern] = useState<any>(null);
  const [questionsArray, setQuestionsArray] = useState({
    keyperformance: [], personalattributes: [], functionalattributes: [], organisationalattributes: [],
    appraiserfeedback: { significantachievements: null, improvementareas: null, technicalskills: null, softskills: null, appraisercomments: null }
  })
  const [employeeDetails, setEmployeeDetails] = useState<any>({});
  const initialValues = {
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: false,
    probationExtension: ''
  };
  const validationSchema = Yup.object().shape({
    reviewtype: Yup.string().required('Review Type is required'),
    salaryRevised: Yup.string().test(
      'require-salaryRevised',
      'Salary Revised Date is required',
      function (value) {
        const incrementEligible = this.parent?.incrementEligible;
        if (
          (incrementEligible === true) &&
          !value
        ) {
          return this.createError({
            path: this.path,
            message: 'Salary Revised Date is required',
          });
        }
        return true;
      }
    ),
    probationExtension: Yup.string().test(
      'require-probationExtension',
      'Probation Extension date is required',
      function (value) {
        const reviewtype: any = this.parent.reviewtype;

        if (reviewtype === 'Probation Extension' && !value) {
          return this.createError({
            path: this.path,
            message: 'Probation Extension date is required',
          });
        }
        return true;
      }
    ),
  });
  const getCurrentDate = () => {
    const now = dayjs();
    const currentdate = now.format('YYYY-MM-DD');
    setDate(currentdate);
    storeData("salaryRevised", currentdate, true)
  }
  useEffect(() => {
    getQuestionsApi();
    getCurrentDate()
  }, [])

  const onChangeDate = (date: any, dateString: string, setFieldValue: any): any => {
    setDate(dateString)
    setFieldValue('salaryRevised', dateString);
    storeData("salaryRevised", dateString, true)

  };

  const getQuestionsApi = () => {
    const params: any = {
      designationId: designationIdEmail, organisationId: organisationId
    };
    const pathParams: any = {
      id: employeeId
    }
    setLoader(true);
    getQuestions('', params, pathParams)
      .then((response: any) => {
        let tempQuestionList: any = questionsArray;
        let questionKeys: any = Object.keys(response?.data?.data?.questions);
        questionKeys.forEach((item: any) => {
          let editedKey = item.split("_").join("").toLowerCase();
          if (editedKey === "appraiserfeedback") {
            response?.data?.data?.questions &&
              response?.data?.data?.questions[item].forEach((data: any) => {
                let tempData = data;
                let questionKey = data.question.split(" ").join("").toLowerCase();
                tempData["answer"] = "";
                tempData["range"] = null;
                tempQuestionList[editedKey][questionKey] = tempData;
              });
          } else {
            response?.data?.data?.questions &&
              response?.data?.data?.questions[item].forEach((data: any) => {
                let tempData = data;
                tempData["answer"] = "";
                tempData["range"] = null;
                tempQuestionList[editedKey].push(tempData);
              });
          }
        });
        setQuestionsArray(tempQuestionList);
        const apiResponse = response?.data?.data?.questions;
        const temDropDownArray: any = [];
        if (response?.data?.data?.reviewType !== null) {
          setItsIntern(false);
          response?.data?.data.reviewType.map((item: any) => {
            const tempDropDown: any = {
              value: item,
              label: item
            }
            temDropDownArray.push(tempDropDown)
            setDropdownList(temDropDownArray)
            if (formName === 'Half_Yearly') {
              setReviewtype('Half Yearly');
              storeData("reviewtype", 'Half Yearly', true)
            } else if (formName === 'Annual') {
              setReviewtype('Annual');
              storeData("reviewtype", 'Annual', true)
            }
          });
        } else {
          setItsIntern(true);
          const dropdownOptions = Object.entries(response?.data?.data?.status).map(([value, label]) => ({
            value: value,
            label
          }));
          setDropdownList(dropdownOptions)
        }
        setAppraiserFeedback(apiResponse?.APPRAISER_FEEDBACK);
        setFunctionalAttributes(apiResponse?.FUNCTIONAL_ATTRIBUTES);
        setKeyPerformance(apiResponse?.KEY_PERFORMANCE);
        setOrganisationAttributes(apiResponse?.ORGANISATIONAL_ATTRIBUTES);
        setPersonalAttributes(apiResponse?.PERSONAL_ATTRIBUTES);
        const keyMappings: any = {
          "Employee Name": "name",
          "Designation": "designation",
          "Reporting Manager": "reportingManager",
          "Role In Current Project": "roleInCurrentProject",
          "Employee Id": "employeeId",
          "Date Of Join": "dateOfJoin",
          "Probation Period": "probationPeriod",
          "Location": "location"
        };

        const newEmployeeDetails: any = {};

        response?.data?.data?.employeeDetails.forEach((item: any) => {
          const [key, value] = item.split(' : ');
          if (keyMappings[key]) {
            newEmployeeDetails[keyMappings[key]] = value;
          }
        });

        setEmployeeDetails(newEmployeeDetails);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handleClose = (): any => {
    return null
  };
  const handleReviewType = (value: any, setFieldValue: any) => {
    setFieldValue('reviewtype', value)
    setReviewtype(value);
    storeData("reviewtype", value, true)
    const reviewObject: any = dropdownList.find((item: any) => item.value === value)
    storeData("reviewObject", reviewObject, true)
  }
  return (
    <div className="main-container" id="formToConvert">
      <div className="probation" >
        <section className="sheet">
          <div className="main-content">
            <div className="logo-header">
              <img src={img} alt="logo" />
            </div>
            <h1>{formName === 'Half_Yearly' ? 'HALF YEARLY' : formName === 'Annual' ? 'ANNUAL' : formName.toUpperCase()} REVIEW FORM </h1>
            <div className="main">
              <ul>
                <li><span>Employee Name</span><span className="dept">{employeeDetails?.name}</span></li>
                <li><span>Designation</span><span className="dept">{employeeDetails?.designation}</span></li>
                <li> <span>Reporting Manager</span><span className="dept">{employeeDetails?.reportingManager}</span></li>
                <li><span>Role in Current Project</span><span className="dept">{employeeDetails?.roleInCurrentProject}</span></li>
              </ul>
              <ul>
                <li><span>Employee ID</span><span className="dept">{employeeDetails?.employeeId}</span></li>
                <li><span>DOJ</span> <span className="dept">{employeeDetails?.dateOfJoin}</span></li>
                <li><span>Probation Period</span><span className="dept">{employeeDetails?.probationPeriod}</span>
                </li>
                <li><span>Location</span><span className="dept">{employeeDetails?.location}</span></li>
              </ul>
            </div>
            <div>
              <p data-testid="para" className="paragraph">
                This form is to be filled by Supervisors/Reporting Managers (Appraisers). Supervisors/Reporting Managers
                should support the rating with comments in the Remarks column provided for each parameter. For
                statements/parameters that do not apply to the person being evaluated, please mark “Not Applicable” (NA).
                Remarks should be specific and explanatory.
              </p>
            </div>
          </div>
          <div>
            <div className="salary-date review-modal email-template">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleClose}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='grid grid-cols-3 gap-x-12 gap-y-4 mt-4 w-full form-item'>
                      {formName !== 'Half_Yearly' && formName !== 'Annual' ? (
                        <Select
                          placeholder={'Status Type*'} options={dropdownList}
                          onChange={(value) => { handleReviewType(value, setFieldValue) }}
                        ></Select>) : (
                        <Select
                          placeholder={'Status Type*'} options={dropdownList}
                          value={
                            formName === 'Half_Yearly'
                              ? dropdownList.filter((item: any) => item.value === 'Half Yearly')
                              : dropdownList.filter((item: any) => item.value === 'Annual')
                          }
                          disabled={true}
                        // onChange={(value) => { handleReviewType(value, setFieldValue) }}
                        ></Select>
                      )}
                      {!itsIntern && itsIntern !== null && (
                        <div className="review-toggle flex items-center justify-end">
                          <label htmlFor="incrementEligible" className='pr-2 pt-0'>
                            Eligible for Increment?
                          </label>
                          <Field
                            type="checkbox"
                            name="incrementEligible"
                            as={Switch}
                            onChange={(event: any) => {
                              setFieldValue('incrementEligible', !values.incrementEligible);
                            }}
                          />
                        </div>)}
                      {values.incrementEligible === true && (
                        <div className="revised-date">
                          <FloatLabel label='Salary Revised on' name='salaryRevised'>
                            <DatePicker
                              className='float-control'
                              name="salaryRevised"
                              onChange={() => onChangeDate} defaultValue={dayjs(date, dateFormat)} format="YYYY-MM-DD" />
                          </FloatLabel>
                        </div>)}
                    </div>
                    {values.reviewtype === '31' && (
                      <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5'>
                        <FloatLabel label='Probation Extension Date' name='probationExtension' className="w-11">
                          <DatePicker
                            className='float-control'
                            name="probationExtension"
                            onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                            defaultValue={dayjs(date, dateFormat)} format="YYYY-MM-DD"
                          />
                        </FloatLabel>
                      </div>)}
                    <div>
                      <EmailAppraisalContent questionsArray={questionsArray} appraiserFeedback={appraiserFeedback} functionalAttributes={functionalAttributes} keyPerformance={keyPerformance} organisationAttributes={organisationAttributes} personalAttributes={personalAttributes} reviewType={values.reviewtype ?? reviewtype} salaryRevised={values.salaryRevised !== '' ? values.salaryRevised : date} probationExtension={values.probationExtension !== '' ? values.probationExtension : date} incrementEligible={values.incrementEligible} getReviewsApi={'getReviewsApi'} employeeApiKey={employeeApiKey} employeeReviewId={employeeReviewId} employeeId={employeeId} userId={userId} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {loader && <Loader />}
          </div>
        </section>
      </div>
    </div>
  )
}