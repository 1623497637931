import React from 'react'
import img from "../../../../../../assets/images/cnwlogo.png"
import DeprovisionFormMail from './DeprovisionFormMail'


const EmailDeprovision = () => {

  return (
    <div>
      <div className="main-container" id="formToConvert">
        <div className="probation bg-[#DFE1F1]  py-16 " >
          <section className="sheet rounded-[50px]  py-15">
            <div className="main-content pb-10 mt-10">
              <div className="logo-header">
                <img src={img} alt="logo" />
              </div>

              <div className="flex justify-center">
                <h1> Exit Interview Form </h1>
              </div>
              <div className="flex justify-end">
                <h3 className='text-[#595959] text-[18px] font-semibold '>Date : <span>DD/MM/YYYY</span></h3>
              </div>
              <div className="main pb-10">
                <ul>
                  <li><span>Employee Name</span><span className="dept">Ranjith</span></li>
                  <li><span>Designation</span><span className="dept">JS</span></li>
                  <li> <span>Reporting Manager</span><span className="dept">Ranjith</span></li>
                  <li><span>Resignation Date</span><span className="dept">12/12/12</span></li>
                </ul>
                <ul>
                  <li><span>Employee ID</span><span className="dept">4444</span></li>
                  <li><span>Date Of Joining</span> <span className="dept">12/12/12</span></li>
                  <li><span>Department</span><span className="dept">UI </span>
                  </li>
                  <li><span>Last Working Date</span><span className="dept">12/12/12</span></li>
                </ul>
              </div>
              <div>
                <DeprovisionFormMail />
              </div>
            </div>
            <div>

            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default EmailDeprovision