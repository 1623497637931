import React from 'react'
import { Input } from 'antd';
import { ErrorMessage } from '../../../../../../common/CommonExport';
export default function TextAreaRendering(props: any) {
  const { TextArea } = Input;
  const { handleAppraisals, significantAchievementsError, improvementAreasError, technicalSkillsError, softSkillsError, appraiserCommentsError, appraiserFeedback, isView, appraisercommentsNew, softskills, technicalskills, improvementareas, significantachievements, isPending } = props;
  return (
    <div>
      <div className="appraisal-comments mb-5">
        <div className="grid grid-cols-2 gap-4">
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Significant Achievements*</h3>
            <TextArea disabled={!!(isView === true && isPending === false)} rows={3} style={{ resize: 'none' }} required={significantAchievementsError} data-testid="significant-achievements" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "significantachievements") }} value={isView ? appraiserFeedback[0]?.otherText : significantachievements} />
            {significantAchievementsError && <span className='text-area'> <ErrorMessage message='This field is mandatory*' /></span>}
          </div>
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Improvement Areas*</h3>
            <TextArea disabled={!!(isView === true && isPending === false)} rows={3} style={{ resize: 'none' }} required={improvementAreasError} data-testid="Improvement Areas" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "improvementareas") }} value={isView ? appraiserFeedback[1]?.otherText : improvementareas} />
            {improvementAreasError && <span className='text-area'> <ErrorMessage message='This field is mandatory*' /></span>}
          </div>
        </div>
      </div>
      <div className="appraisal-comments">
        <h3 className='general-assessment-header'>Training Requirements</h3>
        <div className="grid grid-cols-2 gap-4">

          <div className="appraisal-comment">
            <h3 className='general-assessment-subtitle py-4'>Technical Skills*</h3>
            <TextArea disabled={!!(isView === true && isPending === false)} rows={3} style={{ resize: 'none' }} required={technicalSkillsError} data-testid="Technical Skills" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "technicalskills") }} value={isView ? appraiserFeedback[2]?.otherText : technicalskills} />
            {technicalSkillsError && <span className='text-area'> <ErrorMessage message='This field is mandatory*' /></span>}
          </div>
          <div className="appraisal-comment">
            <h3 className='general-assessment-subtitle py-4'>Soft Skills*</h3>
            <TextArea disabled={!!(isView === true && isPending === false)} rows={3} style={{ resize: 'none' }} required={softSkillsError} data-testid="Soft Skills" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "softskills") }} value={isView ? appraiserFeedback[3]?.otherText : softskills} />
            {softSkillsError && <span className='text-area'> <ErrorMessage message='This field is mandatory*' /></span>}
          </div>
        </div>
      </div>
      <div className="appraisal-comments my-5">
        <div className="grid grid-cols-1 gap-4">
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Appraiser Comments*</h3>
            <TextArea disabled={!!(isView === true && isPending === false)} rows={3} style={{ resize: 'none' }} required={appraiserCommentsError} data-testid="Appraiser Comments" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "appraisercomments") }} value={isView ? appraiserFeedback[4]?.otherText : appraisercommentsNew} />
            {appraiserCommentsError && <span className='text-area'> <ErrorMessage message='This field is mandatory*' /></span>}
          </div>
        </div>
      </div>
    </div>)
}
