import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    userData: any;
}

const initialState: AuthState = {
    userData: [],
};

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<string>) => {
            state.userData = action.payload;
        },
        clearUserData: state => {
            state.userData = [];
        },
    },
});

export const { setUserData, clearUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
