import { Table } from 'antd';
import React from 'react'

const KpiTable = (props: any) => {
  const { tableData } = props
  const columnData = [
    {
      title: <p className='text-center'>Assignee</p>,
      dataIndex: 'name',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row w-[250px]"}>
          <p >{record.name}</p>
        </div>
      ),
    },
    {
      title: 'Total Tasks',
      dataIndex: 'issueCount',
      key: 'issueCount',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.issueCount}</p>
        </div>
      ),
    },
    {
      title: 'Hours',
      children: [
        {
          title: 'Estimated',
          dataIndex: 'totalOriginalEstimateHours',
          key: 'totalOriginalEstimateHours',
          render: (_: any, record: any) => (
            <div className={getColor(record.employeeScore) + " row"}>
              <p >{record.totalOriginalEstimateHours}</p>
            </div>
          ),
        },
        {
          title: 'Consumed',
          dataIndex: 'totalTimeSpentHours',
          key: 'totalTimeSpentHours',
          render: (_: any, record: any) => (
            <div className={getColor(record.employeeScore) + " row"}>
              <p >{record.totalTimeSpentHours}</p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Time Deviation %',
      dataIndex: 'deviationPercentage',
      key: 'deviationPercentage',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.deviationPercentage}</p>
        </div>
      ),
    },
    {
      title: 'Total lines of code',
      dataIndex: 'totalLinesOfCode',
      key: 'totalLinesOfCode',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.totalLinesOfCode}</p>
        </div>
      ),
    },
    {
      title: 'Bugs',
      children: [
        {
          title: 'Individual',
          dataIndex: 'bugs',
          key: 'bugs',
          render: (_: any, record: any) => (
            <div className={getColor(record.employeeScore) + " row"}>
              <p >{record.bugs}</p>
            </div>
          ),
        },
        {
          title: 'Links',
          dataIndex: 'linkedBugCount',
          key: 'linkedBugCount',
          render: (_: any, record: any) => (
            <div className={getColor(record.employeeScore) + " row"}>
              <p >{record.linkedBugCount}</p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Bug Fixed Hours',
      dataIndex: 'linkedBugHours',
      key: 'linkedBugHours',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.linkedBugHours}</p>
        </div>
      ),
    },
    {
      title: 'Linked Bugs %',
      dataIndex: 'bugsPercentage',
      key: 'bugsPercentage',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.bugsPercentage}</p>
        </div>
      ),
    },
    {
      title: 'Bug Score',
      dataIndex: 'bugsScore',
      key: 'bugsScore',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.bugsScore}</p>
        </div>
      ),
    },
    {
      title: 'Time deviation score',
      dataIndex: 'timeDeviationScore',
      key: 'timeDeviationScore',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.timeDeviationScore}</p>
        </div>
      ),
    },
    {
      title: 'Code Quality',
      dataIndex: 'codeQualityScore',
      key: 'codeQualityScore',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.codeQualityScore}</p>
        </div>
      ),
    },
    {
      title: 'Employee Score %',
      dataIndex: 'employeeScore',
      key: 'employeeScore',
      render: (_: any, record: any) => (
        <div className={getColor(record.employeeScore) + " row"}>
          <p >{record.employeeScore}</p>
        </div>
      ),
    },
  ];
  const getColor: any = (value: any) => {
    if (value < 40) {
      return "bg-[#FFC3C6]";
    } else if (value >= 40 && value <= 59) {
      return "bg-[#FFCFA0]  ";
    }

    else if (value >= 60 && value <= 79) {
      return "bg-[#FCEDB3]";
    }
    else if (value >= 80) {
      return "bg-[#DFF8D1]";
    }

  }
  return (
    <>
      <div className="employee-kpi-table">
        <Table dataSource={tableData} columns={columnData} />
      </div>
    </>
  )
}

export default KpiTable
