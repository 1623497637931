import React, { useState } from 'react'
import LearningPathListOfCourses from './LearningPathListOfCourses'
import { debounce } from 'lodash';


export default function LearningPathRight(props: any) {
  const { mainArray } = props;

  const [searchTerm, setSearchTerm] = useState<any>('');
  const [swapQuestion, setswapQuestion] = useState<any>('');


  const handleSearchTermChange = debounce((serachName: any) => {
    setSearchTerm(serachName);
  }, 200);

  return (
    <div className='flex w-full h-full'>

      <div className='learningPathRight-content w-full h-[70vh] overflow-y-auto level-tab'>
        <div className="w-full">
          <div className="search-box mb-4 mr-5 flex">
            <button className="search-btn mr-5">
              <span className="material-symbols-outlined" onChange={(e: any) => handleSearchTermChange(e)}>
                search
              </span>
            </button>
            <input className="search-txt  text-black" type="text" name="" value={searchTerm}
              placeholder="Search list here" onChange={(e: any) => handleSearchTermChange(e.target.value)} />
          </div>
          <div className='LearningList'>
            <LearningPathListOfCourses mainArray={mainArray} searchTerm={searchTerm} setswapQuestion={setswapQuestion} />
          </div>
        </div>
      </div>
    </div>
  )
}
