import React from 'react'
import ChapterCard from './ChapterCard'
import { StarFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

const CourseStatus = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const pathName: any = new URLSearchParams(search).get("learningPathName")

  const handelNavigate = () => {
    navigate("/home/dashboard/learning-management/progress/employee-progress")
  }
  const handelNavigateDashboard = () => {
    navigate("/home/dashboard/learning-management")
  }
  const handelNavigateTable = () => {
    navigate("/home/dashboard/learning-management/progress")
  }

  return (
    <div className='page-container bg-customBg'>
      <div className=' pl-16 pt-4' >
        <ul className='flex'>
          <li className='text-[#878787] text-[15px]' >Dashboard /</li>
          <li className='text-[#878787] text-[15px] cursor-pointer' onClick={handelNavigateDashboard}>Learning Management /</li>
          <li className='text-[#878787] text-[15px]cursor-pointer' onClick={handelNavigateDashboard}>Course /</li>
          <li className='text-[#878787] text-[15px] cursor-pointer' onClick={handelNavigateTable}>Employee Progress /</li>
          <li className='text-[15px]'>Learning Path</li>
        </ul>
      </div>
      <div className='full-width-container mt-5'>
        <div className='tab-container bg-white'>
          <div className='employee-progress'>
            <p className='flex text-[#1F1F1F] font-medium text-[20px] pt-4 pb-2 items-center'> <span className="material-symbols-outlined text-[#AEAEAE] mr-2 cursor-pointer" onClick={handelNavigate}>
              keyboard_backspace
            </span>{pathName}</p>
            <div className="py-2 px-4 ml-4 bg-[#FFF0F5] rounded-md flex ">
              <p className='  font-rubik mr-6 flex text-[#747474] text-[18px]' ><span className="material-symbols-outlined mr-2 text-[#17D951]">
                license
              </span> Total Levels : <span className='text-[#434343] font-medium'>05</span></p>
              <p className='  font-rubik mr-6 flex text-[#747474] text-[18px]' ><span className="material-symbols-outlined mr-2 text-[#1F85DD]">
                lock_open
              </span>Unlocked Levels : <span className='text-[#434343] font-medium'>05</span></p>
              <p className='flex  font-rubik text-[#747474] text-[18px]' ><StarFilled className='mr-2 text-[#FED11C]' />Earning points : <span className='text-[#434343] font-medium'> 05</span></p>
            </div>
            {/* <div className='grid grid-cols-3'>
              <div className='col-span-2'> */}
            <div className="px-4">
              <div className='listOf-course mt-4 overflow-y-auto'>
                <div>
                  <div className="employee-unlocked mb-4">
                    <div className="employee-unlocked-level flex items-center justify-center">
                      <p className='text-[#D34A7C] text-[18px]' >Unlocked level <span>(02)</span></p>
                    </div>
                  </div>
                  <div className='listOf-course '>
                    <ChapterCard />
                  </div>
                </div>
                {/* <div>
                      <div className="employee-unlocked mb-4">
                        <div className="employee-unlocked-level flex items-center justify-center">
                          <p className='text-[#D34A7C] text-[18px]' >Locked level <span>(02)</span></p>
                        </div>
                      </div>
                      <ChapterCard />
                    </div> */}
              </div>
            </div>
          </div>
          {/* <div className='ml-8 mb-4 overflow-y-auto bg-[#F5F5F5] rounded-md mt-4  '>
                <EmployeeProgressLearningPath />
              </div> */}
        </div>
      </div>
    </div>
    //   </div>
    // </div>

  )
}

export default CourseStatus
