import { Modal } from 'antd'
import React from 'react'

const CourseDescription = (props: any) => {
  const { description, handelClose } = props
  const handleClosed = () => {
    handelClose()
  }
  return (
    <>
      <Modal
        title="Course Description"
        centered
        open={description}
        footer={null}
        className='description_ '
        maskClosable={false}
        onCancel={() => handleClosed()}>
        <p className='text-[16px] text-[#333333] p-4 pl-0'>{description}</p>
      </Modal>
    </>
  )
}

export default CourseDescription
