import React from 'react';
import LoginGif from '../../assets/images/login-img.gif';
import AllAboardLogo from '../../assets/images/logo.png';
import ImagesComponent from '../../components/Images/Images';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword: React.FC = () => {
    return (
        <div className='backgroundImage h-screen w-full login-left-content'>
            <div className='bg-transparent md:flex md:flex-row login'>
                <div className='md:w-2/3 flex items-center justify-center'>
                    <div>
                        <ImagesComponent alt='login' src={LoginGif} className='object-center lg:w-61 p-3 login-img' />
                        <div className='pt-5 text-center font-sans font-rubik login-sitename'>
                            <span className='font-rubik font-sans text-black flex items-center justify-center text-4xl font-light mb-0'>
                                <ImagesComponent className='w-16' alt='loginImage' src={AllAboardLogo} />
                                <p className='pb-1 text-5xl'>ALL-Aboard!</p>
                            </span>
                            <p className='text-base text-sm unique-platform-text'>A unique platform for employee onboarding</p>
                        </div>
                    </div>
                </div>
                <div className='md:w-1/3 containerTwoWrapper'>
                    <ForgotPasswordForm />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
