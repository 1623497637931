import { Route, Routes, useNavigate, retrieveData, useDispatch } from '../common/CommonExport';
import Home from '../pages/Home/Home';
import Login from '../pages/login/Login';
import ProtectedRoute from './PrivateRoute';
import Organization from '../pages/Organization/Organization';
import AssetMaster from '../components/AssetMaster/AssetMaster';
import SalaryStructure from '../pages/SalaryStructure/SalaryStructure';
import Users from '../pages/Users/Users';
import ProjectList from '../pages/Project/ProjectList/ProjectList';
import ProjectBoard from '../pages/Project/ProjectBoard/ProjectBoard';
import ForgotPassword from '../pages/forgot-password/ForgotPassword';
import ProjectBoardDetails from '../pages/Project/ProjectBoard/ProjectBoardDetails';
import Employee from '../pages/employee/employee-details';
import BasicForm from '../pages/BasicForm/BasicForm';
import KpiCalender from '../pages/employee/employee-details/employee-profile/layout/Kpi/KpiCalender';
import PreviewDocumentDetail from '../pages/mailTemplate/PreviewDocumentDetail';
import DesignationApproval from '../pages/mailTemplate/designation-approval/DesignationApproval';
import LearningManagementTab from '../pages/LearningManagement/LearningManagementTab';
import CourseDetails from '../pages/LearningManagement/CourseDetails/CourseDetails';
import SetPassword from '../pages/SetPassword/SetPassword';
import EmailAppraisalForm from '../pages/employee/employee-details/employee-profile/layout/Appraisal/EmailAppraisal/EmailAppraisalForm';
import { clearEventData } from '../redux/slices/empEventsSlice';
import LeaveManagement from '../pages/LeaveManagement/LeaveManagement';
import NewLearningPath from '../pages/learningPath/NewLearningPath';
import AssignedEmployee from '../pages/learningPath/AssignedEmployee';
import Attanance from '../pages/LeaveManagement/attenance/Attanance';
import Holidays from '../pages/LeaveManagement/holidays/Holidays';
import EmployeeProgress from '../pages/LearningManagement/employee-progress/EmployeeProgress';
import EmployeeProgressCourseListAndLearningPath from '../pages/employeeProgress/EmployeeProgressCourseListAndLearningPath';
import EmployeeKpi from '../pages/employee-kpi/EmployeeKpi';
import CourseStatus from '../pages/employeeProgress/CourseStatus';
import HomePage from '../pages/client-onboarding/HomePage';
import Signup from '../pages/client-onboarding/signup/Signup';
import VerifyAccount from '../pages/client-onboarding/signup/VerifyAccount';
import Payments from '../pages/client-onboarding/payment/Payments';
import WelcomePage from '../pages/welcome-page/WelcomePage';
import Organisation from '../pages/Organization/EditOrganisation/Organisation';

import AssignedEmployeeToCourseTable from '../pages/LearningManagement/Course/AssignedEmployeeToCourseTable';
import EmailDeprovision from '../pages/employee/employee-details/employee-profile/layout/deprovision/EmailDeprovision';
import Announcements from '../pages/announcement/Announcements';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
function Routing() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let isLoggedIn = retrieveData('isLoggedIn', true);
    let token = retrieveData('authToken', true);
    if (isLoggedIn && !token) {
        setTimeout(() => {
            navigate('/login');
            dispatch(clearEventData());
        }, 1);
    }
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/set-password' element={<SetPassword />} />
            <Route path='/basicform' element={<BasicForm />}></Route>
            <Route path='/employee-information-form' element={<BasicForm />}></Route>
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/preview-document-detail' element={<PreviewDocumentDetail />}></Route>
            <Route path='/employee-designation-approval' element={<DesignationApproval />}></Route>
            <Route path='/employee-bonus-update-status/' element={<DesignationApproval />}></Route>
            <Route path='/leave-request-update-status/' element={<DesignationApproval />}></Route>
            <Route path='/deprovision-form' element={<EmailDeprovision />}></Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/reset-password' element={<SetPassword />} />
            <Route path='/probation-completion' element={<EmailAppraisalForm />} />
            <Route path='/register' element={<HomePage />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/signup/verification' element={<VerifyAccount />} />
            <Route path='/signup/payments' element={<Payments />} />

            {/* protected url map */}
            <Route element={<ProtectedRoute />}>
                <Route path='/home/dashboard' element={<Home />}></Route>
                <Route path='/home/organisation' element={<Organization />}></Route>
                <Route path='/home/organisation/edit-organisation' element={<Organisation />}></Route>
                <Route path='/home/asset-master' element={<AssetMaster />}></Route>
                <Route path='/home/salary-master' element={<SalaryStructure />}></Route>
                <Route path='/home/users' element={<Users />}></Route>
                <Route path='/home/project-list' element={<ProjectList />}></Route>
                <Route path='/home/project-list/project-board' element={<ProjectBoard />}></Route>
                <Route path='/home/project-list/project-board-details' element={<ProjectBoardDetails />}></Route>
                <Route path='/home/kpi-calender' element={<KpiCalender />}></Route>
                <Route path='/home/dashboard/learning-management' element={<LearningManagementTab />}></Route>
                <Route path='/home/dashboard/leaveMangement' element={<LeaveManagement />}></Route>
                <Route path='/home/dashboard/newLearningPath' element={<NewLearningPath />}></Route>
                <Route path='/home/dashboard/assignedEmployee' element={<AssignedEmployee />}></Route>
                <Route path='/employee/details/:id' element={<Employee />}></Route>
                <Route path='/home/dashboard/attanance' element={<Attanance />}></Route>
                <Route path='/home/dashboard/holiday' element={<Holidays />}></Route>
                <Route path='/home/dashboard/learning-management/progress' element={<EmployeeProgress />}></Route>
                <Route path='/home/dashboard/learning-management/progress/employee-progress' element={<EmployeeProgressCourseListAndLearningPath />}></Route>
                <Route path='/home/dashboard/kpi' element={<EmployeeKpi />}></Route>
                <Route path='/home/dashboard/learning-management/course-status' element={<CourseStatus />}></Route>
                <Route path='/home/dashboard/learning-management/courseDetails' element={<CourseDetails />}></Route>
                <Route path='/home/welcome' element={<WelcomePage />} />
                <Route path='/home/dashboard/announcements' element={<Announcements />} />



                <Route path='/home/dashboard/learning-management/assignedemployee/:id' element={<AssignedEmployeeToCourseTable />}></Route>
            </Route >
        </Routes >
    );
}

export default Routing;
