export const filterNavigationItems = (navigationItems: any, permissions: any) => {
    return navigationItems.filter((item: any) => {
        const matchingPermission = permissions?.find((permission: any) => permission.component.name === item.permission);
        // Check if at least one of the permissions is true
        if (matchingPermission) {
            const { isView, isCreate, isEdit, isDelete } = matchingPermission;
            if (isView || isCreate || isEdit || isDelete) return true; // Keep the item
        } else return true; //// Keep the item if no matching permission is found
        return false; // Remove the item if matching permission is found and none of the permissions is true
    });
};
