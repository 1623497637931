import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  rolePermissions: any;
}

const initialState: AuthState = {
  rolePermissions: [],
};

const rolePermissionsSlice = createSlice({
  name: 'rolePermissions',
  initialState,
  reducers: {
    setRolePermissions: (state, action: PayloadAction<any>) => {
      state.rolePermissions = action.payload;
    },
    clearRolePermissions: state => {
      state.rolePermissions = [];
    },
  },
});

export const { setRolePermissions, clearRolePermissions } = rolePermissionsSlice.actions;
export default rolePermissionsSlice.reducer;
