import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import QuestionAllocation from '../assessment/chapter/QuestionAllocation';
import { useSelector, useDispatch } from 'react-redux';
import UploadBulkQuestions from '../assessment/chapter/UploadBulkQuestions';
import MultiChoiceQuestions from '../assessment/chapter/multichoice-questions/MultiChoiceQuestions';
import {
  clearAssessmentAssignChapterID, clearChapterInEdit,
  clearCurrentAssessmentTab, clearQuizChapterMapPayload, setCurrentAssessmentTab, setFinalAssessmentAddOrEdit
} from '../../../redux/slices/currentCourseDetailSlics';
import { setCurrentTab } from '../../../redux/slices/courseSlice';
import { questionCurrentTab } from '../../../redux/slices/multipleQuestions';

const AddFinalAssessment = (props: any) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(1);
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);

  useEffect(() => {
    dispatch(setCurrentAssessmentTab('2'))
    setShowComponent(reduxToken)
    setIsOpen(open);
  }, [open, reduxToken]);

  const handel = () => {
    dispatch(setFinalAssessmentAddOrEdit(false))
    setIsOpen(false);
    dispatch(setCurrentTab(1))
    dispatch(clearCurrentAssessmentTab())
    dispatch(questionCurrentTab(1))
    dispatch(clearChapterInEdit())
    dispatch(clearQuizChapterMapPayload())
    handleClose();
    dispatch(clearAssessmentAssignChapterID())
  }

  return (
    <div>
      <Modal title={'Add Final Assesment'} className='addcourse w-full final-course' maskClosable={false} centered open={isOpen} footer={null} onCancel={handel}>
        <div className="main w-full">
          <div className='main-container w-full overflow-y-auto mt-5 final-assessment overflow-x-hidden'>
            {showComponent === 8 && <QuestionAllocation handle={handel} />}
            {showComponent === 10 && <MultiChoiceQuestions />}
            {showComponent === 9 && <UploadBulkQuestions />}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddFinalAssessment