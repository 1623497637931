import React from 'react'
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { Select } from 'antd';
import "./chapter/Addcertificate.scss"

const AddCertificateFormikFields = ({ initialValues, validationSchema, onSubmit, certificateFields,
  dBFormFields }: any) => {
  return (
    < div className='add-certificate-details'>
      <div className='grid grid-cols-2  add-certificate-form-top'>
        <div><p className='text-[#645F61] text-[16px] font-rubik font-medium'>DB DATA (Automatically Generated)</p></div>
        <div><p className='text-[#645F61] text-[16px] font-rubik font-medium pl-5'>Form Fields</p></div>

      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, values, setFieldValue, isValidating }) => (
          <Form onSubmit={handleSubmit}>
            {certificateFields?.second.map((field: any, index: any) => (
              <div key={field.pdfFormField} className='grid grid-cols-2'>
                <div>
                  <div className='flex mt-[10px]'>
                    <p className='mr-4 text-[#434343] text-[16px] font-rubik font-medium '>{index + 1}</p>
                    <label className='text-[#434343] text-[16px] font-rubik font-medium ' htmlFor={field.pdfFormField}>{field.pdfFormField}</label>
                  </div>
                </div>
                <div>
                  <Field as={Select} className='float-control' name={field.pdfFormField}
                    value={values[field.pdfFormField]} onChange={(value: any) => { setFieldValue(field.pdfFormField, value) }}>
                    {dBFormFields?.map((item: any) => (
                      <Select.Option key={item?.value} value={item?.value} >
                        {item?.value}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={field.pdfFormField} component="div" className="error-message" />
                </div>
              </div>
            ))}
            <div className="footer mb-2 absolute right-5 bottom-0 mr-[10px]">
              <div className="btnGroup flex justify-end">
                <button className='addButton primary px-5 confirmElms' type="submit" disabled={isValidating}>Save</button>
              </div></div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddCertificateFormikFields
