import { Avatar } from 'antd'
import React from 'react'

const Namecard = (props: any) => {
  const { record } = props
  const setFirstLetterandLastLetter = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words?.[0]?.[0]?.toUpperCase();
      const lastNameInitial = words[words?.length - 1]?.[0]?.toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words?.[0]?.[0]?.toUpperCase();
    } else {
      return '';
    }
  };
  return (
    <div className='name-card '>
      <Avatar.Group
        maxCount={3}
        maxPopoverTrigger="click"
        size="large"
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
      >
        <Avatar style={{ backgroundColor: '#FFD6D6', color: '#D34A7C' }}>{setFirstLetterandLastLetter(record?.employeename)}</Avatar>
      </Avatar.Group>
    </div>
  )
}

export default Namecard
