import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').max(15, 'Password must not exceed 15 characters'),
    domainName: Yup.string().required('Domain name is required'),
    emailId: Yup.string().required('Email id is required').email('Invalid email'),
    mobileNumber: Yup.string().required('Phone number is required').min(10, 'Phone number must be at least 10 characters').max(10, 'Phone number must not exceed 10 characters'),
});

export const initialState = {
    organisationDto: {
        domainName: '',
        emailId: '',
        mobileNumber: '',
    },
    name: '',
    password: '',
    designation: '',
    // subscriptionType: '',
    subscriptionPeriod: '',
    emailId: '',
    domainName: '',
    mobileNumber: '',
};
