import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  eventData: any;
}

const initialState: AuthState = {
  eventData: {},
};

const coueseFilterSlice = createSlice({
  name: 'eventData',
  initialState,
  reducers: {
    setFiltertData: (state, action: PayloadAction<any>) => {
      state.eventData = action.payload;
    },
    clearFilterData: state => {
      state.eventData = {};
    },
  },
});

export const { setFiltertData, clearFilterData } = coueseFilterSlice.actions;
export default coueseFilterSlice.reducer;
