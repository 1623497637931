import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CurrentCourseState {
  flow: boolean
  courseId: number;
  assessmentAssignChapterID: any;
  quizChapterMapPayload: any;
  currentAssessmentTab: any;
  chapterinTableEdit: any;
  assessmentTabToStart: boolean;
  allChapterList: any;
  finalAssessmentInCourse: boolean;
  addNewChapterFlow: boolean;
  currentQuestion: object
  courseDetails: object
}

const initialState: CurrentCourseState = {
  flow: false,
  courseId: 0,
  assessmentAssignChapterID: null,
  quizChapterMapPayload: null,
  currentAssessmentTab: '1',
  chapterinTableEdit: {},
  assessmentTabToStart: false,
  allChapterList: {},
  finalAssessmentInCourse: false,
  addNewChapterFlow: false,
  currentQuestion: {},
  courseDetails: {
    courseStatus: 'draft',
    employeeCount: 0
  }
};

const currentCourseSlice = createSlice({
  name: 'currentCourse',
  initialState,
  reducers: {
    setCurrentCourseId: (state, action: PayloadAction<number>) => {
      state.courseId = action.payload;
    },
    clearCurrentCourseId: state => {
      state.courseId = 0;
    },
    setAssessmentAssignChapterID: (state, action: PayloadAction<number>) => {
      state.assessmentAssignChapterID = action.payload;
    },
    clearAssessmentAssignChapterID: state => {
      state.assessmentAssignChapterID = null;
    },
    setQuizChapterMapPayload: (state, action: PayloadAction<any>) => {
      state.quizChapterMapPayload = action.payload;
    },
    clearQuizChapterMapPayload: state => {
      state.quizChapterMapPayload = null;
    },
    setCurrentAssessmentTab: (state, action: PayloadAction<any>) => {
      state.currentAssessmentTab = action.payload;
    },
    clearCurrentAssessmentTab: state => {
      state.currentAssessmentTab = '1';
    },
    setChapterInEdit: (state, action: PayloadAction<any>) => {
      state.chapterinTableEdit = action.payload;
    },
    clearChapterInEdit: state => {
      state.chapterinTableEdit = {};
    },
    setFlowDirection: (state, action: PayloadAction<any>) => {
      state.flow = action.payload;
    },
    setAssessmentAssignTab: state => {
      state.assessmentTabToStart = !state.assessmentTabToStart;
    },
    setAllChapterList: (state, action: PayloadAction<any>) => {
      state.allChapterList = action.payload;
    },
    clearAllChapterList: state => {
      state.allChapterList = {};
    },
    setFinalAssessmentAddOrEdit: (state, action: PayloadAction<any>) => {
      state.finalAssessmentInCourse = action.payload;
    },
    setAddChapterNewFlow: (state, action: PayloadAction<any>) => {
      state.addNewChapterFlow = action.payload;
    },
    setCurrentQuestion: (state, action: PayloadAction<any>) => {
      state.currentQuestion = action.payload;
    },
    clearCurrentQuestion: (state) => {
      state.currentQuestion = {};
    },
    setCourseDetails: (state, action: PayloadAction<any>) => {
      state.courseDetails = action.payload;
    },
  },
});

export const { setCurrentCourseId, clearCurrentCourseId,
  setAssessmentAssignChapterID, clearAssessmentAssignChapterID,
  setQuizChapterMapPayload, clearQuizChapterMapPayload,
  setCurrentAssessmentTab, clearCurrentAssessmentTab,
  setChapterInEdit, clearChapterInEdit, setFlowDirection, setAssessmentAssignTab,
  setAllChapterList, clearAllChapterList, setFinalAssessmentAddOrEdit,
  setAddChapterNewFlow, setCurrentQuestion, clearCurrentQuestion, setCourseDetails } = currentCourseSlice.actions;
export default currentCourseSlice.reducer;
