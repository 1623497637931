import React from 'react'
import "./BusinessPlan.scss"
import PlanCard from './PlanCard'
import { storeData } from '../../../storage/Storage'

const BusinessPlan = () => {
  const basicPlan: any = ["Employee Management", "Correspondence & Document Management", "24x7 Support", "Email Support"]
  const businessPlan = ["Everything in Free plus", "Leave Management", "Geofencing", "Learning Management", "MFA & SSO", "Mobile Application", "Company Asset Management", "Unlimited Integrations"]
  const enterprisePlan = ["Everything in Business plus", "Custom Learning Paths", "Appraisal Management", "Employee KPI Management"]
  const handelSubscription = (value: any) => {
    if (value.target.checked) storeData("subscriptionType", "yearly", true)
    else storeData("subscriptionType", "monthly", true)
  }
  return (
    <div>
      <div className="btn-container flex justify-end pb-16">
        <label className="switch btn-color-mode-switch relative">
          <input type="checkbox" name="color_mode" id="color_mode" onChange={(e) => handelSubscription(e)} />
          <label htmlFor="color_mode" data-on="Dark" data-off="Light" className="btn-color-mode-switch-inner"></label>
        </label>
      </div>
      <div className="business-plans  flex ">
        <PlanCard basicPlan={basicPlan} type={"Free"} />
        <PlanCard basicPlan={businessPlan} type={"Business"} />
        <PlanCard basicPlan={enterprisePlan} type={"Enterprise"} />
      </div>
    </div>
  )
}

export default BusinessPlan
