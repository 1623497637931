import { AxiosError, AxiosResponse } from 'axios';

import axios from './apiInterceptors';

const useGoogleLoginApi = (): any => {
    const apiCalls = async (token: any) => {
        return new Promise<AxiosResponse | AxiosError>((resolve, reject) => {
            axios({
                method: 'GET',
                url: 'https://www.googleapis.com/oauth2/v1/userinfo',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res: AxiosResponse) => {
                    resolve(res);
                })
                .catch((err: AxiosError) => {
                    reject(err);
                });
        });
    };

    return apiCalls;
};

export default useGoogleLoginApi;
