import React, { ChangeEvent, useEffect, useState } from "react";
import { enterpriseSchema, handleGetOrganisationDetailsById, initialStateEnterprise } from "../Organization/EditOrganisation/EditOrganisationHelper";
import { Formik, Form } from "formik";
import BasicOrganisationDetails from "./BasicOrganisationDetails";
import AddressOfEmployee from "../BasicForm/addressOfEmployee";
import { State } from 'country-state-city';
import EditOrganisationSectionTwo from "../Organization/EditOrganisation/EditOrganisationSectionTwo";
import { ErrorMessages, triggerNotification, useGetApiRequests } from "../../common/CommonExport";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../Organization/DeleteConfirmationModal";
import ModalLoader from "../../components/Loader/ModalLoader";

const CreateOrganisationEnterprise = ({ handelClose }: any) => {
  const getOrgDetails = useGetApiRequests('getOrganisationDetails', 'GET');
  const updateOrganisationDetails = useGetApiRequests('editOrganisation', 'PUT');
  const industryTypeLists = useGetApiRequests('getIndustryTypeList', 'GET');
  const verifyDoaminName = useGetApiRequests('verifyDomainName', 'GET');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [initValues, setInitValues] = useState<any>()
  const [dropDownList, setDropDownList] = useState<any>({})
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedhrImage, setSelectedhrImage] = useState<string>("");
  const [imageDataLogo, setImageDataLogo] = useState<any>();
  const [imageDataSign, setImageDataSign] = useState<any>();
  const [industryTypes, setIndustryTypes] = useState<any>("");
  const [loader, setLoader] = useState<any>(false)
  const deleteMessage = 'Domain Name has been changed Verify to Proceed.'
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [verifyDomainName, setVerifyDomainName] = useState<boolean>(false);

  const getIndustryList = async () => {
    setLoader(true)
    try {
      const response: any = await industryTypeLists()
      setIndustryTypes(response?.data?.data)
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
    }
  }

  useEffect(() => {
    handleGetOrganisationDetailsById({ getOrgDetails, orgId, setInitValues, setSelectedImage, setSelectedhrImage })
    const states = State.getStatesOfCountry('IN');
    setDropDownList({ states: states, cState: states })
    // setInitValues(initialStateEnterprise)
    getIndustryList()
  }, [])

  const handleUpdateOrganisationDetails = async (values: any) => {
    setLoader(true)
    const payload = { organisationDto: values }
    const formData = new FormData();
    if (imageDataLogo) formData.append('organisationLogo', imageDataLogo);
    if (imageDataSign) formData.append('hrSignature', imageDataSign);
    const header = { 'Content-Type': 'multipart/form-data' };
    try {
      const response: any = await updateOrganisationDetails(formData, payload, { organisationId: orgId }, header)
      setLoader(false)
      handelClose()
    } catch (err: any) {
      // handelClose()
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.message ?? ErrorMessages.somethingWentWrong, 'topRight');
    }
  }

  const onSubmit = (values: any) => {
    if (initValues?.domainName !== values?.domainName && !verifyDomainName) {
      setDeletePopUp(true)
      return
    }
    const flattenedObj = { ...values, ...values?.employeeDto };
    delete flattenedObj.employeeDto;
    handleUpdateOrganisationDetails(flattenedObj)
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files?.[0];
    setImageDataLogo(file);
    if (file) setSelectedImage(URL.createObjectURL(file));
  };
  const handlehrImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files?.[0];
    setImageDataSign(file);
    if (file) setSelectedhrImage(URL.createObjectURL(file));
  };

  const handleVerifyDomain = async (domainName: any, setFieldValue: any) => {
    setLoader(true)
    const queryParam = { subDomainName: domainName }
    try {
      const res: any = await verifyDoaminName('', queryParam)
      if (res?.data?.status?.message !== 'Domain does not exist') {
        setFieldValue('domainName', '');
      } else if (res?.data?.status?.message === 'Domain does not exist') {
        setVerifyDomainName(true)
      }
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.status?.message ?? ErrorMessages.somethingWentWrong, 'topRight');
    }
  }

  const handleCloseDelete = () => setDeletePopUp(false);

  return (
    <div>
      {loader && <ModalLoader />}
      {(industryTypes?.length > 0 && initValues) &&
        <Formik
          initialValues={initValues}
          validationSchema={enterpriseSchema}
          onSubmit={onSubmit}
          values={initValues}
          enableReinitialize={true}
        >
          {({ handleSubmit, values, setFieldValue, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='w-full flex'>
                  <div className='w-9/12'>
                    <BasicOrganisationDetails values={values} setFieldValue={setFieldValue} industryTypes={industryTypes} handleVerifyDomain={handleVerifyDomain} />
                  </div>
                  <div className='p-5 w-1/4' style={{ width: '25%' }}>
                    <EditOrganisationSectionTwo selectedImage={selectedImage} selectedhrImage={selectedhrImage} handleImageUpload={handleImageUpload}
                      handlehrImage={handlehrImage} />
                  </div>
                </div>
                {Object.keys(initValues?.employeeDto)?.length > 0 &&
                  <div className='edit-organisation_address'>
                    <AddressOfEmployee values={values} setFieldValue={setFieldValue} dropDownList={dropDownList} setDropDownList={setDropDownList} errorStyle={true} />
                  </div>
                }
                <div className='mt-5'>
                  <button type="submit" className='addButton mr-4 secondary organisation-btn'  >
                    Save
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
      {deletePopUp && <DeleteConfirmationModal contentChange={true} open={deletePopUp} handleDeleteSelected={handleCloseDelete} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
    </div>
  )
}

export default CreateOrganisationEnterprise