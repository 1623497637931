const columnData = [
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    key: 'assignee',
  },
  {
    title: 'Total Tasks',
    dataIndex: 'totaltask',
    key: 'totaltask',
  },
  {
    title: 'Hours',
    children: [
      {
        title: 'Estimated',
        dataIndex: 'estHrs',
        key: 'estHrs',
      },
      {
        title: 'Consumed',
        dataIndex: 'consumedHrs',
        key: 'consumedHrs',
      },
    ],
  },
  {
    title: 'Development',
    children: [
      {
        title: 'Open',
        dataIndex: 'devOpen',
        key: 'devOpen',
      },
      {
        title: 'In Progress',
        dataIndex: 'devInprogress',
        key: 'devInprogress',
      },
      {
        title: 'Done',
        dataIndex: 'devDone',
        key: 'devDone',
      },
    ],
  },
  {
    title: 'Quality Assurance',
    children: [
      {
        title: 'Ready',
        dataIndex: 'qaReady',
        key: 'qaReady',
      },
      {
        title: 'In Progress',
        dataIndex: 'qaInprogress',
        key: 'qaInprogress',
      },
      {
        title: 'Completed',
        dataIndex: 'qaCompleted',
        key: 'qaCompleted',
      },
    ],
  },
  {
    title: 'Closed',
    dataIndex: 'closedTask',
    key: 'closedTask',
  },
  {
    title: 'Bugs',
    children: [
      {
        title: 'Total',
        dataIndex: 'totalBugs',
        key: 'totalBugs',
      },
      {
        title: 'Open',
        dataIndex: 'openBugs',
        key: 'openBugs',
      },
    ],
  },
];

export default columnData;
