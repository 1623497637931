import React, { useState } from 'react'
import setWelcome from "../../assets/images/client-onboard/welcome-img.png"
import CreateOrganisation from './CreateOrganisation'
import done from "../../assets/images/client-onboard/image-removebg-preview.png"
import AddEmployeeModal from '../Dashboard/AddEmployeeModal/AddEmployeeModal'
import AddAttenanceModal from '../LeaveManagement/attenance/AddAttenanceModal'

const WelcomePage = () => {
  const [opened, setOpen] = useState<boolean>(false)
  const [isOpen, setIsopen] = useState(false);

  const [open, setOpened] = useState(false);

  const handelVerification = () => {
    setOpen(true)
  }
  const handelClose = () => {
    setOpen(false)


  }

  const handelAddEmployee = () => {
    setIsopen(true)
  }
  const handleClose = () => {
    setIsopen(false)
    setOpened(false)

  }
  const handelBulkUpload = () => {
    setOpened(true)
  }
  return (
    <div className='welcome-page'>
      <div className="welcome-content flex items-center justify-center h-[100vh] flex-col">
        <p className='font-rubik text-[25px] text-[#696969] font-semibold mr-[4rem] pb-[30px]'>Welcome, John Doe</p>
        <img src={setWelcome} alt="set Organisation" />
        <div className='flex justify-center flex-col items-center  pr-[4rem] pt-[30px] '>
          <p className='font-rubik text-[24px] text-[#696969] pb-[40px]'>Let’s get you started</p>
          <button type='submit' className='addButton w-[400px] mb-6 ' onClick={() => handelVerification()}>
            Set Organisation
          </button>
        </div>
      </div>
      {opened && <CreateOrganisation isopen={opened} handelClose={handelClose} />}

      <div className="welcome-content flex items-center justify-center h-[100vh] flex-col">
        <p className='font-rubik text-[25px] text-[#696969] font-semibold mr-[4rem] pb-[30px]'>Almost Done!</p>
        <img src={done} alt="set Organisation" />
        <div className='flex justify-center flex-col items-center  pt-[30px] '>
          <p className='font-rubik text-[24px] text-[#696969] pb-[40px]'>Select below option to add employee to your organisation</p>
          <div className="flex justify-center">
            <button type='submit' className='addButton w-[200px] mb-6 mr-6 ' onClick={handelAddEmployee}>
              Add employee
            </button>
            <button type='submit' className='addButton addemploye py-3 h-[50px] w-[200px] mb-6 ' onClick={handelBulkUpload}>
              Bulk Upload
            </button>
          </div>
        </div>
      </div>
      {isOpen && <AddEmployeeModal isOpen={isOpen} handleClose={handleClose} setIsOpen={isOpen} />}
      {open && <AddAttenanceModal open={open} handleClose={handleClose} bulk={true} />}
    </div>
  )
}

export default WelcomePage
