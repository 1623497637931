import React, { useEffect, useState } from 'react';
import { useGetApiRequests } from '../../common/CommonExport';
import { useLocation } from 'react-router-dom';
import ConfidentialityLetter from './confidentialityLetter/ConfidentialityLetter';
import PerformanceImprovement from './deprovision-letters/PerformanceImprovement';

const PreviewDocumentDetail = () => {
  const getDocumentDetails = useGetApiRequests('getDocumentDetails', 'GET');
  const [getLetterDetails, setgetLetterDetails] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employeeId = searchParams.get('employeeId');
  const documentCode = searchParams.get('documentCode');

  useEffect(() => {
    if (documentCode === "PBL" || documentCode === "PROJTBL") {
      getProjectBonusData()
    }
    else {
      getDocumentDetail()
    }
  }, [])
  const getDocumentDetail = () => {
    const params = {
      "employeeId": employeeId,
      "documentCode": documentCode
    }
    getDocumentDetails('', {}, params).then((response: any) => {
      if (response) {
        setgetLetterDetails(response?.data?.data)
      }
    })
      .catch((err: any) => {
        console.error(err);
      });
  }
  const getProjectBonusData = () => {
    const params = {
      "employeeId": employeeId,
      "documentCode": documentCode
    }
    getDocumentDetails(params, {}, params).then((response: any) => {
      if (response) {
        setgetLetterDetails(response?.data?.data)
      }
    })
      .catch((err: any) => {
        console.error(err);
      });
  }
  return (
    <div data-testid="template-container" >
      {documentCode === "INTNAGR" &&
        <ConfidentialityLetter getLetterDetails={getLetterDetails} />
      }
      {documentCode === "CONFAGR" &&
        <ConfidentialityLetter getLetterDetails={getLetterDetails} />
      }
      {documentCode === "APPTLTR" &&
        <ConfidentialityLetter getLetterDetails={getLetterDetails} />
      }
      {documentCode === "PIPGM" &&
        <PerformanceImprovement getLetterDetails={getLetterDetails} />
      }
      {documentCode === "PCL" &&
        <PerformanceImprovement getLetterDetails={getLetterDetails} />
      }
      {documentCode === "PBL" &&
        <PerformanceImprovement getLetterDetails={getLetterDetails} />
      }
    </div>
  )
}

export default PreviewDocumentDetail
