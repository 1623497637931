import React from 'react';
import { ErrorMessage } from 'formik';
import FloatLabel from '../../components/FloatLabel';
import { Checkbox, Input, Select } from 'antd';
import { City, State } from 'country-state-city';
import { initialEmpFormValues } from './empInfoFormSchema';

const AddressOfEmployee = ({ values, setFieldValue, setDropDownList, dropDownList, styleChange = false, errorStyle = false, setFieldTouched }: any) => {
  return (
    <React.Fragment>
      <div className='basic-info-address'>
        <h3 className={errorStyle ? 'text-primary text-[19px] my-4' : 'my-5 text-xl'}>{`${errorStyle ? '1.' : ''}`} Current Address</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='Door No.*' name='employeeDto.permanentAddress.doorNo' value={values.employeeDto.permanentAddress.doorNo}>
              <Input
                type="text"
                className='float-control'
                name="employeeDto.permanentAddress.doorNo"
                value={values.employeeDto.permanentAddress.doorNo}
                onChange={(e) => {
                  if (values.employeeDto.sameAsCurrentAddress) {
                    setFieldValue('employeeDto.presentAddress.doorNo', e.target.value)
                  }
                  setFieldValue('employeeDto.permanentAddress.doorNo', e.target.value)
                }}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.permanentAddress.doorNo" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields col-span-2 mb-12' : 'form-item edit-personal-details col-span-2'} >
            <FloatLabel label='Street*' name='employeeDto.permanentAddress.street' value={values.employeeDto.permanentAddress.street}>
              <Input
                type="text"
                className='float-control'
                name="employeeDto.permanentAddress.street"
                value={values.employeeDto.permanentAddress.street}
                onChange={(e) => {
                  if (values.employeeDto.sameAsCurrentAddress) {
                    setFieldValue('employeeDto.presentAddress.street', e.target.value)
                  }
                  setFieldValue('employeeDto.permanentAddress.street', e.target.value)
                }}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.permanentAddress.street" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='State*' name='employeeDto.permanentAddress.state' value={values.employeeDto.permanentAddress.state}>
              <Select
                className='float-control' value={values.employeeDto.permanentAddress.state}
                showSearch
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  if (values.employeeDto.sameAsCurrentAddress) {
                    setFieldValue('employeeDto.presentAddress.state', value)
                  }
                  const states = State.getStatesOfCountry('IN');
                  const isoCodeState: any = states?.find((item: any) => { return value === item?.name })
                  const listCity: any = City.getCitiesOfState('IN', isoCodeState?.isoCode)
                  setDropDownList((prevDropDownList: any) => ({
                    ...prevDropDownList,
                    city: listCity,
                    ...(values.employeeDto.sameAsCurrentAddress && { cCity: listCity })
                  }));
                  setFieldValue('employeeDto.permanentAddress.city', '')
                  setFieldValue('employeeDto.permanentAddress.state', value)
                  if (values.employeeDto.sameAsCurrentAddress) {
                    setFieldValue('employeeDto.presentAddress.city', '')
                    setFieldValue('employeeDto.presentAddress.state', value)
                  }
                }}>
                {dropDownList?.states?.map((item: any) => (
                  <Select.Option key={item?.isoCode} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="employeeDto.permanentAddress.state" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <p></p>
            <FloatLabel label='City*' name='employeeDto.permanentAddress.city' value={values.employeeDto.permanentAddress.city}>
              <Select
                className='float-control' value={values.employeeDto.permanentAddress.city}
                showSearch
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  if (values.employeeDto.sameAsCurrentAddress) {
                    setFieldValue('employeeDto.presentAddress.city', value)
                  }
                  setFieldValue('employeeDto.permanentAddress.city', value)
                }}>
                {dropDownList?.city?.map((item: any) => (
                  <Select.Option key={item?.stateCode} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="employeeDto.permanentAddress.city" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='Pincode*' name='employeeDto.permanentAddress.pinCode' value={values.employeeDto.permanentAddress.pinCode}>
              <Input
                maxLength={6}
                type="text"
                className='float-control'
                name="employeeDto.permanentAddress.pinCode"
                value={values.employeeDto.permanentAddress.pinCode}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.permanentAddress.pinCode', false);
                  else setFieldTouched('employeeDto.permanentAddress.pinCode', true)
                  if (values.employeeDto.sameAsCurrentAddress) setFieldValue('employeeDto.presentAddress.pinCode', e.target.value)
                  setFieldValue('employeeDto.permanentAddress.pinCode', e.target.value)
                }}
                onBlur={(e) => {
                  if (/^\d{6}$/.test(e.target.value)) setFieldTouched('employeeDto.permanentAddress.pinCode', false);
                  else setFieldTouched('employeeDto.permanentAddress.pinCode', true)
                }}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.permanentAddress.pinCode" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
        </div>
      </div>
      <div className='basic-info-address'>
        <h3 className={errorStyle ? 'text-primary text-[19px] my-4' : 'my-5 text-xl'}>{`${errorStyle ? '2.' : ''}`} Permanent Address
          {!errorStyle &&
            <>
              <span className='ml-4 '>
                <Checkbox
                  checked={values.employeeDto.sameAsCurrentAddress}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    setFieldValue('employeeDto.sameAsCurrentAddress', newValue);
                    if (!newValue) {
                      setFieldValue('employeeDto.presentAddress', {
                        ...initialEmpFormValues.employeeDto.permanentAddress,
                        addressId: values?.employeeDto?.presentAddress?.addressId ?? ''
                      });
                    } else {
                      setFieldValue('employeeDto.presentAddress', {
                        ...values?.employeeDto.permanentAddress,
                        addressId: values?.employeeDto?.presentAddress?.addressId ?? ''
                      });
                    }
                  }}
                />
              </span> <span className='addressSmall'> Same as current address</span>
            </>
          }
        </h3>
        {/* {!values?.employeeDto?.sameAsCurrentAddress && ( */}
        <div className="grid grid-cols-3 gap-4">
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='Door No.*' name='employeeDto.presentAddress.doorNo' value={values.employeeDto.presentAddress.doorNo}>
              <Input
                type="text"
                className='float-control'
                name="employeeDto.presentAddress.doorNo"
                value={values.employeeDto.presentAddress.doorNo}
                disabled={values.employeeDto.sameAsCurrentAddress}
                onChange={(e) => {
                  setFieldValue('employeeDto.presentAddress.doorNo', e.target.value)
                }}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.presentAddress.doorNo" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields col-span-2 mb-12' : 'form-item edit-personal-details col-span-2'}  >
            <FloatLabel label='Street*' name='employeeDto.presentAddress.street' value={values.employeeDto.presentAddress.street}>
              <Input
                type="text"
                className='float-control'
                name="employeeDto.presentAddress.street"
                disabled={values.employeeDto.sameAsCurrentAddress}
                value={values.employeeDto.presentAddress.street}
                onChange={(e) => setFieldValue('employeeDto.presentAddress.street', e.target.value)}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.presentAddress.street" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='State*' name='employeeDto.presentAddress.state' value={values.employeeDto.presentAddress.state}>
              <Select
                className='float-control' value={values.employeeDto.presentAddress.state}
                showSearch
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                disabled={values.employeeDto.sameAsCurrentAddress}
                onChange={(value) => {
                  const states = State.getStatesOfCountry('IN');
                  const isoCodeState: any = states?.find((item: any) => { return value === item?.name })
                  setDropDownList({ ...dropDownList, cCity: City.getCitiesOfState('IN', isoCodeState?.isoCode) })
                  setFieldValue('employeeDto.presentAddress.city', '')
                  setFieldValue('employeeDto.presentAddress.state', value)
                }}>
                {dropDownList?.cState?.map((item: any) => (
                  <Select.Option key={item?.isoCode} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="employeeDto.presentAddress.state" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <p></p>
            <FloatLabel label='City*' name='employeeDto.presentAddress.city' value={values.employeeDto.presentAddress.city}>
              <Select
                className='float-control' value={values.employeeDto.presentAddress.city}
                showSearch
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                disabled={values.employeeDto.sameAsCurrentAddress}
                onChange={(value) => setFieldValue('employeeDto.presentAddress.city', value)}>
                {dropDownList?.cCity?.map((item: any) => (
                  <Select.Option key={item?.stateCode} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="employeeDto.presentAddress.city" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
          <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
            <FloatLabel label='Pincode*' name='cPinCode' value={values.employeeDto.presentAddress.pinCode}>
              <Input
                maxLength={6}
                type="text"
                className='float-control'
                name="employeeDto.presentAddress.pinCode"
                value={values.employeeDto.presentAddress.pinCode}
                disabled={values.employeeDto.sameAsCurrentAddress}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.presentAddress.pinCode', false);
                  else setFieldTouched('employeeDto.presentAddress.pinCode', true)
                  setFieldValue('employeeDto.presentAddress.pinCode', e.target.value)
                }}
                onBlur={(e) => {
                  if (/^\d{6}$/.test(e.target.value))
                    setFieldTouched('employeeDto.presentAddress.pinCode', false);
                  else setFieldTouched('employeeDto.presentAddress.pinCode', true)
                }}
              />
            </FloatLabel>
            <ErrorMessage name="employeeDto.presentAddress.pinCode" component="div" className={`error-message ${errorStyle ? 'pt-3' : ''}`} />
          </div>
        </div>
        {/* )} */}
      </div>
    </React.Fragment>
  )
};
export default AddressOfEmployee;