import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { Controller, Input, useForm, yupResolver, SubmitHandler, useGetApiRequests, triggerNotification, useSelector, ErrorMessages, ErrorMessage } from '../../../common/CommonExport';
import { DesignationProps } from '../OrganisationsInterfaces';
import { CreateDesignationSchema } from '../Department/CreateDepartmentSchema';
import DesignationEditor from './DesignationEditor/DesignationEditor';
import FloatLabel from '../../../components/FloatLabel';
import ModalLoader from '../../../components/Loader/ModalLoader';

const CreateDesignationModal = (props: any) => {
    const { editRecord, editModal, isOpen, handleClose } = props;
    const designationDepartmentApi = useGetApiRequests('designationDepartmentList', 'GET');
    const designationGradeApi = useGetApiRequests('designationGrade', 'GET');
    const designationCreate = useGetApiRequests('createDesignation', 'POST');
    const designationEdit = useGetApiRequests('editDesignation', 'PUT');
    const [loader, setLoader] = useState(false);
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const [open, setOpen] = useState(false);
    const [designationDepartmentList, setDesignationDepartmentList]: any = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [editId, setEditId] = useState(props.editRecord.departmentId);
    const [editGrade, setEditGrade] = useState(props.editRecord?.gradeId);
    const [validateDept, setvalidateDept] = useState<boolean>(true);
    const [validateGrade, setvalidateGrade] = useState<boolean>(true);


    const modalEdit = editModal;
    const editValues = editRecord;
    const [createGrade, setCreateGrade] = useState<any>('');
    const [createDepartment, setCreateDepartment] = useState<any>('');

    useEffect(() => {
        setOpen(isOpen);
        departmentGetListApi();
        gradeListGetApi();
        if (modalEdit === false) {
            reset({ department: '', designation: '', grade: '' });
        } else if (modalEdit === true && editRecord !== undefined && editRecord !== null && Object.keys(editRecord).length > 0) {
            setValue('department', editId);
            setValue('grade', editGrade);
            setValue('designation', editValues.designation);
            setValue('roles', editValues.rolesAndResponsibilities);
        }
    }, []);
    const departmentGetListApi = () => {
        const params: any = {
            organisationId: orgId,
        };
        setLoader(true);
        designationDepartmentApi('', params)
            .then((response: any) => {
                const reformedDepartmentList = response?.data?.data.map((item: any) => ({
                    value: item.departmentId,
                    label: item.name,
                    designation: item.designation,
                }));
                setDesignationDepartmentList(reformedDepartmentList);
                setLoader(false)
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false)
            });
    };
    const gradeListGetApi = () => {
        setLoader(true)
        designationGradeApi('')
            .then((response: any) => {
                const reformedGradeList = response?.data?.data.map((item: any) => ({
                    value: item?.cntGradeId,
                    label: item.name,
                    tier: item.tier,
                    cntGradeId: item?.cntGradeId,
                    name: item.name,
                }));
                setGradeList(reformedGradeList);
                setLoader(false)
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false)
            });
    };
    const onChange = (item: any) => {
        if (modalEdit) {
            setvalidateDept(false)
            setEditId(item);
            setValue('department', item);
        } else {
            setvalidateDept(false)
            const gradeTempList: any = designationDepartmentList.find((e: any) => e.value === item);
            setCreateDepartment(gradeTempList.value)
            setValue('department', item);
        }

    };
    const gradeOnChange = (value: any) => {
        if (modalEdit) {
            setvalidateGrade(false)
            setEditGrade(value);
            setValue('grade', value);
        } else {
            const gradeTempList: any = gradeList.find((e: any) => e.value === value);
            setCreateGrade(gradeTempList.value)
            setValue('grade', value);
            setvalidateGrade(false)
        }
    };

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm<DesignationProps>({
        resolver: yupResolver(CreateDesignationSchema) as any,
        defaultValues: { department: '', designation: '', grade: '', roles: '' }
    });
    const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const handleCloseModal = () => {
        setOpen(false);
        reset({ department: '', designation: '', grade: '', roles: '' });
        handleClose();
    };
    const handleApiCall = async (payload: object, pathParams: object, successMessage: string) => {
        try {
            await (modalEdit ? designationEdit(payload, {}, pathParams) : designationCreate(payload));
            triggerNotification('success', '', successMessage, 'topRight');
            props.designationGetApi();
            handleCloseModal();
        } catch (err: any) {
            triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        }
    };

    const removeHtmlTags = (htmlString: any) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    };
    const renderRolesAndResponsibilites = (values: any): any => {
        let finalOutputArray: any = [];
        let finalOutput = values.split('</p></li>');
        finalOutput.map((item: string) => {
            let ulReplace = item.split('<ul>').join(' ');
            let pReplace = ulReplace.split('<p>').join(' ');
            let pEndReplace = pReplace.split('</p>').join(' ');
            let liReplace = pEndReplace.split('</li>').join(' ');
            let liEndReplace = liReplace.split('<li>').join(' ');
            let ulEndReplace = liEndReplace.split('</ul>').join(' ');
            if (ulEndReplace !== '' && ulEndReplace !== ' ') {
                finalOutputArray.push(ulEndReplace.trim());
            }
        });
        let finalRolesAndResponsibilities = finalOutputArray.join(";");
        return finalRolesAndResponsibilities;
    }
    const onSubmit: SubmitHandler<DesignationProps> = (data: DesignationProps) => {
        const gradeObject = gradeList.find((item: any) => {
            if (item.value === parseInt(data.grade)) {
                return item;
            }
        });
        const payload = {
            name: data.designation,
            department: {
                departmentId: data.department,
            },
            grade: gradeObject,
            organisationId: orgId,
            rolesAndResponsiblities: renderRolesAndResponsibilites(data?.roles)
        };
        if (modalEdit) {
            const pathParams: object = {
                id: editRecord.key,
            };
            handleApiCall(payload, pathParams, ErrorMessages.designationUpdation);
        } else {
            handleApiCall(payload, {}, ErrorMessages.designationCreation);
        }
    };

    return (
        <div>
            <Modal title={modalEdit ? 'Edit Designation' : 'Create Designation'} centered open={open} footer={null} onCancel={handleCloseModal} maskClosable={false}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='relative z-0 mt-5 form-item'>
                        <FloatLabel label='Department Name*' name='department' value={modalEdit ? designationDepartmentList.filter((e: any) => e.value === editId) : createDepartment}>
                            <Select className='float-control' showSearch placeholder='Search Department*' optionFilterProp='children' onChange={item => onChange(item)} filterOption={filterOption} options={designationDepartmentList} value={modalEdit ? designationDepartmentList.filter((e: any) => e.value === editId) : createDepartment} />
                        </FloatLabel>
                        {errors.department && validateDept && <ErrorMessage message={errors.department.message} />}

                    </div>
                    <div className='relative z-0 mt-5 form-item'>
                        <Controller name='designation' control={control} render={({ field }) => <Input type='text' id='email' label='Designation*' control={control} {...field} hasError={!!errors.designation} />} />
                        {errors.designation && <ErrorMessage message={errors.designation.message} />}
                    </div>
                    <div className='relative z-0 mt-5 form-item  mb-4'>
                        <FloatLabel label='Grade *' name='grade' value={modalEdit ? gradeList.filter((e: any) => e.value === editGrade) : createGrade}>
                            <Select showSearch placeholder='Select a grade' className='float-control' optionFilterProp='children' onChange={e => gradeOnChange(e)} filterOption={filterOption} options={gradeList} value={modalEdit ? gradeList.filter((e: any) => e.value === editGrade) : createGrade} />{' '}
                        </FloatLabel>
                        {errors.grade && validateGrade && <ErrorMessage message={errors.grade.message?.toString()} />}
                    </div>
                    <div>
                        <DesignationEditor setValue={setValue} editRecord={props?.editRecord} editModal={modalEdit} />
                        {errors.roles && <ErrorMessage message={errors.roles.message?.toString()} />}
                    </div>
                    <div className='mt-5 flex justify-end '>
                        <button className='addButton mr-4 secondary' onClick={handleCloseModal}  >
                            Cancel
                        </button>
                        <button className='addButton mr-4 ' type="submit"  >
                            {modalEdit ? 'Update' : 'Create'}
                        </button>
                    </div>
                </form>
            </Modal>
            {loader && <ModalLoader />}
        </div>
    );
};

export default CreateDesignationModal;
