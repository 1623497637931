export const ErrorMessages = {
    somethingWentWrong: 'Oops! something went wrong',
    invalidEmail: 'Invalid email',
    enterEmail: 'Email is required',
    invalidPassword: 'Invalid password',
    enterPassword: 'Password is required',
    maxPasswordAllowed: 'Atmost 30 characters only allowed',
    departmentName: 'Department name is required',
    hod: 'Head of the department is required',
    departmentEmail: 'Department email is required',
    departmentDescription: 'Description is required',
    maxDeptDescriptionAlloed: 'Atmost 100 characters only allowed',
    departmentUpdation: 'Department updated successfully',
    departmentCreation: 'Department created successfully',
    organizationName: 'Organization name is required',
    systemvendor: 'Admin email id is required',
    designation: 'Designation is required',
    reviewMonth: 'Review month is required',
    probationPeriod: 'Probation period is required',
    noticePeriod: 'Notice period is required',
    employee: 'Employee name is required',
    grade: 'Grade is required',
    Roles: 'Roles and Responsibilities is required',
    designationUpdation: 'Designation updated successfully',
    designationCreation: 'Designation created successfully',
    userCreation: 'User saved successfully',
    sessionExpired: 'Session Expired',
    designationUpdated: 'Designation updated succesfully',
    mandatoryValues: 'Please Fill all the mandatory values',
    organisationUpdation: 'Organisation updated successfully',
    assetUpdation: 'Asset Deleted successfully',
    ratingValue: 'Please fill all the ratings and submit the form',
    internalServerError: 'Internal Server Error',
    leaveReportMandatory: 'Please enter any one of Employee Name or Department or Employement Type',
    grossSalaryRequire: 'Gross Salary should not be zero',
    newCtc: 'New CTC should not be zero',
    maximumBalance: 'Maximum balance should be greater than the opening balance',
    employeeSalary: 'Please add salary for the employee before generating',
    employeeDelete: "Employee can't be deleted at this time",
    unassignCourse: 'Are you sure to unassign the employee from this course?',
    fullAttendance: 'Astonishing! 100% attendance today',
    cancellationDate: 'Cancellation date is required',
    deprovisionGenerate: 'Deprovision cannot be proceed further, please wait for sometime',
    selectReturnDate: 'Select the return date',
};
