import React from 'react';
interface HeadingProps {
    title: string;
    subheading: string;
}

const Heading: React.FC<HeadingProps> = ({ title, subheading }) => {
    return (
        <>
            <h2 className='text-black text-3xl font-normal tracking-wide mb-1'>{title}</h2>
            <p className='text-gray-500 font-normal text-base leading-5 font-roboto font-helvetica-neue font-sans tracking-normal mt-4'>{subheading}</p>
        </>
    );
};

export default Heading;
