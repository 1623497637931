import CryptoJS from 'crypto-js';
const secretKey = 'key@123';

export function encryptData(data: any): string {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    return encrypted?.toString();
}
export function decryptData(encryptedData: string): any {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes && JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}
export function storeData(storageKey: string, data: any, useSessionStorage: boolean = false): void {
    const storage = useSessionStorage ? sessionStorage : localStorage;
    const encryptedData = encryptData(data);
    storage.setItem(storageKey, encryptedData);
}
export function retrieveData(storageKey: string, useSessionStorage: boolean = false): any {
    const storage = useSessionStorage ? sessionStorage : localStorage;
    const encryptedData = storage.getItem(storageKey);
    if (encryptedData) {
        const decryptedData = decryptData(encryptedData);
        return decryptedData;
    } else {
        return null;
    }
}
