import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  paymentDetail: object;
}

const initialState: AuthState = {
  paymentDetail: {},
};

const razorPaymentDetailSlice = createSlice({
  name: 'paymentDetails',
  initialState,
  reducers: {
    setPaymentDetails: (state, action: PayloadAction<any>) => {
      state.paymentDetail = action.payload;
    },
    clearPaymentDetails: state => {
      state.paymentDetail = {};
    },
  },
});

export const { setPaymentDetails, clearPaymentDetails } = razorPaymentDetailSlice.actions;
export default razorPaymentDetailSlice.reducer;
