import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ChartList = (props: any) => {
    const eventData = useSelector((state: any) => state?.eventData?.eventData);
    const { dashboardData } = props;
    const { probationEmployeesCount, confirmedEmployeesCount, appraisalDueCount } = dashboardData;
    const [chartList, setChartList] = useState<any>([]);

    useEffect(() => {
        setChartList([
            { label: 'Confirmed', count: confirmedEmployeesCount },
            { label: 'Appraisal Due', count: appraisalDueCount },
            { label: 'Probation', count: probationEmployeesCount },
        ]);
    }, [appraisalDueCount, confirmedEmployeesCount, eventData, probationEmployeesCount]);

    return (
        <ul className='chart_list flex flex-col'>
            {chartList?.map((item: any, index: any) => (
                <li key={`${index + item?.count}`} className='chart_list-item flex items-center pb-3'>
                    <span className={`chart_list-span mr-4 employee-${item.label.toLowerCase()}`}></span>
                    <span className='chart_list-count mr-4'>{item.count}</span>
                    {item.label}
                </li>
            ))}
        </ul>
    );
};

export default ChartList;
