// BankDetails.js
import React from 'react';
import { ErrorMessage } from 'formik';
import FloatLabel from '../../components/FloatLabel';
import { Input } from 'antd';

const EmployeeBankDetails = ({ values, setFieldValue, styleChange = false, setFieldTouched }: any) => (
  <div className='basic-info-address'>
    <h3 className={styleChange ? 'my-5 text-2xl font-bold' : 'company-details-header mb-4 pt-5'} >Bank Account Details</h3>
    <div className="grid grid-cols-3 gap-4">
      <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}>
        {styleChange && <p>Name as per bank records</p>}
        <FloatLabel label={`${styleChange ? 'Enter the name*' : 'Name as per bank records*'}`} value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord} name='employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord'>
          <Input
            type="text"
            className='float-control'
            name="employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord"
            value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord', true)
              setFieldValue('employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord', e.target.value)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeAdditionalInformationDto.employeeBankDetailsDto.nameAsPerBankRecord" component="div" className="error-message" />
      </div>
      <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
        {styleChange && <p>Name of the bank</p>}
        <FloatLabel label={`Enter the bank name*`} value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.bankName} name='employeeAdditionalInformationDto.employeeBankDetailsDto.bankName'>
          <Input
            type="text"
            className='float-control'
            name="employeeAdditionalInformationDto.employeeBankDetailsDto.bankName"
            value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.bankName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.bankName', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.bankName', true)
              setFieldValue('employeeAdditionalInformationDto.employeeBankDetailsDto.bankName', e.target.value)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeAdditionalInformationDto.employeeBankDetailsDto.bankName" component="div" className="error-message" />
      </div>
      <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'} >
        {styleChange && <p>Branch</p>}
        <FloatLabel label={`${styleChange ? 'Enter the branch name*' : 'Branch*'}`} value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.branchName} name='employeeAdditionalInformationDto.employeeBankDetailsDto.branchName'>
          <Input
            type="text"
            className='float-control'
            name="employeeAdditionalInformationDto.employeeBankDetailsDto.branchName"
            value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.branchName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.branchName', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.branchName', true)
              setFieldValue('employeeAdditionalInformationDto.employeeBankDetailsDto.branchName', e.target.value)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeAdditionalInformationDto.employeeBankDetailsDto.branchName" component="div" className="error-message" />
      </div>
      <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}  >
        {styleChange && <p>Account Number</p>}
        <FloatLabel label={`${styleChange ? 'Enter the account number*' : 'Account number*'}`} value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.accountName} name='employeeAdditionalInformationDto.employeeBankDetailsDto.accountName'>
          <Input
            type="text"
            maxLength={18}
            className='float-control'
            name="employeeAdditionalInformationDto.employeeBankDetailsDto.accountName"
            value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.accountName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d+$/.test(inputValue)) setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.accountName', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.accountName', true)
              setFieldValue('employeeAdditionalInformationDto.employeeBankDetailsDto.accountName', e.target.value)
            }}
            onBlur={(e) => {
              if (e.target.value?.length >= 10)
                setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.accountName', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.accountName', true)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeAdditionalInformationDto.employeeBankDetailsDto.accountName" component="div" className="error-message" />
      </div>
      <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}  >
        {styleChange && <p>IFSC</p>}
        <FloatLabel label={`${styleChange ? 'Enter the IFSC*' : 'IFSC *'}`} value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode} name='employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode'>
          <Input
            maxLength={11}
            type="text"
            className='float-control'
            name="employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode"
            value={values.employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z0-9]+$/.test(inputValue)) setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode', true)
              setFieldValue('employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode', e.target.value)
            }}
            onBlur={(e) => {
              if (e.target.value?.length >= 8)
                setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode', false);
              else setFieldTouched('employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode', true)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeAdditionalInformationDto.employeeBankDetailsDto.ifscCode" component="div" className="error-message" />
      </div>
    </div>
  </div>
);

export default EmployeeBankDetails;
