import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  orgName: any;
}

const initialState: AuthState = {
  orgName: null,
};

const orgNameSlice = createSlice({
  name: 'organisationName',
  initialState,
  reducers: {
    setOrgName: (state, action: PayloadAction<string>) => {
      state.orgName = action.payload;
    },
    clearOrgName: state => {
      state.orgName = null;
    },
  },
});

export const { setOrgName, clearOrgName } = orgNameSlice.actions;
export default orgNameSlice.reducer;
