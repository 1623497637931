import { triggerNotification } from '../../common/CommonExport';

export const handleDeleteDesignation = async (apiCall: any, selectedRows: any, callingGetFuntion?: any, queryParams?: any, pathParams?: any, from?: any, start?: any) => {
    try {
        const res = await apiCall(selectedRows, queryParams, pathParams);
        if (from === 'elms') {
            triggerNotification('success', '', res?.data?.message, 'topRight');
        } else {
            triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        }
        if (start) {
            callingGetFuntion(start);
        } else {
            callingGetFuntion();
        }
    } catch (err: any) {
        triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
        return err;
    }
};
