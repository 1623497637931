import { Yup } from '../../common/CommonExport';

export const addressSchema = Yup.object().shape({
    doorNo: Yup.string().required('Please enter the door number'),
    street: Yup.string().required('Please enter the street'),
    city: Yup.string().required('Please select the city'),
    state: Yup.string().required('Please select the state'),
    pinCode: Yup.string()
        .matches(/^\d{6}$/, 'Please enter the valid pincode')
        .required('Please enter the pincode'),
});

export const generateEmpPreviousEmploymentSchema = () => {
    return Yup.array().of(
        Yup.object().shape({
            key: Yup.number().required('Key is required'),
            companyName: Yup.string().test('validate-employer', 'Employer Name is required', function (value, { parent }) {
                if (parent.key === 1 && parent.employeeTypeId === 1) return !!value;
                return true;
            }),
            jobTitle: Yup.string().test('validate-jobTitle', 'Job Title is required', function (value, { parent }) {
                if (parent.key === 1 && parent.employeeTypeId === 1) return !!value;
                return true;
            }),
            startDate: Yup.string().test('validate-startDate', 'Start Date is required', function (value, { parent }) {
                if (parent.key === 1 && parent.employeeTypeId === 1) return !!value;
                return true;
            }),
            endDate: Yup.string().test('validate-endDate', 'End Date is required', function (value, { parent }) {
                if (parent.key === 1 && parent.employeeTypeId === 1) return !!value;
                return true;
            }),
        }),
    );
};

export const generateEmpDependentInformationSchema = () => {
    return Yup.array().of(
        Yup.object().shape({
            relationship: Yup.string().test('validate-relationship', 'Please enter the relationship', function (value: any, { parent }) {
                if (parent.key === 1)
                    return (
                        (value !== undefined && /^[A-Za-z\s-]+$/.test(value)) ||
                        this.createError({
                            message: value === undefined ? 'Please enter the relationship' : 'Please enter the valid relationship name',
                        })
                    );
                return true;
            }),
            dependentName: Yup.string().test('validate-dependentName', 'Dependent Name is required', function (value: any, { parent }) {
                if (parent.key === 1)
                    return (
                        (value !== undefined && /^[A-Za-z ]+$/.test(value)) ||
                        this.createError({
                            message: value === undefined ? 'Please enter the dependent name' : 'Please enter the valid dependent name',
                        })
                    );
                return true;
            }),
            dateOfBirth: Yup.string().test('validate-dateOfBirth', 'Please select the date of birth', function (value: any, { parent }) {
                if (parent.key === 1) return !!value;
                return true;
            }),
        }),
    );
};

export const employeeDtoSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Invalid first name')
        .required('Please enter the first name'),
    lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Invalid last name')
        .required('Please enter the last name'),
    dateOfBirth: Yup.date().required('Please select the date of birth'),
    bloodGroup: Yup.string().required('Please select the blood group'),
    isMarried: Yup.boolean().default(false),
    fatherOrSpouseName: Yup.string().test('required-if-married', 'Please enter the spouse name', function (value) {
        if (this.parent.isMarried) return !!value;
        return true;
    }),
    fatherName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Invalid father name')
        .required('Please enter the father name'),
    // motherName: Yup.string()
    //     .matches(/^[A-Za-z ]+$/, 'Invalid mother name')
    //     .required('Please enter the mother name'),
    personalContactNo: Yup.string()
        .matches(/^\d{10}$/, 'Please enter the valid contact number')
        .required('Please enter the contact number'),
    aadharNumber: Yup.string()
        .matches(/^\d{12}$/, 'Please enter the valid Aadhar number')
        .max(12, 'Aadhar number must not exceed 18 characters')
        .required('Please enter the Aadhar number'),
    panNumber: Yup.string()
        .required('Please enter the PAN')
        .matches(/^([A-Z]{5}\d{4}[A-Z])$/, 'Please enter the valid PAN'),
    presentAddress: addressSchema,
    permanentAddress: addressSchema,
    sameAsCurrentAddress: Yup.boolean().default(false),
    emergencyContactNo1: Yup.string()
        .matches(/^\d{10}$/, 'Please enter the valid emergency contact number')
        .required('Please enter the emergency contact number'),
    emergencyContactNo1Relationship: Yup.string()
        .matches(/^[A-Za-z -]+$/, 'Please enter the valid relationship name')
        .required('Please enter the emergency contact relationship'),
    emergencyContactNo2: Yup.string()
        .matches(/^\d{10}$/, 'Please enter the valid emergency contact number')
        .required('Please enter the emergency contact number'),
    emergencyContactNo2Relationship: Yup.string()
        .matches(/^[A-Za-z -]+$/, 'Please enter the valid relationship name')
        .required('Please enter the emergency contact relationship'),
});

export const employeeBankDetailsDtoSchema = Yup.object().shape({
    nameAsPerBankRecord: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Please enter the valid name')
        .required('Please enter the name as per the bank record'),
    bankName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Please enter the valid bank name')
        .required('Please enter the bank name'),
    branchName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Please enter the valid branch name')
        .required('Please enter the branch name'),
    accountName: Yup.string()
        .matches(/^\d{10,18}$/, 'Please enter the valid account number')
        .required('Please enter the account number'),
    ifscCode: Yup.string()
        .required('Please enter the IFSC')
        .matches(/^[A-Za-z0-9]+$/, 'IFSC should only contain alphabets and numbers')
        .min(8, 'IFSC must be atleast 8 characters')
        .max(11, 'IFSC cannot exceed 11 characters'),
});

export const companyDtoSchema = Yup.object().shape({
    dateOfJoin: Yup.date().required('Date of Join is required'),
    designationEffectiveDate: Yup.date().test('required', 'Designation Effective Date is required', function (value) {
        if (this.parent.preDesignationDto.preDesignationId !== this.parent.designationDto.designationId) {
            return !!value;
        }
        return true;
    }),
    reportingManagerId: Yup.string().required('Reporting Manager ID is required'),
    nextProbationEndDate: Yup.string().test('nextProbationEndDate', 'Next Probation EndDate is required', function (value) {
        if (this.parent.statusDto.statusId === '31' && !value) return false;
        return true;
    }),
    backGroundVerification: Yup.string().required('BackGround Verification is required'),
    designationDto: Yup.object().shape({
        designationId: Yup.string().required('Designation  is required'),
    }),
    preDesignationDto: Yup.object().shape({
        preDesignationId: Yup.string(),
    }),
    statusDto: Yup.object().shape({
        statusId: Yup.string().required('Status ID is required'),
    }),
    departmentId: Yup.string().required('Department ID is required'),
    employeeTypeId: Yup.number().required('Employee Type ID is required'),
});

export const personalEmailAndProfileValidateSchema = {
    ...employeeDtoSchema.fields,
    personalEmail: Yup.string()
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter a valid email address')
        .required('Email is required'),
};

export const pairSchema = Yup.object().shape({
    option: Yup.string().required('Option is required'),
    optionMatching: Yup.string().required('Option matching is required'),
});
