import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import DownloadIcon from '../confidentialityLetter/DownloadIcon';

const PerformanceImprovement = (props: any) => {
  const uploadEmployeSignature = useGetApiRequests('uploadEmployeeSignature', 'POST');
  const getDocumentsData = useGetApiRequests('documentStatus', 'GET');
  const [loader, setLoader] = useState(false);

  const foldersNameCheck = [
    'no response employee',
    'not serving notice period',
    'performance improvement program',
    'violation code of conduct',
    "performancebonusletter",
    "projectbonusletter"
  ];
  const imageBaseUrls = process.env.REACT_APP_IMAGE_VIEW;
  const [selectedDownloadDocument, setSelectedDownloadDocument] = useState<any>({
    signed: null,
    unsigned: null,
    signature: null
  });
  const { getLetterDetails } = props;
  const locations = useLocation();
  const urlParams = new URLSearchParams(locations.search);
  const employeeId = urlParams.get('employeeId');
  const documentsCode = urlParams.get('documentCode');
  const employeeApKey = urlParams.get('employeeApiKey');
  const _document: any = document;
  let imageFlags: boolean;
  let signature: any;
  useEffect(() => {

    let htmlElement: any = document.getElementById("documentDetails");
    htmlElement.innerHTML = getLetterDetails;
    if (props.getLetterDetails) {
      setTimeout(() => {

        const submitedButton = _document.getElementsByClassName('btn')[0];
        const submitedBtn = _document.getElementsByClassName('submit-btn')[0];

        const imageUploadedInput = _document.getElementById('imageUpload');
        const imagesPreview = _document.getElementById('imagePreview');
        if (imagesPreview) {
          imageUploadedInput.addEventListener('change', handleFileSelect, false);
          imagesPreview.addEventListener('click', handleImageChange, false);
          submitedButton.addEventListener('click', submitForm, false);
        }
        if (submitedBtn) {
          submitedBtn.addEventListener('click', submitForm, false);
        }
      }, 3000)
    }
    getDocuments()

  }, [props])
  const updateSelectedDownloadDocument = (selectDocument: any, item: any) => {
    const val = { ...selectDocument };
    if (item.name.toLowerCase().includes('signed')) {
      val.signed = item;
      val.unsigned = selectDocument.unsigned;
    } else {
      val.signed = selectDocument.signed;
      val.unsigned = item;
    }
    return val;
  };

  const getDocuments = () => {
    const params = {
      "employeeId": employeeId,
      "document": "documentDetail"
    }
    setLoader(true)

    getDocumentsData('', {}, params)
      .then((response: any) => {
        setLoader(false)
        response?.data?.data.forEach((item: any) => {
          const folderName = item.document.folderName.toLowerCase();
          const isMatchingFolder = foldersNameCheck.includes(folderName);
          const substrings = ["jpeg", "png", "jpg"];
          const containSubstring = substrings.some(substring => item.documentPath.includes(substring));
          if (containSubstring && isMatchingFolder) {
            let footerPreviewLists: any = _document.querySelectorAll('.imagePreview-footer');
            let imageObjects = `<img style="width:200px; height:70px;"  src="${imageBaseUrls + item.documentPath}" alt="uploaded_image">`;
            footerPreviewLists.forEach((item: any) => {
              item.innerHTML = imageObjects;
            });
            _document.getElementById('imagePreview').innerHTML = imageObjects;
            const val = {
              ...selectedDownloadDocument,
              signature: item,
            };
            setSelectedDownloadDocument(val);
          }
          if (item.name.toLowerCase().includes('pdf') && isMatchingFolder) {
            const fileName = updateSelectedDownloadDocument(selectedDownloadDocument, item);
            setSelectedDownloadDocument(fileName);
          }
        });
      })
      .catch((err: any) => {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  }
  const handleImageChange = () => {
    signature = null
    _document.getElementById('imageUpload').click();
  }

  const handleFileSelect = (event: any) => {
    let lastValues: any;
    lastValues = event.target.files[0];
    const submitedBtn = _document.getElementsByClassName('submit-btn')[0];
    if (submitedBtn) {
      submitedBtn.style.pointerEvents = "auto"
    }
    if (lastValues) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(lastValues);
      fileReader.onload = function (e: any) {
        signature = null
        let img: any = new Image();
        img.src = e.target.result;
        img.onload = function () {
          signature = lastValues
          imageFlags = true
          let imageUrlObjects = URL.createObjectURL(lastValues);
          let imageObject = `<img style="width:200px; height:100px;" src="${imageUrlObjects}" alt="uploaded_image">`;
          _document.getElementById('imagePreview').innerHTML = lastValues ? imageObject : '';
          _document.getElementsByClassName('imageUpload-div')[0].style.display = 'block';
          _document.getElementsByClassName('image-error')[0].style.display = "none";
          let labelElement = _document.querySelector('label[for="imageUpload"]');
          labelElement.style.display = "none";
          let footerPreviewList: any = _document.querySelectorAll('.imagePreview-footer');
          footerPreviewList?.length > 0 && footerPreviewList.forEach((item: any) => {
            item.innerHTML = imageObject;
          })
        }
      };
    }
    else {
      _document.querySelector('.imageUpload-div').style.display = 'block';
      _document.getElementById('imageError').innerHTML = 'Please upload the signature';
      imageFlags = false
    }
  }
  const submitForm = (event: any) => {
    const submitedBtn = _document.getElementsByClassName('submit-btn')[0];
    if (submitedBtn) {
      submitedBtn.style.pointerEvents = "none"
    }
    setLoader(true)
    event.preventDefault();
    if (imageFlags) {
      let formData: any = new FormData();
      formData.append("employeeSignature", signature);
      const params = {
        "employeeId": employeeId,
        "documentCode": documentsCode,
        "employeeApiKey": employeeApKey
      }
      uploadEmployeSignature(formData, {}, params)
        .then((res: any) => {
          setLoader(false)
          if (res?.data?.status?.success == "Success") {
            triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          }
          else {
            triggerNotification('error', '', res?.data?.status?.message, 'topRight');
          }
          setTimeout(() => {
            window.close();
          }, 3000);
        })
        .catch((err: any) => {
          setLoader(false)
          triggerNotification('error', '', err?.message, 'topRight');
        });
    }
    else {
      const setErrorMessages = (elementId: any, message: any) => {
        const elements = _document.getElementsByClassName(elementId)[0];
        if (elements) {
          elements.innerHTML = message;
          elements.style.color = "red";
          elements.style.top = "18px";
        }
      };
      if (signature == null) {
        setErrorMessages('image-error', 'Please upload the signature');
      }
      setLoader(false)

    }
  }
  return (
    <div>
      {loader && <Loader />}
      <DownloadIcon selectedDownloadDocument={selectedDownloadDocument} />
      <div id='documentDetails' data-testid="documentDetails" />
    </div>
  )
}

export default PerformanceImprovement
