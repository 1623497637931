import { Navigate, Outlet } from 'react-router-dom';
import { retrieveData, storeData } from '../storage/Storage';

function ProtectedRoute(props: any) {
    // Reference : https://www.robinwieruch.de/react-router-private-routes/
    const redirectPath = '/login';
    const isLoggedIn = retrieveData('isLoggedIn', true);
    let user: string | null = '';
    if (isLoggedIn) {
        user = retrieveData('authToken', true);
        storeData('appToken', user, true);
    } else {
        user = retrieveData('authToken', true);
    }

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
}
export default ProtectedRoute;
