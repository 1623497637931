import React from 'react'

export const Chapter = ({ detail }: any) => {
  return (
    <div>
      <p data-testid="para" className='flex items-end'><span className="material-symbols-outlined mt-1 text-primary mr-2">
        import_contacts
      </span><span data-testid="span" className='text-[#303030]'>{detail?.chapterCount} Chapter</span></p></div>
  )
}
