import React, { useState } from "react";
import { Switch } from "antd";
import { triggerNotification, useGetApiRequests } from "../../../../common/CommonExport";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { setCurrentTab } from "../../../../redux/slices/courseSlice";
import ModalLoader from "../../../../components/Loader/ModalLoader";

const EnableCourseCompletionCertificate = ({ handel }: any) => {
  const dispatch = useDispatch()
  const certificateList = useGetApiRequests('getAllCertificate', 'GET');
  const mapCertificateToCourse = useGetApiRequests('mapCertificateToCourse', 'POST');
  const changeMode = useGetApiRequests('updateCourse', 'PUT');
  const organisationId = useSelector((state: any) => state?.organisationId?.id);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const [certificateDetails, setCertificateDetails] = useState<any>({})
  const [values, setValues] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)

  const handleGetAllCertificate = async () => {
    setLoader(true)
    try {
      const response: any = await certificateList('', { organisationId: organisationId })
      setLoader(false)
      if (response?.data?.status === 200) {
        const courseCertificate = response?.data?.data?.find((item: any) => { return item?.certificateType === "COURSE_CO" })
        setCertificateDetails(courseCertificate)
      }
    } catch (err: any) {
      setLoader(false)
    }
  }

  React.useEffect(() => {
    handleGetAllCertificate()
  }, [])

  const handleAssignCertificate = async (mode: any) => {
    const payload = {
      course: { courseId: currentCourseId },
      certificate: { certificateId: certificateDetails?.certificateId }
    }
    try {
      const response: any = await mapCertificateToCourse(payload)
      handleChangeMode(mode)
      // triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      console.log(err)
    }
  }

  const handleChangeMode = async (courseStatus: any) => {
    const pathParams = { id: currentCourseId, endPoint: 'status' }
    try {
      const response: any = await changeMode('', { courseStatus }, pathParams)
      handel()
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }

  const handleSaveSelection = (mode: any) => {
    if (values) handleAssignCertificate(mode)
    else
      handleChangeMode(mode)
  }
  const handleBack = () => {
    dispatch(setCurrentTab(4))
  }
  return (
    <div className="w-full flex h-[470px] bg-[#EFEFEF]">
      {loader && <ModalLoader />}
      <div className="chapter-toggle p-4 ">
        <label htmlFor="" className='text-[#737373] text-lg pr-2 font-medium'>Certificate Section</label>
        <Switch checked={values} onChange={(event) => setValues(event)} />
      </div>
      <div className="footer mb-5 ">
        <div className="btnGroup flex justify-end mr-3">
          <button className='addButton mr-4 primary px-5 cancelElms' onClick={() => handleBack()}>Back</button>
          <button className='addButton mr-4 primary px-5 cancelElms' onClick={() => handleSaveSelection('draft')}>Save and Draft</button>
          <button className='addButton mr-4 primary px-5 confirmElms' onClick={() => handleSaveSelection('publish')}>Save and Publish </button>
        </div>
      </div>
    </div>
  )
}

export default EnableCourseCompletionCertificate