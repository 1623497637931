import React, { useState } from 'react'
import "../home/Banner.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ListMarker from "../../../assets/images/client-onboard/list-marker.png"
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import ThankyouModal from './ThankyouModal';


const ContactForm = () => {
  const enquiryApi = useGetApiRequests('enquiry', 'POST');
  const [loader, setLoader] = useState(false);
  const [thankyou, setThankyou] = useState(false);


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    businessEmail: Yup.string().required('Email id is required').email('Invalid email').test(
      'is-business-email',
      'Invalid business email',
      function (value) {
        const disallowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
        const emailParts = value.split('@');
        const domain = emailParts[emailParts.length - 1];
        return !disallowedDomains.includes(domain);
      }
    ),
    companyName: Yup.string().required('Company name is required'),
    phoneNumber: Yup.number().min(10, 'Phone number must be 10 characters').required('Phone number is required'),

  });
  const [initialValues, setInitialValues]: any = useState({
    name: "",
    businessEmail: '',
    companyName: '',
    phoneNumber: ''
  });

  const handelSaveRevise = (values: any) => {
    setLoader(true)
    enquiryApi(values).then((response: any) => {
      setLoader(false);
      setThankyou(true)
      setTimeout(() => {
        setThankyou(false)

      }, 5000);


    })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
      });
  }
  const handleOnchange = (event: any, setFieldValue: any, name: any) => {
    const input: any = event.target.value;
    setFieldValue(name, input)
    setInitialValues(name, input)
  };
  const handelClose = () => {
    setThankyou(false)
  }

  return (
    <>
      {loader && <Loader />}
      <div className='client-container w-[80%]'>
        <div className="flex w-full pt-32 pb-40">
          <div className="w-1/2">
            <h1 className='text-[68px] font-bold font-rubik  pb-16 leading-tight text-[#ffffff]'>Reimagine your HR <br /> operations!</h1>
            <ul>
              <li className='flex text-[25px] font-bold text-[#ffffff] pb-8'> <img className='h-[30px] pr-3 pt-1' src={ListMarker} alt="" /><span>Create a streamlined and positive onboarding experience</span> </li>
              <li className='flex text-[25px] font-bold text-[#ffffff] pb-8'> <img className='h-[30px] pr-3 pt-1' src={ListMarker} alt="" /><span>Review and manage employee performance, attendance, shifts and much more</span></li>
              <li className='flex text-[25px] font-bold text-[#ffffff] pb-8 '> <img className='h-[30px] pr-3 pt-1' src={ListMarker} alt="" /> <span>Get real-time insights</span></li>
            </ul>
          </div>
          <div className="w-1/2 flex items-center justify-end">
            <div className="card contact-form py-12  px-16">
              <h1 className='pb-14 text-[#1B1B1B] text-center font-rubik text-[25px] font-medium'>Talk to a All Aboard Expert <br />
                Today!</h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  handelSaveRevise(values);
                  resetForm();
                }}
              >
                {({ values, setFieldValue, errors }) => {
                  return (
                    <Form>
                      <div className='w-full'>
                        <div className='  pb-6 contact_form'>
                          <label >Your Name*</label>
                          <Field
                            type='text'
                            name='name'
                            value={values.name}
                            onChange={(e: any) => { handleOnchange(e, setFieldValue, 'name') }}
                            placeholder="Enter Name"
                          />
                          <ErrorMessage name='name' component='div' className='error-message absolute' />
                        </div>
                        <div className='  pb-6 contact_form'>
                          <label >Business Email ID*</label>
                          <Field
                            type='text'
                            name='businessEmail'
                            value={values.businessEmail}
                            onChange={(e: any) => { handleOnchange(e, setFieldValue, 'businessEmail') }}
                            placeholder="Enter Mail"
                          />
                          <ErrorMessage name='businessEmail' component='div' className='error-message absolute' />
                        </div>
                        <div className='  pb-6 contact_form'>
                          <label >Company Name*</label>
                          <Field
                            type='text'
                            name='companyName'
                            value={values.companyName}
                            onChange={(e: any) => { handleOnchange(e, setFieldValue, 'companyName') }}
                            placeholder="Enter Company"
                          />
                          <ErrorMessage name='companyName' component='div' className='error-message absolute' />
                        </div>    <div className='  pb-6 contact_form'>
                          <label >Phone Number*</label>
                          <Field
                            type='text'
                            name='phoneNumber'
                            value={values.phoneNumber}
                            onChange={(e: any) => { handleOnchange(e, setFieldValue, 'phoneNumber') }}
                            placeholder="00000-00000"
                            maxLength={10}
                          />
                          <ErrorMessage name='phoneNumber' component='div' className='error-message absolute' />
                        </div>
                      </div>
                      <div className='flex justify-center  salary_add-btn pt-4'>
                        <button type='submit' className='addButton w-[400px] '>
                          Request Demo
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
        {thankyou && <ThankyouModal thankyou={thankyou} handelClose={handelClose} />}

      </div></>
  )
}

export default ContactForm
