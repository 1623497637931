import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './routes/AppRouter';
import './App.scss';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';

const config: ThemeConfig = {
    token: { colorPrimary: '#D34A7C' },
    components: {
        Button: {
            colorPrimary: '#D23B7D',
            algorithm: true,
        },
        Input: {
            colorPrimary: '#eb2f96',
            algorithm: true,
        },
    },
};

function App() {
    return (
        <div className='App'>
            <ConfigProvider theme={config}>
                <BrowserRouter>
                    <Header />
                    <Sidebar />
                    <AppRouter />
                </BrowserRouter>
            </ConfigProvider>
        </div>
    );
}

export default App;
