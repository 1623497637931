import React, { useEffect, useState } from 'react';
import DocumentPreviewModal from '../DocumentPreviewModal';
import { CheckOutlined } from '@ant-design/icons';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
interface StepTrackerProps {
    currentStep: number;
    currentData: any;
    record: any;
    setCurrentStep: any;
}

const steps = ['Employee Information', 'Document Sent', 'Document Signed Off'];
const employeeSalaryApi = (findEmployeeHasSalaryOrNot: any, record: any, setOpenDocumentmodal: any) => {
    const params: any = {
        id: record?.key, end: 'employeecurrentctc'
    };
    findEmployeeHasSalaryOrNot('', '', params)
        .then((response: any) => {
            const apiResponse = response?.data?.data;
            if (apiResponse !== 0) {
                setOpenDocumentmodal(true)
            } else {
                triggerNotification('error', '', ErrorMessages.employeeSalary, 'topRight');
                setOpenDocumentmodal(false)
            }
        })
        .catch((err: any) => {
            triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        });
}
const renderButton = (currentStep: any, setOpenDocumentmodal: any, findEmployeeHasSalaryOrNot: any, record: any) => {
    if (currentStep === 0) {
        return <button className='options'>Generate</button>;
    } else if (currentStep === 1) {
        return (
            // <button onClick={() => setOpenDocumentmodal(true)} className='options active'>
            //     Generate
            // </button>
            <button onClick={() => employeeSalaryApi(findEmployeeHasSalaryOrNot, record, setOpenDocumentmodal)} className='options active'>
                Generate
            </button>
        );
    } else {
        return <button className='options active'>Mail Sent</button>;
    }
}

const StepTracker: React.FC<StepTrackerProps> = ({ currentData, currentStep, record, setCurrentStep }) => {
    const [openDocumentmodal, setOpenDocumentmodal] = useState(false);
    const [currentStepRecord, setCurrentStepRecord] = useState(currentStep);
    const findEmployeeHasSalaryOrNot = useGetApiRequests('getEmployeeCtc', 'GET');

    useEffect(() => {
        setCurrentStepRecord(currentStep)
    }, [currentStep])

    return (
        <div className='step-tracker steps'>
            <div className='employee-stepper'>
                <div className='stepper-row'>
                    <p className='step-title'>{currentData?.name}</p>
                    <div className='stepper'>
                        {steps.map((step, index) => (
                            <div key={`${index + 1}step`} className={`step ${index < currentStepRecord ? 'done' : ''}`}>
                                <span className='material-symbols-outlined'><CheckOutlined /></span>
                                <p>{step}</p>
                            </div>
                        ))}
                    </div>
                    <div className='childAction'>
                        {renderButton(currentStepRecord, setOpenDocumentmodal, findEmployeeHasSalaryOrNot, record)}
                    </div>
                </div>
            </div>
            {openDocumentmodal && <DocumentPreviewModal setCurrentStepRecord={setCurrentStepRecord} open={openDocumentmodal} setOpenDocumentmodal={setOpenDocumentmodal} currentData={currentData} record={record} setCurrentStep={setCurrentStep} />}
        </div>
    );
};

export default StepTracker;
