import React from 'react'
import { CloudUploadOutlined } from '@ant-design/icons';
import editButton from '../../../assets/images/new-dashboard/edit.svg';

const EditOrganisationSectionTwo = (props: any) => {
  const { selectedImage, selectedhrImage, handleImageUpload, handlehrImage } = props
  return (
    <div className='grid grid-cols-1' style={{ gridTemplateColumns: '100%' }}>
      <div className='organisation_logo'>
        <h4>Upload Logo</h4>
        <div className='file-upload relative'>
          <div className='file-upload-inner relative flex  items-center justify-center'>

            <input type='file' accept='image/*' className='absolute' onChange={handleImageUpload} />

            {selectedImage && <img src={selectedImage} alt="Selected" className='sign-uploaded' />}

            {!selectedImage && (
              <span className='material-symbols-outlined cloud'>
                {' '}
                <CloudUploadOutlined />
              </span>
            )}
          </div>
          <span className='material-symbols-outlined edit absolute'>
            <img src={editButton} alt='Another Image' className='cursor-pointer' />
          </span>
        </div>
        <div></div>
      </div>
      <div className='organisation_sign'>
        <div className=''>
          <h4>Upload signature image</h4>
          <div className='sign-file-upload relative'>
            <div className='sign-file-upload-inner relative flex items-center justify-between '>
              <input type='file' accept='image/*' className='absolute' onChange={handlehrImage} />

              {selectedhrImage && <img src={selectedhrImage} alt='' className='sign-uploaded' />}
              {!selectedhrImage && (
                <span className='material-symbols-outlined cloud'>
                  <CloudUploadOutlined />
                </span>
              )}
              <span className='material-symbols-outlined edit flex items-center '>
                <img src={editButton} alt='Another Image' className='cursor-pointer' />
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default EditOrganisationSectionTwo
