import React from 'react'
import { Tooltip } from 'antd';

export default function PersonalDetails(props: any) {
  const { employeeInformation } = props;
  const {
    dateOfBirth = '',
    personalEmail = '',
    gender = '',
    bloodGroup = '',
    permanentAddress = '',
    presentAddress = ''
  } = employeeInformation;

  return (
    <div>
      <ul>
        <li>
          <div>DOB :</div>
          <div>{dateOfBirth ?? '-'}</div>
        </li>
        <li>
          <div>Personal Email :</div>
          <div className='break-all'>
            <Tooltip placement="top" title={personalEmail ?? '-'}>
              {personalEmail ?? '-'}
            </Tooltip>
          </div>
        </li>
        <li>
          <div>Contact Number :</div>
          <div>{employeeInformation?.personalContactNo ?? '-'}</div>
        </li>
        <li>
          <div>Gender :</div>
          <div>{gender ?? '-'}</div>
        </li>
        <li>
          <div>Current Address  :</div>
          {permanentAddress === null ? <div>-</div> :
            <div>{`${permanentAddress?.doorNo}, ${permanentAddress?.street}, ${permanentAddress?.city}, ${permanentAddress?.state}, ${permanentAddress?.pinCode}`}</div>
          }
        </li>
        <li>
          <div>Permanent Address   :</div>
          {presentAddress === null ? <div>-</div>
            : <div>{`${presentAddress?.doorNo}, ${presentAddress?.street}, ${presentAddress?.city}, ${presentAddress?.state}, ${presentAddress?.pinCode}`}</div>
          }
        </li>
        <li>
          <div>Blood Group  :</div>
          <div>{bloodGroup ?? '-'}</div>
        </li>
      </ul></div>
  )
}