import { ErrorMessages, triggerNotification } from '../../../common/CommonExport';
import { clearEventData } from '../../../redux/slices/empEventsSlice';
import { isObjectEmpty } from '../DashBoardHelper';

export const handleChange = (value: string, field: any, setValues: any, values: any, designationList: any, setDropDownList: any, dropDownList: any, officialEmailEdit: any) => {
    if (field === 'firstName') {
        setValues({
            ...values,
            firstName: value?.charAt(0)?.toUpperCase() + value?.slice(1),
            ...(officialEmailEdit && { officialEmail: `${value?.replace(/\s/g, '')}${value?.length > 0 ? '.' : ''}${values?.lastName?.replace(/\s/g, '')}`.toLowerCase() }),
        });
    } else if (field === 'lastName') {
        setValues({
            ...values,
            lastName: value?.charAt(0)?.toUpperCase() + value?.slice(1),
            ...(officialEmailEdit && { officialEmail: `${values?.firstName?.replace(/\s/g, '')}${value?.length > 0 ? '.' : ''}${value?.charAt(0)?.replace(/\s/g, '')}`?.toLowerCase() }),
        });
    } else if (field === 'department') {
        setValues({
            ...values,
            department: {
                departmentId: value,
            },
            designation: {
                designationId: '',
            },
        });
        handleDesignationCall(value, designationList, setDropDownList, dropDownList);
    } else if (field === 'designation') {
        setValues({
            ...values,
            designation: {
                designationId: value,
            },
        });
    } else if (field === 'emailId') {
        setValues({
            ...values,
            personalEmail: value,
        });
    } else if (field === 'officialEmailId') {
        setValues({
            ...values,
            officialEmail: value,
        });
    } else if (field === 'reportingManager') {
        setValues({
            ...values,
            reportingManager: {
                employeeId: value,
            },
        });
    } else if (field === 'employeeStatus') {
        setValues({
            ...values,
            status: {
                statusId: value,
            },
        });
    } else if (field === 'initiatingManager') {
        setValues({
            ...values,
            initiationManager: {
                employeeId: value,
            },
        });
    } else if (field === 'employeeType') {
        setValues({
            ...values,
            employeeType: {
                employeeTypeId: value,
            },
        });
    }
};

export const finalSubmit = (values: any, setLoader: any, employeeCreate: any, imageData: any, handleCloseModal: any, domain: any, dispatch: any, setOfficialEmailEdit: any) => {
    setLoader(true);
    const payload = { ...values };
    payload.officialEmail += domain;
    const employeeDto = { employeeDto: payload };
    const headers = { 'Content-Type': 'multipart/form-data' };
    employeeCreate(imageData, employeeDto, {}, headers)
        .then((res: any) => {
            dispatch(clearEventData());
            setLoader(false);
            triggerNotification('success', '', res?.data?.status?.message, 'topRight');
            handleCloseModal();
        })
        .catch((err: any) => {
            setLoader(false);
            if (err?.response?.data?.message?.includes('Official Email Already Exists')) {
                setOfficialEmailEdit(false);
            }
            triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        });
};

const handleDesignationCall = (id: any, designationList: any, setDropDownList: any, dropDownList: any) => {
    const designationParams = {
        departmentId: id,
    };
    designationList('', designationParams)
        .then((res: any) => {
            setDropDownList({
                ...dropDownList,
                designationList: res,
            });
        })
        .catch((err: any) => {
            triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        });
};

export const onSubmit = ({ values, orgId, setShowError, setLoader, employeeCreate, imageData, handleCloseModal, finalSubmit, setOfficialEmailEdit }: any, domain: any, imgCheck: any, dispatch: any) => {
    values.organisationId = orgId;
    let isFormValid = isObjectEmpty(values);
    if (!imgCheck) isFormValid = false;
    setShowError(!isFormValid);
    if (isFormValid) finalSubmit(values, setLoader, employeeCreate, imageData, handleCloseModal, domain, dispatch, setOfficialEmailEdit);
};
