import React, { useEffect } from 'react';
import ImagesComponent from '../../Images/Images';
import MenuIcon from '../../../assets/images/new-dashboard/menu.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideBar } from '../../../redux/header/headerAction';

export default function ToggleMenu() {
    const dispatch = useDispatch();
    const toggle: any = useSelector((state: any) => state?.toggleSideBar?.data);
    // let toggle = isSidebarOpen.toggleSideBar.data
    const handleToggleSideBar = () => {
        dispatch(toggleSideBar(!toggle));
    };
    useEffect(() => {
        const handleDocumentClick = (event: any) => {
            if (toggle && !event.target.closest('.toggle-content')) {
                dispatch(toggleSideBar(false));
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [dispatch, toggle]);

    return (
        <div className='toggle-content' onClick={handleToggleSideBar}>
            <ImagesComponent alt='toggleMenu' src={MenuIcon} className='toggle__icon' />
        </div>
    );
}
