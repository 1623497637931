import React from 'react';
import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationProps {
    type: NotificationType;
    message: string;
    description: string;
    placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

const ToastBar: React.FC<NotificationProps> = ({ type, message, description, placement }) => {
    return null; // This component does not render visible UI elements
};

const triggerNotification = (type: NotificationType, message: string, description: string, placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight', duration?: number) => {
    notification[type]({
        message,
        description,
        placement,
        duration
    });
};

export { ToastBar, triggerNotification };
