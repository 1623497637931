import { Button, DatePicker, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import FloatLabel from '../../../../../../components/FloatLabel';
import calender from "../../../../../../assets/images/calender-week.svg"
import { useDispatch, useSelector } from 'react-redux';
import { setDeprovision } from '../../../../../../redux/slices/updateProfileSlice';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { useParams } from 'react-router-dom';


const ResignationNotification = (props: any) => {
  const dispatch = useDispatch()
  const { open, handelCancel, manaualFill } = props;
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const { id }: any = useParams();
  const reasonsList = useGetApiRequests('getResignationReasons', 'GET');
  const getEmployeeReasons = useGetApiRequests('getDeprovisionApprovalList', 'GET');
  const saveDeprovision = useGetApiRequests('saveDeprovision', "POST");
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [dropDownList, setDropDownList] = useState<any>([])
  const [relievingDate, setRelievingDate] = useState('')
  const [relievingReasons, setRelievingReasons] = useState(0)
  const [specifyValue, setSpecifyValue] = useState('')
  const [reasonErrMsg, setReasonErrMsg] = useState('')
  const [relievingDateErrMsg, setRelievingDateErrMsg] = useState('')
  const [specifyErrMsg, setSpecifyErrMsg] = useState('');
  const [reason, setReason] = useState('');
  const [currentDate, setCurrentDate] = useState('')
  const getReasons = async () => {
    setLoader(true)
    const queryParams: any = {
      statusCategory: 'Deprovision'
    }

    await reasonsList('', queryParams).then((res: any) => {
      const list = res?.data?.data.filter((item: any) => [33, 34, 35, 39].includes(item.statusId))
      const reformedReasonList = list.map((item: any) => ({
        value: item?.statusId,
        label: item?.name
      }))
      setDropDownList(reformedReasonList);
      setLoader(false)

    })
      .catch(() => {
        setLoader(false)
      });
  }
  const getValidReasonsFromEmployee = async () => {
    setLoader(true)
    const queryParams: any = {
      isEmployee: true
    };
    const pathParams: any = {
      id: `${id}/resignation`
    };
    await getEmployeeReasons('', queryParams, pathParams).then((res: any) => {
      setLoader(false)
      if (res?.data?.data?.otherText !== null) {
        setReason(res?.data?.data?.otherText)
      } else {
        const reasonFromApi = res?.data?.data?.status?.name;
        setReason(reasonFromApi);
      }
    })
      .catch(() => {
        setLoader(false)
      });
  }
  useEffect(() => {
    setSendMail(open);
    if (!manaualFill) {
      setRelievingDate(employeeDetails?.dateOfResigned)
      getValidReasonsFromEmployee()
    } else {
      const now = dayjs();
      const date = now.format('DD-MM-YYYY');
      setCurrentDate(date)
      getReasons();
    }
  }, [])

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onCancel = () => {
    handelCancel()
  }

  const reasonsChange = (value: any) => {
    setRelievingReasons(value);
    setReasonErrMsg('')
  };
  const relievingDateOnSelect = (dateString: any) => {
    setRelievingDate(dateString);
    setRelievingDateErrMsg('')
  }
  const handelSendMail = () => {

    const query: any = {
      organisationId: orgId
    };
    const pathParams: any = {
      id: `${id}/deprovision`
    };
    const payload: any = {
      relevingDate: manaualFill ? relievingDate : employeeDetails?.dateOfResigned,
      statusId: manaualFill ? relievingReasons : 33
    }
    if (manaualFill) {
      if (relievingDate === '' && relievingReasons !== 0) {
        setRelievingDateErrMsg('Relieving date is required');
      }
      if (relievingReasons === 0 && relievingDate !== '') {
        setReasonErrMsg('Relieving Reason is required');
      }
      if (relievingDate === '' && relievingReasons === 0) {
        setRelievingDateErrMsg('Relieving date is required');
        setReasonErrMsg('Relieving Reason is required');
      }
      if (relievingReasons === 39 && specifyValue === '') {
        setSpecifyErrMsg('Please specify the reason');
      }
      if (relievingDate !== '' && relievingReasons !== 0) {
        setLoader(true)
        dispatch(setDeprovision(true));
        saveDeprovision(payload, query, pathParams).then((res: any) => {
          dispatch(setDeprovision(true));
          setLoader(false)
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          onCancel();
        })
          .catch((err: any) => {
            setLoader(false)
            triggerNotification('error', '', err?.message, 'topRight');
          })
      }
    } else {

      setLoader(true)
      saveDeprovision(payload, query, pathParams).then((res: any) => {
        dispatch(setDeprovision(true));
        setLoader(false)
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        onCancel();
      })
        .catch((err: any) => {
          setLoader(false)
          triggerNotification('error', '', err?.message, 'topRight');
        })
    }
  }

  const handelText = (inputValue: any) => {
    setSpecifyValue(inputValue);
    setSpecifyErrMsg('')
  }
  return (
    <Modal title="Resignation Details" className='deprovision_requestion w-[885px]' centered open={sendMail} footer={null} maskClosable={false} onCancel={onCancel}>
      <div className="flex w-full pt-6 mt-10">
        <div className="w-1/2 pl-10">
          <div className="flex items-center w-full pb-4">
            <p className='text-[16px] w-[180px]  font-rubik   text-[#878787]'>Employee name  &nbsp; &nbsp; &nbsp; &nbsp; :</p>
            <span className='text-[16px] pl-2 font-rubik font-medium text-[#333333]'> {employeeDetails?.firstName + " " + employeeDetails?.lastName}</span>
          </div>
          <div className="flex items-center w-full pb-4">
            <p className='text-[16px] w-[180px]  font-rubik text-[#878787]'>Resignation date &nbsp; &nbsp; &nbsp; : </p>
            <span className='text-[16px] pl-2 font-rubik font-medium text-[#333333]'>{!manaualFill ? employeeDetails?.dateOfResigned : currentDate}</span>
          </div>

        </div>
        <div className="w-1/2 flex justify-center items-center date_picker flex-col">
          {!manaualFill ? (
            <div className='form-item w-[70%] flex flex-col'>
              <label className='text-[14px] text-[#878787] font-rubik' htmlFor="">Date of Relieving</label>
              <DatePicker suffixIcon={<img src={calender} className='mb-2' />} placeholder='Select relieving date' className='float-control pointer-events-none' format="YYYY-MM-DD"
                value={dayjs(relievingDate, 'YYYY-MM-DD')} onChange={(date, dateString) => { relievingDateOnSelect(dateString) }} />
              {relievingDateErrMsg && <div className="error-message">{relievingDateErrMsg}</div>}
            </div>) : (
            <div className='form-item w-[70%] flex flex-col'>
              <label className='text-[14px] text-[#878787] font-rubik' htmlFor="">Date of Relieving</label>
              <DatePicker suffixIcon={<img src={calender} className='mb-2' />} placeholder='Select relieving date' className='float-control' format="YYYY-MM-DD"
                onChange={(date, dateString) => { relievingDateOnSelect(dateString) }} />
              {relievingDateErrMsg && <div className="error-message">{relievingDateErrMsg}</div>}
            </div>
          )}
          {manaualFill ? (
            <div className='form-item w-[70%] flex flex-col mt-4'>
              <p className='text-[#878787] text-[13px] font-rubik'>Reason for Relieving</p>
              <Select className='float-control w-full __select-kt' options={dropDownList} onChange={reasonsChange} showSearch filterOption={filterOption} placeholder="Select reason" optionFilterProp="children"
                suffixIcon={<span className=" text-[#000] material-symbols-outlined" >  arrow_drop_down </span>} />
              {reasonErrMsg && <div className="error-message">{reasonErrMsg}</div>}
            </div>) : (
            <div className='form-item w-[70%] flex flex-col mt-4 pointer-events-none'>
              <FloatLabel label='Reason for Relieving' value={reason}>
                <Input type="text" className='float-control' value={reason} />
              </FloatLabel>
            </div>)}
          {relievingReasons === 39 && (
            <div className='w-[70%] mt-4'>
              <TextArea rows={4} onChange={(e) => handelText(e.target.value)} className='border-0 bg-[#F9F9F9] rounded' placeholder='Please specify here' />
              {specifyErrMsg && <div className="error-message">{relievingDateErrMsg}</div>}

            </div>)}
        </div>

      </div>
      <div className="flex justify-end mt-40 pb-5">
        <Button type="primary" shape="round" className='addButton w-[140px]   _deprovision mr-4' onClick={() => onCancel()} >
          Cancel
        </Button>
        <Button type="primary" shape="round" className='addButton  deprovision text-[#d23b7d]' onClick={() => handelSendMail()} >
          Send no due Certificate
        </Button>
      </div>
      {loader && <ModalLoader />}
    </Modal>
  )
}

export default ResignationNotification