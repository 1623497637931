import { displayName } from "react-quill"
import { triggerNotification } from "../../../../../common/CommonExport"
import { setCurrentTab } from "../../../../../redux/slices/courseSlice"
import { questionCurrentTab } from "../../../../../redux/slices/multipleQuestions"
import { arrangeInOrder, arrangeInOrderSchema, briefQuestion, fillInTheBlanks, fillInTheBlanksSchema, matchingPairs, matchingPairsSchema, multipleChoiceQuestion, multipleChoiceQuestionSchema, trueOrFalse, trueOrFalseSchema } from "../../../../BasicForm/empInfoFormSchema"

const handleClearMediaData = (setMediaData: any) => {
  setMediaData('')
}

export const handleInitialStateValues = (currentQuestionTab: any, setFieldSchema: any, setInitialValue: any, setMediaData: any, payload: any = {}) => {
  handleClearMediaData(setMediaData)
  let mainValue: any, schema: any
  const check = Object.keys(payload)?.length > 0
  if (currentQuestionTab === 1) {
    mainValue = check ? payload : multipleChoiceQuestion
    schema = multipleChoiceQuestionSchema
  } else if (currentQuestionTab === 2) {
    mainValue = check ? payload : trueOrFalse
    schema = trueOrFalseSchema
  } else if (currentQuestionTab === 3) {
    mainValue = check ? payload : fillInTheBlanks
    schema = fillInTheBlanksSchema
  } else if (currentQuestionTab === 4) {
    mainValue = check ? payload : briefQuestion
    schema = fillInTheBlanksSchema
  } else if (currentQuestionTab === 6) {
    mainValue = check ? payload : matchingPairs
    schema = matchingPairsSchema
  } else if (currentQuestionTab === 5) {
    mainValue = check ? payload : arrangeInOrder
    schema = arrangeInOrderSchema
  }
  setFieldSchema(schema)
  setInitialValue(mainValue)
}

export const checkIfAnyFalse = (arr: any) => {
  return arr.some((item: any) => item.check === false);
};

const handlePostData = async (payload: any, mediaData: any, setLoader: any, quizCreate: any, loggedInUser: any, handleBack: any, putOrPost?: any) => {
  setLoader(true)
  let imageData: any
  if (!mediaData) {
    imageData = new FormData();
    imageData.append('question-image', '');
  } else imageData = mediaData

  try {
    let pathParams = {}
    if (putOrPost)
      pathParams = { id: payload?.questionaireId }
    const headers = { 'Content-Type': 'multipart/form-data' };
    const res: any = await quizCreate(imageData, { questionaireDtoStr: payload, loggedInUserId: loggedInUser?.userId }, pathParams, headers);
    triggerNotification('success', '', res?.data?.message, 'topRight');
    handleBack()
    setLoader(false)
  } catch (err: any) {
    triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    setLoader(false)
  }
};

export const onSubmit = ({ mediaData, arrangeInOrderState, setShowError, setLoader, quizCreate, loggedInUser, handleBack, currentQuestion, quizUpdate }: any) => (values: any) => {
  let payload: any
  if (values?.questionType === 'MCQ') {
    payload = {
      ...(values?.questionaireId && { questionaireId: values?.questionaireId }),
      questionType: "MCQ", question: values?.question, answerExplanation: values?.explanation,
      questionaireOptions: [values?.option1, values?.option2, values?.option3, values?.option4].map((option, index) => ({
        ...(values?.questionaireId && { questionaireOptionId: values?.[`optionId${index + 1}`] }),
        option,
        isCorrectAnswer: values?.correctAnswer === `option${index + 1}`,
        displaySequence: index + 1
      }))
    }
    const formData = new FormData();
    formData.append('question-image', mediaData);
    handlePostData(payload, formData, setLoader, values?.questionaireId ? quizUpdate : quizCreate, loggedInUser, handleBack, !!values?.questionaireId)
  } else if (values?.questionType === 'TF') {
    payload = {
      ...(values?.questionaireId && { questionaireId: values?.questionaireId }),
      questionType: "TF", question: values?.question, answerExplanation: values?.explanation,
      questionaireOptions: [
        { option: "TRUE", displaySequence: 1, isCorrectAnswer: false },
        { option: "FALSE", displaySequence: 2, isCorrectAnswer: false }
      ].map((optionObj) => ({
        ...optionObj,
        isCorrectAnswer: values?.correctAnswer === optionObj.option.toUpperCase()
      }))
    }
    const formData = new FormData();
    formData.append('question-image', mediaData);
    handlePostData(payload, formData, setLoader, values?.questionaireId ? quizUpdate : quizCreate, loggedInUser, handleBack, !!values?.questionaireId)
  } else if (values?.questionType === 'FIB' || values?.questionType === 'OQ') {
    payload = {
      ...(values?.questionaireId && { questionaireId: values?.questionaireId }),
      questionType: values?.questionType,
      question: values?.question,
      questionaireOptions: [{ keyword: values?.correctAnswer }]
    }
    const formData = new FormData();
    formData.append('question-image', mediaData);
    handlePostData(payload, formData, setLoader, values?.questionaireId ? quizUpdate : quizCreate, loggedInUser, handleBack, !!values?.questionaireId)
  } else if (values?.questionType === 'MFP') {
    payload = {
      ...(values?.questionaireId && { questionaireId: values?.questionaireId }),
      question: values?.question,
      questionType: values?.questionType,
      matchingHeader1: values?.matchingHeader1,
      matchingHeader2: values?.matchingHeader2,
      questionaireOptions: Object.keys(values)
        .filter(key => key.startsWith('pair'))
        .map(pairKey => ({
          ...(values[pairKey]?.questionaireOptionId && { questionaireOptionId: values[pairKey]?.questionaireOptionId }),
          option: values[pairKey].option,
          displaySequence: parseInt(pairKey.replace('pair', ''), 10),
          optionMatching: values[pairKey].optionMatching
        }))
    };
    const formData = new FormData();
    formData.append('question-image', mediaData);
    handlePostData(payload, formData, setLoader, values?.questionaireId ? quizUpdate : quizCreate, loggedInUser, handleBack, !!values?.questionaireId)
  } else if (values?.questionType === 'AOI') {
    const result = checkIfAnyFalse(arrangeInOrderState);
    if (result) {
      setShowError(result)
      return
    }
    payload = {
      ...(values?.questionaireId && { questionaireId: values?.questionaireId }),
      question: values?.question,
      questionType: values?.questionType,
      questionaireOptions: arrangeInOrderState?.map((item: any, index: any) => ({
        ...(item?.imageUrl?.length === 0 && {
          displaySequence: index + 1,
        }),
        ...((values?.questionaireOptions?.[index]?.questionaireOptionId && item?.imageUrl?.length === 0)
          && { questionaireOptionId: values?.questionaireOptions[index]?.questionaireOptionId }),
      })).filter((item: any) => Object.keys(item).length > 0),
    }
    const formData = new FormData();
    arrangeInOrderState?.forEach((item: any) => {
      if (item.check && item.file && item.imageUrl?.length === 0) {
        formData.append(`option-images`, item.file);
      }
    });
    formData.append('question-image', mediaData);
    handlePostData(payload, formData, setLoader, values?.questionaireId ? quizUpdate : quizCreate, loggedInUser, handleBack, !!values?.questionaireId)
  }
};