import React, { useEffect, useState } from "react";
import { useGetApiRequests } from "../../../../../../common/CommonExport";
import DocumentPreviewAll from "./DocumentPreviewAll";

const DocumentPDFView = (props: any) => {
  const downloadUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL
  const baseUrl = process.env.REACT_APP_GOOGLR_DOCUMENT_VIEW;
  const documentDownload = useGetApiRequests('documentDownload', 'GET');
  const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
  const [open, setOpen] = useState<any>(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    setOpen(props?.open);
    setIframeUrl(`${baseUrl}${props?.currentPdfDetails?.documentPath}&embedded=true`);
    let intervalId: any;
    if (props?.currentPdfDetails?.documentPath?.includes('.pdf')) {
      intervalId = setInterval(() => {
        if (iframeLoaded) {
          clearInterval(intervalId);
        } else {
          setIframeUrl(`${baseUrl}${props?.currentPdfDetails?.documentPath}&embedded=true&timestamp=${Date.now()}`);
          setIframeLoaded(false);
        }
      }, 2000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [iframeLoaded, props?.currentPdfDetails?.documentPath]);

  const handleClose = () => {
    setOpen(false);
    setIframeLoaded(false);
    props?.setDocumentView(false);
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const handleDownloadDocument = (downloadUrl: any) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = 'document_filename.ext';
    downloadLink.click();
    // document.body.removeChild(downloadLink);
  };

  const handleDownload = () => {
    if (props?.currentPdfDetails?.documentPath?.includes('course-certificate')) {
      handleDownloadDocument(`${downloadUrl}${props?.currentPdfDetails?.documentPath}`)
    } else {
      const param = {
        documentId: props?.currentPdfDetails?.documentDetailId,
        end: 'download',
      };
      documentDownload('', {}, param)
        .then((res: any) => {
          handleDownloadDocument(res?.data?.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const renderPdfContent = () => (
    <>
      {props?.currentPdfDetails?.documentPath?.includes('.pdf') ? (
        <>
          {!props?.mainPage && (
            !iframeLoaded &&
            <p>Document is loading...</p>
          )}
          <iframe className="documentPreview" title="Document Preview" src={iframeUrl} width="100%" onLoad={handleIframeLoad} />
        </>
      ) : (
        <img alt="sign_image" src={`${imageBaseUrl}${props?.currentPdfDetails?.documentPath}`} />
      )
      }
      {!props?.certificate && <button onClick={() => handleDownload()} className="btn-modal__download"> <span className="material-symbols-outlined file_download"> file_download </span></button>}
    </>
  )

  return (
    <>
      {!props?.mainPage ? <DocumentPreviewAll renderPdfContent={renderPdfContent} open={open} handleClose={handleClose} iframeLoaded={iframeLoaded} iframeUrl={iframeUrl} handleIframeLoad={handleIframeLoad} /> :
        renderPdfContent()}
    </>
  );
};

export default DocumentPDFView