import React, { useEffect, useState } from 'react';
import { useGetApiRequests, useNavigate, useForm, SubmitHandler, Controller, yupResolver, Button, Input, Heading, ErrorMessage, triggerNotification, ErrorMessages } from '../../common/CommonExport';
import showPassword from '../../assets/images/eyeOpen.svg';
import hidePassword from '../../assets/images/eyeClose.svg';
import Loader from '../../components/Loader/Loader';
import { SetPasswordSchema } from './SetPasswordSchema';
import { useLocation } from 'react-router-dom';

interface SetPasswordPaylaod {
  newPassword: string;
  confirmPassword: string;
}

const SetPasswordForm: React.FC = () => {
  const resetPassword = useGetApiRequests('setPassword', 'POST');
  const setPasswordTokenValidation = useGetApiRequests('setPasswordTokenValidation', 'POST');

  const navigate = useNavigate();
  const { search } = useLocation();
  const userEmail: any = new URLSearchParams(search).get("email")
  const token: any = new URLSearchParams(search).get("token")
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [fromSetPassword, setFromSetPassword] = useState(false);
  const [passwordVisibleConfirmPassword, setPasswordVisibleConfirmPassword] = useState(false);

  useEffect(() => {
    const url: any = window.location.href;
    setFromSetPassword(url.includes('/set-password'));
    if (token) {
      tokenValidator()
    }
  }, [])
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SetPasswordPaylaod>({
    resolver: yupResolver(SetPasswordSchema) as any,
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibilityConfirmPassword = () => {
    setPasswordVisibleConfirmPassword(!passwordVisibleConfirmPassword);
  };

  const onSave: SubmitHandler<SetPasswordPaylaod> = (data: SetPasswordPaylaod) => {
    const payload: any = {
      email: userEmail ?? '',
      userPassword: data?.confirmPassword,
    };
    resetPassword(payload)
      .then((response: any) => {
        setLoader(false);
        triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        navigate('/login');
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
      });
  };
  const tokenValidator = () => {
    const payload: any = {
      token: token,
    };
    setPasswordTokenValidation('', payload)
      .then((response: any) => {
        setLoader(false);
        // triggerNotification('success', '', response?.data?.status?.message, 'topRight');
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });

  }

  const passwordConversion = (item: any): any => {
    const password: string = item;
    const bytesArray = [];
    for (let i = 0; i < password.length; ++i) {
      bytesArray.push(item.charCodeAt(i));
    }
    const bytelength: string = bytesArray.length.toString();
    const appendedcode = bytelength.charCodeAt(0);
    bytesArray.splice(0, 0, appendedcode);
    bytesArray.splice(bytesArray.length, 0, appendedcode);
    return bytesArray.reverse();
  };

  return (
    <div className='w-full min-h-screen flex items-center justify-center login-form '>
      <div className='w-full mx-auto my:auto bg-transparent'>
        <Heading title={fromSetPassword ? 'Set Password' : 'Reset Password'} subheading='Just one step, to complete your login process' />
        <div className='w-9/12 mt-12'>
          <form onSubmit={handleSubmit(onSave)} noValidate>

            <div className='z-0 mt-6 relative'>
              <Controller name='newPassword' control={control} render={({ field }) => <Input type={passwordVisible ? 'text' : 'password'} id='newPassword' label='New Password*' control={control} {...field} hasError={!!errors.newPassword} />} />
              <img className='cursor-pointer eyeIcon right-0 absolute w-5 top-5' alt='passwordVisibilitynew' title={passwordVisible ? 'Show password' : 'Hide password'} src={passwordVisible ? showPassword : hidePassword} onClick={() => togglePasswordVisibility()} />
            </div>
            {errors.newPassword && <ErrorMessage message={errors.newPassword.message} />}
            <div className='z-0 mt-6 relative'>
              <Controller name='confirmPassword' control={control} render={({ field }) => <Input type={passwordVisibleConfirmPassword ? 'text' : 'password'} id='confirmPassword' label='Confirm Password*' control={control} {...field} hasError={!!errors.confirmPassword} />} />
              <img className='cursor-pointer eyeIcon right-0 absolute w-5 top-5' alt='passwordVisibility' title={passwordVisibleConfirmPassword ? 'Show password' : 'Hide password'} src={passwordVisibleConfirmPassword ? showPassword : hidePassword} onClick={() => togglePasswordVisibilityConfirmPassword()} />
            </div>
            {errors.confirmPassword && <ErrorMessage message={errors.confirmPassword.message} />}
            <div className='flex items-center justify-between mt-16 mb-12 login-forgot-password'>
              <a className='font-roboto text-sm font-medium' href='/'>{''}</a>
              <Button type='submit' variant='primary' label={fromSetPassword ? 'SET PASSWORD' : 'RESET PASSWORD'} />
            </div>
          </form>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default SetPasswordForm;
