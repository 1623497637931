import React from 'react'
import { Input } from 'antd';
import { ErrorMessage } from '../../../../../../../common/CommonExport';

export default function EmailTextAreaRendering(props: any) {
  const { TextArea } = Input;
  const { handleAppraisals, significantAchievementsErrorEmail, improvementAreasErrorEmail, technicalSkillsErrorEmail, softSkillsErrorEmail, appraiserCommentsErrorEmail } = props;

  return (
    <div>
      <div className="appraisal-comments mb-5">
        <div className="grid grid-cols-2 gap-4">
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Significant Achievements*</h3>
            <TextArea rows={3} style={{ resize: 'none' }} required={significantAchievementsErrorEmail} data-testid="significant-achievements" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "significantachievements") }} />
            {significantAchievementsErrorEmail && <ErrorMessage message='This field is mandatory*' />}
          </div>
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Improvement Areas*</h3>
            <TextArea rows={3} style={{ resize: 'none' }} required={improvementAreasErrorEmail} data-testid="Improvement Areas" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "improvementareas") }} />
            {improvementAreasErrorEmail && <ErrorMessage message='This field is mandatory*' />}
          </div>
        </div>
      </div>
      <div className="appraisal-comments">
        <h3 className='general-assessment-header'>Training Requirements</h3>
        <div className="grid grid-cols-2 gap-4">

          <div className="appraisal-comment">
            <h3 className='general-assessment-subtitle py-4'>Technical Skills*</h3>
            <TextArea rows={3} style={{ resize: 'none' }} required={technicalSkillsErrorEmail} data-testid="Technical Skills" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "technicalskills") }} />
            {technicalSkillsErrorEmail && <ErrorMessage message='This field is mandatory*' />}
          </div>
          <div className="appraisal-comment">
            <h3 className='general-assessment-subtitle py-4'>Soft Skills*</h3>
            <TextArea rows={3} style={{ resize: 'none' }} required={softSkillsErrorEmail} data-testid="Soft Skills" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "softskills") }} />
            {softSkillsErrorEmail && <ErrorMessage message='This field is mandatory*' />}
          </div>
        </div>
      </div>
      <div className="appraisal-comments my-5">
        <div className="grid grid-cols-1 gap-4">
          <div className="appraisal-comment">
            <h3 className='general-assessment-header py-4'>Appraiser Comments*</h3>
            <TextArea rows={3} style={{ resize: 'none' }} required={appraiserCommentsErrorEmail} data-testid="Appraiser Comments" placeholder='Enter your comments' onChange={(e) => { handleAppraisals(e, "appraiserfeedback", "appraisercomments") }} />
            {appraiserCommentsErrorEmail && <ErrorMessage message='This field is mandatory*' />}
          </div>
        </div>
      </div>
    </div>)
}
