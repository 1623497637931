import dayjs from 'dayjs';
import { triggerNotification } from '../../../../../../common/CommonExport';
import { employeeBonus } from '../../../../../BasicForm/empInfoFormSchema';
import { setBonusLoad } from '../../../../../../redux/slices/updateProfileSlice';

export const bonusList = (getBonusDetails: any, setBonusDetails: any, id: any, setLoader: any) => {
    setLoader(true);
    getBonusDetails('', {}, { id })
        .then((res: any) => {
            setBonusDetails(res);
            setLoader(false);
        })
        .catch((err: any) => {
            setLoader(false);
            triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
        });
};

export const handleEditEmployee = (item: any, setCurrentEmpBonusDetail: any, setOpenAddBonus: any) => {
    setCurrentEmpBonusDetail({
        isEdit: true,
        detail: item,
    });
    setOpenAddBonus(true);
};

export const handlePostData = async (payloads: any, saveBonus: any, setLoader: any, handleClose: any, dispatch: any) => {
    try {
        const response: any = await saveBonus(payloads);
        triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        dispatch(setBonusLoad());
        setLoader(false);
        handleClose();
    } catch (err: any) {
        triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
        setLoader(false);
        handleClose();
    }
};

export const handlePutData = async (values: any, updateBonus: any, props: any, id: any, setLoader: any, handleClose: any, dispatch: any) => {
    const payloads: any = values;
    payloads.endDate = dayjs(values?.endDate).format('YYYY-MM-DD');
    payloads.startDate = dayjs(values?.startDate).format('YYYY-MM-DD');
    payloads.amount = parseInt(values?.amount);
    payloads.bonusId = props?.currentEmpBonusDetail?.detail?.bonusId;
    try {
        const response: any = await updateBonus(payloads, {}, { id: values?.bonusId });
        triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        dispatch(setBonusLoad());
        setLoader(false);
        handleClose();
    } catch (err: any) {
        triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
        setLoader(false);
        handleClose();
    }
};

export const initialLoadData = (props: any, setInitialValues: any, setOpen: any) => {
    if (props?.currentEmpBonusDetail?.isEdit) {
        const { detail } = props ? props.currentEmpBonusDetail : '';
        setInitialValues({
            name: detail?.name,
            amount: detail?.amount,
            startDate: detail?.startDate,
            endDate: detail?.endDate,
            type: detail?.type,
        });
    } else {
        setInitialValues(employeeBonus);
    }
    setOpen(props?.open);
};
