import React, { useState } from 'react'
import { Button, Tabs, Tooltip } from 'antd';
import LeavePolicy from './LeavePolicy';
import LeaveReport from './LeaveReport';
import LeaveRequest from './leave-request/LeaveRequest';
import AddLeavePolicyModal from './AddLeavePolicyModal'
import DownloadReport from './DownloadReport';
import { triggerNotification, useGetApiRequests, useNavigate, useSelector } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';

const { TabPane } = Tabs;

export default function LeaveManagementTab() {
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState<any>('1');
  const [showHistory, setShowHistory] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const getLeaveReportHistoryApi = useGetApiRequests('leaveReportHistory', "GET");
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [historyList, setHistoryList] = useState([]);
  const [loader, setLoader] = useState(false)
  const items = [
    {
      key: '1',
      label: 'Leave Policy',
      children: <LeavePolicy />,
    },
    {
      key: '2',
      label: 'Leave Report',
      children: <LeaveReport />,
    },
    {
      key: '3',
      label: 'Leave Request',
      children: <LeaveRequest />,
    },


  ];
  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    setShowHistory(false)
  };
  const [modalOpen, setModalOpen] = useState(false);

  const handelpopup = () => {
    setModalOpen(true);
  }

  const handelClose = () => {
    setModalOpen(false);
  }
  const handelDocumentDownload = () => {
    setModalDownload(false);
  }
  const handelHistory = () => {
    setLoader(true);
    const payload: any = {
      start: 0,
      limit: 10000,
      organisationId: orgId
    }
    getLeaveReportHistoryApi('', payload).then((res: any) => {
      setLoader(false);
      setHistoryList(res?.data?.data)
    })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', err?.message, 'topRight');
      });
    setShowHistory(!showHistory)
  }
  const handelDownload = () => {
    setModalDownload(true)
  }
  const handleRoute = () => {
    navigate('/home/dashboard');
  }

  const dateConversionIntoMonthFormat = (inputDate: any) => {
    const newDate = new Date(inputDate);
    const days = ("0" + newDate.getDate()).slice(-2);
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = months[newDate.getMonth()];
    const year = newDate.getFullYear();
    return `${days} ${month} ${year}`;
  };

  return (
    <div className='  '>
      <div className='page-container bg-customBg w-full active_ flex '>
        <div className={!showHistory ? "w-full" : "w-4/5"}>
          <div className='full-width-container mt-5 active-history'>
            <div className=' bg-white p-5 w-full h-full'>
              <div className=" flex justify-between">
                <div className='' >
                  <ul className='flex'>
                    <li className='text-[#878787] text-[16px] cursor-pointer' onClick={handleRoute} >Dashboard /</li>
                    <li className='text-[16px] pl-1 text-[#ff4081] font-normal'>Leave management</li>
                  </ul>
                </div>
                {activeTabKey === "1" &&
                  <Tooltip placement="bottom" title="Create Leave Policy">
                    <Button type="primary" shape="circle" size={'large'} onClick={() => handelpopup()}>
                      <span data-testid="addSpan" className="material-symbols-outlined">
                        add
                      </span>
                    </Button>
                  </Tooltip>
                }
                {activeTabKey === "2" &&
                  <div className='flex'>
                    <Tooltip placement="bottom" title="Download Leave Report">
                      <Button data-testid="downloadSpan" type="primary" shape="circle" className='mr-4 cursor-pointer' size={'large'} onClick={() => handelDownload()}>
                        <span className="material-symbols-outlined">
                          download
                        </span>
                      </Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Leave History">
                      <Button type="default" shape="circle" size={'large'} onClick={() => handelHistory()}>
                        <span className="material-symbols-outlined">
                          history
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                }
              </div>
              <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                {items.map(item => (
                  <TabPane key={item.key} tab={item.label}>
                    {item.children}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
        {showHistory &&
          <div className=' bg-white p-5 history w-1/5 mt-5'>
            <p className='text-[#D23B7D] font-medium text-[14px] pb-6'>History</p>
            {historyList && historyList.length > 0 ? historyList.map((item: any) => (
              <div key={item.leaveReportId} className="file-name pb-5">
                <p className='text-[#000000] font-medium text-[16px]'>{item?.name}</p>
                <span className='text-[#6F6F6F] font-normal text-[14px]'>{dateConversionIntoMonthFormat(item?.auditFieldInfo?.createdOn)}</span>
              </div>)) : (
              <p className='font-medium text-[14px] pb-6'>No history available</p>
            )}
          </div>

        }
      </div>
      {modalOpen && <AddLeavePolicyModal open={modalOpen} handelClose={handelClose} isEdit={false} />}
      {modalDownload && <DownloadReport open={modalDownload} handelDocumentDownload={handelDocumentDownload} isEdit={false} />}
      {loader && <Loader />}
    </div>

  )
}
