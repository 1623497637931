import React from 'react';
import LoginGif from '../../assets/images/login-img.gif';
import AllAboardLogo from '../../assets/images/logo.png';
import SetPasswordForm from './SetPasswordForm';

const SetPassword: React.FC = () => {
  return (
    <div data-testid='set-password-form' className='login-left-content h-screen w-full backgroundImage'>
      <div className='md:flex md:flex-row  login bg-transparent '>
        <div className='flex items-center md:w-2/3 justify-center'>
          <div>
            <img alt='login' src={LoginGif} className='object-center lg:w-61 p-3 login-img' />
            <div className='pt-5 text-center font-sans font-rubik login-sitename'>
              <span className='font-rubik font-sans text-black flex items-center justify-center text-4xl font-light mb-0'>
                <img className='w-16' alt='loginImage' src={AllAboardLogo} />
                <p className='text-5xl pb-1 '>ALL-Aboard!</p>
              </span>
              <p className='text-base unique-platform-text'>A unique platform for employee onboarding</p>
            </div>
          </div>
        </div>
        <div className='containerTwoWrapper md:w-1/3'>
          <SetPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
