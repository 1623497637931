import React, { useState } from 'react'
import { Tabs } from 'antd';
import EmployeeListOfCourse from './EmployeeListOfCourse';
import EmployeeLearningPath from './EmployeeLearningPath';
import { useLocation, useNavigate } from 'react-router-dom';
const { TabPane } = Tabs;

export default function EmployeeProgressCourseListAndLearningPath() {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const { search } = useLocation();
  let items;
  const isLearningPath: any = new URLSearchParams(search).get("learningPath");
  const navigate = useNavigate();
  if (isLearningPath === 'false') {
    items = [
      {
        key: '1',
        label: 'Assigned Course',
        children: <EmployeeListOfCourse />,
      },]
  } else {
    items = [
      {
        key: '1',
        label: 'Assigned Course',
        children: <EmployeeLearningPath />,
      },]
  }

  const handleTabChange = (key: string) => {

    setActiveTabKey(key);
  };
  const handelNavigate = () => {
    navigate("/home/dashboard/learning-management")
  }
  const handelNavigateTable = () => {
    navigate("/home/dashboard/learning-management/progress")
  }
  return (
    <div>
      <div className='page-container bg-customBg'>
        <div className=' pl-16 pt-4' >
          <ul className='flex'>
            <li className='text-[#878787] text-[15px]' >Dashboard /</li>
            <li className='text-[#878787] text-[15px] cursor-pointer' onClick={handelNavigate}>Learning Management /</li>
            <li className='text-[#878787] text-[15px] cursor-pointer' onClick={handelNavigate}>Course /</li>
            <li className='text-[#878787] text-[15px] cursor-pointer' onClick={handelNavigateTable}>Employee Progress /</li>
            <li className='text-[15px]'>Ranjith</li>
          </ul>
        </div>
        <div className='full-width-container mt-5'>
          <div className='tab-container bg-white'>
            <Tabs activeKey={activeTabKey} onChange={handleTabChange} >
              {items.map(item => (
                <TabPane key={item.key} tab={item.label}>
                  {item.children}
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>

  )
}
