import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import './employeeProfile.scss'

const EmployeeProfileImage = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props?.open);
  }, []);

  const handleClose = () => {
    setOpen(false)
    props?.setImageOpen(false)
  }

  return (
    <Modal title='Profile Image' centered open={open} onCancel={() => handleClose()} maskClosable={false} footer={null} className="image_view_profile">
      <img alt='profile'
        className='imageSizeModal'
        src={props?.imageURL}
      />
    </Modal>
  )

}

export default EmployeeProfileImage