import React, { useEffect, useState } from 'react'
import { Collapse, Select, Input } from 'antd';
import { ErrorMessage, ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { ratings } from '../AppraisalFormValidationSchema';
import Loader from '../../../../../../../components/Loader/Loader';
import EmailTextAreaRendering from './EmailTextArea';
import EmailChangeCtc from './EmailAppraisalChangeCtc';
import { emailHandleAppraisalsApiCall } from './EmailHandleApi';
import EmailAppraisalButtonRendering from './EmailButtonRendering';
const { TextArea } = Input;
const { Panel } = Collapse;
const EmailAppraisalContent = (props: any) => {
  const { questionsArray, functionalAttributes, keyPerformance, organisationAttributes, personalAttributes, reviewtype, salaryRevised, incrementEligible, probationExtension, employeeApiKey, employeeReviewId, employeeId, userId } = props;
  const [tempQuestionArrayEmail, setTempQuestionArrayEmail] = useState<any>();
  const [changeEmailCtcModal, setChangeEmailCtcModal] = useState(false);
  const [keyPerformanceErrorsEmail, setKeyPerformanceErrorsEmail] = useState<any>([]);
  const [personalErrorsEmail, setPersonalErrorsEmail] = useState<any>([]);
  const [functionalErrorsEmail, setFunctionalErrorsEmail] = useState<any>([]);
  const [organisationErrorsEmail, setOrganisationErrorsEmail] = useState<any>([]);
  const [significantAchievementsErrorEmail, setSignificantAchievementsErrorEmail] = useState(false);
  const [improvementAreasErrorEmail, setImprovementAreasErrorEmail] = useState(false);
  const [technicalSkillsErrorEmail, setTechnicalSkillsErrorEmail] = useState(false);
  const [softSkillsErrorEmail, setSoftSkillsErrorEmail] = useState(false);
  const [appraiserCommentsErrorEmail, setAppraiserCommentsErrorEmail] = useState(false);
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  const createReviewApi = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const { id }: any = useParams();

  const handelSaveRevise = () => {
    let keyperformanceFlag: any;
    const hasTrueValue: any = [...keyPerformanceErrorsEmail, ...personalErrorsEmail, ...functionalErrorsEmail, ...organisationErrorsEmail,].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArrayEmail.personalattributes.some((item: any) => item.range === null);
    if (tempQuestionArrayEmail.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArrayEmail.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }
    const functionalattributesFlag: any = tempQuestionArrayEmail.functionalattributes.some((item: any) => item.range === null);
    const organisationalattributesFlag: any = tempQuestionArrayEmail.organisationalattributes.some((item: any) => item.range === null);
    const hasEmptyAnswer = Object.values(tempQuestionArrayEmail.appraiserfeedback).some((feedbackItem: any) => feedbackItem.answer === "");
    if (!hasTrueValue && personalattributesFlag === false && keyperformanceFlag === false && functionalattributesFlag === false && organisationalattributesFlag === false) {
      if (!significantAchievementsErrorEmail && !improvementAreasErrorEmail && !technicalSkillsErrorEmail && !softSkillsErrorEmail && !appraiserCommentsErrorEmail && !hasEmptyAnswer) {
        emailHandleAppraisalsApiCall(tempQuestionArrayEmail, incrementEligible, id, createReviewApi, setChangeEmailCtcModal, employeeReviewId, employeeApiKey, employeeId, userId, setLoader, probationExtension);
      } else {
        triggerNotification('warning', '', ErrorMessages.mandatoryValues, 'topRight');
      }

    } else {
      triggerNotification('warning', '', ErrorMessages.mandatoryValues, 'topRight')
    }
  };

  const selectRating = (index: number, val: string, event: any) => {
    if ((val === 'keyperformance' && keyPerformance) || (val === 'personalattributes' && personalAttributes) || (val === 'functionalattributes' && functionalAttributes) || (val === 'organisationalattributes' && organisationAttributes)) {
      const updatedQuestions = { ...tempQuestionArrayEmail };
      const attributeType = val
      const currentAttribute = updatedQuestions[attributeType][index];
      currentAttribute.ansFlag = true;
      currentAttribute.answer = '';
      currentAttribute.commentFlag = event === 1 || event === 2;
      currentAttribute.range = event;
      updatedQuestions[attributeType] = updatedQuestions[attributeType].slice();
      setTempQuestionArrayEmail(updatedQuestions);

      const stateMap = {
        keyperformance: { state: keyPerformanceErrorsEmail, setter: setKeyPerformanceErrorsEmail },
        personalattributes: { state: personalErrorsEmail, setter: setPersonalErrorsEmail },
        functionalattributes: { state: functionalErrorsEmail, setter: setFunctionalErrorsEmail },
        organisationalattributes: { state: organisationErrorsEmail, setter: setOrganisationErrorsEmail },
      };
      const selectedState = stateMap[val];
      if (selectedState) {
        const { state, setter } = selectedState;
        const updatedErrors = [...state];
        updatedErrors[index] = event === 1 || event === 2;
        setter(updatedErrors);
      }
      setTouchedEmail(true);
    }
  };

  const handleTextArea = (event: any, type: string, index: number) => {
    const updatedQuestions = { ...tempQuestionArrayEmail };
    const currentQuestion = updatedQuestions[type][index];

    if (event.target.value !== '') {
      currentQuestion.answer = event.target.value;
      currentQuestion.commentFlag = false;
      if (type === 'keyperformance' && keyPerformanceErrorsEmail[index]) {
        const updatedErrors = [...keyPerformanceErrorsEmail];
        updatedErrors[index] = false;
        setKeyPerformanceErrorsEmail(updatedErrors);
      } else if (type === 'personalattributes' && personalErrorsEmail[index]) {
        const updatedErrors = [...personalErrorsEmail];
        updatedErrors[index] = false;
        setPersonalErrorsEmail(updatedErrors);
      } else if (type === 'functionalattributes' && functionalErrorsEmail[index]) {
        const updatedErrors = [...functionalErrorsEmail];
        updatedErrors[index] = false;
        setFunctionalErrorsEmail(updatedErrors);
      } else if (type === 'organisationalattributes' && organisationErrorsEmail[index]) {
        const updatedErrors = [...organisationErrorsEmail];
        updatedErrors[index] = false;
        setOrganisationErrorsEmail(updatedErrors);
      }
    } else if (currentQuestion.range === 1 || currentQuestion.range === 2) {
      const errorTypes: any = {
        keyperformance: { errors: keyPerformanceErrorsEmail, setter: setKeyPerformanceErrorsEmail },
        personalattributes: { errors: personalErrorsEmail, setter: setPersonalErrorsEmail },
        functionalattributes: { errors: functionalErrorsEmail, setter: setFunctionalErrorsEmail },
        organisationalattributes: { errors: organisationErrorsEmail, setter: setOrganisationErrorsEmail },
      };

      const emailErrorType = errorTypes[type];
      if (emailErrorType) {
        const updatedErrors = [...emailErrorType.errors];
        updatedErrors[index] = true;
        emailErrorType.setter(updatedErrors);
      }
      currentQuestion.commentFlag = true;
    } else {
      currentQuestion.commentFlag = false;
    }

    setTempQuestionArrayEmail(updatedQuestions);
  };
  const handleAppraisals = (event: any, name: string, type: string) => {
    const updatedQuestions = { ...tempQuestionArrayEmail };
    const inputValue = event.target.value;
    if (name === 'appraiserfeedback') {
      updatedQuestions[name][type].answer = inputValue;
    }
    setTempQuestionArrayEmail(updatedQuestions);
    switch (type) {
      case "significantachievements":
        setSignificantAchievementsErrorEmail(inputValue.trim() === "");
        break;
      case "improvementareas":
        setImprovementAreasErrorEmail(inputValue.trim() === "");
        break;
      case "technicalskills":
        setTechnicalSkillsErrorEmail(inputValue.trim() === "");
        break;
      case "softskills":
        setSoftSkillsErrorEmail(inputValue.trim() === "");
        break;
      case "appraisercomments":
        setAppraiserCommentsErrorEmail(inputValue.trim() === "");
        break;
      default:
        break;
    }
    setTouchedEmail(true);
  }
  useEffect(() => {
    if (questionsArray !== undefined)
      setTempQuestionArrayEmail(questionsArray)
  }, []);
  const handelChangeSalary = () => {
    let keyperformanceFlag: any
    const trueValue: any = [...keyPerformanceErrorsEmail, ...personalErrorsEmail, ...functionalErrorsEmail, ...organisationErrorsEmail,].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArrayEmail.personalattributes.some((item: any) => item.range === null);
    if (tempQuestionArrayEmail.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArrayEmail.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }
    const functionalattributesFlag: any = tempQuestionArrayEmail.functionalattributes.some((item: any) => item.range === null);
    const organisationalattributesFlag: any = tempQuestionArrayEmail.organisationalattributes.some((item: any) => item.range === null);
    const feedbacks: any = Object.values(tempQuestionArrayEmail.appraiserfeedback).some((feedbackItem: any) => feedbackItem.answer === "");
    if (!trueValue && personalattributesFlag === false && keyperformanceFlag === false && functionalattributesFlag === false && organisationalattributesFlag === false) {
      if (!significantAchievementsErrorEmail && !improvementAreasErrorEmail && !technicalSkillsErrorEmail && !softSkillsErrorEmail && !appraiserCommentsErrorEmail && !feedbacks) {
        setChangeEmailCtcModal(true)
        setTouchedEmail(false);
      } else {
        triggerNotification('warning', '', ErrorMessages.mandatoryValues, 'topRight');
      }
    } else {
      triggerNotification('warning', '', ErrorMessages.ratingValue, 'topRight')
    }
  }
  return (
    <div className="general-assessment">
      {keyPerformance && keyPerformance.length > 0 && keyPerformance !== undefined && (
        <div>
          <h3 className='general-assessment-header' data-testid='general-assessment-id'>General Assessment</h3>
          <h4 className='general-assessment-subtitle py-4'>Key Performance</h4>
          {keyPerformance.map((item: any, index: number) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"}>
              {keyPerformanceErrorsEmail[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question}</p>
                <div className="form-item">
                  <Select
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "keyperformance", e)}
                  />
                </div>
              </div>} key="1">
                <TextArea rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "keyperformance", index) }} />
              </Panel>
            </Collapse>))}
        </div>
      )}
      {personalAttributes && personalAttributes.length > 0 && questionsArray !== undefined && (
        <div>
          <h3 className='general-assessment-header pt-4'>Competency Assessment</h3>
          <h4 className='general-assessment-subtitle py-4' >Personal Attributes</h4>
          {personalAttributes.map((item: any, index: number) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"}>
              {personalErrorsEmail[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question}</p>
                <div className="form-item">
                  <Select
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "personalattributes", e)}
                  />
                </div>
              </div>} key="2">
                <TextArea rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "personalattributes", index) }} />
              </Panel>
            </Collapse>))}
        </div>
      )}
      {functionalAttributes && functionalAttributes.length > 0 && questionsArray !== undefined && (
        <div>
          <h3 className='general-assessment-subtitle py-4'>Functional Attributes</h3>
          {functionalAttributes.map((item: any, index: any) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"}>
              {functionalErrorsEmail[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question}</p>
                <div className="form-item">
                  <Select
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "functionalattributes", e)}
                  />
                </div>
              </div>} key="3">
                <TextArea rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "functionalattributes", index) }} />
              </Panel>
            </Collapse>))}
        </div>
      )}
      {organisationAttributes && organisationAttributes.length > 0 && questionsArray !== undefined && (
        <div>
          <h4 className='general-assessment-subtitle py-4' >Organisation Attributes</h4>
          {organisationAttributes.map((item: any, index: any) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"}>
              {organisationErrorsEmail[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question}</p>
                <div className="form-item">
                  <Select
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "organisationalattributes", e)}
                  />
                </div>
              </div>} key="4">
                <TextArea rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "organisationalattributes", index) }} />
              </Panel>
            </Collapse>))}
        </div>
      )}
      <EmailTextAreaRendering handleAppraisals={handleAppraisals} significantAchievementsErrorEmail={significantAchievementsErrorEmail} improvementAreasErrorEmail={improvementAreasErrorEmail} technicalSkillsErrorEmail={technicalSkillsErrorEmail} softSkillsErrorEmail={softSkillsErrorEmail} appraiserCommentsErrorEmail={appraiserCommentsErrorEmail} />
      <EmailAppraisalButtonRendering incrementEligibleEmail={incrementEligible} touchedEmail={touchedEmail} handelSaveReviseEmail={handelSaveRevise} handelChangeSalaryEmail={handelChangeSalary} />
      {changeEmailCtcModal && (
        <EmailChangeCtc setChangeEmailCtcModal={setChangeEmailCtcModal} questionObjectEmail={tempQuestionArrayEmail} changeEmailCtcModal={changeEmailCtcModal} reviewTypeEmail={reviewtype !== undefined && reviewtype} salaryRevisedEmail={salaryRevised} probationExtensionEmail={probationExtension} incrementEligibleEmail={incrementEligible} />
      )}
      {loader && <Loader />}
    </div>
  )
}
export default EmailAppraisalContent
