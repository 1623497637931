import React, { useEffect, useState } from 'react';
import { Select, Space, Modal, Tooltip } from 'antd';
import EmployeeDetails from './EmployeeDetails';
import Namecard from '../assessment/chapter/Namecard';
import { useSelector, useDispatch } from 'react-redux';
import { triggerNotification, useNavigate } from '../../../common/CommonExport';
import axios from 'axios';
import { setChapterLoad } from '../../../redux/slices/updateProfileSlice';
import { setCurrentCourseId } from '../../../redux/slices/currentCourseDetailSlics';
import ModalLoader from '../../../components/Loader/ModalLoader';

const AssignCourseModal = (props: any) => {
  const { open, handleCloseModal, editRecord } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [assignEmployeeList, setAssignEmployeeList] = useState<any>([])
  const employeeList = useSelector((state: any) => state?.employeeListArray?.employeeList);
  const [employeeIds, setEmployeeIds] = useState<any>([]);
  const [loader, setLoader] = useState(false)

  const handelClose = () => {
    setIsOpenModal(false);
    handleCloseModal();
  }

  const filterOption = (input: any, option: any) => {
    const labelMatches = option?.label.toLowerCase().includes(input.toLowerCase());
    return labelMatches;
  };
  useEffect(() => {
    setIsOpenModal(open)
    const reformedEmployeeList = employeeList.map((item: any) => ({
      value: item?.key,
      label: item?.employeename + '-' + item?.designation,
      designation: item?.designation,
      department: item?.dept,
      emoji: <Namecard record={item} />,
      desc: <EmployeeDetails record={item} />,
    }))
    setAssignEmployeeList(reformedEmployeeList)
  }, [open]);

  const handleChange = (value: string[]) => {
    const employeeIds: number[] = value.map((employeeId) => parseInt(employeeId, 10));
    setEmployeeIds(employeeIds)
  };

  const handleSubmit = () => {
    if (employeeIds.length > 0) {
      setLoader(true)
      const apiurl = `/employee/${props?.courseFlow ? 'course' : 'learning-path'}/assign`;
      const baseURL = '/elms-app-api/v1';
      const queryString = `${props?.courseFlow ? 'courseId' : 'learningPathId'}=${editRecord?.key}&${employeeIds.map((id: number) => `employeeIds=${id}`).join('&')}`;
      const url: any = process.env.REACT_APP_ELMS_BASEURL + baseURL + apiurl + `?${queryString}`;
      axios.post(url)
        .then((res: any) => {
          if (res?.data?.data !== null) {
            dispatch(setChapterLoad())
            if (res?.data?.meta?.CourseUnAssignedEmployees?.length > 0) {
              let resultString = "";
              if (res?.data?.meta?.CourseUnAssignedEmployees?.length === 1) {
                resultString = `Employee ${res?.data?.meta?.CourseUnAssignedEmployees[0]} is already assigned or may be facing some other issues. Please contact support Team.`;
              } else {
                const lastEmployee = res?.data?.meta?.CourseUnAssignedEmployees?.pop();
                resultString = `Employee ${res?.data?.meta?.CourseUnAssignedEmployees?.join(", ")} and ${lastEmployee} are already assigned or may be facing some other issues. Please contact support Team.`;
              }
              triggerNotification('warning', '', resultString, 'topRight');
            } else {
              triggerNotification('success', '', res?.data?.message, 'topRight');
            }
            setLoader(false);
            handelClose()
          }
        })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.message, 'topRight');
        })
    } else {
      triggerNotification('warning', '', 'Please select an employee', 'topRight');
    }
  }
  const handleAssignedEmployee = () => {
    dispatch(setCurrentCourseId(editRecord?.key))
    navigate(`/home/dashboard/learning-management/assignedemployee/${editRecord?.key}`);
  }
  return (
    <div>
      <Modal title='Assign Course' className='employee-addcourse w-full relative' centered open={isOpenModal} footer={null} onCancel={handelClose} maskClosable={false} >
        <div className="px-4">
          <div className=' w-full flex h-24 pt-8 '>
            <div className='w-9/12'>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select by Role and Employee Name"
                onChange={handleChange}
                optionLabelProp="label"
                showSearch
                allowClear
                className='h-10 employee-select'
                optionFilterProp='children'
                filterOption={filterOption}
              >
                {assignEmployeeList.map((option: any) => (
                  <Select.Option key={option.value} value={option.value} label={option.label}>
                    <Space>
                      {option.emoji}
                      {option.desc}
                    </Space>
                  </Select.Option>
                ))}
              </Select>

            </div>
            <div className='w-1/4 flex justify-center '  >
              <button className='h-12 addButton  primary px-5 confirmElms' type="submit" onClick={() => handleSubmit()}>Assign</button>

            </div>
            <div>

              {props?.courseFlow &&

                <Tooltip placement="bottom" title="Assigned Employees">
                  <button className="material-symbols-outlined bg-primary text-white cursor-pointer p-2 rounded-full" onClick={() => handleAssignedEmployee()}>
                    groups
                  </button>
                </Tooltip>
              }


            </div>

          </div>
          <div className="employee-recent">
            {/* <p className='employee-text' >recently adeed</p> */}
            <div className="flex py-2">
              {/* <Namecard />
              <EmployeeDetails /> */}
            </div>
            <div className="flex py-2">
              {/* <Namecard />
              <EmployeeDetails /> */}
            </div>

          </div>
        </div>
        {loader && <ModalLoader />}
      </Modal>
    </div>
  )
}

export default AssignCourseModal
