import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import PassCriteria from './PassCriteria';
import AssignAssessment from './AssessmentAssign';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const QuestionAllocation = ({ handle, handleBackParent }: any) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const assessmentChapter = useSelector((state: any) => state?.currentCourse?.assessmentAssignChapterID);
  const assessmentTabChangeSet = useSelector((state: any) => state?.currentCourse?.assessmentTabToStart);

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleTabChangeInSide = (key: string) => {
    if (assessmentChapter && assessmentChapter?.quiz !== null)
      handleTabChange(key)
  }

  useEffect(() => {
    setActiveTabKey('1')
  }, [assessmentTabChangeSet])

  useEffect(() => {
    setActiveTabKey('1')
  }, [reduxToken]);
  const items = [
    {
      key: '1',
      label: 'List of Questions Allocation',
      children: <AssignAssessment handleTabChange={handleTabChange} handleBackParent={handleBackParent} />,
    },
    {
      key: '2',
      label: 'Pass Criteria',
      children: <PassCriteria handleTabChange={handleTabChange} handle={handle} />,
    },
  ]

  return (
    <div className='course-tab-containers questions min-h[470px]'>
      <Tabs activeKey={activeTabKey}
      // onChange={handleTabChangeInSide}
      >
        {items.map(item => (
          <TabPane key={item.key} tab={item.label}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default QuestionAllocation
