import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { retrieveData } from '../../../storage/Storage';
import { useGetApiRequests } from '../../../common/CommonExport';
import { DataType } from './TableHelper';
import columnData from './TableColums';

const ProjectBoardDetails = () => {
    const projectsList = useGetApiRequests('projectsList', 'GET');
    const initialData: DataType[] = [];
    const [tableData, setTableData] = useState(initialData);
    const [sprintDay, setSprintDay] = useState<any>('')
    const [loader, setLoader] = useState<any>(false)
    const [sprintName, setSprintName] = useState<any>('');
    const projectName = retrieveData('projectName', false);

    useEffect(() => {
        getBoardData()
    }, [])

    const getBoardData = () => {
        const jiraProjectId = retrieveData('jiraProjectId', false);
        let board = retrieveData('boardId', false)
        console.log(board, "board");
        setSprintName(board?.name ? board?.name : board?.boardName)
        const boardType = retrieveData("boardType", false)
        let pathParams: any = {}
        if (boardType === "kanban") {
            pathParams = {
                "instance-information-id": 1,
            };
        }
        else {
            pathParams = {
                "sprint-id": board?.id,
                "instance-information-id": 1,
            };
        }
        const queryParams = {
            "jiraProjectId": jiraProjectId,
            "board": "board",
            "boardId": board?.boardId,
            "kpi-kpr": "kpi-kpr"
        };
        projectsList({}, pathParams, queryParams)
            .then((response: any) => {
                if (response?.data?.data?.employeeKpiKpr) {
                    const employeeKpiKpr = response?.data?.data?.employeeKpiKpr;
                    const totalEmployeeKpiKpr = response?.data?.data?.employeeTotalKpiKpr;
                    setSprintDay(response?.data?.data?.employeeKpiKpr[0]?.totalSprintDay)
                    employeeKpiKpr.forEach((sprint: any, index: any) => {
                        initialData.push({
                            key: index,
                            assignee: String(sprint.employeeName),
                            totaltask: Number(sprint.totalTasks),
                            estHrs: Number(sprint.estimated),
                            consumedHrs: Number(sprint.consumed),
                            devOpen: Number(sprint.devOpen),
                            devInprogress: Number(sprint.devInProgress),
                            devDone: Number(sprint.devDone),
                            qaReady: Number(sprint.qaReady),
                            qaInprogress: Number(sprint.qaInProgress),
                            qaCompleted: Number(sprint.qaCompleted),
                            closedTask: Number(sprint.deployed),
                            totalBugs: Number(sprint.totalBugs),
                            openBugs: Number(sprint.openBugs),
                            deviationPercentage: Number(sprint.deviationPercentage),
                            bugsPercentage: Number(sprint.bugsPercentage),
                            openTaskPercentage: Number(sprint.openTaskPercentage),
                            timeScore: Number(sprint.timeScore),
                            bugsScore: Number(sprint.bugsScore),
                            openTaskScore: Number(sprint.openTaskScore),
                            employeeScore: Number(sprint.employeeScore)
                        });
                    });
                    initialData.push({
                        key: employeeKpiKpr.length,
                        assignee: "Total ",
                        totaltask: Number(totalEmployeeKpiKpr.totalTasks),
                        estHrs: Number(totalEmployeeKpiKpr.estimated),
                        consumedHrs: Number(totalEmployeeKpiKpr.consumed),
                        devOpen: Number(totalEmployeeKpiKpr.devOpen),
                        devInprogress: Number(totalEmployeeKpiKpr.devInProgress),
                        devDone: Number(totalEmployeeKpiKpr.devDone),
                        qaReady: Number(totalEmployeeKpiKpr.qaReady),
                        qaInprogress: Number(totalEmployeeKpiKpr.qaInProgress),
                        qaCompleted: Number(totalEmployeeKpiKpr.qaCompleted),
                        closedTask: Number(totalEmployeeKpiKpr.deployed),
                        totalBugs: Number(totalEmployeeKpiKpr.totalBugs),
                        openBugs: Number(totalEmployeeKpiKpr.openBugs),
                        deviationPercentage: Number(totalEmployeeKpiKpr.deviationPercentage),
                        bugsPercentage: Number(totalEmployeeKpiKpr.bugsPercentage),
                        openTaskPercentage: Number(totalEmployeeKpiKpr.openTaskPercentage),
                        timeScore: Number(totalEmployeeKpiKpr.timeScore),
                        bugsScore: Number(totalEmployeeKpiKpr.bugsScore),
                        openTaskScore: Number(totalEmployeeKpiKpr.openTaskScore),
                        employeeScore: Number(totalEmployeeKpiKpr.employeeScore)
                    });
                }
                setTableData(initialData)
                setLoader(true)
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getColorClass = (value: any) => {
        if (value >= 1 && value <= 4) {
            return 'color-green';
        } else if (value >= 4 && value <= 8) {
            return 'color-yellow';
        }
        else if (value >= 9 && value <= 12) {
            return 'color-orange';
        }
        else if (value >= 13 && value <= 15) {
            return 'color-red';
        }
        return '';
    };
    const columns: any = [
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            key: 'assignee',
        },
        {
            title: 'Time Deviation %',
            dataIndex: 'deviationPercentage',
            key: 'deviationPercentage',
        },
        {
            title: 'Code Quality',
            dataIndex: 'bugsPercentage',
            key: 'bugsPercentage',
        },
        {
            title: 'Open Task %',
            dataIndex: 'openTaskPercentage',
            key: 'openTaskPercentage',
        },
        {
            title: 'Deviation Score',
            children: [
                {
                    title: 'Time',
                    dataIndex: 'timeScore',
                    key: 'timeScore',
                },
                {
                    title: 'Bugs',
                    dataIndex: 'bugsScore',
                    key: 'bugsScore',
                },
                {
                    title: 'Open Task',
                    dataIndex: 'openTaskScore',
                    key: 'openTaskScore',
                }
            ]
        },
        {
            title: 'Employee Score',
            dataIndex: 'employeeScore',
            key: 'employeeScore',
            render: (text: any) => <span className={getColorClass(text)}>{text}</span>
        },
    ]
    return (
        <div className='page-container bg-customBg'>
            <div className="full-width-container pt-5">
                <div className='bg-white tab-container project-page '>
                    <section className='projects-container project-board-details'>
                        <div className='card_'>
                            <div className='employeeScore'>
                                <div className='scoreDetail'><p>Employee Score</p></div>
                                <div className='exceptionalScore'><p>0-4 <br /> Exceptional </p></div>
                                <div className='goodScore'><p>5-8 <br /> Good</p> </div>
                                <div className='exceptionalAvg'>9-12 <br /> Average</div>
                                <div className='belowAvg'>13-15 <br /> Below Average </div>
                            </div>
                            <div className='details-card-main'>
                                <div className='project-sprint'>
                                    <div className='sprintName'>
                                        <span>{projectName}</span> -  <span>{sprintName}</span>
                                    </div>
                                    <div className='totslSprintDays'>
                                        <span>Total Sprint Hours </span><span>-</span><span> {sprintDay} </span>
                                    </div>
                                </div>
                                {initialData && loader &&
                                    <Table dataSource={tableData} columns={columnData} />
                                }
                                <div className='project-sprint performance-res'>Key Performance Result</div>
                                {initialData && loader &&
                                    <Table dataSource={tableData} columns={columns} />
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};
export default ProjectBoardDetails;
