import React from 'react'

const Banner = ({ scrollToContactForm }: any) => {
  return (
    <div className='flex justify-center items-center flex-col client_banner'>
      <h1 className='text-[68px] text-center text-[#ffffff] leading-tight pb-5'>Reimagine your HR Operations <br /> from Onboarding to Exit Management</h1>
      <p className='text-[#F9F9F9] text-center text-[24px]  pb-12'>All Aboard is a comprehensive solution that automates and centralizes all employee management <br /> functions, including employee data management, learning management, benefits administration, <br /> and performance tracking.</p>
      <button className='addButton register w-[235px] font-medium text-[20px]' onClick={scrollToContactForm}>
        Request Demo
      </button>
    </div>
  )
}

export default Banner
