import React from 'react'
import CourseList from './UnassignedCourse'
import { clearCurrentCourseId } from '../../../redux/slices/currentCourseDetailSlics'
import { useDispatch } from 'react-redux'
import { setCurrentTab } from '../../../redux/slices/courseSlice'

export const CourseTab = ({ chapterSearch }: any) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(clearCurrentCourseId())
    dispatch(setCurrentTab(1))
  }, [chapterSearch])
  return (
    <div className='lm-course m-3 ml-0 p-1 bg-[#F5F5F5]'>
      <div className=" py-4">
        <CourseList courseSearch={chapterSearch} title='Unassigned Course' courseType='unassigned' keyValue='unassignedCourses' countTotal='totalUnassignedCourses' />
        <CourseList courseSearch={chapterSearch} title='Recently Added Course' courseType='recentlyAdded' keyValue='recentlyAddedCourses' countTotal='totalRecentlyAddedCourses' />
        <CourseList courseSearch={chapterSearch} title='Frequently Assigned Course' courseType='frequentlyAssigned' keyValue='frequentlyAssignedCourses' countTotal='totalFrequentlyAssignedCourses' />
      </div>
    </div>
  )
}
