import React, { useEffect, useState } from 'react';
import { Modal, Steps } from 'antd'
import AddChapter from './chapter/AddChapter';
import CourseTab from './chapter/CourseTab';
import UploadCertificate from './chapter/UploadCertificate';
import AddChapterHome from './chapter/AddChapterHome';
import AssignChapter from './chapter/AssignChapter';
import QuestionAllocation from './chapter/QuestionAllocation';
import UploadBulkQuestions from './chapter/UploadBulkQuestions';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTab } from '../../../redux/slices/courseSlice';
import { clearAssessmentAssignChapterID, clearChapterInEdit, clearCurrentAssessmentTab, clearCurrentCourseId, clearQuizChapterMapPayload, setCourseDetails, setFlowDirection } from '../../../redux/slices/currentCourseDetailSlics';
import MultiChoiceQuestions from './chapter/multichoice-questions/MultiChoiceQuestions';
import { questionCurrentTab } from '../../../redux/slices/multipleQuestions';
import EnableCourseCompletionCertificate from './chapter/EnableCourseCompletionCertificate';
import AddNewChapterInCourseSide from './chapter/AddNewChapterInCourseSide';
import { setChapterLoad } from '../../../redux/slices/updateProfileSlice';

const AddAssessmentModal = (props: any) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const [showComponent, setShowComponent] = useState(1);
  const [courseName, setCourseName] = useState('')

  useEffect(() => {
    dispatch(setFlowDirection(false))
    const empDetails = { courseStatus: 'draft', employeeCount: 0 }
    dispatch(setCourseDetails(empDetails))
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setShowComponent(reduxToken)
  }, [reduxToken]);

  const handel = () => {
    if (courseName === '') {
      setIsOpen(false);
      handleClose();
    } else {
      dispatch(setCurrentTab(1))
      dispatch(clearCurrentCourseId())
      setIsOpen(false);
      handleClose();
      dispatch(clearCurrentAssessmentTab())
      dispatch(questionCurrentTab(1))
      dispatch(clearChapterInEdit())
      dispatch(clearQuizChapterMapPayload())
      dispatch(clearAssessmentAssignChapterID())
      dispatch(setChapterLoad())
    }
  }

  const handleStepperComp = () => {
    if (showComponent === 1)
      return 0
    else if (showComponent === 2 || showComponent === 3 || showComponent === 6)
      return 1
    else if (showComponent === 4 || showComponent === 8 || showComponent === 9 || showComponent === 10)
      return 2
    else if (showComponent === 11)
      return 3
  }

  const completedList = [{ title: 'Course' }, { title: 'Chapter' }, { title: 'Assessment' }, { title: 'Certificate' }]

  return (
    <div>
      <Modal className='addcourse w-full' centered open={isOpen} footer={null}
        onCancel={handel} maskClosable={false}
      >
        <div className="main w-full">
          <div className="addcourse-container w-4/5 justify-center flex flex-col ">
            <div className="addcourse-stepper w-4/5 flex">
              <Steps
                current={handleStepperComp()}
                items={completedList}
              />
            </div>
          </div>
          <div className={`main-container  w-full ${(showComponent === 1 || showComponent === 10) ? 'bg-white ' : 'grey'} ${(showComponent === 0) ? ' bulk-question-container bg-white ' : ''} `}>
            {showComponent === 1 && <AddChapterHome setCourseName={setCourseName} />}
            {showComponent === 2 && <AddChapter />}
            {showComponent === 3 && <AssignChapter />}
            {showComponent === 4 && <CourseTab />}
            {showComponent === 5 && <UploadCertificate />}
            {showComponent === 6 && <AddNewChapterInCourseSide />}
            {showComponent === 8 && <QuestionAllocation />}
            {showComponent === 9 && <UploadBulkQuestions />}
            {showComponent === 10 && <MultiChoiceQuestions />}
            {showComponent === 11 && <EnableCourseCompletionCertificate handel={handel} />}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddAssessmentModal
