import { Radio } from 'antd'
import React, { useState } from 'react'
import "../signup/Signup.scss"
import PaymentForm from './PaymentForm'



const Payments = () => {

  return (
    <div className='w-full flex payment p-11 bg-[#F2F2F2] h-[100vh]'>
      <div className="w-1/2">
        <div className="payments-navigate">
          <p className='font-rubik text-[27px] font-bold text-[#0C0C0C] flex items-center pb-2'><span className="material-symbols-outlined text-[27px]  text-[#585858] mr-2">
            keyboard_backspace
          </span > Payment Option</p>
          <div className="payment-summary w-[90%] p-[30px]">
            <p className='text-[25px] text-[#585858] font-roboto font-medium' >Order Summary</p>
            <div className="payment-description flex justify-between pr-[20px] pr-[35px] bg-[#fcfcfc] p-[10px] rounded-[5px]">
              <Radio.Group className='pl-4 ' >
                <Radio >
                  <span className='font-rubik text-[27px] font-bold text-[#0C0C0C] '>Business</span>
                </Radio >
              </Radio.Group >
              <span className='font-roboto business'> $14.99</span>
            </div>
            <div className="payment-description ">
              <div className="amount">
                <p className='font-roboto '>Subtotal</p>
                <span className='font-roboto '> $14.99</span>
              </div>
            </div>
            <div className="payment-description">
              <div className="amount">
                <p className='font-roboto '>GST  (6.5%)</p>
                <span className='font-roboto '> $1</span>
              </div>
            </div>
            <div className="payment-description">
              <div className="amount">
                <p className='font-roboto font'>Total due</p>
                <span className='font-roboto text  '> $15.99</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentForm />
    </div>
  )
}

export default Payments
