import React from 'react';

interface ErrorMessageProps {
    message: string | undefined;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
    return (
        <p className='text-xs text-red-500 pt-1 appraisal-form'>{message}</p>
    );
};

export default ErrorMessage;
