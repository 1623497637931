import React, { useEffect } from 'react'

const DownloadIcon = (props: any) => {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;

  const { selectedDownloadDocument } = props;
  useEffect(() => {

  }, [props])
  const downloadDocument = () => {
    if (selectedDownloadDocument.signed !== null) {
      let url: any = imageBaseUrl + selectedDownloadDocument.signed?.documentPath;
      window.location.href = url;
    }
    else {
      let url: any = imageBaseUrl + selectedDownloadDocument.unsigned.documentPath;
      window.location.href = url;
    }
  }
  const handelClose = () => {
    window.close();
  }
  return (
    <>
      <div className='absolute right-5'>
        <p className='flex items-center justify-center download-icon'>
          <span className="material-symbols-outlined cursor-pointer" onClick={() => { handelClose() }} >
            close
          </span></p>
        <p className='flex items-center justify-center download-icon' onClick={() => { downloadDocument() }} >
          <span className="material-symbols-outlined cursor-pointer" >
            file_download
          </span></p>
      </div>

    </>

  )
}

export default DownloadIcon
