import React from 'react'
import Logo from "../../../assets/images/logo.png"
import Location from "../../../assets/images/client-onboard/location_on.svg"
import email from "../../../assets/images/client-onboard/email.svg"
import Phone from "../../../assets/images/client-onboard/phone.svg"



const Footer = () => {

  const employeManage = ["Employee Management", "Correspondence & Document Management", "24x7 Support", "Email Support", "Employee KPI Management"]
  const leaveManage = ["Leave Management", "Learning Management", "MFA & SSO", "Mobile Application", "Company Asset Management"]
  const projectManage = ["Custom Learning Paths", "Project Management", "Geofencing", "Employee KPI Management"]

  return (
    <div className='footer-container pb-10'>
      <div className=" flex  py-12">
        <img src={Logo} alt="logo" />
        <p className='flex items-center text-[#4857B0] text-[30px]'>All Aboard</p>
      </div>
      <div className="grid grid-cols-4 gap-14  pb-10 footer-container-list">
        <div>
          <ul>
            {employeManage && employeManage.map((feature: any, index: number) => (
              <li key={index} className='text-[#464646] pb-5  font-rubik text-[18px] font-normal'>{feature}</li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            {leaveManage && leaveManage.map((feature: any, index: number) => (
              <li key={index} className='text-[#464646] pb-5  font-rubik text-[18px] font-normal'>{feature}</li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            {projectManage && projectManage.map((feature: any, index: number) => (
              <li key={index} className='text-[#464646] pb-5  font-rubik text-[18px] font-normal'>{feature}</li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            <li className='flex items-center text-[#464646] pb-5  font-rubik text-[18px] font-normal'><img src={Location} alt="Location" className='mr-2' /> <span>USA : </span> +1 803 746 7178</li>
            <li className='flex items-center text-[#464646] pb-5  font-rubik text-[18px] font-normal'><img src={Phone} alt="Location" className='mx-1 pr-2' /> <span>IND : </span>044-24619130</li>
            <li className='flex items-center text-[#464646] pb-5  font-rubik text-[18px] font-normal'><img src={email} alt="Location" className='mr-2' />  info@cloudnowtech.com</li>

          </ul>
        </div>

      </div>
    </div>

  )
}

export default Footer
