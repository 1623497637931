// CustomFilterDropdown.js

import React, { useEffect } from 'react';
import { Radio } from 'antd';
import { employeeFilterApiCall } from '../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import { setTableFilter } from '../../redux/slices/tableFilterSlice';

export const handleRadioChange = (value: any, {
  setSelectedKeys,
  setLastFilter,
  EmployeeFilter,
  setAllFilterValues,
  setEmployeeData,
  setEmployeeDataSearch,
  confirm,
  setLoader
}: any) => {
  setSelectedKeys([value]);
  setLastFilter(value);
  employeeFilterApiCall(value, EmployeeFilter, setAllFilterValues, setEmployeeData, setEmployeeDataSearch, setLoader);
  confirm();
};

const CustomFilterDropdown = ({
  filters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  setLastFilter,
  EmployeeFilter,
  setAllFilterValues,
  setEmployeeData,
  setEmployeeDataSearch,
  reLoadPage,
  setLoader
}: any) => {
  const dispatch = useDispatch();
  const tableReload = useSelector((state: any) => state?.tableFilter?.filterValue);

  useEffect(() => {
    setSelectedKeys([undefined]);
    setLastFilter(undefined);
  }, [reLoadPage, tableReload]);

  return (
    <div style={{ padding: 8 }}>
      <Radio.Group
        onChange={e => {
          handleRadioChange(e.target.value, {
            setSelectedKeys,
            setLastFilter,
            EmployeeFilter,
            setAllFilterValues,
            setEmployeeData,
            setEmployeeDataSearch,
            confirm,
            setLoader
          })
          dispatch(setTableFilter(e.target.value))

        }
        }
        value={tableReload}
      >
        {filters.map((filter: any) => (
          <div key={filter.value}>
            <Radio value={filter.value}>{filter.text}</Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );
};

export default CustomFilterDropdown;
