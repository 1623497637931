import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
const { Meta } = Card;

const EmployeeLearningPath = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const empTypeId: any = new URLSearchParams(search).get("employeeId");
  const getEmployeeProgressLearningApi = useGetApiRequests('getEmployeeProgressLearningPath', 'GET');
  const idOfTheCourse: any = new URLSearchParams(search).get("courseId")
  const [loader, setLoader] = useState(false);
  const [learningProgressList, setLearningProgressList] = useState<any>([])
  const handelNavigate = (item: any) => {
    navigate(`/home/dashboard/learning-management/course-status?employeeId=${empTypeId}&learningPathName=${item?.learningPath?.pathName}&learningPathId=${item?.learningPath?.learningPathId}&learningPath=true`)
  }
  const getLearningProgressOfEmployee = async () => {
    const pathParams: any = {
      id: empTypeId
    }
    const response: any = await getEmployeeProgressLearningApi('', '', pathParams)
    try {
      const responses: any = response?.data?.data;
      responses.sort((a: any, b: any) => {
        if (a.courseId === parseInt(idOfTheCourse)) {
          return -1;
        } else if (b.courseId === parseInt(idOfTheCourse)) {
          return 1;
        } else {
          return 0;
        }
      });

      setLearningProgressList(responses)
      setLoader(false)
    }
    catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    };
  }
  useEffect(() => {
    getLearningProgressOfEmployee()
  }, [])
  return (
    <div className=" bg-[#F5F5F5] p-8 pt-4 rounded-md employee-progress-card ">
      <p className='text-[#696969] text-[18px] font-semibold '>Recently Created Learning path</p>
      <div className="flex  pt-0 pb-7 flex-wrap w-full employee-progress-card-container">
        {learningProgressList && learningProgressList.map((item: any) => (
          <Card key={item?.key} className='employee-progress-card-list cursor-pointer' onClick={() => handelNavigate(item)}
            cover={<img alt="example" src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png" />}>
            <Meta title={item?.learningPath?.pathName} />
          </Card>
        ))}
      </div>
      {loader && <Loader />}
    </div>
  )
}

export default EmployeeLearningPath
