import React, { useEffect, useState } from 'react'
import { Input, Radio } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';
import MultipleQuestions from './MultipleQuestions';
import TrueOrFalseQuestions from './TrueOrFalseQuestions';
import Fillups from './Fillups';
import ArrangeInOrder from './ArrangeInOrder';
import MatchTheFollowing from './MatchTheFollowing';
import { questionCurrentTab } from '../../../../../redux/slices/multipleQuestions';
import { useDispatch, useSelector } from 'react-redux';
import BriefQuestions from './BriefQuestions';
import { setCurrentTab } from '../../../../../redux/slices/courseSlice';
import { questionTypes } from '../../../../Organization/TableColumn';
import { useGetApiRequests } from '../../../../../common/CommonExport';
import Loader from '../../../../../components/Loader/Loader';
import { handleInitialStateValues, onSubmit } from './QuizCreateHelper';
import { clearCurrentQuestion } from '../../../../../redux/slices/currentCourseDetailSlics';
import { handleGetQuestionById } from './QuizHelperEdit';
import ModalLoader from '../../../../../components/Loader/ModalLoader';

const MultiChoiceQuestions = () => {
  const questionType = questionTypes
  const dispatch = useDispatch()
  const quizUpdate = useGetApiRequests('questionById', 'PUT');
  const quizCreate = useGetApiRequests('singleQuizCreate', 'POST');
  const questionById = useGetApiRequests('questionById', 'GET');
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const currentQuestionTab = useSelector((state: any) => state?.multipleQuestions?.questionsTab);
  const finalAssessmentInCourse = useSelector((state: any) => state?.currentCourse?.finalAssessmentInCourse);
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const currentQuestion = useSelector((state: any) => state?.currentCourse?.currentQuestion);
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const [previewImage, setPreviewImage] = useState<any>('');
  const [initialValue, setInitialValue] = useState<any>({});
  const [loader, setLoader] = useState<any>(false)
  const [fieldSchema, setFieldSchema] = useState<any>({});
  const [arrangeInOrderState, setArrangeInOrderState] = useState([
    { imageUrl: '', selectedFileName: '', file: null, check: false },
    { imageUrl: '', selectedFileName: '', file: null, check: false },
    { imageUrl: '', selectedFileName: '', file: null, check: false },
    { imageUrl: '', selectedFileName: '', file: null, check: false },
  ]);
  const [showError, setShowError] = useState<boolean>(false)

  const arrangeImageObject = {
    arrangeInOrderState, setArrangeInOrderState, showError, setMediaData, mediaData
  }

  useEffect(() => {
    if (Object.keys(currentQuestion)?.length === 0)
      handleInitialStateValues(currentQuestionTab, setFieldSchema, setInitialValue, setMediaData, {})
    dispatch(questionCurrentTab(currentQuestionTab))
  }, [currentQuestionTab, currentQuestion])

  useEffect(() => {
    if (Object.keys(currentQuestion)?.length > 0)
      handleGetQuestionById({ setLoader, questionById, currentQuestion, setPreviewImage, currentQuestionTab, setArrangeInOrderState, handleInitialStateValues, setFieldSchema, setInitialValue, setMediaData })
  }, [currentQuestion])

  const handleChange = (tab: any) => {
    dispatch(questionCurrentTab(tab))
  }
  const handleBack = () => {
    dispatch(clearCurrentQuestion())
    dispatch(questionCurrentTab(1))
    if (!flowDirection)
      dispatch(setCurrentTab(4))
    else if (flowDirection) {
      if (!finalAssessmentInCourse)
        dispatch(setCurrentTab(6))
      else if (finalAssessmentInCourse)
        dispatch(setCurrentTab(8))
    }
  }

  return (
    <>
      {reduxToken === 11 &&
        <p className=' text-[#737373] text-[18px]'>Chapter Name : <span className='font-medium text-[#434343] text-[18px]'>Sales and operations</span></p>
      }
      {loader && <ModalLoader />}
      {Object.keys(currentQuestion)?.length === 0 && (
        <div className='flex w-full pb-2 pt-5'>
          <div className="radio-container w-full">
            <Radio.Group name="radiogroup" defaultValue={1} >
              {questionType.map((label, index) => (
                <Radio key={label.id} value={index + 1} onChange={() => handleChange(label.id)}>
                  {label.name}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      )}
      <div className="w-full  bulk-question">
        <>
          <Formik
            initialValues={initialValue}
            validationSchema={fieldSchema}
            enableReinitialize={true}
            onSubmit={onSubmit({ mediaData, arrangeInOrderState, setShowError, setLoader, quizCreate, loggedInUser, handleBack, currentQuestion, quizUpdate })}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>

                  {currentQuestionTab === 1 && <MultipleQuestions setMediaData={setMediaData} mediaData={mediaData} values={values} setFieldValue={setFieldValue} previewImage={previewImage} />}
                  {currentQuestionTab === 2 && <TrueOrFalseQuestions setMediaData={setMediaData} mediaData={mediaData} values={values} setFieldValue={setFieldValue} previewImage={previewImage} />}
                  {currentQuestionTab === 3 && <Fillups setMediaData={setMediaData} mediaData={mediaData} values={values} setFieldValue={setFieldValue} previewImage={previewImage} />}
                  {currentQuestionTab === 4 && <BriefQuestions setMediaData={setMediaData} mediaData={mediaData} values={values} setFieldValue={setFieldValue} previewImage={previewImage} />}
                  {currentQuestionTab === 5 && <ArrangeInOrder {...arrangeImageObject} values={values} setFieldValue={setFieldValue} />}
                  {currentQuestionTab === 6 && <MatchTheFollowing setMediaData={setMediaData} mediaData={mediaData} values={values} setFieldValue={setFieldValue} previewImage={previewImage} />}

                  <div className={Object.keys(currentQuestion)?.length === 0 ? "footer pb-2 _multiple" : "footer -edit pb-2 _multiple"} >
                    <div className='mb-3 flex justify-between '>
                      <div className="btnGroup flex">
                      </div>
                      <div className="btnGroup flex justify-start ">
                        <button type='button' className='addButton mr-4  px-5 cancelElms'
                          onClick={() => handleBack()}>Back</button>
                        <button type="submit" className='addButton mr-6 primary px-5 confirmElms' >Next</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      </div >
    </>
  )
}

export default MultiChoiceQuestions
