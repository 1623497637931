import React from 'react'
import { Input, Radio, Checkbox, Select } from 'antd';
import { ErrorMessage } from 'formik';
import { accuralType, effectiveFromList, employeeType, unitList, yearsAndMonths } from './LeavePolicyConstants';

const LeavePolicyForm = (props: any) => {
  const { values, setFieldValue } = props;

  const handleUnit = (e: any) => {
    setFieldValue('unit', e.target.value)
    if (e.target.value === 'hours') {
      setFieldValue('restriction', true)
    } else {
      setFieldValue('restriction', false)
    }
  }
  const handleInputField = (value: any, type: any, setFieldValue: any) => {
    const inputValue = value.trim();
    if (/^\d*$/.test(inputValue) || inputValue === '') {
      setFieldValue(type, inputValue)
    }
  }
  return (
    <>
      <div className='flex w-full'>
        <div className="leave-name flex w-full">
          <div className=' w-1/2 '>
            <div className='addpolicyModalForm flex items-center'>
              <label className='mr-3'>Leave Name*</label>
              <Input name='policyName' value={values?.policyName} className=' w-3/5' placeholder="Enter a Name" onChange={(e) => setFieldValue('policyName', e.target.value)} />
            </div>
            <div>
              <ErrorMessage name="policyName" component="div" className="error-message" />
            </div>
          </div>
          <div className=' w-1/2'>
            <div className='addpolicyModalForm flex items-center'>
              <label className='mr-3'>Employment Type*</label>
              <Select options={employeeType} value={employeeType.filter((item: any) => item.value === values?.employmentTypeId)} className='w-3/5' placeholder={'Employment Type'} onChange={(value) => setFieldValue('employmentTypeId', value)}></Select>
            </div>
            <div>
              <ErrorMessage name="employmentTypeId" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
      <div className=' flex  pt-8 flex-col justify-start items-start'>
        <div>
          <label className='mr-3'>Units*</label>
          <Radio.Group name="unit" options={unitList} value={values?.unit} onChange={(e: any) => handleUnit(e)}></Radio.Group>
        </div>
        <div>
          <ErrorMessage name="unit" component="div" className="error-message" />
        </div>
      </div>
      <div className='flex  pt-8'>
        <div className=' mr-4'>
          <div className='addpolicyModalForm  flex items-center'>
            <label className=' leave-custom-select w-full'>Effective After*</label>
            <Input className=' leave-custom' placeholder="0" name='effectiveAfterValue' value={values?.effectiveAfterValue} onChange={(e: any) => handleInputField(e.target.value, 'effectiveAfterValue', setFieldValue)} />
            <Select className=' leave-custom-label w-3/5' options={yearsAndMonths} value={yearsAndMonths.filter((item: any) => item.value === values?.effectiveAfterUnit)} placeholder="Months" onChange={(value) => setFieldValue('effectiveAfterUnit', value)}></Select>
          </div>
          <div>
            <ErrorMessage name="effectiveAfterValue" component="div" className="error-message" />
          </div>
          <div>
            <ErrorMessage name="effectiveAfterUnit" component="div" className="error-message" />
          </div>
        </div>
        <div className=''>
          <div className='addpolicyModalForm leave-after  flex items-center'>
            <label className='mr-3 '>from*</label>
            <Select options={effectiveFromList} placeholder="Months" value={effectiveFromList.filter((item: any) => item.value === values?.effectiveFrom)} onChange={(value) => setFieldValue('effectiveFrom', value)}></Select>
          </div>
          <ErrorMessage name="effectiveFrom" component="div" className="error-message" />
        </div>
      </div>
      <div className='flex pt-8'>
        <div className='  mr-4'>
          <div className='addpolicyModalForm leave-accrual w-full  flex items-center'>
            <label className='mr-4 w-36'>Accrual Type*</label>
            <Select options={accuralType} placeholder="Months" value={accuralType.filter((item: any) => item.value === values?.accrualUnit)} onChange={(value) => setFieldValue('accrualUnit', value)}></Select>
          </div>
          <ErrorMessage name="accrualUnit" component="div" className="error-message" />
        </div>
        <div className=' '>
          <div className='addpolicyModalForm flex leave-count items-center'>
            <label className='mr-3'>No.of Days</label>
            <Input className='mr-8 ' name='accrualUnitValue' value={values?.accrualUnitValue} placeholder="0" onChange={(e: any) => handleInputField(e.target.value, 'accrualUnitValue', setFieldValue)} />
            <Checkbox checked={values?.isCarryOverAllowed} onChange={(e) => setFieldValue('isCarryOverAllowed', e.target.checked)}>Carry forward</Checkbox>
          </div>
          <ErrorMessage name="accrualUnitValue" component="div" className="error-message" />
        </div>
      </div>
      <div className='flex pt-8 '>
        <div className='  mr-4'>
          <div className='addpolicyModalForm leave-count flex items-center'>
            <label className=' w-full'>Opening Balance</label>
            <Input className=' ' placeholder="0" name='openingBalance' value={values?.openingBalance} onChange={(e: any) => handleInputField(e.target.value, 'openingBalance', setFieldValue)} />
          </div>
          <ErrorMessage name="openingBalance" component="div" className="error-message" />
        </div>
        <div className=' '>
          <div className='addpolicyModalForm leave-count flex items-center'>
            <label className='mr-3'>Maximum balance</label>
            <Input className=' ' placeholder="0" name='maximumBalance' value={values?.maximumBalance} onChange={(e: any) => handleInputField(e.target.value, 'maximumBalance', setFieldValue)} />
          </div>
          <ErrorMessage name="maximumBalance" component="div" className="error-message" />
        </div>
      </div>
      <div className="pt-8">
        <Checkbox name='restriction' type='checkbox' checked={values?.restriction} onChange={(e) => setFieldValue('restriction', e.target.checked)}>Restrictions</Checkbox>
      </div>
    </>
  )
}

export default LeavePolicyForm
