// EmployeeDependentInfo.js
import React from 'react';
import { ErrorMessage } from 'formik';
import FloatLabel from '../../components/FloatLabel';
import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

const EmployeeDependentInfo = ({ values, setFieldValue, handleAddRelation, styleChange = false, handleRowDelete, setFieldTouched }: any) => {
  const generateLabel = (index: any) => {
    return `Enter the Relationship${index === 0 ? '*' : ''}`;
  }
  return (
    <div className='basic-info-address'>
      <h3 className={styleChange ? 'my-5 text-2xl font-bold' : 'company-details-header mb-4 pt-5'}>{`Dependent Information ${styleChange ? '(For Insurance Purpose only)' : ''}`}</h3>
      <div className="grid grid-cols-3 gap-4">
        {values.employeeAdditionalInformationDto.employeeDependentDto.map((relation: any, index: any) => (
          <React.Fragment key={`${index + 1}`}>
            <div className={styleChange ? 'form-item basicForm-fields mb-6' : 'form-item edit-personal-details'}>
              {styleChange && <p>Relationship</p>}
              <FloatLabel label={generateLabel(index)} value={values.employeeAdditionalInformationDto.employeeDependentDto[index].relationship} name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`}>
                <Input
                  type="text"
                  className='float-control'
                  name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`}
                  value={values.employeeAdditionalInformationDto.employeeDependentDto[index].relationship}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[A-Za-z\s-]+$/.test(inputValue)) setFieldTouched(`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`, false);
                    else setFieldTouched(`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`, true)
                    setFieldValue(`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`, e.target.value)
                  }}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].relationship`} component="div" className="error-message" />
            </div>
            <div className={styleChange ? 'form-item basicForm-fields mb-6' : 'form-item edit-personal-details'}>
              {styleChange && <p>Name</p>}
              <FloatLabel label={`Enter the dependent name${index === 0 ? '*' : ''}`} value={values.employeeAdditionalInformationDto.employeeDependentDto[index].dependentName} name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`}>
                <Input
                  type="text"
                  className='float-control'
                  name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`}
                  value={values.employeeAdditionalInformationDto.employeeDependentDto[index].dependentName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[A-Za-z\s-]+$/.test(inputValue)) setFieldTouched(`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`, false);
                    else setFieldTouched(`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`, true)
                    setFieldValue(`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`, e.target.value)
                  }}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dependentName`} component="div" className="error-message" />
            </div>
            <div className={styleChange ? 'form-item basicForm-fields mb-6 flex relative flex-col w-[80%]' : ' flex-col form-item w-[80%]  relative edit-personal-details flex'}>
              {styleChange && <p>Date of Birth</p>}
              <FloatLabel label={`Select the date of birth${index === 0 ? '*' : ''}`} value={values.employeeAdditionalInformationDto.employeeDependentDto[index].dateOfBirth ? dayjs(values.employeeAdditionalInformationDto.employeeDependentDto[index].dateOfBirth) : null} name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dateOfBirth`}>
                <DatePicker
                  className='float-control'
                  placeholder=''
                  name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dateOfBirth`}
                  value={values.employeeAdditionalInformationDto.employeeDependentDto[index].dateOfBirth ? dayjs(values.employeeAdditionalInformationDto.employeeDependentDto[index].dateOfBirth) : null}
                  onChange={(date, dateString) => setFieldValue(`employeeAdditionalInformationDto.employeeDependentDto[${index}].dateOfBirth`, dateString)}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeeDependentDto[${index}].dateOfBirth`} component="div" className="error-message" />
              {index !== 0 &&
                <div className={styleChange ? "absolute right-[-20%] top-9" : "absolute right-[-20%] top-0"}>
                  <button type="button" className={`bg-primary font-medium h-10 w-[40px] flex items-center justify-center text-white px-2 py-1 rounded-sm ${styleChange ? "" : "h-7 mt-2 "} `} onClick={() => handleRowDelete(index, values)}><span className="material-symbols-outlined delete ">
                    delete
                  </span></button>
                </div>

              }
            </div>
          </React.Fragment>
        ))}
      </div>
      <button className='bg-primary font-medium text-white px-2 py-1 rounded-sm' type="button" onClick={() => handleAddRelation(values)}>+ Add</button>
    </div >
  )
}

export default EmployeeDependentInfo;
