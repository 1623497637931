import React, { useEffect, useState } from 'react'
import AddFinalAssessment from './AddFinalAssessment'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../../redux/slices/courseSlice';
import { setAssessmentAssignChapterID, setFinalAssessmentAddOrEdit } from '../../../redux/slices/currentCourseDetailSlics';
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal';

export default function FinalAssessment() {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false);
  const allChapters = useSelector((state: any) => state?.currentCourse?.allChapterList);
  const [finalAssessment, setFinalAssessment] = useState<any>(null);
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const deleteMessage = 'Course Is in Publish Mode Can not able to edit'
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);

  const handleClose = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    if (allChapters?.length > 0) {
      const filteresChapter = allChapters?.find((item: any) => { return item?.chapter === null })
      setFinalAssessment(filteresChapter ?? null)
    }
  }, [allChapters])

  const handleEditAssessment = () => {
    if (employeeDetail?.courseStatus === 'publish') {
      setDeletePopUp(true)
    } else if (employeeDetail?.courseStatus !== 'publish') {
      dispatch(setFinalAssessmentAddOrEdit(true))
      dispatch(setAssessmentAssignChapterID(finalAssessment))
      dispatch(setCurrentTab(8))
      setModalOpen(true)
    }
  }

  const handleCloseDelete = () => setDeletePopUp(false);

  const handleDeleteSelected = () => {
    handleCloseDelete()
  };

  return (
    <div className='courseDetails-fa p-4 mt-3'>
      <div className='flex justify-between '><h4 className='text-primary text-xl font-medium'>Final Assessment</h4><div>
        {finalAssessment === null && (
          <span className="material-symbols-outlined text-white p-1 bg-primary rounded-full cursor-pointer" onClick={() => handleEditAssessment()}>
            add
          </span>
        )}
      </div>
      </div>
      {finalAssessment !== null && (
        <div className='flex justify-between  mt-10'>
          <p className='text-[#737373] text-base'>{finalAssessment?.quiz?.name ?? 'Final Quiz'}</p>
          <div onClick={() => handleEditAssessment()}>
            <span className="material-symbols-outlined  cursor-pointer">
              edit
            </span>
          </div>
        </div>
      )}
      {deletePopUp && <DeleteConfirmationModal contentChange={true} open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
      {modalOpen && <AddFinalAssessment open={modalOpen} handleClose={handleClose} />}
    </div>
  )
}
