import React from 'react';
import blueArrow from '../../assets/images/blue_left_arrow.svg';
import rightarrow from "../../assets/images/right-arrow.svg"
interface ButtonProps {
    onClick?: () => void;
    label: React.ReactNode;
    className?: string;
    type: 'button' | 'submit' | 'reset';
    icon?: React.ReactNode;
    iconPosition?: 'start' | 'end';
    variant: 'primary' | 'secondary';
}

const Button: React.FC<ButtonProps> = ({ onClick, label, className, type, icon, iconPosition, variant }) => {
    return (
        <>
            {variant === 'primary' ? (
                <button type={type} onClick={onClick} className='w-max mb-4 text-white bg-pink-600 hover:bg-pink-800 focus:ring-4 shadow-md rounded-3xl items-end focus:outline-none focus:ring-white-300 font-medium pl-6 pr-4 py-2.5 text-center inline-flex text-sm '>
                    {iconPosition === 'start' && icon && icon} {/* Render icon at the start if specified */}
                    {label}
                    {iconPosition === 'end' && icon && icon} {/* Render icon at the end if specified */} <img className="right-arrow" src={rightarrow} alt="arrow" />
                </button>
            ) : (
                <button type={type} onClick={onClick} className='font-roboto text-sm font-medium text-indigo-600 gap-1  py-2.5 text-center flex items-center justify-evenly'>
                    <img className='mr-3' src={blueArrow} alt='icon' /> {iconPosition === 'start' && icon && icon}
                    {label}
                    {iconPosition === 'end' && icon && icon}
                </button>
            )}
        </>
    );
};

export default Button;
