export const Designationcolumns = [
    {
        title: 'Designation',
        dataIndex: 'designation',
    },
    {
        title: 'Department',
        dataIndex: 'department',
    },
    {
        title: 'Grade',
        dataIndex: 'grade',
    },
    {
        title: 'Roles & Responsibilities ',
        dataIndex: 'rolesAndResponsibilities',
        className: 'custom-cell-class',
    },
];

export const DepartmentColumns = [
    {
        title: 'Department',
        dataIndex: 'department',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'HOD',
        dataIndex: 'hod',
    },
    {
        title: 'Description ',
        dataIndex: 'description',
    },
];

export const bloodGroup = [
    {
        text: 'A+',
        value: 'A+',
    },
    {
        text: 'B+',
        value: 'B+',
    },
    {
        text: 'AB+',
        value: 'AB+',
    },
    {
        text: 'O+',
        value: 'O+',
    },
    {
        text: 'O-',
        value: 'O-',
    },
    {
        text: 'AB-',
        value: 'AB-',
    },
    {
        text: 'B-',
        value: 'B-',
    },
    {
        text: 'A-',
        value: 'A-',
    },
    {
        text: 'NA',
        value: 'NA',
    },
];

export const genderOptions = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
];

export const martialStatus = [
    { label: 'Single', value: false },
    { label: 'Married', value: true },
];

export const backGroundVerification = [
    { label: 'Completed', value: 'Completed' },
    { label: 'Pending', value: 'Pending' },
];

export const bonusTypes = [
    {
        label: 'Project Bonus',
        value: 'project_bonus',
    },
    {
        label: 'Performance Bonus',
        value: 'performance_bonus',
    },
];

export const selectMultipleOption = [
    {
        text: 'Option1',
        value: 'option1',
    },
    {
        text: 'Option2',
        value: 'option2',
    },
    {
        text: 'Option3',
        value: 'option3',
    },
    {
        text: 'Option4',
        value: 'option4',
    },
];

export const selectTrueOrFalse = [
    {
        text: 'TRUE',
        value: 'TRUE',
    },
    {
        text: 'FALSE',
        value: 'FALSE',
    },
];

export const questionTypes = [
    { id: 1, name: 'Multichoice Question' },
    { id: 2, name: 'True or False' },
    { id: 3, name: 'Fill ups' },
    { id: 4, name: 'Brief Questions' },
    { id: 5, name: 'Arrange in order' },
    { id: 6, name: 'Match the following' },
];

export const certificateType = [
    {
        text: 'Course',
        value: 'COURSE_CO',
    },
    {
        text: 'Learning Path Completion',
        value: 'LEARNING_PATH_CO',
    },
    {
        text: 'Learning Level',
        value: 'LEARNING_PATH_LEVEL_CO',
    },
];

export const uploadQuestionTypes = [
    {
        label: 'Multichoice Question',
        value: 'MCQ',
    },
    {
        label: 'True or False',
        value: 'TF',
    },
    {
        label: 'Fill ups',
        value: 'FIB',
    },
    {
        label: 'Brief Questions',
        value: 'OQ',
    },
];

export const courseStatusSelect = [
    {
        text: 'Publish',
        value: 'publish',
    },
    {
        text: 'Draft',
        value: 'draft',
    },
];

export const naviPaths = [
    { name: 'Dashboard', path: `/home/dashboard`, key: 'dashboard' },
    { name: 'Learning management', path: `/home/dashboard/learning-management`, key: 'learning-management' },
    { name: 'Course', path: `/home/dashboard/learning-management`, key: 'dashboard' },
    { name: 'Course detail', path: `/home/dashboard/learning-management/courseDetails`, key: 'courseDetails' },
];

const naviPathsLearningManagement = [
    { name: 'Dashboard', path: `/home/dashboard`, key: 'dashboard' },
    { name: 'Learning management', path: `/home/dashboard/learning-management`, key: 'learning-management' },
];

export const courseNav = [...naviPathsLearningManagement];
export const chapterNav = [...naviPathsLearningManagement];
export const learningPathNav = [...naviPathsLearningManagement];
export const assignedEmployeePath = [...naviPaths, { name: 'Assigned Employees', path: `/home/dashboard/learning-management/courseDetails`, key: 'courseDetails' }];
