import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import AppraisalReviewFoem from './AppraisalReviewFoem';
import { storeData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../components/Loader/Loader';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import { useSelector, useDispatch } from 'react-redux';
import { setProfileLoad } from '../../../../../../redux/slices/updateProfileSlice';

const EmployeeAppraisal = () => {
  const dispatch = useDispatch();
  const salaryRange = useGetApiRequests('getAppraisal', 'GET');
  const getQuestions = useGetApiRequests('getquestions', 'GET');
  const { id }: any = useParams();
  const size: any = 'large';
  const [modalOpen, setModalOpen] = useState(false);
  const [reviewList, setReviewList] = useState<any>([])
  const [loader, setLoader] = useState(false);
  const [isView, setIsView] = useState(false)
  const [isPending, setIsPending] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const [reviewType, setReviewType] = useState(null);
  const [employeeReviewId, setEmployeeReviewId] = useState(null)

  useEffect(() => {

    designationId && getReviews();
  }, [designationId])
  const dateConvertion = (date: any) => {
    let spliteValue = date.split('T')[0];
    let value = spliteValue;
    let dateValue = value.split('-')
    return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
  }
  const getReviews = () => {
    const params: any = {
      id: id,
    };
    setLoader(true)
    salaryRange('', '', params)
      .then((response: any) => {
        dispatch(setProfileLoad());
        const apiResponse = response?.data?.data;
        setReviewList(apiResponse)
        getQuestionsApi();
        setLoader(false)
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        setLoader(false)
      });
  }
  const getQuestionsApi = () => {
    const params: any = {
      designationId: designationId, organisationId: orgId
    };
    const pathParams: any = {
      id: id
    }
    setLoader(true);
    getQuestions('', params, pathParams)
      .then((response: any) => {
        const apiResponse: any = response?.data?.data;
        setReviewType(apiResponse?.reviewType)
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handelReviews = () => {
    storeData("reviewtype", '', true)
    setModalOpen(true)
    setIsView(false);
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleView = (object: any, employeeReviewId: any) => {
    storeData("reviewtype", '', true)
    if (object?.reviewStatus === 'Pending') {
      setIsPending(true);
    } else {
      setIsPending(false);
    }
    setIsView(true);
    setModalOpen(true);
    setEmployeeReviewId(employeeReviewId)
  }
  return (
    <div className='employee-appraisal'>
      <div className="grid grid-cols-1 ">
        <div className='flex justify-end'>
          <Tooltip placement="bottom" title="Create Appraisal">
            <Button type="primary" shape="circle" size={size} onClick={() => handelReviews()}>
              <span className="material-symbols-outlined">
                add
              </span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="employee-appraisal-container overflow-y-auto">
        {reviewList && reviewList.length > 0 ? reviewList.map((item: any) => (
          <div key={item?.employeeReviewId} className='employee-appraisal-card cursor-pointer' onClick={() => handleView(item, item?.employeeReviewId)} >
            <p className='employee-appraisal-title' >{item?.reviewFormName}</p>
            <div className=" grid-rows-4 grid-flow-col gap-4 flex asset-header pt-3">
              {item?.reviewedOn !== null ? (
                <div className='header-status header-border' >Review date : <span>{item?.reviewedOn !== null ? dateConvertion(item?.reviewedOn) : '-'}</span>  </div>) : (
                <div className='header-status header-border' >Probation Extension end date : <span>{item?.nextProbationEndDate !== null ? dateConvertion(item?.nextProbationEndDate) : '-'}</span></div>
              )}
              <div className='header-status header-border'> Review Status : <span>{item?.reviewStatus}</span> </div>
              {item?.type !== 'Probation' && item?.type !== 'Probation Extension' && reviewType !== null && (
                <div className='header-status'>Eligible for Increment:  <span>{item?.eligibleForIncrement === false || item?.eligibleForIncrement === null ? 'No' : 'Yes'}</span> </div>)}
            </div>
          </div>
        )) : <div className="no-record appraisal">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />

          </p>
        </div>}

      </div>
      {modalOpen && <AppraisalReviewFoem open={modalOpen} handleCloseModal={handleCloseModal} getReviewsApi={getReviews} isView={isView} employeeReviewId={employeeReviewId} isPending={isPending} />}
      {loader && <Loader />}
    </div >
  )
}

export default EmployeeAppraisal
