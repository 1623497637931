import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import logo from "../../../assets/images/logo.png"
import { ErrorMessage, retrieveData, storeData, triggerNotification, useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import web from "../../../assets/images/client-onboard/web.png"
import Loader from '../../../components/Loader/Loader';

const VerifyAccount = () => {
  const navigate = useNavigate()
  const otpVerify = useGetApiRequests('otpVerify', 'POST');
  const resendOtp = useGetApiRequests('resendOtp', 'POST');
  const [showError, setShowError] = useState<any>(false)
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [employeeId, setEmployeeId] = useState()
  const [email, setEmail] = useState('')

  const handleOtpChange = (index: any) => (e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams: any = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    setEmail(queryParams?.email)
    const newOrganizationData = retrieveData("newOrganizationData", true)
    const employeeId = newOrganizationData?.employeeDto?.employeeId
    setEmployeeId(employeeId)
  }, [])

  const handleKeyPress = (index: any) => (e: any) => {
    setShowError(false)
    if (e.key === 'Backspace' && index >= 1) {
      inputRefs[index - 1].focus();
    }
    else if (e.key !== 'Backspace' && index <= 4) {
      inputRefs[index + 1].focus();
    }
  };

  const inputRefs: any = otp.map((_, index) => React.createRef());

  const handelVerification = () => {
    const result = otp.join("");
    if (result?.length !== 6) {
      setShowError(true)
      return
    }
    const queryParam = {
      inputOtp: result,
      employeeId: employeeId
    }
    setLoader(true)
    otpVerify({}, queryParam)
      .then((response: any) => {
        const apiResponse = response?.data?.status.message
        if (!apiResponse.includes("Not Found")) {
          navigate("/home/welcome")
          storeData("newOrganization", true, true)
        }
        else {
          triggerNotification('error', '', apiResponse, 'topRight');
          setOtp(['', '', '', '', '', ''])
        }
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
      });
  }

  const handelResendOtp = () => {
    setLoader(true)
    const queryparam = {
      employeeId: employeeId
    }
    resendOtp({}, queryparam)
      .then((response: any) => {
        triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
      });
  }

  return (
    <div className='relative h-[100vh]'>
      {loader && <Loader />}
      <div className="signup-container flex relative ">
        <div className="account-verification flex justify-center items-center w-full flex-col">
          <div className="w-2/5 flex items-center justify-center pb-12">
            <img src={logo} alt="logo " />
            <p className='text-[#ffffff] font-rubik text-[30px]'>All-Aboard </p>
          </div>
          <div className="card w-[750px]">
            <h4 className='text-center font-rubik text-[25px] font-semibold text-[#000] pb-2 '>Verify Your Account</h4>
            <p className='text-center font-rubik text-[16px]  text-[#00000080]'>We've emailed you a six digit code to <span>{email}</span></p>
            <div className="otp-input w-full relative">
              <ul className='flex justify-center pb-10'>
                {otp.map((digit: any, index: number) => (
                  <li className='w-[75px] h-[75px] mt-[55px] mx-[10px] '>
                    <Input className='h-full text-center text-[20px]'
                      key={index}
                      value={digit}
                      onChange={handleOtpChange(index)}
                      onKeyUp={handleKeyPress(index)}
                      maxLength={1}
                      ref={(input) => (inputRefs[index] = input)}
                    />
                  </li>
                ))}
              </ul>
              {showError &&
                <div className="absolute bottom-[10%] left-[40%]">
                  <ErrorMessage message='Please entert 6 digit code' />
                </div>
              }
            </div>
            <div className='flex justify-center flex-col items-center '>
              <button type='submit' className='addButton w-[400px] mb-6 ' onClick={handelVerification}>
                Verify
              </button>
              <p className='text-center font-rubik text-[16px] text-[#00000080]  '>Didn't receive your code?<span className='font-semibold cursor-pointer' onClick={handelResendOtp} >RESEND</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="web absolute bottom-0">
        <img src={web} alt="web" />
      </div>
    </div>
  )
}

export default VerifyAccount
