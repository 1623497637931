import React from 'react';

interface ImageProps {
    src: any;
    alt: string;
    className?: string;
    onClick?: () => void;
}
const ImagesComponent: React.FC<ImageProps> = ({ src, alt, className, onClick, ...props }) => {
    return (
        <img alt={alt} src={src} className={className}></img>
    );
};

export default ImagesComponent;
