import React from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function AssetMaster() {
    const navigate = useNavigate()
    const dataSource = [
        { assetName: 'Desktop', type: 'Mac, PC', configuration: 'Configuration 1 - Mac, Configuration 2 - PC', edit: '-' },
        { assetName: 'Laptop', type: 'Mac, PC', configuration: 'Configuration 1 - Mac, Configuration 2 - PC', edit: '-' },
        { assetName: 'Charger / Adaptor', type: 'type C, Others', configuration: ' - ', edit: '-' },
        { assetName: 'Mouse', type: 'Wired, Wireless', configuration: ' - ', edit: '-' },
        { assetName: 'Keyboard', type: 'Wired, Wireless', configuration: ' - ', edit: '-' },
        { assetName: 'SIM', type: '-', configuration: ' Jio, Airtel ', edit: '-' },
        { assetName: 'Mobile', type: 'Basic , Smart Phone', configuration: ' - ', edit: '-' },
    ];
    const columns = [
        {
            title: 'Asset Name',
            dataIndex: 'assetName',
            key: 'assetName',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Configuration',
            dataIndex: 'configuration',
            key: 'configuration',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
        },
    ];
    const handelNavigate = () => {
        navigate('/home/dashboard')
    }
    return (
        <div className='page-container bg-customBg w-full active_ flex '>
            <div className="w-full">
                <div className='full-width-container mt-5 '>
                    <div className=' bg-white p-5 w-full h-full'>
                        <div className="bredcum-container py-5 ">
                            <ul className="breadcrumb breadcrumb--classic">
                                <li className='pl-0' onClick={handelNavigate}>
                                    Dashboard
                                </li>
                                <li className='text-primary'>
                                    Asset Master
                                </li>
                            </ul>
                        </div>
                        <div className='table-style__Tab bg-white'>
                            <Table dataSource={dataSource} columns={columns} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
