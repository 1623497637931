import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ChapterAssesmentTable from './ChapterAssesmentTable';
import { clearAssessmentAssignChapterID, setCurrentAssessmentTab } from '../../../../redux/slices/currentCourseDetailSlics';
const { TabPane } = Tabs;

const CourseTab = () => {
  const currentAssessmentTab = useSelector((state: any) => state?.currentCourse?.currentAssessmentTab);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const dispatch = useDispatch()

  const handleTabChange = (key: string) => {
    dispatch(clearAssessmentAssignChapterID())
    dispatch(setCurrentAssessmentTab(key))
    setActiveTabKey(key);
  };
  useEffect(() => {
    setActiveTabKey(currentAssessmentTab)
  }, [currentAssessmentTab])
  const items = [
    {
      key: '1',
      label: 'Chapter Assessment',
      children: <ChapterAssesmentTable handleTabChange={handleTabChange} activeTabKey={activeTabKey} />,
    },
    {
      key: '2',
      label: 'Final Assessment',
      children: <ChapterAssesmentTable handleTabChange={handleTabChange} activeTabKey={activeTabKey} />,
    },
  ]

  return (
    <div className='course-tab-container'>
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        {items.map(item => (
          <TabPane key={item.key} tab={item.label}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default CourseTab
