import React from "react";
import { Modal } from "antd";

const DocumentPreviewAll = ({ open, handleClose, renderPdfContent }: any) => {
  return (
    <Modal title='Document Preview' centered open={open} onCancel={() => handleClose()} footer={null} className="modal-documentPreview" maskClosable={false}>
      {renderPdfContent()}
    </Modal>
  )
}

export default DocumentPreviewAll;