import React from 'react';
import CommonTab from '../../components/Tab/Tab';
import { ArrowLeftOutlined } from '@ant-design/icons';
import editButton from '../../assets/images/new-dashboard/edit.svg';
import { useSelector } from 'react-redux';

export default function Organization() {
    const orgName = useSelector((state: any) => state?.organisationName?.orgName);
    return (
        <div className='organization page-container bg-customBg '>
            <div className=' full-width-container'>
                <div className='organization_header'>
                    <h1 className='organization_title py-5 flex'>
                        <a href='/home/dashboard'>
                            <span className='organization_back-arrow pr-4'>
                                <ArrowLeftOutlined />
                            </span>
                        </a>
                        {orgName}
                        <a href='/home/organisation/edit-organisation'>

                            <img src={editButton} alt='edit' className='pl-4 cursor-pointer' />
                        </a>
                    </h1>
                </div>

                <div className='organization__Tab bg-white relative'>
                    <CommonTab />
                </div>
            </div>
        </div>
    );
}
