import React from 'react';
import { Table } from 'antd';
interface TableProps {
    onSubmit?: any;
    columns: any;
    data: any;
    handleSelectedRows?: any;
}

const CustomizeTable = ({ onSubmit, columns, data, handleSelectedRows }: TableProps) => {
    const onSelectChange = (newSelectedRows: React.Key[]) => {
        handleSelectedRows(newSelectedRows);
    };
    const rowSelection = {
        onChange: onSelectChange,
    };

    return (
        <div>
            <Table rowSelection={rowSelection} columns={columns} dataSource={data} pagination={false} />
        </div>
    );
};

const DynamicTable: React.FC<TableProps> = ({ onSubmit, columns, data, handleSelectedRows }: TableProps) => {
    return (
        <CustomizeTable onSubmit={onSubmit} columns={columns} data={data} handleSelectedRows={handleSelectedRows} />
    );
};

export default DynamicTable;
