import { Input } from 'antd'
import { ErrorMessage } from 'formik'
import React from 'react'
import UploadImages from './UploadImages'

const BriefQuestions = ({ values, setFieldValue, mediaData, setMediaData, previewImage }: any) => {
  return (
    <>
      <div className="flex">
        <div className="w-3/5">
          <div className="addcourse-field pt-5">
            <p>Question</p>
            <Input.TextArea
              name="question"
              value={values.question}
              placeholder="Question"
              onChange={(e: any) => setFieldValue('question', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="question" component="div" className="error-message" />
          </div>
          <div className="addcourse-field pt-5">
            <p>Enter Correct Answer</p>
            <Input.TextArea
              name="correctAnswer"
              placeholder="Enter your text"
              value={values.correctAnswer}
              onChange={(e) => setFieldValue('correctAnswer', e.target.value)}
            />
            <ErrorMessage name="correctAnswer" component="div" className="error-message" />
          </div>
        </div>
        <div className='w-2/5 pt-5 pl-8 upload-error-tooltip multi-ques'>
          <label className='addcourse-lable pl-2' htmlFor="firstName">Question Image</label>
          <UploadImages setMediaData={setMediaData} mediaData={mediaData} previewImage={previewImage} />
        </div>
      </div>
    </>
  )
}

export default BriefQuestions
