import React from 'react'

export default function EmailAppraisalButtonRendering(props: any) {
  const { incrementEligibleEmail, touchedEmail, handelSaveReviseEmail, handelChangeSalaryEmail } = props;
  return (
    <div data-testid='button-id'>
      {touchedEmail && (
        <div className='flex justify-end mr-5 salary_add-btn'>
          {!incrementEligibleEmail ? (
            <button className='addButton ' onClick={() => handelSaveReviseEmail()}>
              Save
            </button>) : (
            <button className='addButton ' onClick={() => handelChangeSalaryEmail()}>
              Next
            </button>
          )}
        </div>)}
    </div>
  )
}
