import React from 'react';
import allAboardLogo from '../../assets/images/logo.png';
import ProfilePicture from './ProfilePicture/ProfilePicture';
import ToggleMenu from './ToggleMenu/ToggleMenu';
import Notification from './Notification/Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieveData } from '../../common/CommonExport';
import { toggleEmployeeTable } from '../../redux/employeetable/employeeTableAction';
import { useDispatch } from 'react-redux';

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const loginUserData = retrieveData('loginUserData', true);
    const routesToHideHeader = ['/', '/login', '/forgot-password', '/reset-password', '/probation-completion/',
        '/employee-information-form/', '/preview-document-detail/', '/preview-document-detail', '/employee-designation-approval', '/set-password', '/preview-document'
        , '/employee-bonus-update-status/', '/register', '/signup', '/signup/verification', "/signup/payments", '/home', '/leave-request-update-status/', '/deprovision-form', '/privacy-policy'];
    if (routesToHideHeader.includes(location.pathname)) {
        return null;
    }
    const handleNavigate = () => {
        dispatch(toggleEmployeeTable(false))
        navigate(`/home/dashboard`);
    }

    return (
        <div className='header'>
            <div className='container mx-auto max-w-full md:px-14 px-4'>
                <div className='header__menu flex justify-between items-center'>
                    <div className='header__leftSideMenu flex'>
                        <ToggleMenu />
                        <img alt='logo' src={allAboardLogo} className='logo' onClick={() => handleNavigate()} />
                        <p className='ml-3 cursor-pointer' onClick={() => handleNavigate()}>All-Aboard!</p>
                    </div>
                    <div className='header__rightSideMenu'>
                        <ul className='flex items-center'>
                            <li>Welcome {loginUserData?.name}</li>
                            <li>
                                <Notification />
                            </li>
                            <li>
                                <ProfilePicture />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
