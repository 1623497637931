import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessages, triggerNotification, useGetApiRequests } from "../../../common/CommonExport";
import CourseBreadCrums from "../CourseDetails/CourseBreadCrums";
import { assignedEmployeePath } from "../../Organization/TableColumn";
import { ColumnsType } from "antd/es/table";
import { Avatar, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import DeleteConfirmationModal from "../../Organization/DeleteConfirmationModal";
import Loader from "../../../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { setCourseDetails } from "../../../redux/slices/currentCourseDetailSlics";
import noRecordFound from "../../../assets/images//empty-folder.png"

const AssignedEmployeeToCourseTable = () => {
  const { id: courseId } = useParams();
  const dispatch = useDispatch()
  const getAssignedEmployee = useGetApiRequests('assignedEmployeeList', "GET");
  const deleteEmployee = useGetApiRequests('deleteAssignedEmployee', "DELETE");
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const [employeeList, setEmployeeList] = useState<any>([])
  const colours = [
    { background: '#E2D3F5', font: '#8B25FF' },
    { background: '#CFF6FF', font: '#0E96B0' },
    { background: '#FAE9C6', font: '#A16F0B' }
  ]
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const deleteMessage = ErrorMessages?.unassignCourse;
  const [deleteEmployeeId, setDeleteEmployeeId] = useState<any>()
  const [loader, setLoader] = useState<any>(false)

  const getEmployeeListByCourse = async () => {
    setLoader(true)
    try {
      const response: any = await getAssignedEmployee('', { courseId })
      const reformedPolicyList = response?.data?.data.map((item: any, index: number) => ({
        key: item?.employeeId,
        employeeId: item?.employeeId,
        name: `${item?.firstName} ${item?.lastName}`,
        details: item,
        department: item?.designation?.department?.name,
        title: item?.designation?.name,
        assignedOn: item?.assignedDate,
      }));
      setLoader(false)
      const empDetailsUpdate = {
        ...employeeDetail,
        employeeCount: reformedPolicyList?.length
      }
      dispatch(setCourseDetails(empDetailsUpdate))
      setEmployeeList(reformedPolicyList)
    } catch (err: any) {
      setLoader(false)
      if (err?.response?.data?.message !== 'Employee Details not found')
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  }

  useEffect(() => {
    getEmployeeListByCourse()
  }, [])

  const getAvatarText = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };

  const handleDeleteSelected = async () => {
    const queryParams = { courseId, employeeIds: deleteEmployeeId }
    try {
      const response: any = await deleteEmployee('', queryParams)
      getEmployeeListByCourse()
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  };
  const handleCloseDelete = () => setDeletePopUp(false);
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colours.length);
    return colours[randomIndex];
  };

  const singleDeleteFunction = async (item: any) => {
    setDeleteEmployeeId(item?.employeeId)
    setDeletePopUp(true)
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      width: "25%",
      render: (text) => {
        const randomColor = getRandomColor();
        return (
          <div className='flex items-center leave-type  '>
            <Avatar.Group
              maxCount={3}
              maxPopoverTrigger="click"
              size="large"
              maxStyle={{ color: '#green', cursor: 'pointer' }}
            >
              <Avatar
                style={{ color: randomColor?.font, backgroundColor: randomColor?.background }}
                className={"leave-type-" + text} >{getAvatarText(text)}</Avatar>
            </Avatar.Group>
            <p className='ml-2'>{text}</p>
          </div>
        )
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: "25%",

    },
    {
      title: 'Department ',
      dataIndex: 'department',
      width: "30%",
    },
    {
      title: 'Assigned on',
      dataIndex: 'Assigned on',
      key: 'assignedOn',
      render: (_: any, record: any) => (
        <div className='flex'>
          {dayjs(record?.assignedOn).format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'deleteButton',
      render: (_: any, record: any) => (
        <Tooltip placement="top" title="Delete">
          <span className="material-symbols-outlined cursor-pointer hover:text-primary text-lg" onClick={() => singleDeleteFunction(record)}>
            Delete
          </span>
        </Tooltip>
      ),
    },
  ]

  return (
    <div>
      {loader && <Loader />}
      <div className='newLearningPath page-container bg-customBg'>
        <div className="px-[3.5rem] pt-5">
          <CourseBreadCrums naviPaths={assignedEmployeePath} />
        </div>
        <div className='assignedEmployee full-width-container mt-5'>
          <div className='lm-tab bg-white p-5'>
            <h3>{`Assigned Employees (${employeeList?.length})`}</h3>
            <div className=' mt-6'>
              {employeeList?.length > 0 ? (
                <Table columns={columns} dataSource={employeeList} pagination={false} />
              ) : (<div className="relative flex justify-center items-center min-h-[70vh]">
                <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
                </p>
              </div>)
              }
            </div>
          </div>
        </div>
      </div>
      {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
    </div>
  )
}

export default AssignedEmployeeToCourseTable