import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import './AddLeavePolicyModal.scss'
import LeavePolicyForm from './LeavePolicyForms/LeavePolicyForm';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import { setLeavePolicyReload } from '../../redux/slices/updateProfileSlice';
import leavePolicyValidationSchema from './LeavePolicyForms/LeavePloicySchema';
import AddLeavePolicyRestriction from './AddLeavePolicyRestriction';
import ModalLoader from '../../components/Loader/ModalLoader';

const AddLeavePolicyModal = (props: any) => {
  const { open, handelClose, policyData, isEdit } = props;
  const dispatch = useDispatch()
  const leaveList = useSelector((state: any) => state?.leaveList?.leaveList);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const userId = useSelector((state: any) => state?.userId?.id);
  const createPolicyApi = useGetApiRequests('createLeavePolicy', 'POST');
  const editPolicyApi = useGetApiRequests('editLeavePolicy', 'PUT');
  const [initialValues, setInitialValues] = useState({
    policyName: "",
    employmentTypeId: '',
    unit: "",
    effectiveAfterValue: "",
    effectiveAfterUnit: "",
    effectiveFrom: "",
    openingBalance: 0,
    maximumBalance: 0,
    restriction: false,
    accrualUnit: '',
    accrualUnitValue: 0,
    isCarryOverAllowed: false,
    //restriction values
    weekendsDontCountAsLeave: false,
    holidaysDontCountAsLeave: false,
    exceedLeaveBalance: false,
    maxHrPerDay: 0,
    maxHrPerMonth: 0,
    allowPastDates: false,
    pastDaysAllowed: 0,
    allowFutureDates: true,
    nextDaysAllowed: 0,
    minimumDaysInAdvance: 0,
    maximumConsecutiveLeaveDays: 0,
    incompatibleLeaveTypes: ""
  })
  useEffect(() => {
    if (isEdit === true) {
      setInitialValues({
        policyName: policyData?.leavePolicy?.policyName,
        employmentTypeId: policyData?.leavePolicy?.employmentTypeId.toString(),
        unit: policyData?.leavePolicy?.unit,
        effectiveAfterValue: policyData?.leavePolicy?.effectiveAfterValue,
        effectiveAfterUnit: policyData?.leavePolicy?.effectiveAfterUnit,
        effectiveFrom: policyData?.leavePolicy?.effectiveFrom,
        openingBalance: policyData?.leavePolicy?.openingBalance,
        maximumBalance: policyData?.leavePolicy?.maximumBalance,
        restriction: !!(policyData?.leaveRestriction && policyData?.leaveRestriction !== null),
        accrualUnit: policyData?.leaveAccrual?.accrualUnit,
        accrualUnitValue: policyData?.leaveAccrual?.accrualUnitValue,
        isCarryOverAllowed: policyData?.leaveAccrual?.isCarryOverAllowed,
        //restriction values
        weekendsDontCountAsLeave: policyData?.leaveRestriction?.weekendsDontCountAsLeave,
        holidaysDontCountAsLeave: policyData?.leaveRestriction?.holidaysDontCountAsLeave,
        exceedLeaveBalance: policyData?.leaveRestriction?.exceedLeaveBalance,
        maxHrPerDay: policyData?.leaveRestriction?.maxHrPerDay,
        maxHrPerMonth: policyData?.leaveRestriction?.maxHrPerMonth,
        allowPastDates: policyData?.leaveRestriction?.allowPastDates,
        pastDaysAllowed: policyData?.leaveRestriction?.pastDaysAllowed,
        allowFutureDates: policyData?.leaveRestriction?.allowFutureDates,
        nextDaysAllowed: policyData?.leaveRestriction?.nextDaysAllowed,
        minimumDaysInAdvance: policyData?.leaveRestriction?.minimumDaysInAdvance,
        maximumConsecutiveLeaveDays: policyData?.leaveRestriction?.maximumConsecutiveLeaveDays,
        incompatibleLeaveTypes: policyData?.leaveRestriction?.incompatibleLeaveTypes
      })
    }
  }, [])
  const [loader, setLoader] = useState(false);
  const handleCloseModal = () => {
    handelClose()
  }

  const handleSubmit = (values: any) => {
    if (parseInt(values.openingBalance) < parseInt(values.maximumBalance)) {
      setLoader(true);
      const params: object = {
        loggedInUserId: userId
      }
      const leavePolicy: any = {
        leavePolicy: {
          "policyName": values.policyName,
          "employmentTypeId": parseInt(values.employmentTypeId),
          "unit": values.unit,
          "effectiveAfterValue": parseInt(values.effectiveAfterValue),
          "effectiveAfterUnit": values.effectiveAfterUnit,
          "effectiveFrom": values.effectiveFrom,
          "openingBalance": parseInt(values.openingBalance),
          "maximumBalance": parseInt(values.maximumBalance),
          "isActive": true,
        },
        leaveAccrual: {
          "accrualUnit": values.accrualUnit,
          "accrualUnitValue": parseInt(values.accrualUnitValue),
          "isCarryOverAllowed": values.isCarryOverAllowed
        },
        "organisationId": orgId
      };
      if (values.restriction === true) {
        leavePolicy.leaveRestriction = {
          "weekendsDontCountAsLeave": values.weekendsDontCountAsLeave,
          "holidaysDontCountAsLeave": values.holidaysDontCountAsLeave,
          "exceedLeaveBalance": values.exceedLeaveBalance,
          "maxHrPerDay": parseInt(values.maxHrPerDay),
          "maxHrPerMonth": parseInt(values.maxHrPerMonth),
          "allowPastDates": false,
          "pastDaysAllowed": 0,
          "allowFutureDates": values.allowFutureDates,
          "nextDaysAllowed": parseInt(values.nextDaysAllowed),
          "minimumDaysInAdvance": parseInt(values.minimumDaysInAdvance),
          "maximumConsecutiveLeaveDays": parseInt(values.maximumConsecutiveLeaveDays),
          "incompatibleLeaveTypes": values.incompatibleLeaveTypes
        }
      }
      if (isEdit) {
        leavePolicy["leavePolicyMappingId"] = retrieveData('leavePolicyMappingId', true)
      }
      if (!isEdit) {
        createPolicyApi(leavePolicy, params)
          .then((response: any) => {
            triggerNotification('success', '', response?.data?.message, 'topRight');
            handleCloseModal();
            dispatch(setLeavePolicyReload());
          })
          .catch((err: any) => {
            setLoader(false)
            triggerNotification('error', '', err?.message, 'topRight');
            handleCloseModal();
          });
      } else {
        const queryParams: any = {
          loggedInUserId: userId
        }
        const pathParams: any = {
          id: retrieveData('leavePolicyMappingId', true)
        }
        editPolicyApi(leavePolicy, queryParams, pathParams)
          .then((response: any) => {
            handleCloseModal();
            triggerNotification('success', '', response?.data?.message, 'topRight');
            dispatch(setLeavePolicyReload());
          })
          .catch((err: any) => {
            triggerNotification('error', '', err?.response?.data?.data, 'topRight');
            setLoader(false)
            handleCloseModal();
          });
      }
    } else {
      triggerNotification('warning', '', ErrorMessages.maximumBalance, 'topRight');
    }
  };
  return (
    <Modal className='addleaveapolicy' title={isEdit ? 'Edit Leave Policy' : 'Create Leave Policy'} centered open={open} onCancel={() => handleCloseModal()} footer={null} maskClosable={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        values={initialValues}
        enableReinitialize={true}
        validationSchema={leavePolicyValidationSchema}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="py-6 ">
                <div>
                  <div className='form-item'>
                    <LeavePolicyForm values={values} setFieldValue={setFieldValue} />
                    {values.restriction && (
                      <AddLeavePolicyRestriction values={values} setFieldValue={setFieldValue} leaveList={leaveList} isEdit={isEdit} />
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className='addButton float-right w-[150px] mr-5'>Save</button>
            </Form>
          )
        }}
      </Formik>
      {loader && <ModalLoader />}
    </Modal >
  );
};

export default AddLeavePolicyModal;