import React from "react";
import '../../../components/Sidebar/Sidebar.scss'
import { useNavigate } from "react-router-dom";

const CourseBreadCrums = ({ naviPaths }: any) => {
  const navigate = useNavigate();

  const handleNavigation = (item: any) => {
    navigate(item?.path);
  }

  return (
    <div className="bredcum-container">
      <ul className="breadcrumb breadcrumb--classic breadCrumbsStyle">
        {naviPaths?.map((item: any, index: any) => (
          <li onClick={() => handleNavigation(item)}
            className={`${naviPaths?.length - 1 === index ? 'active-breadcrumb  ml-2' : '' + "pr-2"}`}
            key={item?.name}>
            {item?.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CourseBreadCrums