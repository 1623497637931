import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, DatePicker, DatePickerProps } from 'antd';
import FloatLabel from '../../../components/FloatLabel';
import { ErrorMessage, ErrorMessages, triggerNotification, useGetApiRequests, empInitValues } from '../../../common/CommonExport';
import ImageUpload from './imageUpload/ImageUpload';
import { useSelector, useDispatch } from 'react-redux';
import { finalSubmit, handleChange, onSubmit } from './AddEmployeeHelper';
import dayjs from 'dayjs';
import CloseModalForm from '../../employee/employee-details/employee-profile/layout/Appraisal/EmailAppraisal/CloseModalForm';
import ModalLoader from '../../../components/Loader/ModalLoader';

const AddEmployeeModal = (props: any) => {
    const dispatch = useDispatch();
    const employeeTypes = useGetApiRequests('employeeTypes', 'GET');
    const departmentList = useGetApiRequests('departmentList', 'GET');
    const organisationList = useGetApiRequests('organisationList', 'GET');
    const listByCategory = useGetApiRequests('listByCategory', 'GET');
    const employeedeprovision = useGetApiRequests('employeedeprovision', 'GET');
    const designationList = useGetApiRequests('designationList', 'GET');
    const employeeCreate = useGetApiRequests('createEmployee', 'POST');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const userData = useSelector((state: any) => state?.loginUserData?.userData?.data);
    const reduxToken = useSelector((state: any) => state?.authToken?.token);
    const [dropDownList, setDropDownList] = useState<any>();
    const [open, setOpen] = useState(false);
    const [officialEmailEdit, setOfficialEmailEdit] = useState<any>(true);
    const [organisationName, setOrganisationName] = useState<any>('');
    const [imageData, setImageData] = useState<any>(new FormData());
    const [showError, setShowError] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [values, setValues] = useState<any>(empInitValues);
    const [imgCheck, setImgCheck] = useState<any>(false)
    const [closeConfirmationModal, setCloseConfirmationModal] = useState<any>(false);

    const getAllApiData = async () => {
        const departmentListQueryParams = { organisationId: orgId };
        const organisationListQueryParams = { userEmailId: userData?.email };
        const listByCategoryQueryParams = { statusCategory: 'employee' };
        const employeedeprovisionListQueryParams = { roleName: reduxToken, organisationId: orgId, nameSearch: '' };
        try {
            setLoader(true)
            const [employeeTypesData, departmentListData, organisationListData, listByCategoryData, employeedeprovisionList] = await Promise.all([
                employeeTypes(),
                departmentList('', departmentListQueryParams),
                organisationList('', organisationListQueryParams),
                listByCategory('', listByCategoryQueryParams),
                employeedeprovision('', employeedeprovisionListQueryParams),
            ]);
            setDropDownList({
                employeeTypesData,
                listByCategoryData,
                departmentListData,
                reportingManager: employeedeprovisionList,
                designationList: {},
            });
            setOrganisationName(organisationListData);
            setLoader(false)
        } catch (error) {
            triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
            handleCloseModal()
        }
    };
    useEffect(() => {
        setOpen(props?.isOpen);
        getAllApiData();
    }, []);

    const handleCloseModal = () => {
        setOpen(false);
        props?.handleClose(false);
    };
    const onChange: DatePickerProps['onChange'] = (date, dateString) => setValues({ ...values, dateOfJoin: dateString });
    const handleImageUpload = (file: File) => {
        setImgCheck(true)
        const formData = new FormData();
        formData.append('employeeProfile', file);
        setImageData(formData);
    };
    const disabledDate = (current: any) => {
        const oneWeekAgo = dayjs().subtract(7, 'days');
        return current && current < oneWeekAgo.startOf('day');
    };
    const handleImageDelete = (file: File) => {
        setImgCheck(false)
        setImageData('');
    }
    const handleClosed = () => {
        setCloseConfirmationModal(false)
        setOpen(false)
        props?.setIsOpen(false)
    }
    return (
        <Modal title='Add Employee' centered open={open} onOk={() => onSubmit({ values, orgId, setShowError, setLoader, employeeCreate, imageData, handleCloseModal, finalSubmit, setOfficialEmailEdit }, `@${organisationName?.data?.data?.domainName}`, imgCheck, dispatch)}
            maskClosable={false}
            onCancel={() => {
                setCloseConfirmationModal(true)
            }} className='add-employee-modal' >
            <div className='add-employee_container'>
                {loader && <ModalLoader />}
                <div className='add-employee_form w-full flex'>
                    <div className='grid grid-cols-3 gap-x-12 gap-y-4 mt-4 w-full form'>
                        <div className='form-item'>
                            <FloatLabel label='First Name*' name='firstName' value={values?.firstName}>
                                <Input placeholder="first-name-input" value={values?.firstName} onChange={(value: any) => handleChange(value.target.value, 'firstName', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} className='float-control' />
                            </FloatLabel>
                            {(showError && values?.firstName?.length === 0) && <ErrorMessage message='Please enter the first name' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Last Name*' name='lastName' value={values?.lastName}>
                                <Input placeholder="last-name-input" value={values?.lastName} onChange={(value: any) => handleChange(value.target.value, 'lastName', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} className='float-control' />
                            </FloatLabel>
                            {(showError && values?.lastName?.length === 0) && <ErrorMessage message='Please enter the last name' />}

                        </div>
                        <div className='row-span-5 form-item pl-10'>
                            <label htmlFor="" className='text-[grey]'> Upload Employee Image</label>
                            <div className=' pt-4'>
                                <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 3}
                                    allowedTypes={['image/jpeg', 'image/png']}
                                />
                            </div>
                            {(showError && !imgCheck) && <ErrorMessage message='Please upload the image' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Employee Department*' name='department' value={values?.department?.departmentId}>
                                <Select className='float-control' onChange={(value: any) => handleChange(value, 'department', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} showSearch value={values?.department?.departmentId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.departmentListData?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.departmentId} value={option.departmentId}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FloatLabel>
                            {(showError && !values?.department?.departmentId) && <ErrorMessage message='Please select the department' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Employee Designation*' name='designation' value={values?.designation?.designationId}>
                                <Select className='float-control' onChange={(value: any) => handleChange(value, 'designation', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} showSearch disabled={!dropDownList?.designationList?.data?.data} value={values?.designation?.designationId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.designationList?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.designationId} value={String(option.designationId)}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {(showError && !values?.designation?.designationId) && <ErrorMessage message='Please select the designation' />}
                            </FloatLabel>
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Personal Email ID*' name='emailId' value={values?.personalEmail}>
                                <Input data-testid='per-mail-id' value={values?.personalEmail} onChange={(value: any) => handleChange(value.target.value, 'emailId', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} className='float-control' />
                            </FloatLabel>
                            {(showError && values?.personalEmail?.length === 0) && <ErrorMessage message='Please enter the email Id' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Organization*' name='organisation' value={organisationName?.data?.data?.name}>
                                <Input placeholder='org' disabled={true} value={organisationName?.data?.data?.name} onChange={(value: any) => handleChange(value.target.value, 'organisation', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} className='float-control' />
                            </FloatLabel>
                        </div>
                        <div className='form-item relative'>
                            <FloatLabel label='Official Email ID*' name='officialEmailId' value={values?.officialEmail}>
                                <Input placeholder='official-mail-id' disabled={officialEmailEdit} value={values?.officialEmail} onChange={(value: any) => handleChange(value.target.value, 'officialEmailId', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} className='float-control official-email-Id' />
                            </FloatLabel>
                            <span className='absolute organisation-edit cursor-pointer bg-[rgb(235, 235, 235)]'>{`@${organisationName?.data?.data?.domainName}`} <span onClick={() => setOfficialEmailEdit(!officialEmailEdit)} >
                                {/* <span className="material-symbols-outlined text-[14px] icon ">
                                    border_color
                                </span> */}
                            </span></span>
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Reporting Manager*' name='reportingManager' value={values?.reportingManager?.employeeId}>
                                <Select data-testid="report-manager-input" placeholder='report-manger' className='float-control' onChange={(value: any) => handleChange(value, 'reportingManager', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} showSearch value={values?.reportingManager?.employeeId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.reportingManager?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.employeeId} value={option.employeeId}>
                                            {`${option.firstName} ${option.lastName}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FloatLabel>
                            {(showError && !values?.reportingManager?.employeeId) && <ErrorMessage message='Please select the reporting manager' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Employment Status*' name='employeeStatus' value={values?.status?.statusId}>
                                <Select className='float-control' onChange={(value: any) => handleChange(value, 'employeeStatus', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} showSearch value={values?.status?.statusId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.listByCategoryData?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.statusId} value={option.statusId}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FloatLabel>
                            {(showError && !values?.status?.statusId) && <ErrorMessage message='Please select the employee status' />}
                        </div>
                        {/* <div className='form-item'>
                            <FloatLabel label='Initiating Manager *' name='initiatingManager' value={values?.initiationManager?.employeeId}>
                                <Select className='float-control' onChange={(value: any) => handleChange(value, 'initiatingManager', setValues, values, designationList, setDropDownList, dropDownList,officialEmailEdit)} showSearch value={values?.initiationManager?.employeeId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.initiatingManager?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.employeeId} value={option.employeeId}>
                                            {`${option.firstName} ${option.lastName}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FloatLabel>
                            {(showError && !values?.initiationManager?.employeeId) && <ErrorMessage message='Please select Initiation Manager' />}
                        </div> */}
                        <div className='form-item'>
                            <FloatLabel label='Type of Employee*' name='employeeType' value={values?.employeeType?.employeeTypeId}>
                                <Select placeholder="etype-input" className='float-control' onChange={(value: any) => handleChange(value, 'employeeType', setValues, values, designationList, setDropDownList, dropDownList, officialEmailEdit)} showSearch value={values?.employeeType?.employeeTypeId} filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                                    {dropDownList?.employeeTypesData?.data?.data?.map((option: any) => (
                                        <Select.Option key={option.employeeTypeId} value={option.employeeTypeId}>
                                            {option.typeofEmployment}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FloatLabel>
                            {(showError && !values?.employeeType?.employeeTypeId) && <ErrorMessage message='Please select the employee type' />}
                        </div>
                        <div className='form-item'>
                            <FloatLabel label='Date of Joining*' name='doj' value={values?.dateOfJoin ? dayjs(values?.dateOfJoin) : null}>
                                <DatePicker disabledDate={disabledDate} placeholder='dataPick' onChange={onChange} value={values?.dateOfJoin ? dayjs(values?.dateOfJoin) : null} className='float-control' />
                            </FloatLabel>
                            {(showError && values?.dateOfJoin?.length === 0) && <ErrorMessage message='Please select the date of joining' />}
                        </div>
                    </div>
                </div>
            </div>
            {closeConfirmationModal && <CloseModalForm handleCloseModal={handleClosed} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}
        </Modal>
    );
};

export default AddEmployeeModal;
