import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

const DeleteConfirmationModal = (props: any) => {
    const { contentChange = false } = props
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props?.open);
    }, []);

    const handleClose = () => {
        setOpen(false);
        props?.handleCloseDelete();
    };

    return (
        <Modal
            title={`${contentChange ? 'Note' : 'Delete Confirmation'}`}
            centered
            open={open}
            footer={null}
            maskClosable={false}
            onCancel={() => handleClose()}>
            <div className='pt-4'>{props?.deleteMessage}</div>
            <div className='mt-5 flex justify-end '>
                {!contentChange && <button className='addButton mr-4 secondary' onClick={() => { handleClose() }}>Cancel</button>}
                <button className='addButton mr-4 ' onClick={() => {
                    handleClose();
                    if (props.isSingleDelete) {
                        props?.handleSingleSelectDelete(props.selectedRows[0]);
                    } else {
                        props?.handleDeleteSelected();
                    }
                }}  >
                    {`${contentChange ? 'Ok' : 'Delete'}`}
                </button>
            </div>
        </Modal>
    );
};

export default DeleteConfirmationModal;
