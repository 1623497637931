import React, { useState } from 'react';
import { Tabs } from 'antd';
import DepartmentTable from '../../pages/Organization/Department/Department';
import Designation from '../../pages/Organization/Designation/Designation';

const { TabPane } = Tabs;

const CommonTab: React.FC = () => {
    const [activeTabKey, setActiveTabKey] = useState('1');

    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items = [
        {
            key: '1',
            label: 'Departments',
            children: <DepartmentTable />, // Render the Home component
        },
        {
            key: '2',
            label: 'Designation',
            children: <Designation />, // Render the Organization component
        },
    ];

    return (
        // <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        //     {items.map(item => (
        //         <Tabs.TabPane key={item.key} tab={item.label}>
        //             {item.children}
        //         </Tabs.TabPane>
        //     ))}
        // </Tabs>
        <Tabs activeKey={activeTabKey} onChange={handleTabChange} items={items} />
    );
};

export default CommonTab;
