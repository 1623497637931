import React, { useState } from 'react'
import FloatLabel from '../../../components/FloatLabel'
import { Button, Input, Radio, Select } from 'antd'
import { Form, ErrorMessage, Formik } from 'formik';
import { retrieveData, storeData, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import { newEmployeeOtherDetailsValidationSchema } from './NewAddEmployeeSchema';
import ModalLoader from '../../../components/Loader/ModalLoader';
import { useDispatch, useSelector } from 'react-redux';
import { clearEventData } from '../../../redux/slices/empEventsSlice';
import { assetConfig, assetType } from '../../../hooks/commonHooks';
import { handleRequestError } from '../../../layouts/toast/ErrorNotificationMessage';

const ItAssetAquestion = (props: any) => {
  const { nextPage, dropDownList, setDropDownList, handelCancel, setNextPage, designationList, organisationName, imageData, setOfficialEmailEdit, setFormValues, formValues, setIsSubmit, onClosingConfirmationModal } = props;
  const dispatch = useDispatch();
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const employeeCreate = useGetApiRequests('createEmployee', 'POST');
  const [loader, setLoader] = useState(false);
  const [backButton, setBackButton] = useState(false)
  const [specify, setSpecify] = useState(false);
  const [specifyError, setSpecifyError] = useState("");

  const handleChange = (e: any, type: string, setFieldValue: any, values: any) => {
    if (type === 'laptopConfig') {
      setFieldValue('laptopConfig', e)
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        laptopConfig: e,
        specify: values.specify
      }));
    } else if (type === 'deviceType') {
      setFieldValue('deviceType', e)
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        deviceType: e,
        specify: values.specify
      }));
    }
  }
  const handleSelectPeripherals = (value: string[], setFieldValue: any, values: any) => {
    setFieldValue('otherPeripherals', value)
    if (value.includes('Others')) {
      setSpecify(true)
    } else {
      setSpecify(false)
    }
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      otherPeripherals: value,
      specify: values.specify
    }));
  };


  const onCancel = () => {
    handelCancel()
    setNextPage(false);
    setIsSubmit(false);
    storeData('initialEmployeeData', '', true);
  }
  const onGoingBack = () => {
    setNextPage(false)
  }
  const handleDesignationCall = (id: any, designationList: any, setDropDownList: any, dropDownList: any, setFieldValue: any) => {
    const designationParams = {
      departmentId: id,
    };
    designationList('', designationParams)
      .then((res: any) => {
        setDropDownList({
          ...dropDownList,
          designationList: res,
        });
        setFieldValue('designation', '')
      })
      .catch((err: any) => {
        triggerNotification('error', '', err.message, 'topRight');
      });
  };
  const finalApiCall = (payload: any) => {
    const employeeDto = { employeeDto: payload };
    const headers = { 'Content-Type': 'multipart/form-data' };
    employeeCreate(imageData, employeeDto, {}, headers)
      .then((res: any) => {
        dispatch(clearEventData());
        setLoader(false);
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        onClosingConfirmationModal();
        setBackButton(false)
      })
      .catch((err: any) => {
        setLoader(false);
        if (err?.response?.data?.message?.includes('Official Email Already Exists')) {
          setOfficialEmailEdit(true);
          setBackButton(true)
        }
        handleRequestError(err)
      });
  }
  const handleSubmit = (values: any) => {
    setLoader(true)
    if (specify && !values?.specify) {
      setSpecifyError("Please specify the *other peripherals")
    } else {

      let storeOtherPeripherals: any = values?.otherPeripherals || []
      const filteredDevices = storeOtherPeripherals.filter((element: any) => element !== 'Others');
      if (storeOtherPeripherals?.includes('Others')) {
        storeOtherPeripherals = [...filteredDevices, values?.specify]
      } else {
        storeOtherPeripherals = [...filteredDevices]
      }
      const joinOtherPeripherals = storeOtherPeripherals.length === 0 ? "" : storeOtherPeripherals.join(', ')

      const initialEmployeeData = retrieveData('initialEmployeeData', true);
      let payload: any = {
        firstName: initialEmployeeData?.firstName?.charAt(0)?.toUpperCase() + initialEmployeeData?.firstName?.slice(1),
        lastName: initialEmployeeData?.lastName?.charAt(0)?.toUpperCase() + initialEmployeeData?.lastName?.slice(1),
        officialEmail: initialEmployeeData?.officialEmailId + `@${organisationName?.data?.data?.domainName}`,
        dateOfJoin: initialEmployeeData?.dateOfJoin,
        employeeType: { employeeTypeId: initialEmployeeData?.employeeType },
        designation: { designationId: values?.designation },
        department: { departmentId: values?.department },
        laptopConfigType: values?.laptopConfig,
        deviceType: values?.deviceType,
        organisationId: orgId,
        otherPeripherals: joinOtherPeripherals
      };
      finalApiCall(payload)


      // if (specify && values?.specify !== '' && values?.specify === null) {
      //   setLoader(true);
      //   const otherPeripheral: any = values.otherPeripherals;
      //   otherPeripheral.push(values?.specify)
      //   payload.otherPeripherals = otherPeripheral.filter((element: any) => element !== 'Others').toString();
      //   debugger
      // } else {
      //   setLoader(true);
      //   debugger
      //   payload.otherPeripherals = values?.otherPeripherals.filter((element: any) => element !== 'Others').toString();
      //   debugger
      // }
    }
  }
  const handleSpecify = (e: any, setFieldValue: any, values: any) => {
    setFieldValue('specify', e.target.value)
    setSpecifyError("")

  }
  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      // values={initialValues}
      enableReinitialize={true}
      validationSchema={newEmployeeOtherDetailsValidationSchema}
    >
      {({ values, handleSubmit, setFieldValue, errors }) => {
        console.log(errors)
        return (
          <div>
            {nextPage && (
              <Form>

                <div className="w-full flex flex-wrap px-[25px]">
                  <div className="w-1/2 mt-2 mb-6 cursor-pointer ">
                    <div className='form-item mr-10'>
                      <FloatLabel label='Department*' name='department' value={formValues?.department}>
                        <Select suffixIcon={<span className=" text-[#000] material-symbols-outlined cursor-pointer">
                          arrow_drop_down
                        </span>} className='float-control' onChange={(value) => {
                          setFieldValue('department', value);
                          setFieldValue('designation', '')
                          handleDesignationCall(value, designationList, setDropDownList, dropDownList, setFieldValue);
                          setFormValues((prevFormValues: any) => ({
                            ...prevFormValues,
                            department: value,
                            specify: values.specify
                          }));
                          setFormValues((prevFormValues: any) => ({
                            ...prevFormValues,
                            designation: '',
                            specify: values.specify
                          }));
                        }} showSearch value={formValues?.department} filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                          {dropDownList?.departmentListData?.data?.data?.map((option: any) => (
                            <Select.Option key={option.departmentId} value={option.departmentId}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage name="department" component="div" className="error-message" />
                      </FloatLabel>
                    </div>
                  </div>

                  <div className="w-1/2 mt-2 mb-6 cursor-pointer">
                    <div className='form-item '>
                      <FloatLabel label='Designation*' name='designation' value={formValues?.designation}>
                        <Select value={formValues?.designation} suffixIcon={<span className=" text-[#000] material-symbols-outlined">
                          arrow_drop_down
                        </span>} onChange={(value) => {
                          setFieldValue('designation', value);
                          setFormValues((prevFormValues: any) => ({
                            ...prevFormValues,
                            designation: value,
                            specify: values.specify
                          }));
                        }} className='float-control' showSearch filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                          {dropDownList?.designationList?.data?.data?.map((option: any) => (
                            <Select.Option key={option.designationId} value={option.designationId}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage name="designation" component="div" className="error-message" />
                      </FloatLabel>
                    </div>
                  </div>

                  <div className="w-full items-center flex mb-6">
                    <div className="flex w-[200px] justify-between mr-5">
                      <label htmlFor="laptopConfig" className='pr-2 font-rubik  inline-block text-[#000000] text-[15px]'>Configuration Type </label>
                      <span>:</span>
                    </div>
                    <Radio.Group className='font-rubik' name="laptopConfig" options={assetConfig} value={formValues?.laptopConfig} onChange={(e: any) => handleChange(e.target.value, 'laptopConfig', setFieldValue, values)}></Radio.Group>
                  </div>
                  <ErrorMessage name="laptopConfig" component="div" className="error-message mt-[-20px]" />

                  <div className="w-full items-center mb-6 flex">
                    <div className="flex w-[200px] justify-between mr-5">
                      <label htmlFor="deviceType" className='pr-2 font-rubik inline-block text-[#000000] text-[15px]' >Device Type</label>
                      <span>:</span>
                    </div>
                    <Radio.Group className='font-rubik' name="deviceType" options={assetType} value={formValues?.deviceType} onChange={(e: any) => handleChange(e.target.value, 'deviceType', setFieldValue, values)}></Radio.Group>
                  </div>
                  <div>
                    <ErrorMessage name="deviceType" component="div" className="error-message mt-[-20px]" />

                    <div className="w-full items-center flex mb-6 asset-type">
                      <div className="flex w-[200px] justify-between mr-5">
                        <label htmlFor="otherPeripherals" className='pr-2 font-rubik inline-block text-[#000000] text-[15px]' >Other Peripherals</label>
                        <span>:</span>
                      </div>

                      <Select value={formValues?.otherPeripherals} style={{ width: 200 }} onChange={(value: string[]) => handleSelectPeripherals(value, setFieldValue, values)} placeholder="Select Peripherals" className='otherPeripherals' mode='multiple' allowClear>
                        {dropDownList?.peripheralsData?.data?.data?.map((option: any) => (
                          <Select.Option key={option.name} value={option.name}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <ErrorMessage name="otherPeripherals" component="div" className="error-message mt-[-20px]" />
                  </div>
                  {formValues?.otherPeripherals?.includes("Others") && (
                    <div className="w-full">
                      <div className='flex items-center flex-wrap'>
                        <div className="flex w-[200px] justify-between mr-5">
                          <label htmlFor="assetsType" className='pr-2 font-rubik inline-block text-[#000000] text-[15px]'>Please Specify</label>
                          <span>:</span>
                        </div>

                        <Input name='specify' value={values?.specify} onChange={(value: any) => handleSpecify(value, setFieldValue, values)} className='h-11 w-[325px]' />
                        <div className="error-message w-full">{specifyError}</div>
                        {/* <ErrorMessage name="specify" component="div" className="error-message" /> */}
                      </div>
                    </div>)}

                  <div className="flex mt-20 mb-8  justify-end w-full ">
                    {backButton ? (
                      <Button type="primary" shape="round" className='addButton w-[200px]   _deprovision mr-4' onClick={onGoingBack}> Back</Button>
                    ) : (
                      <Button type="primary" shape="round" className='addButton w-[200px]   _deprovision mr-4' onClick={onCancel}> Cancel</Button>

                    )}
                    <button className='addButton w-[200px] deprovision text-[#d23b7d]' type='submit'> Submit</button>
                  </div>
                </div >
              </Form>

            )
            }
            {loader && <ModalLoader />}
          </div >
        )
      }}
    </Formik>
  )
}

export default ItAssetAquestion
