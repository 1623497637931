import React from 'react';
import { ErrorMessage } from 'formik';
import FloatLabel from '../../components/FloatLabel';
import { Input } from 'antd';

const EmergencyContactInfo = ({ values, setFieldValue, setFieldTouched }: any) => (
  <React.Fragment>
    <div className='basic-info-address'>
      <h3 className='my-5 text-2xl font-bold'>Emergency Contact 01</h3>
      <div className="grid grid-cols-3 gap-4">
        <div className='form-item basicForm-fields mb-12' >
          <p>Emergency Contact</p>
          <FloatLabel label='Enter the emergency contact number*' name='employeeDto.emergencyContactNo1' value={values.employeeDto.emergencyContactNo1}>
            <Input
              maxLength={10}
              type="text"
              className='float-control'
              name="employeeDto.emergencyContactNo1"
              value={values.employeeDto.emergencyContactNo1}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo1', false);
                else setFieldTouched('employeeDto.emergencyContactNo1', true)
                setFieldValue('employeeDto.emergencyContactNo1', e.target.value)
              }}
              onBlur={(e) => {
                if (/^\d{10}$/.test(e.target.value))
                  setFieldTouched('employeeDto.emergencyContactNo1', false);
                else setFieldTouched('employeeDto.emergencyContactNo1', true)
              }}
            />
          </FloatLabel>
          <ErrorMessage name="employeeDto.emergencyContactNo1" component="div" className="error-message" />
        </div>
        <div className='form-item basicForm-fields col-span-2 mb-12' >
          <p>Relationship</p>
          <FloatLabel label='Enter the relationship*' name='employeeDto.emergencyContactNo1Relationship' value={values.employeeDto.emergencyContactNo1Relationship}>
            <Input
              type="text"
              className='float-control'
              name="employeeDto.emergencyContactNo1Relationship"
              value={values.employeeDto.emergencyContactNo1Relationship}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^[A-Za-z\s-]+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo1Relationship', false);
                else setFieldTouched('employeeDto.emergencyContactNo1Relationship', true)
                setFieldValue('employeeDto.emergencyContactNo1Relationship', e.target.value)
              }}
            />
          </FloatLabel>
          <ErrorMessage name="employeeDto.emergencyContactNo1Relationship" component="div" className="error-message" />
        </div>
      </div>
    </div>
    <div className='basic-info-address'>
      <h3 className='my-5 text-2xl font-bold'>Emergency Contact 02</h3>
      <div className="grid grid-cols-3 gap-4">
        <div className='form-item basicForm-fields mb-12' >
          <p>Emergency Contact</p>
          <FloatLabel label='Enter the emergency contact number*' name='employeeDto.emergencyContactNo2' value={values.employeeDto.emergencyContactNo2}>
            <Input
              maxLength={10}
              type="text"
              className='float-control'
              name="employeeDto.emergencyContactNo2"
              value={values.employeeDto.emergencyContactNo2}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo2', false);
                else setFieldTouched('employeeDto.emergencyContactNo2', true)
                setFieldValue('employeeDto.emergencyContactNo2', e.target.value)
              }}
              onBlur={(e) => {
                if (/^\d{10}$/.test(e.target.value))
                  setFieldTouched('employeeDto.emergencyContactNo2', false);
                else setFieldTouched('employeeDto.emergencyContactNo2', true)
              }}
            />
          </FloatLabel>
          <ErrorMessage name="employeeDto.emergencyContactNo2" component="div" className="error-message" />
        </div>
        <div className='form-item basicForm-fields col-span-2 mb-12' >
          <p>Relationship</p>
          <FloatLabel label='Enter the relationship*' name='employeeDto.emergencyContactNo2Relationship' value={values.employeeDto.emergencyContactNo2Relationship}>
            <Input
              type="text"
              className='float-control'
              name="employeeDto.emergencyContactNo2Relationship"
              value={values.employeeDto.emergencyContactNo2Relationship}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^[A-Za-z\s-]+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo2Relationship', false);
                else setFieldTouched('employeeDto.emergencyContactNo2Relationship', true)
                setFieldValue('employeeDto.emergencyContactNo2Relationship', e.target.value)
              }}
            />
          </FloatLabel>
          <ErrorMessage name="employeeDto.emergencyContactNo2Relationship" component="div" className="error-message" />
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default EmergencyContactInfo;