const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL;

export const handleGetQuestionById = async ({ setLoader, questionById, currentQuestion, setPreviewImage, currentQuestionTab, setArrangeInOrderState, handleInitialStateValues, setFieldSchema, setInitialValue, setMediaData }: any) => {
    setLoader(true);
    try {
        const response: any = await questionById('', {}, { id: currentQuestion?.questionaireId });
        let payload: any = {};
        if (response?.data?.data?.imagePath !== null) setPreviewImage(`${baseUrl}${response?.data?.data?.imagePath}`);
        if (currentQuestionTab === 3 || currentQuestionTab === 4) {
            payload = {
                questionaireId: response?.data?.data?.questionaireId,
                questionType: response?.data?.data?.questionType,
                question: response?.data?.data?.question,
                correctAnswer: response?.data?.data?.questionaireOptions[0]?.keyword,
            };
        } else if (currentQuestionTab === 1) {
            payload = {
                questionaireId: response?.data?.data?.questionaireId,
                questionType: response?.data?.data?.questionType,
                question: response?.data?.data?.question,
                explanation: response?.data?.data?.answerExplanation ?? '',
            };
            response?.data?.data?.questionaireOptions?.forEach((option: any, index: any) => {
                payload[`optionId${index + 1}`] = option?.questionaireOptionId;
                payload[`option${index + 1}`] = option?.option;
                // payload[`correctAnswer`] = option?.isCorrectAnswer && `option${index + 1}`;
                if (option?.isCorrectAnswer) {
                    payload['correctAnswer'] = `option${index + 1}`;
                }
            });
            console.log('main : ', payload);
        } else if (currentQuestionTab === 2) {
            payload = {
                questionaireId: response?.data?.data?.questionaireId,
                questionType: response?.data?.data?.questionType,
                question: response?.data?.data?.question,
                explanation: response?.data?.data?.answerExplanation ?? '',
                correctAnswer: response?.data?.data?.questionaireOptions[0]?.isCorrectAnswer?.toString()?.toUpperCase(),
            };
        } else if (currentQuestionTab === 6) {
            payload = {
                questionaireId: response?.data?.data?.questionaireId,
                questionType: response?.data?.data?.questionType,
                question: response?.data?.data?.question,
                matchingHeader1: response?.data?.data?.matchingHeader1,
                matchingHeader2: response?.data?.data?.matchingHeader2,
            };
            response?.data?.data?.questionaireOptions?.forEach((option: any, index: any) => {
                const pairKey = `pair${index + 1}`;
                payload[pairKey] = {
                    questionaireOptionId: option?.questionaireOptionId,
                    option: option?.option,
                    optionMatching: option?.optionMatching,
                };
            });
        } else if (currentQuestionTab === 5) {
            payload = {
                questionaireId: response?.data?.data?.questionaireId,
                questionType: response?.data?.data?.questionType,
                question: response?.data?.data?.question,
                questionaireOptions: response?.data?.data?.questionaireOptions,
            };
            const updatedState = response?.data?.data?.questionaireOptions?.map((option: any, index: any) => ({
                imageUrl: `${baseUrl}${option?.option}`,
                selectedFileName: '',
                file: null,
                check: true,
            }));
            setArrangeInOrderState(updatedState);
        }
        handleInitialStateValues(currentQuestionTab, setFieldSchema, setInitialValue, setMediaData, payload);
        setLoader(false);
    } catch (err) {
        setLoader(false);
    }
};
