import React from 'react';
import Dashboard from '../Dashboard/Dashboard';

const Home: React.FC = () => {
    return (
        <div data-testid='dashboard-id'>
            <Dashboard />
        </div>
    );
};

export default Home;
