import { EMPLOYEE_ASSETS } from './employeeAssetsType';
export const initialState: any = [];

export const employeeAssetsStatus = (state = initialState, action: any) => {
    const { type, data } = action;

    if (type === EMPLOYEE_ASSETS) {
        return {
            data,
        };
    } else {
        return state;
    }
};
