import { triggerNotification } from '../../../../../../../common/CommonExport';
import { retrieveData } from '../../../../../../../storage/Storage';

export const emailHandleAppraisalsApiCall = (tempQuestionArrayEmail: any, incrementEligibleEmail: any, id: any, createReviewEmail: any, setChangeCtcModalEmail: any, employeeReviewId: any, employeeApiKey: any, employeeId: any, userId: any, setLoader: any, probationExtension: any) => {
    let requestPayload: any = {};
    let sendingPayload: any = {};
    let questionOptionPayload: any = [];
    setLoader(true);
    let questionKeys: any = Object.keys(tempQuestionArrayEmail);
    questionKeys.forEach((item: any) => {
        let editedKey = item.split('_').join('').toLowerCase();
        if (editedKey === 'appraiserfeedback') {
            tempQuestionArrayEmail &&
                Object.keys(tempQuestionArrayEmail[item]).forEach((data: any) => {
                    questionOptionPayload.push({
                        otherText: tempQuestionArrayEmail[item][data].answer,
                        questionaire: {
                            questionaireId: tempQuestionArrayEmail[item][data].questionaireId,
                        },
                    });
                });
        } else {
            tempQuestionArrayEmail?.[item]?.forEach((data: any) => {
                questionOptionPayload.push({
                    performanceRatingScale: {
                        performanceRatingScaleId: data?.range,
                    },
                    otherText: data?.answer,
                    questionaire: {
                        questionaireId: data?.questionaireId,
                    },
                });
            });
        }
    });
    requestPayload.isEligibleForIncrement = incrementEligibleEmail;
    requestPayload.employeeReviewId = employeeReviewId;
    requestPayload.employeeReviewDetails = questionOptionPayload;
    requestPayload.employee = {
        employeeId: parseInt(employeeId),
    };
    if (retrieveData('reviewtype', true) !== 'Annual' && retrieveData('reviewtype', true) !== 'Half Yearly') {
        const revObj: any = retrieveData('reviewObject', true);
        requestPayload.type = revObj.label;
        if (revObj.label === 'Probation Extension') {
            requestPayload.nextProbationEndDate = probationExtension;
        }
        sendingPayload.status = {
            statusId: parseInt(retrieveData('reviewtype', true)),
        };
    } else {
        requestPayload.type = retrieveData('reviewtype', true);
    }
    sendingPayload.employeeReview = requestPayload;
    sendingPayload.userId = parseInt(userId);
    const queryParams: any = {
        employeeReviewAndDetailsDtoJson: sendingPayload,
        employeeApiKey: employeeApiKey,
    };

    createReviewEmail(queryParams)
        .then((res: any) => {
            setLoader(false);
            triggerNotification('success', '', res?.data?.status?.message, 'topRight');
            setChangeCtcModalEmail(false);
            setTimeout(() => {
                window.close();
            }, 3000);
        })
        .catch((err: any) => {
            setLoader(false);
            triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        });
};
