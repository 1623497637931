import React from "react";
import { Formik, Form } from "formik";
import AddCourse from "../AddCourse";
import { ErrorMessage } from "../../../../common/CommonExport";
import ModalLoader from "../../../../components/Loader/ModalLoader";

const CreateCourseComp = ({ Loader, loader, initialValues, courseSchema, onSubmit, ImageUpload, courseImage,
  handleImageUpload, handleImageDelete, flowDirection, showError, setCourseName }: any) => {
  return (
    <>
      {loader ? <ModalLoader /> :
        <Formik
          initialValues={initialValues}
          validationSchema={courseSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className={`${flowDirection ? "min-h-[490px]" : ""} grid-cols-3 gap-4  flex justify-center w-4/5 pt-20 pb-10 addcourse-main `}>
                <AddCourse values={values} setFieldValue={setFieldValue} setCourseName={setCourseName} />
                <div>
                  <div className="addcourse-field mb-5 pl-6 upload-error-tooltip course-upload">
                    <label className='addcourse-lable pl-2' htmlFor="courseName">Course Image</label>
                    <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete}
                      imageUrl={courseImage}
                      maxFileSize={1024 * 1024 * 3}
                      allowedTypes={['image/jpeg', 'image/png']}
                    />
                    {showError && <ErrorMessage message='Please upload Image' />}
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className='my-5 flex justify-end '>

                  <div className="btnGroup flex justify-end">
                    <button type='submit' className={flowDirection ? 'addButton mt-[10px] primary px-5 confirmElms' : 'addButton mr-[25px] primary px-5 confirmElms'}>{`${flowDirection ? 'Save' : 'Save & Next'}`}</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  )
}

export default CreateCourseComp