import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import { DatePicker, Input, Select } from 'antd';
import FloatLabel from '../../../../../../components/FloatLabel';
import dayjs from 'dayjs';
import { backGroundVerification } from '../../../../../Organization/TableColumn';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';

const PersonalDetailsCompanInfo = ({ dropDownList, values, setFieldValue, setDropDownList, employeeList, orgId, reduxToken, compantDto, setFieldTouched }: any) => {
  const designationList = useGetApiRequests('designationList', 'GET');
  const [updateEmployeeType, setupdateEmployeeType] = useState<boolean>(false)
  const [designation, setDesignation] = useState()
  const handleDesignationCall = (id: any) => {
    const designationParams = { departmentId: id };
    designationList('', designationParams)
      .then((res: any) => {
        setDropDownList({ ...dropDownList, designationList: res });
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  };

  const handleSelect = (value: any, val: any, designationId: any) => {
    setDesignation(designationId)
    if (value === 1) {
      setupdateEmployeeType(true)
      setFieldValue('companyDto.designationDto.designationId', '')
    }
    else {
      setupdateEmployeeType(false)
      setFieldValue('companyDto.designationDto.designationId', designation)
    }
  }
  const handleSelectDesignation = (event: any) => {
    if (event === designation) {
      setupdateEmployeeType(true)
    }
    else {
      setupdateEmployeeType(false)
    }
  }

  return (
    <div className='basic-info-address'>
      <div>
        <h3 className='edit-employee-header' >Company Details</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Department*' name='companyDto.departmentId' value={values?.companyDto?.departmentId}>
              <Select
                className='float-control' showSearch value={values?.companyDto?.departmentId}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => {
                  setFieldValue('companyDto.designationDto.designationId', '')
                  handleDesignationCall(e)
                  setFieldValue('companyDto.departmentId', e)
                }}>
                {dropDownList?.departmentListData?.data?.data?.map((option: any) => (
                  <Select.Option key={option.departmentId} value={option.departmentId}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.departmentId" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Date of Joining*' name='companyDto.dateOfJoin' value={values.companyDto.dateOfJoin ? dayjs(values.companyDto.dateOfJoin) : null}>
              <DatePicker placeholder='' className='float-control' name="companyDto.dateOfJoin"
                value={values.companyDto.dateOfJoin ? dayjs(values.companyDto.dateOfJoin) : null}
                onChange={(date, dateString) => setFieldValue('companyDto.dateOfJoin', dateString)}
              />
              {values.companyDto.dateOfJoin && <p>YYYY/MM/DD</p>}
            </FloatLabel>
            <ErrorMessage name="companyDto.dateOfJoin" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Official Email ID' name='companyDto.officialEmailId' value={values.companyDto.officialEmailId}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.officialEmailId" value={values.companyDto.officialEmailId}
              />
            </FloatLabel>
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Reporting Manager*' name='companyDto.reportingManagerId' value={values?.companyDto?.reportingManagerId}>
              <Select className='float-control' showSearch value={values?.companyDto?.reportingManagerId}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => setFieldValue('companyDto.reportingManagerId', e)}>
                {dropDownList?.reportingManager?.data?.data?.map((option: any) => (
                  <Select.Option key={option.employeeId} value={option.employeeId}>
                    {`${option.firstName} ${option.lastName}`}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.reportingManagerId" component="div" className="error-message" />
          </div>

          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Designation*' name='companyDto.designationDto.designationId' value={values?.companyDto?.designationDto?.designationId}>
              <Select className='float-control' showSearch value={values?.companyDto?.designationDto?.designationId}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => setFieldValue('companyDto.designationDto.designationId', e)} onSelect={(e) => handleSelectDesignation(e)}>
                {dropDownList?.designationList?.data?.data?.map((option: any) => (
                  <Select.Option key={option.designationId} value={option.designationId}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.designationDto.designationId" component="div" className="error-message" />
          </div>
          {values?.companyDto?.preDesignationDto?.preDesignationId !== values?.companyDto?.designationDto?.designationId && (
            <div className='form-item edit-personal-details ' >
              <FloatLabel label='Redesignation date*' name='companyDto.designationEffectiveDate' value={values.companyDto.designationEffectiveDate ? dayjs(values.companyDto.designationEffectiveDate) : null}>
                <DatePicker placeholder='' className='float-control' name="companyDto.designationEffectiveDate"
                  value={values.companyDto.designationEffectiveDate ? dayjs(values.companyDto.designationEffectiveDate) : null}
                  onChange={(date, dateString) => setFieldValue('companyDto.designationEffectiveDate', dateString)}
                />
                {values.companyDto.designationEffectiveDate && <p>YYYY/MM/DD</p>}
              </FloatLabel>
              <ErrorMessage name="companyDto.designationEffectiveDate" component="div" className="error-message" />
            </div>
          )}
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Status*' name='companyDto.statusDto.statusId' value={values?.companyDto?.statusDto.statusId}>
              <Select className='float-control' showSearch value={values?.companyDto?.statusDto.statusId}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => setFieldValue('companyDto.statusDto.statusId', e)}>
                {dropDownList?.listByCategoryData?.data?.data?.map((option: any) => (
                  <Select.Option key={option.statusId} value={option.statusId}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.statusDto.statusId" component="div" className="error-message" />
          </div>
          {values?.companyDto?.statusDto?.statusId === 31 && (
            <div className='form-item edit-personal-details ' >
              <FloatLabel label='Probation Extension Date*' name='companyDto.nextProbationEndDate' value={values.companyDto.nextProbationEndDate ? dayjs(values.companyDto.nextProbationEndDate) : null}>
                <DatePicker placeholder='' className='float-control' name="companyDto.nextProbationEndDate"
                  value={values.companyDto.nextProbationEndDate ? dayjs(values.companyDto.nextProbationEndDate) : null}
                  onChange={(date, dateString) => setFieldValue('companyDto.nextProbationEndDate', dateString)}
                />
              </FloatLabel>
              {values.companyDto.nextProbationEndDate && <p>YYYY/MM/DD</p>}
              <ErrorMessage name="companyDto.nextProbationEndDate" component="div" className="error-message" />
            </div>
          )}
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Background Verification Status*' name='companyDto.backGroundVerification' value={values?.companyDto?.backGroundVerification}>
              <Select className='float-control' showSearch value={values?.companyDto?.backGroundVerification}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => setFieldValue('companyDto.backGroundVerification', e)}>
                {backGroundVerification?.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.backGroundVerification" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <ErrorMessage name="companyDto.backGroundVerification" component="div" className="error-message" />
            <FloatLabel label='Grade' name='companyDto.grade' value={values.companyDto.grade}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.grade" value={values.companyDto.grade}
              />
            </FloatLabel>
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Type of employment*' name='companyDto.employeeTypeId' value={values?.companyDto?.employeeTypeId}>
              <Select className='float-control' showSearch value={values?.companyDto?.employeeTypeId}
                filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => setFieldValue('companyDto.employeeTypeId', e)} onSelect={(e) => handleSelect(e, 'companyDto.designationDto.designationId', values?.companyDto?.designationDto?.designationId)}>
                {dropDownList?.employeeTypesData?.data?.data?.map((option: any) => (
                  <Select.Option key={option.employeeTypeId} value={option.employeeTypeId}  >
                    {option.typeofEmployment}
                  </Select.Option>
                ))}
              </Select>
            </FloatLabel>
            <ErrorMessage name="companyDto.employeeTypeId" component="div" className="error-message" />
            {updateEmployeeType &&
              <div className="update-warning flex">
                <p><span className="material-symbols-outlined pr-2">
                  info
                </span></p>
                <p>If you change the status to 'regular' you will also need to update the designation</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default PersonalDetailsCompanInfo;
