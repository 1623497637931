export const leaveRequestColumn = [
    {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        key: 'employeeName',
    },
    {
        title: 'From ',
        dataIndex: 'fromDate',
        key: 'fromDate',
    },
    {
        title: 'To ',
        dataIndex: 'toDate',
        key: 'toDate',
    },
    {
        title: 'Duration ',
        dataIndex: 'duration',
        key: 'duration',
    },
];
