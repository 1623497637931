import React, { useEffect, useState } from 'react'
import { Collapse, Modal, Select, Input } from 'antd';
import AppraisalForm from './AppraisalForm';
import { ErrorMessage, ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { ratings } from './AppraisalFormValidationSchema';
import TextAreaRendering from './TextAreaRendering';
import { handleAppraisalsApiCall } from './AppraisalModalHelper';
import AppraisalButtonRendering from './AppraisalButtonRendering';
import Loader from '../../../../../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import CloseModalForm from './EmailAppraisal/CloseModalForm';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';


const { TextArea } = Input;
const { Panel } = Collapse;
const CustomCollapse = ({ isActive }: any) => (
  <div className="custom-collapse-icon">
    {isActive ? <MinusOutlined /> : <PlusOutlined />}
  </div>
);
const AppraisalAssesment = (props: any) => {
  const { nextButton, questionsArray, functionalAttributes, keyPerformance, organisationAttributes, personalAttributes, salaryRevised, incrementEligible, probationExtension, handleCloseModal, getReviewsApi, isView, appraiserFeedback, ctcDetails, isPending, employeeReviewId } = props;
  const [tempQuestionArray, setTempQuestionArray] = useState<any>();
  const [changeCtcModal, setChangeCtcModal] = useState(false);
  const [keyPerformanceErrors, setKeyPerformanceErrors] = useState<any>([]);
  const [personalErrors, setPersonalErrors] = useState<any>([]);
  const [functionalErrors, setFunctionalErrors] = useState<any>([]);
  const [organisationErrors, setOrganisationErrors] = useState<any>([]);
  const [significantAchievementsError, setSignificantAchievementsError] = useState(false);
  const [improvementAreasError, setImprovementAreasError] = useState(false);
  const [technicalSkillsError, setTechnicalSkillsError] = useState(false);
  const [softSkillsError, setSoftSkillsError] = useState(false);
  const [appraiserCommentsError, setAppraiserCommentsError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [loader, setLoader] = useState(false);
  const [significantachievements, setSignificantachievements] = useState('');
  const [improvementareas, setImprovementareas] = useState('');
  const [technicalskills, setTechnicalskills] = useState('');
  const [softskills, setSoftskills] = useState('')
  const [appraisercommentsNew, setAppraisercommentsNew] = useState('');
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const reviewtype: any = retrieveData('reviewtype', true)
  const createReview = useGetApiRequests('createReviewAppraisal', 'POST')
  const updateReview = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const userId = useSelector((state: any) => state?.userId?.id);

  const { id }: any = useParams();

  const handelSaveRevise = () => {
    let keyperformanceFlag: any
    const hasTrueValue: any = [...keyPerformanceErrors, ...personalErrors, ...functionalErrors, ...organisationErrors,].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArray.personalattributes.some((item: any) => item.range === null);
    if (tempQuestionArray.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArray.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }
    const functionalattributesFlag: any = tempQuestionArray.functionalattributes.some((item: any) => item.range === null);
    const organisationalattributesFlag: any = tempQuestionArray.organisationalattributes.some((item: any) => item.range === null);
    const hasEmptyAnswer = Object.values(tempQuestionArray.appraiserfeedback).some((feedbackItem: any) => feedbackItem.answer === "");

    if (!hasTrueValue && personalattributesFlag === false && keyperformanceFlag === false && functionalattributesFlag === false && organisationalattributesFlag === false) {
      if (!significantAchievementsError && !improvementAreasError && !technicalSkillsError && !softSkillsError && !appraiserCommentsError && !hasEmptyAnswer && reviewtype !== '' && reviewtype !== null && reviewtype !== undefined) {
        handleAppraisalsApiCall(tempQuestionArray, incrementEligible, id, createReview, handleCloseModal, setChangeCtcModal, getReviewsApi, setLoader, probationExtension, isView, updateReview, employeeReviewId, userId);
      } else {
        triggerNotification('warning', '', ErrorMessages.mandatoryValues, 'topRight');
      }
    } else {
      triggerNotification('warning', '', ErrorMessages.ratingValue, 'topRight')
    }
  };

  const selectRating = (index: number, val: string, event: any) => {
    if ((val === 'keyperformance' && keyPerformance) || (val === 'personalattributes' && personalAttributes) || (val === 'functionalattributes' && functionalAttributes) || (val === 'organisationalattributes' && organisationAttributes)) {
      const updatedQuestions = { ...tempQuestionArray };
      const attributeType = val
      const currentAttribute = updatedQuestions[attributeType][index];
      currentAttribute.ansFlag = true;
      currentAttribute.answer = '';
      currentAttribute.commentFlag = event === 1 || event === 2;
      currentAttribute.range = event;
      updatedQuestions[attributeType] = updatedQuestions[attributeType].slice();

      setTempQuestionArray(updatedQuestions);

      const stateMap = {
        keyperformance: { state: keyPerformanceErrors, setter: setKeyPerformanceErrors },
        personalattributes: { state: personalErrors, setter: setPersonalErrors },
        functionalattributes: { state: functionalErrors, setter: setFunctionalErrors },
        organisationalattributes: { state: organisationErrors, setter: setOrganisationErrors },
      };
      const selectedState = stateMap[val];
      if (selectedState) {
        const { state, setter } = selectedState;
        const updatedErrors = [...state];
        updatedErrors[index] = event === 1 || event === 2;
        setter(updatedErrors);
      }
      setTouched(true);
    }
  };

  const handleTextArea = (event: any, type: string, index: number) => {
    const updatedQuestions = { ...tempQuestionArray };
    const currentQuestion = updatedQuestions[type][index];
    if (event.target.value !== '') {
      currentQuestion.answer = event.target.value;
      currentQuestion.commentFlag = false;
      if (type === 'keyperformance' && keyPerformanceErrors[index]) {
        const updatedErrors = [...keyPerformanceErrors];
        updatedErrors[index] = false;
        setKeyPerformanceErrors(updatedErrors);
      } else if (type === 'personalattributes' && personalErrors[index]) {
        const updatedErrors = [...personalErrors];
        updatedErrors[index] = false;
        setPersonalErrors(updatedErrors);
      } else if (type === 'functionalattributes' && functionalErrors[index]) {
        const updatedErrors = [...functionalErrors];
        updatedErrors[index] = false;
        setFunctionalErrors(updatedErrors);
      } else if (type === 'organisationalattributes' && organisationErrors[index]) {
        const updatedErrors = [...organisationErrors];
        updatedErrors[index] = false;
        setOrganisationErrors(updatedErrors);
      }
    } else if (currentQuestion.range === 1 || currentQuestion.range === 2) {
      currentQuestion.answer = event.target.value;
      const errorTypes: any = {
        keyperformance: { errors: keyPerformanceErrors, setter: setKeyPerformanceErrors },
        personalattributes: { errors: personalErrors, setter: setPersonalErrors },
        functionalattributes: { errors: functionalErrors, setter: setFunctionalErrors },
        organisationalattributes: { errors: organisationErrors, setter: setOrganisationErrors },
      };

      const errorType = errorTypes[type];
      if (errorType) {
        const updatedErrors = [...errorType.errors];
        updatedErrors[index] = true;
        errorType.setter(updatedErrors);
      }
      currentQuestion.commentFlag = true;

    } else {
      currentQuestion.answer = event.target.value;
      currentQuestion.commentFlag = false;
    }

    setTempQuestionArray(updatedQuestions);
  };
  const handleAppraisals = (event: any, name: string, type: string) => {
    const updatedQuestions = { ...tempQuestionArray };
    const inputValue = event.target.value;
    if (name === 'appraiserfeedback') {
      updatedQuestions[name][type].answer = inputValue;
    }
    setTempQuestionArray(updatedQuestions);
    switch (type) {
      case "significantachievements":
        setSignificantachievements(inputValue)
        setSignificantAchievementsError(inputValue.trim() === "");
        break;
      case "improvementareas":
        setImprovementareas(inputValue)
        setImprovementAreasError(inputValue.trim() === "");
        break;
      case "technicalskills":
        setTechnicalskills(inputValue)
        setTechnicalSkillsError(inputValue.trim() === "");
        break;
      case "softskills":
        setSoftskills(inputValue)
        setSoftSkillsError(inputValue.trim() === "");
        break;
      case "appraisercomments":
        setAppraisercommentsNew(inputValue)
        setAppraiserCommentsError(inputValue.trim() === "");
        break;
      default:
        break;
    }
    setTouched(true);
  }
  useEffect(() => {
    if (questionsArray !== undefined && Object.keys(questionsArray).length > 0)
      setTempQuestionArray(questionsArray)
  }, []);
  const openChangeSalaryModal = () => {
    setChangeCtcModal(true)

  }
  const closeChangeSalary = () => {
    if (isView === true && isPending === false) {
      setChangeCtcModal(!changeCtcModal);
    } else {
      setCloseConfirmationModal(true)
    }
  };

  const handleClosed = () => {
    setChangeCtcModal(!changeCtcModal);
    setCloseConfirmationModal(false)
  }
  const handelChangeSalary = () => {
    let keyperformanceFlag: any
    const trueValue: any = [...keyPerformanceErrors, ...personalErrors, ...functionalErrors, ...organisationErrors,].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArray.personalattributes.some((item: any) => item.range === null);
    if (tempQuestionArray.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArray.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }
    const functionalattributesFlag: any = tempQuestionArray.functionalattributes.some((item: any) => item.range === null);
    const organisationalattributesFlag: any = tempQuestionArray.organisationalattributes.some((item: any) => item.range === null);
    const feedbacks: any = Object.values(tempQuestionArray.appraiserfeedback).some((feedbackItem: any) => feedbackItem.answer === "");
    if (!trueValue && personalattributesFlag === false && keyperformanceFlag === false && functionalattributesFlag === false && organisationalattributesFlag === false) {
      if (!significantAchievementsError && !improvementAreasError && !technicalSkillsError && !softSkillsError && !appraiserCommentsError && !feedbacks && reviewtype !== '' && reviewtype !== null && reviewtype !== undefined) {
        setChangeCtcModal(true)
      } else {
        triggerNotification('warning', '', ErrorMessages.mandatoryValues, 'topRight');
      }
    } else {
      triggerNotification('warning', '', ErrorMessages.ratingValue, 'topRight')
    }
  }
  const getQuestionToShow = (item: any, isView: any, isPending: any) => {
    if (!isView) {
      return item?.question;
    } else if (isView && isPending) {
      return item?.question;
    } else {
      return item?.questionaire?.question;
    }
  }
  return (
    <>
      {loader && <ModalLoader />}
      <div className="general-assessment">
        {keyPerformance && keyPerformance.length > 0 && keyPerformance !== undefined && (
          <div>
            <h3 className='general-assessment-header' data-testid='general-assessment-id'>General Assessment</h3>
            <h4 className='general-assessment-subtitle py-4'>Key Performance</h4>
            {keyPerformance.map((item: any, index: number) => (
              <Collapse key={item.questionaireId} className='my-2'
                size="small" expandIconPosition={"end"} >
                {keyPerformanceErrors[index] && <ErrorMessage message='Comment is required*' />}
                <Panel header={<div className='flex justify-between key-questions items-center'>
                  {/* <p className='question-title'>{!isView ? item?.question : item?.questionaire.question}</p> */}
                  <p className='question-title'>{getQuestionToShow(item, isView, isPending)}</p>
                  <div className="form-item">
                    <Select
                      disabled={!!(isView === true && isPending === false)}
                      placeholder="Select your rating"
                      options={ratings}
                      onChange={(e) => selectRating(index, "keyperformance", e)}
                      value={isView && !isPending ? ratings.filter((element: any) => element.value === item.performanceRatingScale.performanceRatingScaleId) : tempQuestionArray['keyperformance'][index].range}
                    />
                  </div>
                </div>} key="1">
                  <TextArea disabled={!!(isView === true && isPending === false)} rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "keyperformance", index) }} value={isView && !isPending ? item.otherText : tempQuestionArray['keyperformance'][index].answer} />
                </Panel>
              </Collapse>))}
          </div>
        )}
        {personalAttributes && personalAttributes.length > 0 && questionsArray !== undefined && (
          <div>
            <h3 className='general-assessment-header pt-4'>Competency Assessment</h3>
            <h4 className='general-assessment-subtitle py-4' >Personal Attributes</h4>
            {personalAttributes.map((item: any, index: number) => (
              <Collapse key={item.questionaireId} className='my-2'
                size="small" expandIconPosition={"end"} >
                {personalErrors[index] && <ErrorMessage message='Comment is required*' />}
                <Panel header={<div className='flex justify-between key-questions items-center'>
                  {/* <p className='question-title'>{!isView ? item?.question : item?.questionaire?.question}</p> */}
                  <p className='question-title'>{!isView ? item?.question : (isView && isPending) ? item?.question : item?.questionaire?.question}</p>
                  <div className="form-item">
                    <Select
                      disabled={!!(isView === true && isPending === false)}
                      placeholder="Select your rating"
                      options={ratings}
                      onChange={(e) => selectRating(index, "personalattributes", e)}
                      value={isView && !isPending ? ratings.filter((element: any) => element.value === item.performanceRatingScale.performanceRatingScaleId) : tempQuestionArray['personalattributes'][index].range}
                    />
                  </div>
                </div>} key="2">
                  <TextArea disabled={!!(isView === true && isPending === false)} rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "personalattributes", index) }} value={isView && !isPending ? item.otherText : tempQuestionArray['personalattributes'][index].answer} />
                </Panel>
              </Collapse>))}

          </div>
        )}
        {functionalAttributes && functionalAttributes.length > 0 && questionsArray !== undefined && (
          <div>
            <h3 className='general-assessment-subtitle py-4'>Functional Attributes</h3>
            {functionalAttributes.map((item: any, index: any) => (
              <Collapse key={item.questionaireId} className='my-2'
                size="small" expandIconPosition={"end"} >
                {functionalErrors[index] && <ErrorMessage message='Comment is required*' />}
                <Panel header={<div className='flex justify-between key-questions items-center'>
                  {/* <p className='question-title'>{!isView ? item?.question : item?.questionaire?.question}</p> */}
                  <p className='question-title'>{!isView ? item?.question : (isView && isPending) ? item?.question : item?.questionaire?.question}</p>
                  <div className="form-item">
                    <Select
                      disabled={!!(isView === true && isPending === false)}
                      placeholder="Select your rating"
                      options={ratings}
                      onChange={(e) => selectRating(index, "functionalattributes", e)}
                      value={isView && !isPending ? ratings.filter((element: any) => element.value === item.performanceRatingScale.performanceRatingScaleId) : tempQuestionArray['functionalattributes'][index].range}
                    />
                  </div>
                </div>} key="3">
                  <TextArea disabled={!!(isView === true && isPending === false)} rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "functionalattributes", index) }} value={isView && !isPending ? item.otherText : tempQuestionArray['functionalattributes'][index].answer} />
                </Panel>
              </Collapse>))}
          </div>
        )}
        {organisationAttributes && organisationAttributes.length > 0 && questionsArray !== undefined && (
          <div>
            <h4 className='general-assessment-subtitle py-4' >Organisation Attributes</h4>
            {organisationAttributes.map((item: any, index: any) => (
              <Collapse
                key={item.questionaireId} className='my-2'
                size="small" expandIconPosition={"end"}>
                {organisationErrors[index] && <ErrorMessage message='Comment is required*' />}
                <Panel header={<div className='flex justify-between key-questions items-center'>
                  {/* <p className='question-title'>{!isView ? item?.question : item?.questionaire?.question}</p> */}
                  <p className='question-title'>{!isView ? item?.question : (isView && isPending) ? item?.question : item?.questionaire?.question}</p>
                  <div className="form-item">
                    <Select
                      disabled={!!(isView === true && isPending === false)}
                      placeholder="Select your rating"
                      options={ratings}
                      onChange={(e) => selectRating(index, "organisationalattributes", e)}
                      value={isView && !isPending ? ratings.filter((element: any) => element.value === item.performanceRatingScale.performanceRatingScaleId) : tempQuestionArray['organisationalattributes'][index].range}
                    />
                  </div>
                </div>} key="4">
                  <TextArea disabled={!!(isView === true && isPending === false)} rows={2} style={{ resize: 'none' }} placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "organisationalattributes", index) }} value={isView && !isPending ? item.otherText : tempQuestionArray['organisationalattributes'][index].answer} />
                </Panel>
              </Collapse>))}
          </div>
        )}
        <TextAreaRendering significantachievements={significantachievements} improvementareas={improvementareas} technicalskills={technicalskills} softskills={softskills} appraisercommentsNew={appraisercommentsNew} isView={isView} isPending={isPending} appraiserFeedback={appraiserFeedback} handleAppraisals={handleAppraisals} significantAchievementsError={significantAchievementsError} improvementAreasError={improvementAreasError} technicalSkillsError={technicalSkillsError} softSkillsError={softSkillsError} appraiserCommentsError={appraiserCommentsError} />
        {isView && !isPending && (
          <div data-testid='button-id'>
            {nextButton && (
              <div className='flex justify-end mr-5 salary_add-btn'>
                <button className='addButton ' onClick={() => openChangeSalaryModal()}>
                  Next
                </button>
              </div>)}
          </div>
        )}
        <AppraisalButtonRendering incrementEligible={incrementEligible} touched={touched} handelSaveRevise={handelSaveRevise} handelChangeSalary={handelChangeSalary} />
        {changeCtcModal && (
          <Modal title='Salary Increment' centered open={changeCtcModal} onCancel={closeChangeSalary} footer={false} maskClosable={false}>
            <AppraisalForm isView={isView} ctcDetails={ctcDetails} handleCloseModal={handleCloseModal} setChangeCtcModal={setChangeCtcModal} questionObject={tempQuestionArray} changeCtcModal={changeCtcModal} reviewType={reviewtype !== undefined && reviewtype} salaryRevised={salaryRevised} probationExtension={probationExtension} incrementEligible={incrementEligible} getReviewsApi={getReviewsApi} isPending={isPending} employeeReviewId={employeeReviewId} userId={userId} />
          </Modal>
        )}
      </div>
      {closeConfirmationModal && <CloseModalForm handleCloseModal={handleClosed} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}

    </>
  )
}
export default AppraisalAssesment
