import React, { useEffect, useState } from 'react';
import { Switch, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { clearQuizChapterMapPayload } from '../../../../redux/slices/currentCourseDetailSlics';
import Loader from '../../../../components/Loader/Loader';
import { setChapterLoad } from '../../../../redux/slices/updateProfileSlice';
import AssignChapterAndCourseFooter from './AssignChapterAndCourseFooter';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const PassCriteria = ({ handleTabChange, handle }: any) => {
  const dispatch = useDispatch()
  const updateQuizById = useGetApiRequests('quizByQuizId', 'PUT');
  const chapterUpdateByCourseId = useGetApiRequests('updateQuizInChapters', 'POST');
  const chapterListAll = useGetApiRequests('chapterListByCourseId', 'GET');
  const quizUpdateByCourseId = useGetApiRequests('chapterListByCourseId', 'PUT');
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const finalAssessmentInCourse = useSelector((state: any) => state?.currentCourse?.finalAssessmentInCourse);
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const currentAssessmentTab = useSelector((state: any) => state?.currentCourse?.currentAssessmentTab);
  const assessmentChapter = useSelector((state: any) => state?.currentCourse?.assessmentAssignChapterID);
  const questionPayload = useSelector((state: any) => state?.currentCourse?.quizChapterMapPayload);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const [values, setValues] = useState<any>({
    questionSet: 0,
    noOfAttempt: 0,
    noOfQuestionsToBePass: true,
    noOfAttempts: true
  })
  const [loader, setLoader] = useState<any>(false)

  const onChange = (checked: boolean, key: any, pass: any) => {
    let passValue = 0;
    if (key === 'noOfAttempt') passValue = checked ? 0 : 1;
    else if (key === 'questionSet') passValue = checked ? 1 : 0;
    setValues({ ...values, [key]: checked, [pass]: passValue });
  };

  useEffect(() => {
    setValues({
      questionSet: assessmentChapter?.quiz?.noOfQuestionsToBePass,
      noOfAttempt: assessmentChapter?.quiz?.noOfAttempts,
      noOfQuestionsToBePass: assessmentChapter?.quiz?.noOfQuestionsToBePass > 0,
      noOfAttempts: assessmentChapter?.quiz?.noOfAttempts === 0
    });
  }, [assessmentChapter])

  const assignQuizIdToChapterAndCourse = (payload: any) => {
    let queryParams = {}
    //  queryParams = { loggedInUserId: loggedInUser?.userId }
    const pathParams = { id: currentCourseId }
    quizUpdateByCourseId(payload, queryParams, pathParams)
      .then((res: any) => {
        setLoader(false)
        triggerNotification('success', '', res?.data?.message, 'topRight');
        if (!flowDirection)
          dispatch((setCurrentTab(4)))
        else if (flowDirection) {
          dispatch(setChapterLoad())
          handle()
        }
        dispatch((clearQuizChapterMapPayload()))
        handleTabChange(currentAssessmentTab)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const getChapterListBuCourseId = (quizId: any) => {
    const pathParams = { id: currentCourseId }
    chapterListAll('', {}, pathParams)
      .then((response: any) => {
        const payload = response?.data?.data?.map((item: any, index: any) => ({
          course: { courseId: currentCourseId },
          ...(item?.chapter?.chapterId && { chapter: { chapterId: item?.chapter?.chapterId } }),
          displaySequence: item?.displaySequence,
          ...(item?.quiz !== null ? { quiz: { quizId: item?.quiz?.quizId } } :
            (item?.chapter?.chapterId === assessmentChapter?.chapterId && { quiz: { quizId } })),
        }));
        if (currentAssessmentTab === '2') {
          const finalQuizData = {
            course: { courseId: currentCourseId },
            displaySequence: 0,
            quiz: { quizId: quizId }
          }
          payload.push(finalQuizData)
        }
        assignQuizIdToChapterAndCourse(payload)
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleAddQuizData = (payload: any) => {
    setLoader(true)
    let queryParams = {}
    // queryParams = { loggedInUserId: loggedInUser?.userId }
    chapterUpdateByCourseId(payload, queryParams)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        getChapterListBuCourseId(res?.data?.data?.quizId)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const handleEditQuizData = (payload: any) => {
    setLoader(true)
    let queryParams = {}
    // queryParams = { loggedInUserId: loggedInUser?.userId };
    const pathParams = { id: assessmentChapter?.quiz?.quizId };
    updateQuizById(payload, queryParams, pathParams)
      .then((response: any) => {
        setLoader(false)
        if (!flowDirection)
          dispatch((setCurrentTab(4)))
        else if (flowDirection) {
          dispatch(setChapterLoad())
          handle()
        }
        dispatch((clearQuizChapterMapPayload()))
        triggerNotification('success', '', response?.data?.message, 'topRight');
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  const handleSwitchToNextSection = () => {
    const payload = { ...questionPayload }
    payload['noOfAttempts'] = parseInt(values?.noOfAttempt)
    payload['noOfQuestionsToBePass'] = parseInt(values?.questionSet)
    if (currentAssessmentTab === '1') {
      if (assessmentChapter?.quiz === null) {
        handleAddQuizData(payload)
      } else
        handleEditQuizData(payload)
    } else if (currentAssessmentTab === '2') {
      if (assessmentChapter === null) {
        handleAddQuizData(payload)
      } else
        handleEditQuizData(payload)
    }
  }
  const handleBack = () => {
    if (!finalAssessmentInCourse) {
      if (!flowDirection)
        dispatch((setCurrentTab(4)))
      else if (flowDirection)
        handleTabChange('1')
    } else if (finalAssessmentInCourse) {
      handleTabChange('1')
    }
  }
  return (
    <div className="chapter-criteria h-full p-12  bg-[#EFEFEF]">
      {loader && <ModalLoader />}
      <div className="chapter-criteria-container w-full flex pb-4">
        <div className="w-1/3 chapter-assesment-list flex items-center">
          <p className='text-[#737373] text-lg'>No.of Questions in Assessment</p>
        </div>
        <div className="w-3/4">
          <div className="chapter-assesment-count flex  items-center justify-center">
            <p data-testid="para" className='text-[#2C2C2C] text-xl '>{questionPayload?.questionaires?.length ?? assessmentChapter?.quiz?.questionaires?.length}</p>
          </div>
        </div>
      </div>
      <div className="chapter-toggle  ">
        <label htmlFor="" className='text-[#737373] text-lg pr-2'>Pass Criteria</label>
        <Switch checked={values?.noOfQuestionsToBePass} onChange={(event) => onChange(event, 'noOfQuestionsToBePass', 'questionSet')} />
      </div>
      <div className="chapter-criteria-container w-full flex pb-4 ">
        <div className="w-1/3 chapter-assesment-list flex items-center">
          <p className='text-[#737373] text-lg'>No. of Questions to be Set</p>
        </div>
        <div className="w-3/4">
          <div className="chapter-assesment-count flex justify-center items-center">
            <Input data-testid="antInput" value={values?.questionSet}
              disabled={!values?.noOfQuestionsToBePass}
              onChange={(event) => {
                const inputValue: any = event.target.value;
                const sanitizedValue = inputValue.replace(/^0+/, '');

                if (!isNaN(sanitizedValue) && sanitizedValue <= questionPayload?.questionaires?.length) {
                  setValues({ ...values, questionSet: sanitizedValue });
                }
              }}
              className='text-[#2C2C2C] text-xl pl-[50px]' />
          </div>
        </div>
      </div>
      <div className="chapter-toggle  ">
        <label htmlFor="" className='text-[#737373] text-lg pr-2'>Unlimited Attempts</label>
        <Switch checked={values?.noOfAttempts} onChange={(event) => onChange(event, 'noOfAttempts', 'noOfAttempt')} />
      </div>
      <div className="chapter-criteria-container w-full flex ">
        <div className="w-1/3 chapter-assesment-list flex items-center">
          <p className='text-[#737373] text-lg'>No. of attempts</p>
        </div>
        <div className="w-3/4">
          <div className="chapter-assesment-count flex justify-center items-center">
            <Input value={values?.noOfAttempt}
              disabled={values?.noOfAttempts}
              onChange={(event) => {
                const inputValue: any = event.target.value;
                const sanitizedValue = inputValue.replace(/^0+/, '');
                if (!isNaN(sanitizedValue) && sanitizedValue <= 30) {
                  setValues({ ...values, noOfAttempt: sanitizedValue });
                }
              }}
              className='text-[#2C2C2C] text-xl pl-[50px]' />
          </div>
        </div>
      </div>
      <div className="footer">
        <AssignChapterAndCourseFooter handleBack={handleBack} handleSwitchToNextSection={handleSwitchToNextSection} />
      </div>
    </div>

  )
}

export default PassCriteria