import React from 'react'
import { Field, Formik } from 'formik';
import { Form } from 'antd';

const AddCertificateDB = () => {
  const sampleNames = ["Sample Name 01", "Sample Name 02", "Sample Name 03"];

  return (
    <>
      <div className="flex w-full bg-[#F1F1F1] px-7 py-5 mb-5">
        <div className="w-1/2">
          <p className='text-[#D34A7C] text-[18px] font-medium pb-4'>DB DATA (Automatically Generated)</p>
          <ul>
            {sampleNames.map((name, index) => (
              <li key={index} className='text-[#434343] font-rubik font-medium pt-4 pb-4'> <span className='pr-4'>{String(index + 1).padStart(2, '0')}</span>{name}</li>
            ))}
          </ul>
        </div>
        <div className="w-1/2">
          <p className='text-[#D34A7C] text-[18px] font-medium pb-4'>DB DATA (Automatically Generated)</p>
          <Formik
            initialValues={{
              firstName: '',
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            <Form>
              <div className="chapter-field mb-5">
                <Field as="select" name="description" className="w-full h-[40px] rounded-md px-2">
                  <option value="red">Red</option>
                </Field>
              </div>
              <div className="chapter-field mb-5">
                <Field as="select" name="description" className="w-full h-[40px] rounded-md px-2">
                  <option value="red">Red</option>
                </Field>
              </div> <div className="chapter-field mb-5">
                <Field as="select" name="description" className="w-full h-[40px] rounded-md px-2">
                  <option value="red">Red</option>
                </Field>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="flex justify-end">
        <button className='addButton popup  cancelElms px-5 mr-4  '  >Back </button>
        <button className='addButton popup  primary px-5 p-0  '  >Save </button>
      </div>

    </>
  )
}

export default AddCertificateDB