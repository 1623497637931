import { apiResponseMessage } from '../../constants/apiResponseMessage';
import { triggerNotification } from './ToastBar';

export const handleNotFoundError = (errorMessage: string): void => {
    triggerNotification('error', '', errorMessage, 'topRight');
};
export const handleDefaultError = (): void => {
    triggerNotification('error', '', 'Unexpected error', 'topRight');
};
export const handleRequestError = (err: any): void => {
    let errorMessage = '';
    if (err?.response?.data?.data) {
        errorMessage = err?.response?.data?.message;
    } else {
        errorMessage = err?.response?.data?.status?.message;
    }
    switch (err?.response?.status) {
        case 404:
        case 400:
        case 401:
        case 409:
            handleNotFoundError(errorMessage || apiResponseMessage.tryAging);
            break;
        case 401:
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/';
            break;
        default:
            handleNotFoundError(apiResponseMessage.tryAging);
            break;
    }
};
