import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  courseTab: number;
}

const initialState: AuthState = {
  courseTab: 1,
};

const courseTabSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<number>) => {
      state.courseTab = action.payload;
    },
    clearCurrentTab: state => {
      state.courseTab = 1;
    },
  },
});

export const { setCurrentTab, clearCurrentTab } = courseTabSlice.actions;
export default courseTabSlice.reducer;
