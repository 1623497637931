import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import DownloadIcon from './DownloadIcon';
import Loader from '../../../components/Loader/Loader';
const ConfidentialityLetter = (props: any) => {
  const uploadEmployeeSignature = useGetApiRequests('uploadEmployeeSignature', 'POST');
  const getDocumentDetails = useGetApiRequests('getDocumentDetails', 'GET');
  const getDocumentData = useGetApiRequests('documentStatus', 'GET');
  const { getLetterDetails } = props;
  const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uploadedImg: any = []
  const getEmployeeId = searchParams.get('employeeId');
  const documentsCode = searchParams.get('documentCode');
  const employeeApiKey = searchParams.get('employeeApiKey');
  const [loader, setLoader] = useState(false);
  const [selectedDownloadDocument, setSelectDownloadDocument] = useState({
    signed: null,
    unsigned: null,
    signature: null
  });
  let isTerms: boolean = false;
  let isPolicy: boolean = false;
  let imageFlag: boolean = false;
  let signatureImage: any;
  let documentFile: any = document;

  useEffect(() => {
    let templateDetails: any = document.getElementById("templateDetails");
    templateDetails.innerHTML = getLetterDetails;
    if (props.getLetterDetails) {
      setTimeout(() => {
        const submitButton = documentFile.querySelector('.submit-btn');
        const imageUploadInput = documentFile.getElementById('imageUpload');
        const termsCheckbox = documentFile.getElementById('terms');
        const policyCheckbox = documentFile.getElementById('privacy-policy');
        const imagePreview = documentFile.getElementById('imagePreview');
        if (imagePreview) {
          submitButton.addEventListener('click', submit, false);
          imageUploadInput.addEventListener('change', handleFileSelect, false);
          termsCheckbox.addEventListener('click', handleTermsChange, false);
          policyCheckbox.addEventListener('click', handlePolicyChange, false);
          imagePreview.addEventListener('click', handleImageChange, false);
        }
        handelError()
      }, 5000)
    }
    getDocumentStatus()
  }, [props])
  const updateSelectDownloadDocument = (selectedDocument: any, item: any) => {
    const val = { ...selectedDocument };
    if (item.name.toLowerCase().includes('signed') && item.name.toLowerCase().includes('pdf')) {
      val.signed = item;
      val.unsigned = selectedDocument.unsigned;
      documentFile.getElementById("signatureHandler").style.display = "none";
      documentFile.getElementsByClassName("privacy-policy")[0].style.display = "none";
      documentFile.getElementsByClassName("submit-btn")[0].style.display = "none";
    }
    else {
      if (item.name.toLowerCase().includes('appointment') && item.name.toLowerCase().includes('pdf')) {

        val.signed = selectedDocument.signed;
        val.unsigned = item;
      }
    }
    return val;
  };
  const handelUpdatedFile = (isPdf: any, item: any) => {
    const updatedDocument = updateSelectDownloadDocument(selectedDownloadDocument, item);
    setSelectDownloadDocument({ ...updatedDocument, signature: item });
  }
  const getDocumentStatus = () => {
    setLoader(true);
    const params = {
      employeeId: getEmployeeId,
      document: "documentDetail",
    };
    getDocumentData("", {}, params)
      .then((response: any) => {
        response?.data?.data.forEach((item: any) => {
          setLoader(false);
          const folderName = item.document.folderName.toLowerCase();
          const isPdf = item.name.toLowerCase().includes("pdf");
          if (folderName === "confidentiality agreement" && documentsCode == "CONFAGR") {
            handleDocumentType(item, isPdf);
          }
          else if (folderName === "appointment letter" && documentsCode == "APPTLTR") {
            handleDocumentType(item, isPdf);
          }
          else if (folderName === "intern agreement" && documentsCode == "INTNAGR") {
            handleDocumentType(item, isPdf);
          }
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const containsImageSubstring = (path: any) => {
    return ["jpeg", "png", "jpg"].some((substring) => path.includes(substring));
  }
  const handleDocumentType = (item: any, isPdf: any) => {
    if (isPdf) {
      handelUpdatedFile(isPdf, item)
    }
    const footerPreviewList = documentFile.querySelectorAll(".imagePreview-footer");
    let isType: any = containsImageSubstring(item.documentPath)
    if (isType) {
      const imageObject = `<img style="width:200px; height:70px;" src="${imageBaseUrl + item.documentPath}" alt="uploaded_image">`;
      footerPreviewList.forEach((footer: any) => (footer.innerHTML = imageObject));
      documentFile.getElementById("imagePreview").innerHTML = imageObject;
    }
  };
  const handleFileSelect = (event: any) => {
    uploadedImg.push(event.target.files[0])
    const newArray = uploadedImg.filter((item: any) => item !== undefined);
    let lastValue: any;
    if (newArray.length > 0) {
      const lastIndex = newArray.length - 1;
      lastValue = newArray[lastIndex];
    } else {
      lastValue = event.target.files[0];
    }
    let uploadFile = event.target.files[0];
    if (lastValue) {
      let reader = new FileReader();
      reader.readAsDataURL(lastValue);
      reader.onload = function (e: any) {
        signatureImage = null
        let img: any = new Image();
        img.src = e.target.result;
        img.onload = function () {
          if (img) {
            const submitButton = documentFile.querySelector('.submit-btn');
            submitButton.style.pointerEvents = "auto"
            uploadFile = lastValue;
            signatureImage = uploadFile
            imageFlag = true
            let imageUrlObject = URL.createObjectURL(lastValue);
            let imageObject = `<img style="width:200px; height:100px;" src="${imageUrlObject}" alt="uploaded_image">`;
            documentFile.getElementById('imagePreview').innerHTML = lastValue ? imageObject : '';
            documentFile.querySelector('.imageupload-div').style.display = 'block';
            documentFile.getElementById('signatureHandler').style.padding = "20px 0 0 0 ";
            documentFile.getElementById('signatureHandler').innerHTML = "Re-upload Signature"
            let footerPreviewList: any = documentFile.querySelectorAll('.imagePreview-footer');
            documentFile.getElementById("imageError").style.display = 'none'
            footerPreviewList && footerPreviewList.length > 0 && footerPreviewList.forEach((item: any) => {
              item.innerHTML = imageObject;
            })
          }
        }
      };
    }
    else {
      handelError()
      imageFlag = false
    }
  }
  const handleTermsChange = (event: any) => {
    let documentFile: any = document;
    isTerms = event.target.checked
    if (event.target.checked) {
      documentFile.getElementById('termsError').innerHTML = " ";
    }
    else {
      documentFile.getElementById('termsError').innerHTML = "Please select the terms and conditions ";
      documentFile.getElementById('termsError').style.color = "red";
      documentFile.getElementById('termsError').style.position = "absolute";
      documentFile.getElementById('termsError').style.top = "18px";
      documentFile.getElementById('termsError').style.fontSize = "14px";

    }
  }
  const handlePolicyChange = (event: any) => {
    isPolicy = event.target.checked;
    const policyErrorElement = documentFile.getElementById('policyError');
    if (isPolicy) {
      policyErrorElement.innerHTML = '';
    } else {
      policyErrorElement.innerHTML = 'Please select the Privacy Policy';
      policyErrorElement.style.color = 'red';
      policyErrorElement.style.position = 'absolute';
      policyErrorElement.style.top = '18px';
    }
  };
  const handleImageChange = () => {
    documentFile.querySelector('.imageupload-div').style.display = 'block';
    signatureImage = null
    documentFile.getElementById('imageUpload').click();
  }
  const handelErrors = () => {
    const setErrorMessage = (elementId: any, message: any) => {
      const element = documentFile.getElementById(elementId);
      if (element) {
        element.innerHTML = message;
        element.style.color = "red";
        element.style.position = "absolute";
        element.style.top = "20px";
        element.style.opacity = "1";
        element.style.fontSize = "14px"
      }
    };
    if (!isTerms) {
      setErrorMessage('termsError', 'Please select the terms and conditions');
    }
    if (!isPolicy) {
      setErrorMessage('policyError', 'Please select the Privacy Policy');
    }
    if (signatureImage == null) {
      setErrorMessage('imageError', 'Please upload the signature');
      documentFile.getElementById("imageError").style.display = 'block'
    }
  }
  const submit = (event: any) => {
    const submitButton = documentFile.querySelector('.submit-btn');
    submitButton.style.pointerEvents = "none"

    setLoader(true)
    event.preventDefault();
    if (imageFlag && isTerms && isPolicy) {
      let formData: any = new FormData();
      formData.append("employeeSignature", signatureImage);
      const params = {
        "employeeId": getEmployeeId,
        "documentCode": documentsCode,
        "employeeApiKey": employeeApiKey
      }
      uploadEmployeeSignature(formData, {}, params)
        .then((res: any) => {
          setLoader(false)
          if (res.data.status.success.toLowerCase() == "success") {
            triggerNotification('success', '', res.data.status.message, 'topRight');
          }
          else {
            triggerNotification('error', '', res.data.status.message, 'topRight');
          }
          getDocumentDetail()
        })
        .catch((err: any) => {
          setLoader(false)
          triggerNotification('error', '', err.message, 'topRight');
        });
    }
    else {
      setLoader(false)
      handelErrors()
    }

  }
  const handelError = () => {
    let imageupload = documentFile.querySelector('.imageupload-div')
    let imageError = documentFile.createElement("div")
    imageError.setAttribute("id", "imageError")
    imageError.style.opacity = "0";
    imageupload.appendChild(imageError)
  }
  const getDocumentDetail = () => {
    setLoader(true)

    const params = {
      "employeeId": getEmployeeId,
      "documentCode": documentsCode
    }
    getDocumentDetails('', {}, params)
      .then((response: any) => {
        let doc: any = document.getElementById("templateDetails");
        doc.innerHTML = response?.data?.data
        setLoader(false)

        getDocumentStatus()
      })
      .catch((err: any) => {
        console.error(err);
      });
  }
  return (
    <div>
      {loader && <Loader />}
      <DownloadIcon selectedDownloadDocument={selectedDownloadDocument} />
      <div id='templateDetails' data-testid="templateDetails" />
    </div>
  )
}

export default ConfidentialityLetter
