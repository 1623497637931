import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { storeData, triggerNotification, useGetApiRequests, useGoogleLoginApi, useNavigate } from '../../common/CommonExport';
import googleSignIn from '../../assets/images/google_signin.svg';
import { setOrgId } from '../../redux/slices/orgSlice';
import { setToken } from '../../redux/slices/authSlice';
import { setUserData } from '../../redux/slices/userDataSlics';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { setRolePermissions } from '../../redux/slices/rolePermissionsSlice';
import { setUserId } from '../../redux/slices/userIdSlice';
import { setOrgName } from '../../redux/slices/OrganisationNameSlice';
import { constMessage } from '../../constants/constantsMessage';
import { handleRequestError } from '../../layouts/toast/ErrorNotificationMessage';
const GoogleLogin = () => {
    const navigate = useNavigate();
    const loginUserDetails = useGetApiRequests('userLoginDetails', 'GET');

    const LoginGoogleApi = useGoogleLoginApi();
    const dispatch = useDispatch();
    const socialLoginAPI = useGetApiRequests('socialLogin', 'POST');
    const [loader, setLoader] = useState(false);
    const [googleUser, setGoogleUser] = useState<any>();

    const handleGetEmployeeDetails = async (employeeId: any) => {
        try {
            const response: any = await loginUserDetails('', {}, { employeeId })
            if (response?.data?.status?.success === 'Success') {
                dispatch(setRolePermissions(response?.data?.data?.role?.rolePermissions));
                dispatch(setUserData(response.data));
                dispatch(setOrgName(response.data.data?.role?.organisation?.name));
                storeData('userId', response?.data?.data?.userId, true);
                storeData('loginUserData', response?.data?.data, true);
                sessionStorage.setItem("hrDeptId", response?.data?.data?.role?.organisation?.hrDeptId)
                storeData('isLoggedIn', true, true);
                navigate('/home/dashboard');
                setLoader(false)
            }
        } catch (err: any) {
            setLoader(false);
            triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
        }
    }

    const handleLogin = async (email: string, access_token: string, googleUserId: string | number) => {
        const payload = {
            "email": email,
            "subjectToken": access_token,
            "subjectIssuer": "google",
            googleUserId
        }
        setGoogleUser({ ...payload, googleUserId })
        setLoader(true)
        try {
            const response: any = await socialLoginAPI(payload);
            const responseMessage = response?.data?.message
            if (response.status === 200) {
                if (responseMessage === constMessage.finderCloakUser) {
                    handleLogin(email, access_token, googleUserId)
                } else {
                    const employeeId = JSON.parse(response?.data?.data?.employeeId)[0]
                    storeData('loggedInEmployeeId', employeeId, true);
                    storeData('authToken', response?.data?.data?.accessToken, true);
                    handleGetEmployeeDetails(employeeId)
                    dispatch(setOrgId(JSON.parse(response?.data?.data?.organisationId)[0]));
                    dispatch(setUserId(employeeId));
                    dispatch(setToken(response?.data?.data?.accessToken));
                }
            }

        } catch (err: any) {
            setLoader(false)
            handleRequestError(err)
        }
    };

    const handleSuccess = (response: any): void => {
        const access_token = response.access_token
        LoginGoogleApi(access_token)
            .then((res: any) => {
                const email = res?.data?.email
                const googleUserId: string | number = res?.data?.id
                handleLogin(email, access_token, googleUserId);
            })
    };
    const handleGoogleLogin = useGoogleLogin({
        onSuccess: handleSuccess,
    });

    return (
        <div className='flex justify-center items-center'>
            {loader && <Loader />}
            <button onClick={() => handleGoogleLogin()} type='button' className='w-max flex rounded border border-1 border-solid border-gray-300 bg-white px-3 py-1.5 text-xs font-medium leading-normal text-neutral-600 ease-in-out hover:bg-neutral-100 focus:bg-neutral-100 focus:outline-none active:bg-neutral-200 items-center '>
                <img className='w-3.5 h-3.5 mr-1' alt='googleLogin' src={googleSignIn} /> Sign in with Google
            </button>
        </div>
    );
};

export default GoogleLogin;
