import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import FloatLabel from "../../../../../../components/FloatLabel";
import { ErrorMessage, triggerNotification, useGetApiRequests } from "../../../../../../common/CommonExport";
import ImageUpload from "../../../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import './uploadEmployeeDocument.scss'
import Loader from "../../../../../../components/Loader/Loader";
import { setDocumentLoad } from "../../../../../../redux/slices/updateProfileSlice";
import ModalLoader from "../../../../../../components/Loader/ModalLoader";

const UploadEmployeeDocument = (props: any) => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const employeeDocumentTypes = useGetApiRequests('documentType', 'GET');
  const employeeDocumentUpload = useGetApiRequests('documentUpload', 'POST');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [open, setOpen] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [documentList, setDocumentList] = useState([])
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const [showError, setShowError] = useState<any>(false)
  const [imgCheck, setImgCheck] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)

  useEffect(() => {
    setOpen(props?.open)
    employeeDocumentTypes()
      .then((res: any) => {
        setDocumentList(res?.data?.data)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }, [])

  const handleClose = () => {
    setOpen(false);
    props?.setUploadDocument(false);
  };

  const handleChange = (value: any) => setDocumentType(value)

  const handleImageUpload = (file: any) => {
    setImgCheck(true)
    const formData = new FormData();
    file.forEach((file: any) => {
      formData.append('files', file?.originFileObj);
    });
    setMediaData(formData);
  };

  const handleImageDelete = (file: File) => {
    setImgCheck(false)
    setMediaData('')
  };

  const handleAddDocument = () => {
    if (documentType?.length === 0 || !imgCheck) {
      setShowError(true)
      return
    }
    finalSubmit()
  }

  const finalSubmit = () => {
    setLoader(true)
    const documentDto = {
      documentDto:
        { documentTypeId: documentType, employeeId: parseInt(id), organisationId: orgId }
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    employeeDocumentUpload(mediaData, documentDto, {}, headers)
      .then((res: any) => {
        setLoader(false)
        triggerNotification('success', '', res?.data?.message, 'topRight');
        handleClose()
        props?.getDocumentApiData()
        dispatch(setDocumentLoad())
      })
      .catch((err: any) => {
        setLoader(false)
        console.log(err);
      });
  }

  return (
    <Modal title='Upload Document' centered open={open} onCancel={() => handleClose()} footer={null} className="modal-uploadDocument" maskClosable={false}>
      {loader && <ModalLoader />}
      <div className="py-6 documentUpload upload">
        <div className='form-item select-width'>
          <FloatLabel label='Document Type *' name='documentType' value={documentType}>
            <Select
              className='float-control'
              onChange={(value: any) => handleChange(value)}
              showSearch
              value={documentType}
              filterOption={(input: any, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
              {documentList?.map((option: any) => (
                <Select.Option key={option.documentTypeId} value={option.documentTypeId}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </FloatLabel>
          {(showError && documentType?.length === 0) && <ErrorMessage message='Please select Document Type' />}
        </div>
        <div className='form-item'>
          <div className='mt-4 pt-4'>
            <ImageUpload multiple={true} onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 10}
              allowedTypes={['image/jpeg', 'image/png', 'application/pdf']} />
          </div>
          {(showError && !imgCheck) && <ErrorMessage message='Document is Required' />}
        </div>
        <div className="flex justify-end mt-8">
          <button className="addButton secondary mr-5" onClick={() => handleClose()}>Cancel</button>
          <button className="addButton" onClick={() => handleAddDocument()}>Add</button>

        </div>
      </div>
    </Modal>
  )
}

export default UploadEmployeeDocument