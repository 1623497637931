import axios from 'axios';
import { retrieveData } from '../../storage/Storage';
import { ErrorMessages, triggerNotification } from '../../common/CommonExport';

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

axios.interceptors.request.use(
    config => {
        const token: string = retrieveData('authToken', true);
        const employeeId: any = retrieveData('loggedInEmployeeId', true);
        const isTokenAvailable: boolean = !!token;
        if (isTokenAvailable) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['employeeId'] = employeeId;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            triggerNotification('error', '', ErrorMessages.sessionExpired, 'topRight');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    },
);

export default axios;
