import React from 'react'

export default function AppraisalButtonRendering(props: any) {
  const { incrementEligible, touched, handelSaveRevise, handelChangeSalary } = props;
  return (
    <div data-testid='button-id'>
      {touched && (
        <div className='flex justify-center mr-5 salary_add-btn'>
          {!incrementEligible ? (
            <button className='addButton' onClick={() => handelSaveRevise()}>
              Save
            </button>) : (
            <button className='addButton ' onClick={() => handelChangeSalary()}>
              Next
            </button>
          )}
        </div>)}
    </div>
  )
}
