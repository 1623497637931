import React from 'react'
import ImagesComponent from '../Images/Images';
import PlusImg from '../../assets/images/new-dashboard/plus-with-fill.svg';
import { setCurrentTab } from '../../redux/slices/courseSlice';
import { clearAssessmentAssignChapterID, setAssessmentAssignChapterID } from '../../redux/slices/currentCourseDetailSlics';
import { useDispatch, useSelector } from 'react-redux';

const ChapterButton = ({ chapId = null }: any) => {
  const dispatch = useDispatch()
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);

  const handleAddFinalChapter = () => {
    console.log("chapId : ", chapId);

    if (reduxToken === 2) {
      dispatch(clearAssessmentAssignChapterID())
      dispatch(setCurrentTab(3))
    } else {
      dispatch(setAssessmentAssignChapterID(chapId))
      dispatch(setCurrentTab(8))
    }
  }

  return (
    <div>
      <div>
        <button data-testid="btnEl" className='chapterButton flex justify-center ' onClick={handleAddFinalChapter}  >
          <ImagesComponent alt='logo' src={PlusImg} className='plus-Icon' />
          <span className='pl-1'>{reduxToken === 2 ? 'Assign Chapter' : 'Add Assessment'}</span>
        </button>
      </div>
    </div>
  )
}

export default ChapterButton
