import React, { useState } from 'react';
import { useGetApiRequests, useNavigate, useForm, SubmitHandler, Controller, yupResolver, Button, Input, Heading, ErrorMessage, triggerNotification } from '../../common/CommonExport';
import { ForgotPasswordSchema } from './ForgotPasswordSchema';
import Loader from '../../components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import ForgotPasswordAlert from './forgotPasswordAlert';
interface ForgotPasswordPayload {
    email: string;
}

const ForgotPasswordForm: React.FC = () => {
    const { search } = useLocation();
    const email: any = new URLSearchParams(search).get("email")
    const [mailAlert, setMailAlert] = useState<any>(false);
    const forgotPassword = useGetApiRequests('forgotPassword', 'POST');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<ForgotPasswordPayload>({
        resolver: yupResolver(ForgotPasswordSchema) as any,
        defaultValues: {
            email: email !== '' && email !== undefined ? email : '',
        },
    });

    const onSubmit: SubmitHandler<ForgotPasswordPayload> = (data: any) => {
        const param: Object = {
            email: data.email !== '' && data.email !== undefined ? data.email : '',
        };
        setLoader(true);
        forgotPassword('', param)
            .then((response: any) => {
                setLoader(false);
                setMailAlert(true)
            })
            .catch((err: any) => {
                setLoader(false);
                triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
            });
    };

    const navigation = (): void => {
        navigate('/login');
    };

    return (
        <div className='w-full min-h-screen flex items-center justify-center '>
            <div className='w-full mx-auto my:auto bg-transparent'>
                <Heading title='Forgot Password?' subheading='We’ll help you reset it and get back on track.' />
                <div className='w-9/12'>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className='relative z-0 my-12 '>
                            <Controller name='email' control={control} render={({ field }) => <Input type='email' id='email' label='Email Address*' control={control} {...field} hasError={!!errors.email} />} />
                        </div>
                        {errors.email && <ErrorMessage message={errors.email.message} />}

                        <div className='flex items-center justify-between mt-8'>
                            <Button variant='secondary' type='button' label='Back' onClick={() => navigation()} />
                            <Button variant='primary' type='submit' label='Reset Password' />
                        </div>
                    </form>
                </div>
            </div>
            {mailAlert && <ForgotPasswordAlert open={mailAlert} setMailAlert={setMailAlert} />}
            {loader && <Loader />}
        </div>
    );
};

export default ForgotPasswordForm;
