import { Yup } from '../../common/CommonExport';

export const SetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required('New Password is required')
        .min(8, 'New Password must be at least 8 characters')
        .max(50, 'New Password must be at most 50 characters')
        .matches(/^(?=.*[!@#$%^&*])/, 'New Password must contain at least one special character (!@#$%^&*)'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});
