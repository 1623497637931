import React, { useState } from 'react'
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import { setLeavePolicyReload } from '../../redux/slices/updateProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd'

export default function ActiveDeactivePopup(props: any) {
  const { closeConfirmationModal, setCloseConfirmationModal, type, record } = props;
  const [open, setOpen] = useState(closeConfirmationModal)
  const editActiveDeactiveApi = useGetApiRequests('editLeavePolicy', 'PUT');
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const userId = useSelector((state: any) => state?.userId?.id);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setCloseConfirmationModal(false)
  }
  const handleActive = () => {
    setLoader(true);
    const leavePolicy: any = {
      leavePolicy: {
        "policyName": record?.details?.leavePolicy.policyName,
        "employmentTypeId": record?.details?.leavePolicy.employmentTypeId,
        "unit": record?.details?.leavePolicy.unit,
        "effectiveAfterValue": record?.details?.leavePolicy.effectiveAfterValue,
        "effectiveAfterUnit": record?.details?.leavePolicy.effectiveAfterUnit,
        "effectiveFrom": record?.details?.leavePolicy.effectiveFrom,
        "openingBalance": record?.details?.leavePolicy.openingBalance,
        "maximumBalance": record?.details?.leavePolicy.maximumBalance,
        "isActive": record?.details?.leavePolicy?.isActive !== true
      },
      leaveAccrual: {
        "accrualUnit": record?.details?.leaveAccrual.accrualUnit,
        "accrualUnitValue": record?.details?.leaveAccrual.accrualUnitValue,
        "isCarryOverAllowed": record?.details?.leaveAccrual.isCarryOverAllowed
      },
      "organisationId": orgId
    };
    if (record?.details?.leaveRestriction) {
      leavePolicy.leaveRestriction = {
        "weekendsDontCountAsLeave": record?.details?.leaveRestriction.weekendsDontCountAsLeave,
        "holidaysDontCountAsLeave": record?.details?.leaveRestriction.holidaysDontCountAsLeave,
        "exceedLeaveBalance": record?.details?.leaveRestriction.exceedLeaveBalance,
        "maxHrPerDay": record?.details?.leaveRestriction.maxHrPerDay,
        "maxHrPerMonth": record?.details?.leaveRestriction.maxHrPerMonth,
        "allowPastDates": false,
        "pastDaysAllowed": 0,
        "allowFutureDates": record?.details?.leaveRestriction.allowFutureDates,
        "nextDaysAllowed": record?.details?.leaveRestriction.nextDaysAllowed,
        "minimumDaysInAdvance": record?.details?.leaveRestriction.minimumDaysInAdvance,
        "maximumConsecutiveLeaveDays": record?.details?.leaveRestriction.maximumConsecutiveLeaveDays,
        "incompatibleLeaveTypes": record?.details?.leaveRestriction.incompatibleLeaveTypes
      }
    }
    leavePolicy["leavePolicyMappingId"] = record?.details?.leavePolicyMappingId
    const queryParams: any = {
      loggedInUserId: userId
    }
    const pathParams: any = {
      id: record?.details?.leavePolicyMappingId
    }
    editActiveDeactiveApi(leavePolicy, queryParams, pathParams)
      .then((response: any) => {
        handleClose();
        triggerNotification('success', '', response?.data?.message, 'topRight');
        dispatch(setLeavePolicyReload());
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.message, 'topRight');
        setLoader(false)
        handleClose();
      });

  }
  return (
    <div>
      <Modal className='close-confirmation-popup w-[545px]' centered open={open} onCancel={handleClose} footer={null} maskClosable={false} >
        <div className="'appraisal-increment mt-4">
          <p>Are you sure you want to {type} this policy?</p>
          <div className='flex justify-end salary_add-btn mt-7'>
            <button className='addButton secondary mr-4' onClick={handleClose}>Cancel</button>
            <button type='submit' className='addButton' onClick={handleActive}>Proceed</button>
          </div>
        </div>
      </Modal>
      {loader && <Loader />}
    </div>
  )
}
