

import { Button, DatePicker, DatePickerProps, Input, Modal, Select, Calendar } from 'antd';
import React, { useEffect, useState } from 'react'
import FloatLabel from '../../components/FloatLabel';
import "./Announcements.scss"
import { ErrorMessage, Form, Formik } from 'formik'
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import upfold from "../../assets/images/upfold.svg"



const CreateAnnouncement = (props: any) => {
  const { open, handelCancel } = props;
  const [sendMail, setOpen] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({ "reportingManager": "Sakthi" });
  const format = 'h:mm A';

  useEffect(() => {
    setOpen(open)
  }, [sendMail])
  const onCancel = () => {
    handelCancel()
  }
  const handelSendMail = () => {
  }
  const disabledDate = (current: any) => {
    const oneWeekAgo = dayjs().subtract(7, 'days');
    return current && current < oneWeekAgo.startOf('day');
  };
  const onSubmit = (values: any) => { }
  const onchange = (e: any) => {
  }
  const onSelect = (newValue: any) => { }

  const headerRender = ({ value, type, onChange, onTypeChange }: any) => {
    return (
      <div className='px-[10px] mr-[15px]'>
        <div className='flex w-full'>
          <div className='w-[10%] flex'>
            <button onClick={() => onChange(value.clone().subtract(1, 'year'))}>
              <span className="material-symbols-outlined">
                keyboard_double_arrow_left
              </span>
            </button>
            <button onClick={() => onChange(value.clone().subtract(1, 'month'))}>
              <span className="material-symbols-outlined">
                chevron_left
              </span>
            </button>
          </div>
          <div className="w-[80%] flex justify-center">
            <span className='text-[#2D2D2D] text-[14px] font-semibold'>{value.format('MMMM YYYY')}</span>

          </div>
          <div className='w-[10%] flex'>
            <button onClick={() => onChange(value.clone().add(1, 'month'))}>
              <span className="material-symbols-outlined">
                chevron_right
              </span>
            </button>
            <button onClick={() => onChange(value.clone().add(1, 'year'))}>
              <span className="material-symbols-outlined">
                keyboard_double_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal title="Create New Announcement" className='add-announcement' onCancel={onCancel} centered open={sendMail} footer={null} maskClosable={false}>
      <div className="flex w-full mt-5 pb-3 flex-wrap">
        <div className='px-5 w-full'>
          <Formik
            initialValues={initialValues}
            validationSchema={""}
            onSubmit={onSubmit}
            values={initialValues}
            enableReinitialize={true}
          >
            {({ handleSubmit, values, setFieldValue, setFieldTouched }) => {

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="w-full flex flex-col">
                    <div className='form-item pb-5'>
                      <p className='text-[#434343] text-[13px] font-rubik pb-2'>Title of announcement*</p>
                      <Input
                        type="text"
                        className='bg-[#F5F5F5] h-[42px] font-medium'
                        name="reportingManager"
                        value={initialValues.reportingManager}
                        onChange={(e) => { onchange(e) }}
                      />
                      <ErrorMessage name="reportingManager" component="div" className="error-message" />
                    </div>
                    <div className='form-item pb-5'>
                      <p className='text-[#434343] text-[13px] font-rubik pb-2'>Content of the announcement*</p>
                      <TextArea rows={4} className='bg-[#F5F5F5] h-[42px] font-medium'
                        name="reportingManager"
                        value={initialValues.reportingManager}
                        onChange={(e) => { onchange(e) }} placeholder="maxLength is 6" maxLength={6} />

                      <ErrorMessage name="reportingManager" component="div" className="error-message" />
                    </div>

                    <p className='text-[#434343] text-[13px] font-rubik pb-2 pt-2'>Select date and time to schedule</p>
                    <div className="flex w-full pt-3">
                      <div className="w-[60%] border-r ">
                        <Calendar headerRender={headerRender} className='mr-5' mode='month' fullscreen={false} onSelect={onSelect} />
                      </div>
                      <div className="w-[40%] pl-5 file:pt-">
                        <p className='font-rubik text-[#434343] text-[15px]'>Time</p>
                        <TimePicker defaultValue={dayjs()} suffixIcon={<img src={upfold} />} format={format} use12Hours />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-end pt-8">
                    <div className="flex justify-end ">
                      <Button type="primary" shape="round" className='addButton w-[140px]   _deprovision mr-4' onClick={() => onCancel()} >
                        Cancel
                      </Button>
                      <Button type="primary" shape="round" className='addButton w-[140px] deprovision text-[#d23b7d]' onClick={() => handelSendMail()} >
                        Submit
                      </Button>
                    </div>

                  </div>
                </Form>
              )
            }}
          </Formik></div>

      </div>
    </Modal>
  )
}


export default CreateAnnouncement
