import React from 'react';

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <h2 className='text-primary text-2xl font-medium py-5'>{title}</h2>
  );
};

export default PageTitle;
