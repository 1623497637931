import React, { useEffect, useState } from 'react'
import { Modal, Input, Select } from 'antd';
import { retrieveData, storeData, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import FloatLabel from '../../../components/FloatLabel';
import { useSelector, useDispatch } from 'react-redux';
import { salaryUpdate } from '../../../redux/salary-master/salaryAction';
const AddComponentModal = (props: any) => {
  const { open, editParticulars, handleClose, groupName } = props;
  const saveSalaryMaster = useGetApiRequests('saveSalaryMaster', 'PUT');
  const addSalaryMaster = useGetApiRequests('saveSalaryMaster', 'POST');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>('percent');
  const [selectedPeriod, setSelectedPeriod] = useState<any>('monthly');
  const [isYear, setIsYear] = useState<boolean>(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState<any>(false)
  const [isEdit, setIsEdit] = useState()
  const [values, setValues] = useState<any>({
    "name": '',
    "code": '',
    "groupName": '',
    "sectionName": '',
    "salaryComponentId": '',
    "organisation": {
      "organisationId": orgId
    },
    "percent": '',
  })

  useEffect(() => {


    setIsEdit(retrieveData("iSnewItem", true))
    console.log(editParticulars);


    setModalOpen(open);
    let iSnewItem = retrieveData("iSnewItem", true)
    if (!iSnewItem) {
      let particularType = editParticulars.type == " %" ? " %" : "Amount";
      setSelectedValue(particularType)
      setSelectedPeriod(editParticulars.sectionName)
      setValues({
        "name": editParticulars.name,
        "groupName": editParticulars.groupName,
        "sectionName": editParticulars.sectionName,
        "salaryComponentId": editParticulars.salaryComponentId,
        "organisation": {
          "organisationId": orgId
        },
        "percent": editParticulars.percent,
        "amount": editParticulars.amount,
        "type": editParticulars.type,
        "code": editParticulars.code
      })
    }
    else {
      setSelectedValue("")

    }
    return (() => {
      setModalOpen(false);
      storeData("iSnewItem", false, true)
    })
  }, [props.open])

  const handelEDit = () => {
    setShowError(true)
    const payload = values;
    const pathParam = {
      param: payload.salaryComponentId
    }
    const query = {
      query: ""
    }
    let iSnewItem = retrieveData("iSnewItem", true)
    if (iSnewItem && values.name != "") {

      delete payload.salaryComponentId
      delete payload.type

      if ((orgId === 563 || orgId === 564) && groupName === undefined) {
        payload.groupName = "Employee Contribution"
      }
      else {
        payload.groupName = groupName
      }
      addSalaryMaster(payload, {}, query)
        .then((res: any) => {
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          dispatch(salaryUpdate(true))
          setModalOpen(false)
          handleClose()
        })
        .catch((err: any) => {
          triggerNotification('error', '', err?.message, 'topRight');
        })
    }
    else if (!iSnewItem && values.name != "") {

      payload.groupName = editParticulars.groupName
      saveSalaryMaster(payload, {}, pathParam)
        .then((res: any) => {
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          dispatch(salaryUpdate(true))
          setModalOpen(false)
          handleClose()
        })
        .catch((err: any) => {
          triggerNotification('error', '', err?.message, 'topRight');
        })
    }
  }
  const handleChange = (value: string, field: any) => {
    if (field === 'name') {
      setValues({ ...values, name: value });
    } else if (field === 'percents') {
      if (selectedValue === "percent") {
        setValues({ ...values, percent: Number(value), amount: 0, sectionName: "yearly" })
      }
      else {
        setValues({ ...values, amount: Number(value), percent: 0, sectionName: "monthly" })
      }
    }
    else if (field === 'type') {
      setSelectedValue(value)
      if (value === "percent") {
        setSelectedValue(" %")
        setIsYear(false)
        setValues({ ...values, amount: 0, percent: 0, sectionName: "yearly" })
      }
      else {
        setIsYear(true)
        setValues({ ...values, amount: 0, percent: 0, sectionName: "monthly" })
      }
    }
    else if (field === "period") {
      setSelectedPeriod(value)
      setValues({ ...values, sectionName: value })
    }
    else {
      setSelectedPeriod(value)
      setValues({ ...values, groupName: value })
    }
  }
  const handelCloseModal = () => {
    setSelectedValue("")
    setValues({
      "name": "",
      "groupName": "",
      "sectionName": "",
      "salaryComponentId": "",
      "organisation": {
        "organisationId": orgId
      },
      "percent": "",
      "amount": "",
      "type": "",
      "code": ""
    })
    setModalOpen(false)
    handleClose()
  }

  const amount = [{ value: 'percent', label: '%' },
  { value: 'amount', label: 'Amount' }]
  const period = [{ value: 'yearly', label: 'Yearly' },
  { value: 'monthly', label: 'Monthly' }]
  return (
    <Modal title={isEdit ? "Add salary particular " : 'Edit salary particular'} className='salaryStructure-modal' centered open={modalOpen} footer={null} onCancel={handelCloseModal} maskClosable={false} >
      <div className="salary-date ">
        <div className='grid grid-cols-2 gap-10'>
          <div className='form-item basicForm-fields mb-6' >
            <FloatLabel label='Name *' name='ctc' value={values.name}>
              <Input
                type="text"
                className='float-control'
                name="name"
                value={values.name}
                onChange={(value: any) => handleChange(value.target.value, 'name')}
              />
              {showError && values.name === "" && <span className='text-xs text-red-400 '>Enter the particular name</span>}

            </FloatLabel>
          </div>
          <div className='form-item edit-salary-details  flex'>
            <div className='form-item basicForm-fields mb-6' >
              <FloatLabel name='amt' label={selectedValue?.toLowerCase() === "amount"
                ? "Enter Amount"
                : selectedValue?.toLowerCase() === " %"
                  ? "Enter Percent"
                  : "Select Accrual type"} value={values.percent ? (values.percent + 1) : (values.amount + 1)}>
                <Input
                  type="number"
                  className='float-control'
                  name="currentctc" value={values.percent !== 0 ? values.percent : values.amount}
                  onChange={(e) => {
                    const inputValue = parseInt(e.target.value, 10);
                    const limitedValue: any = values.percent !== 0 ? Math.min(inputValue, 100) : Math.min(inputValue, 99999999);
                    handleChange(limitedValue, 'percents');
                  }}
                />
              </FloatLabel>
            </div>
            <div className="form-item relative bottom-[0.5px]">
              <Select
                placeholder="Type"
                options={amount}
                value={selectedValue}
                onChange={(value: any) => handleChange(value, "type")} >
                {amount.map((option: any) => (
                  <Select.Option key={option.value} value={String(option.value)}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {(selectedValue == "Amount" || isYear) &&
          <div className="form-item edit-salary-details ">
            <Select
              placeholder="Select your rating"
              options={period}
              value={selectedPeriod}
              onChange={(value: any) => handleChange(value, "period")} >
              {period.map((option: any) => (
                <Select.Option key={option.value} value={String(option.value)}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        }
      </div>
      <div className='flex justify-end mr-5 salary_add-btn'>
        <button className='addButton ' onClick={handelEDit}>
          Submit
        </button>
      </div>
    </Modal>
  )
}

export default AddComponentModal