import React, { useState } from "react";
import { Modal, Button } from "antd";
import './forgotPasswordAlert.scss'
import { useNavigate } from "react-router-dom";

const ForgotPasswordAlert = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);

  React.useEffect(() => {
    setOpen(props?.open)
  }, [])

  const handleCloseTab = () => {
    setOpen(false)
    props?.setMailAlert(false)
    navigate('/login');
  }

  return (
    <Modal centered open={open} footer={null} className='forgot_password_alert' closable={false}>
      <p>Your password reset link mail has been sent successfully. Please check your inbox or spam folder.</p>
      <div className="flex justify-end mt-5 forgot-pass-alert-popup">
        <Button className="addButton primary " onClick={() => handleCloseTab()}>Ok</Button>
      </div>
    </Modal>
  )
}

export default ForgotPasswordAlert