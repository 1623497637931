import React, { useEffect, useState } from 'react'
import { Button, Pagination, PaginationProps, Table, Tooltip } from 'antd';
import "./EmployeeProgress.scss"
import { useNavigate } from 'react-router-dom';
import { setFiltertData } from '../../../redux/slices/courseFilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import CourseFilter from './CourseFilter';
import { ColumnsType } from 'antd/es/table';
import Loader from '../../../components/Loader/Loader';

const EmployeeProgress = () => {
  const currentTab = useSelector((state: any) => state?.courseFilterSlice?.eventData);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getEmployeeProgressApi = useGetApiRequests('getEmployeeProgress', 'POST');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [loader, setLoader] = useState(false);
  const [progressList, setProgressList] = useState<any>([]);
  const [employeeName, setEmployeeName] = useState("");
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('');
  const [courseCompletionStartDate, setCourseCompletionStartDate] = useState('')
  const [courseCompletionEndDate, setCourseCompletionEndDate] = useState('');
  const [isSubmit, setIsSubmit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState<number>();
  const [pageSize, setPageSize] = useState(100);
  const [certificateStatus, setCertificateStatus] = useState('');
  const [courseStatus, setCourseStatus] = useState('')
  const [isFilter, setIsFilter] = useState<boolean>(true);
  useEffect(() => {
    setIsFilter(currentTab)
  }, [currentTab])

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handelpopup = () => {
    dispatch(setFiltertData(!currentTab))
  }

  const handelNavigate = () => {
    navigate("/home/dashboard/learning-management")
  };
  const formatDates = (inputDate: any) => {
    const date = new Date(inputDate);
    const day = ("0" + date.getDate()).slice(-2);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  }
  const handelNavigateParticular = (record: any) => {
    if (record.learningPathId === null) {
      navigate(`employee-progress?employeeId=${record?.employeeId}&learningPath=false&courseId=${record.courseId}`)
    } else {
      navigate(`employee-progress?employeeId=${record?.employeeId}&learningPath=true&learningPathId=${record.learningPathId}`)
    }
  }
  const createColumn = (title: string, dataIndex: string, key: string, render?: (record: any) => React.ReactNode) => ({
    title,
    dataIndex,
    key,
    render: (_: any, record: any) => (
      <div onClick={() => handelNavigateParticular(record)} className='flex cursor-pointer'>
        {render ? render(record) : <p><span className={`mr-2 leave-request-${record[dataIndex]}`}></span>{record[dataIndex]}</p>}
      </div>
    ),
  });
  const columns: ColumnsType<any> = [
    createColumn('Employee Name', 'employeeName', 'employeeName'),
    createColumn('Name of the Course/ Learning path', 'courseName', 'courseName'),
    createColumn('Enrolled Date', 'enrolledDate', 'enrolledDate'),
    createColumn('Course Completion Date', 'completionDate', 'completionDate'),
    createColumn('Course Status', 'employeeStatus', 'employeeStatus', (record) => (
      <p><span className={`mr-2 leave-request-${record.employeeStatus}`}></span>{record.employeeStatus}</p>
    )),
    createColumn('Certificate Status', 'certificateStatus', 'certificateStatus', (record) => (
      <p className='cursor-pointer'><span className={`mr-2 leave-request-${record.certificateStatus}`}></span>{record.certificateStatus}</p>
    )),
  ];
  function getStatus(input: any) {
    switch (input) {
      case 'QP':
        return 'Quiz Pending';
      case 'IP':
        return 'In Progress';
      case 'YS':
        return 'Yet to Start';
      case 'CO':
        return 'Completed';
      default:
        return 'Invalid Input';
    }
  }
  useEffect(() => {
    getEmployeeProgress()
  }, [isSubmit, currentPage, pageSize])
  const getEmployeeProgress = () => {
    const startNew: number = (currentPage - 1 === 0) ? 0 : currentPage - 1;
    setLoader(true)
    const payload: any = {
      "organisationId": orgId,
      "start": startNew,
      "limit": pageSize
    }
    if (employeeName !== '') {
      payload.search = employeeName
    }
    if (startDate !== '') {
      payload.enrolledStartDate = startDate
    }
    if (endDate !== '') {
      payload.enrolledEndDate = endDate
    }
    if (courseCompletionStartDate !== '') {
      payload.completionStartDate = courseCompletionStartDate
    }
    if (courseCompletionEndDate !== '') {
      payload.completionEndDate = courseCompletionEndDate
    }
    if (certificateStatus !== '') {
      payload.certificateStatus = certificateStatus
    }
    if (courseStatus !== '') {
      payload.courseStatus = courseStatus
    }
    getEmployeeProgressApi(payload)
      .then((response: any) => {
        if (response?.data?.data !== null) {
          const reformedProgressList = response?.data?.data.map((item: any, index: number) => ({
            employeeName: item?.employeeName,
            enrolledDate: formatDates(item?.enrolledDate),
            completionDate: formatDates(item?.completionDate),
            courseId: item?.courseId,
            employeeId: item?.employeeId,
            learningPathId: item?.learningPathId,
            courseName: item?.courseName !== null ? item?.courseName : item?.learningPathName + " learning path",
            learningPathName: item?.learningPathName,
            employeeCourseId: item?.employeeCourseId,
            employeeLearningPathId: item?.employeeLearningPathId,
            employeeStatus: getStatus(item?.employeeStatus),
            certificateStatus: item?.certificateStatus,
            key: index + 1
          }));
          setProgressList(reformedProgressList)
          const totalCount: any = response?.data?.meta?.totalRecords
          setCount(totalCount)
          setLoader(false)
        } else {
          setLoader(false)
          // triggerNotification('warning', '', "No data found", 'topRight');
        }
      })
      .catch((err: any) => {
        console.log(err);

        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handleNavigateDashboard = () => {
    navigate('/home/dashboard')
  }
  return (
    <div className='page-container bg-customBg holiday'>
      <div className='full-width-container mt-5'>
        <div className=' bg-white p-5 h-full'>
          <div className=" flex justify-between">
            <div>
              <ul className='flex'>
                <li className='text-[#878787] text-[15px] cursor-pointer hover:text-pink-500' onClick={handleNavigateDashboard}>{'Dashboard /'}&nbsp;</li>
                <li className='text-[#878787] text-[15px] cursor-pointer hover:text-pink-500' onClick={handelNavigate}>{' Learning Management /'}&nbsp;</li>
                <li className='text-[#878787] text-[15px] cursor-pointer hover:text-pink-500' onClick={handelNavigate}>{'Course / '}&nbsp;</li>
                <li className='text-[15px] text-pink-500'>{' Employee Progress'}</li>
              </ul>
            </div>
            <div className='flex'>
              <Tooltip placement="bottom" title="Filter">
                <Button type="primary" shape="circle" size={'large'} onClick={() => handelpopup()} className='mr-2'>
                  <span className="material-symbols-outlined mt-1">
                    filter_alt
                  </span>
                </Button>
              </Tooltip>
            </div>
          </div>
          <div>
            <div className='card-title justify-start flex'>
              <h4 className='font-medium'>Employee Progress</h4>
            </div>

            <CourseFilter setEmployeeName={setEmployeeName} employeeName={employeeName} setStartDate={setStartDate} setEndDate={setEndDate} setCourseCompletionStartDate={setCourseCompletionStartDate} setCourseCompletionEndDate={setCourseCompletionEndDate} setIsSubmit={setIsSubmit} setCertificateStatus={setCertificateStatus} setCourseStatus={setCourseStatus} courseStatus={courseStatus} certificateStatus={certificateStatus} progressList={progressList} isSubmit={isSubmit} />
            <div className={`pt-5 leave-management progress-report'${isFilter ? ' add_table-height' : ''}`}>
              {progressList && progressList.length > 0 ? (
                <Table columns={columns} dataSource={progressList} />) : (
                <div>
                  <p>No Datas found</p>
                </div>
              )}
              {/* <Pagination className='float-right flex flex-row ml-2' onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={currentPage} total={count} pageSize={pageSize} onChange={(page) => setCurrentPage(page)} /> */}

            </div>
          </div>
          <div className='leave-management holiday-management'>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  )
}

export default EmployeeProgress;