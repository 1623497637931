import React, { useEffect, useState } from 'react'
import ImageUpload from '../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { courseInitialState, courseSchema } from '../../../BasicForm/empInfoFormSchema';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { setCurrentCourseId } from '../../../../redux/slices/currentCourseDetailSlics';
import CreateCourseComp from './CreateCourseComp';
import { setChapterLoad } from '../../../../redux/slices/updateProfileSlice';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const AddChapterHome = ({ handleClose, setCourseName }: any) => {
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL;
  const loggedInUserId = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const courseSave = useGetApiRequests('courseSave', 'POST');
  const updateCourse = useGetApiRequests('updateCourse', 'PUT');
  const getCourseById = useGetApiRequests('getCourseById', 'GET');
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const [imageData, setImageData] = useState<any>('');
  const [initialValues, setInitialValues] = useState<any>(courseInitialState)
  const [loader, setLoader] = useState<any>(false)
  const [courseImage, setCourseImage] = useState<any>(false)
  const [imgCheck, setImgCheck] = useState<any>(false)
  const [showError, setShowError] = useState<any>(false)

  const handleImageUpload = (file: File) => {
    setImgCheck(true)
    setShowError(false)
    const formData = new FormData();
    formData.append('logo', file);
    setImageData(formData);
  };
  const handleImageDelete = (file: File) => {
    setImgCheck(false)
    setImageData('');
  }

  const handleCourseDetils = async () => {
    try {
      setLoader(true);
      const pathParams = { id: currentCourseId };
      const res: any = await getCourseById('', {}, pathParams);
      setLoader(false);
      if (res?.data?.data?.logoPath !== null) setImgCheck(true)
      setCourseImage(`${baseUrl}${res?.data?.data?.logoPath}`)
      setInitialValues({
        ...initialValues,
        courseDTO: {
          ...initialValues.courseDTO,
          name: res?.data?.data?.name || '',
          description: res?.data?.data?.description || '',
          logoPath: res?.data?.data?.logoPath
        },
      });
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (currentCourseId !== 0) {
      handleCourseDetils()
    }
  }, [])

  const handlePostData = async (values: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };
    values.courseDTO.organisationId = loggedInUserId?.organisationId
    values.courseDTO.code = 'CNW_007'
    const queryParams = {
      loggedInUserId: loggedInUserId?.userId,
      courseDto: values?.courseDTO
    }
    let formData: any = new FormData();
    if (imageData) {
      formData = imageData
    }
    else if (!imageData) formData = {};
    try {
      const response: any = await courseSave(formData, queryParams, {}, headers)
      setLoader(false)
      triggerNotification('success', '', response?.data?.message, 'topRight');
      if (flowDirection) {
        dispatch(setChapterLoad())
        handleClose()
      } else
        dispatch(setCurrentTab(2));
      dispatch(setCurrentCourseId(response?.data?.data?.courseId))
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }

  const handlePutData = async (values: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };
    let formData: any = new FormData();
    if (imageData) {
      formData = imageData
    }
    else if (!imageData) formData = {};
    values.courseDTO.organisationId = loggedInUserId?.organisationId
    values.courseDTO.courseId = currentCourseId
    values.courseDTO.code = 'CNW_123'
    const queryParams = {
      loggedInUserId: loggedInUserId?.userId,
      courseDto: values?.courseDTO
    }
    imageData && delete queryParams.courseDto.logoPath
    const pathParams = { id: currentCourseId }
    try {
      const response: any = await updateCourse(formData, queryParams, pathParams, headers)
      setLoader(false)
      triggerNotification('success', '', response?.data?.message, 'topRight');
      if (flowDirection) {
        dispatch(setChapterLoad())
        handleClose()
      } else
        dispatch(setCurrentTab(2));
      dispatch(setCurrentCourseId(response?.data?.data?.courseId))
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }

  const onSubmit = (values: any) => {
    if (!imgCheck) {
      setShowError(true)
      return
    }
    setLoader(true)
    if (currentCourseId === 0) {
      handlePostData(values)
    } else if (currentCourseId !== 0) {
      handlePutData(values)
    }
  }

  return (
    <CreateCourseComp Loader={ModalLoader} loader={loader} initialValues={initialValues} courseSchema={courseSchema} onSubmit={onSubmit}
      ImageUpload={ImageUpload} courseImage={courseImage} flowDirection={flowDirection}
      handleImageUpload={handleImageUpload} handleImageDelete={handleImageDelete} showError={showError} setCourseName={setCourseName} />
  )
}

export default AddChapterHome
