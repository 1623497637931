import React, { useState } from 'react';
import { Input, Button, Tooltip } from 'antd';
import './userHeader.scss';

interface UserHeaderProps {
  types: string;
  handleOpenModal: () => void;
  onSearch: any,
  setDeletePopUp: any,
  selectedRows: any,
  isSingleDelete: boolean, // Corrected the type for handleOpenModal
}

const UserHeader: React.FC<UserHeaderProps> = ({ types, handleOpenModal, onSearch, setDeletePopUp, selectedRows, isSingleDelete }) => {
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div className="filters-main md:flex   items-center mb-4">
      <div className="create-department md:flex items-center justify-between w-full">
        <div className="search-filter mr-4">
          <Input
            placeholder="Search"
            value={searchText}
            className="search"
            onChange={handleSearchChange}
          />
          <span className="material-symbols-outlined"> search </span>
        </div>
        <div className='flex mr-5'>
          {types === 'designations' ? (
            <>
              {selectedRows && selectedRows.length > 0 && !isSingleDelete && (
                <Tooltip placement="top" title="Delete">
                  <Button
                    type="primary"
                    className="flex items-center justify-center px-3 md:mt-0 mt-3 md:mr-3"
                    shape="circle"
                    icon={<span className="material-symbols-outlined text-white"> delete </span>}
                    size="large"
                    onClick={() => setDeletePopUp(true)}
                  >

                  </Button>
                </Tooltip>
              )}
              <Tooltip placement="top" title="Create Role">
                <Button
                  type="primary"
                  shape="circle"
                  className="flex items-center justify-center font-bold"
                  icon={<span className="material-symbols-outlined"> add </span>}
                  size="large"
                  onClick={handleOpenModal}
                  disabled={!!(selectedRows && selectedRows.length > 0)}
                >
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              {selectedRows && selectedRows.length > 0 && !isSingleDelete && (
                <Tooltip placement="top" title="Delete">
                  <Button
                    type="primary"
                    className="flex items-center justify-center px-3 md:mt-0 mt-3 md:mr-3"
                    shape="circle"
                    icon={<span className="material-symbols-outlined"> delete </span>}
                    size="large"
                    onClick={() => setDeletePopUp(true)}
                  >
                  </Button>
                </Tooltip>
              )}
              <Tooltip placement="top" title="Create User">
                <Button
                  type="primary"
                  shape="circle"
                  className="flex items-center justify-center"
                  onClick={handleOpenModal} // Use handleOpenModal here
                  icon={<span className="material-symbols-outlined"> add</span>}
                  size="large"
                  disabled={!!(selectedRows && selectedRows.length > 0)}
                >
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
