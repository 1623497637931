import React from 'react'
import { useSelector } from 'react-redux';

const DeprovisionForm = ({ formDetails }: any) => {
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);

  let hrDetails: any, itAdminDetails: any, financeDetails: any, remortingManagerDetails: any;
  formDetails?.employeeDeprovisionKVDto?.map((item: any) => {
    if (item?.inchargeDescription === 'HR Admin') {
      hrDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'IT Admin') {
      itAdminDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'Finance Admin') {
      financeDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'Reporting Manager') {
      remortingManagerDetails = item?.inchargeEmployee
    }
  })
  return (
    <div className='flex w-full bg-[#FCF7FB] h-[125px] pt-3  px-10'>
      <div className="w-1/3">
        <div className="flex w-full py-1">
          <p className='text-[#595959] text-[16px] w-1/2 font-rubik font-medium'>HR Incharge</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{hrDetails?.firstName + " " + hrDetails?.lastName}</p>
        </div>
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[16px] w-1/2  font-rubik font-medium'>IT Incharge</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{itAdminDetails?.firstName + " " + itAdminDetails?.lastName}</p>
        </div> <div className="flex w-full py-1">
          <p className='text-[#595959] text-[16px] w-1/2  font-rubik font-medium'>Finance Incharge</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{financeDetails?.firstName + " " + financeDetails?.lastName}</p>
        </div>
      </div>
      <div className="w-1/3">
        {/* <div className="flex w-full py-1">
          <p className='text-[#595959] text-[16px] w-1/2 font-rubik font-medium'>Knowledge Transfer</p>
          <p className='text-[#878787] text-[16px] font-rubik '>Darren Hickman</p>
        </div> */}
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[16px] w-1/2  font-rubik font-medium'>Supervisor Incharge</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{remortingManagerDetails?.firstName + " " + remortingManagerDetails?.lastName}</p>
        </div> <div className="flex w-full py-1">
          <p className='text-[#595959] text-[16px] w-1/2  font-rubik font-medium'>Employee Exit Type</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{employeeDetails?.status?.name}</p>
        </div>
      </div>

      <div className="w-1/3">
        <div className="flex w-full py-1">
          <p className='text-[#595959] text-[16px] w-1/2 font-rubik font-medium'>Resignation Date</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{employeeDetails?.dateOfResigned}</p>
        </div>
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[16px] w-1/2  font-rubik font-medium'>Relieving Date</p>
          <p className='text-[#878787] text-[16px] font-rubik '>{employeeDetails?.relievingDate}</p>
        </div>
      </div>
    </div>
  )
}

export default DeprovisionForm