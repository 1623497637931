import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { debounce, useGetApiRequests } from '../../../../common/CommonExport';
import { setQuizChapterMapPayload } from '../../../../redux/slices/currentCourseDetailSlics';
import AssignChapterAndCourse from './AssignChapterAndCourse';
import AssignChapterAndCourseFooter from './AssignChapterAndCourseFooter';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const AssignAssessment = ({ handleTabChange, handleBackParent }: any) => {
  const dispatch = useDispatch()
  const chapterList = useGetApiRequests('questionAllList', 'POST');
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const assessmentChapter = useSelector((state: any) => state?.currentCourse?.assessmentAssignChapterID);
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const finalAssessmentInCourse = useSelector((state: any) => state?.currentCourse?.finalAssessmentInCourse);
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const currentAssessmentTab = useSelector((state: any) => state?.currentCourse?.currentAssessmentTab);
  const [showComponent, setShowComponent] = useState(1);
  const [leftSide, setLeftSide] = useState<any>([]);
  const [rightSide, setRightSide] = useState<any>([]);
  const [leftSideSelected, setLeftSideSelected] = useState<any>([]);
  const [rightSideSelected, setRightSideSelected] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false)

  const onSearch = debounce((searchTerm: string) => {
    if (searchTerm?.length > 3 || searchTerm?.length === 0)
      getChapterList(searchTerm, true)
  }, 300);

  useEffect(() => {
    setShowComponent(reduxToken)
  }, [showComponent])

  const handleAlreadySelectedChapter = (data: any) => {
    const updatedLeftSide = data?.filter(
      (leftChapter: any) =>
        !rightSide.some((rightChapter: any) => rightChapter?.questionaireId === leftChapter?.questionaireId)
    );
    setLeftSide(updatedLeftSide)
  }

  const getChapterList = (searchTerm: any, status: any) => {
    setLoader(true)
    const payload = {
      searchQuestionaireDto: { question: searchTerm, isActive: true, organisationId: loggedInUser?.organisationId },
      start: 0, limit: 20, sortBy: "questionaireId", sortDirection: 'DESC'
    }
    chapterList(payload)
      .then((response: any) => {
        if (status) {
          handleAlreadySelectedChapter(response?.data?.data);
        } else if (assessmentChapter?.quiz === null || assessmentChapter?.quiz !== null) {
          assessmentChapter?.quiz === null
            ? handleAlreadySelectedChapter(response?.data?.data)
            : getChapterListByCourseId(response?.data?.data);
        }
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      });
  }

  const getChapterListByCourseId = (apiData: any) => {
    const extractedChapters = assessmentChapter?.quiz?.questionaires || [];
    const selected = extractedChapters
    setLeftSideSelected([])
    setRightSide((prevData: any) => [...prevData, ...selected]);
    const updatedLeftSide = apiData.filter((leftChapter: any) => {
      return !selected?.some((rightChapter: any) => rightChapter?.questionaireId === leftChapter?.questionaireId);
    });
    setLeftSide(updatedLeftSide)
  }

  useEffect(() => {
    getChapterList('', false)
  }, [])

  const handleBack = () => {
    if (!finalAssessmentInCourse) {
      if (!newChapterFlow)
        dispatch(setCurrentTab(4))
      else if (newChapterFlow)
        handleBackParent('1')
    }
  }

  const handleSwitchToNextSection = () => {
    const payload = {
      name: "Quiz", organisationId: loggedInUser?.organisationId,
      questionaires: rightSide.map((questionID: any) => ({ questionaireId: questionID?.questionaireId })),
      ...(assessmentChapter?.quiz !== null && { quizId: assessmentChapter?.quiz?.quizId })
    };
    dispatch((setQuizChapterMapPayload(payload)))
    handleTabChange('2')
  }

  const handlebackButtonEnable = () => {
    if (!newChapterFlow && !finalAssessmentInCourse && !flowDirection)
      return true
    else
      return false
  }

  return (
    <>
      {loader && <ModalLoader />}

      <div className='chapter-assign p-5 h-full'>
        {currentAssessmentTab !== '2' && <h3 className='pb-3 chapter-title' >Chapter Name: {assessmentChapter?.chaptername}</h3>}
        <AssignChapterAndCourse nameValue='question' keyValue='questionaireId' onSearch={onSearch} leftSide={leftSide} leftSideSelected={leftSideSelected} setLeftSideSelected={setLeftSideSelected}
          rightSide={rightSide} rightSideSelected={rightSideSelected} setRightSideSelected={setRightSideSelected}
          setRightSide={setRightSide} setLeftSide={setLeftSide} />

      </div>
      <AssignChapterAndCourseFooter handleBack={handleBack} handleSwitchToNextSection={handleSwitchToNextSection} backButton={handlebackButtonEnable()} />
    </>
  )
}
export default AssignAssessment
