import React from 'react'

const ChapterDescription = (props: any) => {
  const { datas } = props;
  return (
    <div className='pl-4'>
      <p className={`font-medium text-[#696969] text-[16px] ${datas?.employeeQuizStatus === 'NS' || datas?.employeeQuizStatus === 'YS' ? 'text-[#949494]' : ''}`}>
        Quiz(5/5) : <span className='font-normal'>{datas?.employeeQuizStatus === 'NS' || datas?.employeeQuizStatus === 'YS' ? 'Not Yet' : 'Pass'}</span>
      </p>
      <p className={`font-medium text-[#696969] text-[16px] ${datas?.employeeQuizStatus === 'NS' || datas?.employeeQuizStatus === 'YS' ? 'text-[#949494]' : ''}`}>
        Certificate : <span className='font-normal'>{datas?.employeeQuizStatus === 'NS' || datas?.employeeQuizStatus === 'YS' ? 'Not Yet' : 'Downloaded'}</span>
      </p>
    </div>
  )
}

export default ChapterDescription
