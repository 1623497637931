import React, { useEffect, useState } from 'react'
import { DatePicker, Input, Select } from 'antd';
import './leaveManagement.scss';
import LeaveReportTable from './LeaveReportTable';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import { Dayjs } from 'dayjs';
import { employeeTypeList, statusList } from './LeavePolicyForms/LeaveReportConstants';
import { setLeaveReportPayload } from '../../redux/slices/leaveReportPayloadSlice';

export default function LeaveReport() {

  const { RangePicker } = DatePicker;
  const leaveList = useSelector((state: any) => state?.leaveList?.leaveList);
  const designationDepartmentApi = useGetApiRequests('designationDepartmentList', 'GET');
  const getLeaveReportsApi = useGetApiRequests('getLeaveReport', 'GET');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const dispatch = useDispatch();
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [departmentList, setDepartmentList] = useState([])
  const [loader, setLoader] = useState(false);
  const [employeeName, setEmployeeName] = useState('')
  const [leaveType, setLeaveType] = useState('')
  const [department, setDepartment] = useState('')
  const [statusType, setStatusType] = useState('')
  const [employeeType, setEmployeeType] = useState('')
  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs] | null>(null);
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('');
  const [leavePolicyReports, setLeavePolicyReports] = useState<any>([])
  const [isSubmit, setIsSubmit] = useState(false)

  const handleRangePickerChange = (dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]) => {
    const [startDate, endDate]: any = dates;
    setDateStart(startDate.format('YYYY-MM-DD'));
    setDateEnd(endDate.format('YYYY-MM-DD'))
  };
  const addQueryParam = (queryParams: any, key: string, value: string | undefined, transform?: (val: string) => any) => {
    if (value && value !== '') {
      queryParams[key] = transform ? transform(value) : value;
    }
  };
  const regularDateFormat = (input_value: any) => {
    const apiDate = new Date(input_value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const result = `${day}.${month}.${year}`;
    return result;
  }
  const handleSubmit = () => {
    setLoader(true);
    setIsSubmit(true)
    const queryParams: any = {
      organisationId: orgId
    }
    addQueryParam(queryParams, 'employeeName', employeeName);
    addQueryParam(queryParams, 'leavePolicyId', leaveType, parseInt);
    addQueryParam(queryParams, 'departmentId', department, parseInt);
    addQueryParam(queryParams, 'leaveStatus', statusType);
    addQueryParam(queryParams, 'employementTypeId', employeeType, parseInt);
    addQueryParam(queryParams, 'fromDate', dateStart);
    addQueryParam(queryParams, 'toDate', dateEnd);

    if (!queryParams.employeeName && !queryParams.departmentId && !queryParams.employementTypeId) {
      triggerNotification('warning', '', ErrorMessages.leaveReportMandatory, 'topRight');
      setLoader(false);
    } else {
      dispatch(setLeaveReportPayload(queryParams));
      getLeaveReportsApi('', queryParams)
        .then((response: any) => {
          const reformedLeaveReports = response?.data?.data.map((item: any, index: number) => ({
            policyType: item?.policyType,
            employeeName: item?.employeeName,
            fromDate: item?.fromDate,
            toDate: item?.toDate,
            duration: item?.duration,
            status: item?.status,
            reason: item?.reason,
            key: index + 1,
            formattedFromDate: regularDateFormat(item?.fromDate),
            formattedToDate: regularDateFormat(item?.toDate)
          }));
          setLeavePolicyReports(reformedLeaveReports);
          setLoader(false);
        })
        .catch((err: any) => {
          triggerNotification('error', '', err?.message, 'topRight');
          setLoader(false);
        });
    }
  }
  const handleClear = () => {
    setEmployeeName('');
    setLeaveType('');
    setDepartment('');
    setStatusType('');
    setEmployeeType('');
    setDateStart('');
    setDateEnd('');
    setPickerValue(null);
    setIsSubmit(false)
  }
  useEffect(() => {
    const reformedLeaveType = leaveList.map((item: any) => ({
      value: item?.key,
      label: item?.leavePolicy
    }));
    setLeaveTypeOptions(reformedLeaveType);
    departmentGetListApi()
  }, [leaveList]);
  const departmentGetListApi = () => {
    const params: any = {
      organisationId: orgId,
    };
    setLoader(true);
    designationDepartmentApi('', params)
      .then((response: any) => {
        const reformedDepartmentsList = response?.data?.data.map((item: any) => ({
          value: item.departmentId,
          label: item.name,
        }));
        setDepartmentList(reformedDepartmentsList);
        setLoader(false)
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.message, 'topRight');
        setLoader(false)
      });
  };
  return (
    <div className='leave-report'>
      <div className='grid grid-cols-7  gap-2 mb-6'>
        <div>
          <p className='text-[#D34A7C] font-bold'>Employee name</p>
          <Input placeholder="Employee name" value={employeeName} onChange={(e: any) => setEmployeeName(e.target.value)} />
        </div>
        <div>
          <p className='text-[#D34A7C] font-bold'>Type of leave</p>
          <Select className='w-full' options={leaveTypeOptions} placeholder={'Leave Type'} value={leaveTypeOptions.filter((item: any) => item.value === leaveType)} onChange={(value: any) => setLeaveType(value)}></Select>
        </div>
        <div >
          <p className='text-[#D34A7C] font-bold'>Date of Leave</p>
          <RangePicker data-testid="pickerEl"
            value={pickerValue}
            onChange={(dates: any, dateStrings: any) => {
              handleRangePickerChange(dates, dateStrings);
              setPickerValue(dates);
            }}
          />
        </div>
        <div >
          <p className='text-[#D34A7C] font-bold'>Department</p>
          <Select className='w-full' options={departmentList} placeholder={'Select Department'} value={departmentList.filter((item: any) => item.value === department)} onChange={(value: any) => setDepartment(value)}></Select>
        </div>
        <div >
          <p className='text-[#D34A7C] font-bold'>Status</p>
          <Select className='w-full' options={statusList} placeholder={'Select Status'} value={statusList.filter((item: any) => item.value === statusType)} onChange={(value: any) => setStatusType(value)}></Select>
        </div>
        <div >
          <p className='text-[#D34A7C] font-bold'>Employee type</p>
          <Select className='w-full' options={employeeTypeList} placeholder={'Employee Type'} value={employeeTypeList.filter((item: any) => item.value === employeeType)} onChange={(value: any) => setEmployeeType(value)}></Select>
        </div>
        <div className='flex items-center pt-3'>
          <div className=' mr-1'>
            <button type='button' className='  secondary clear-filter' onClick={handleClear} >Clear filter</button>
          </div>
          <div >
            <button type='submit' className='addButton  primary pr-1 ' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
      <LeaveReportTable leavePolicyReports={leavePolicyReports} isSubmit={isSubmit} />
      {loader && <Loader />}
    </div>
  )
}