// stateHelpers.js

import { ErrorMessages, Yup, triggerNotification } from "../../../common/CommonExport";
import { addressSchema } from "../../BasicForm/AllSchema";

export const initialState = {
  name: '',
  adminEmailId: '',
  accountsEmailId: '',
  hrDeptId: '',
  hrName: '',
  hrEmailId: '',
  hrdesignation: '',
  systemVendor: '',
  probationPeriodDays: '',
  reviewMonth: '',
  notifyingPeriod: '',
  isSlackNotification: false,
  slackCode: '',
  mobileNumber: null,
  emailId: '',
  permanentAddress: {
    doorNo: '',
    street: '',
    city: '',
    state: '',
    pinCode: '',
    phoneNumber: '',
  },
  presentAddress: {
    doorNo: '',
    street: '',
    city: '',
    state: '',
    pinCode: '',
    phoneNumber: '',
  },
  domainName: '',
  ssoCreateUrl: '',
  ssoDeleteUrl: '',
  applocation1CreateUrl: '',
  applocation1DeleteUrl: '',
  emailService: '',
  code: '',
};

export const updateState = (state: any, field: any, value: any) => {
  if (field.includes('.')) {
    const [rootField, nestedField] = field.split('.');
    return {
      ...state,
      [rootField]: {
        ...state[rootField],
        [nestedField]: value,
      },
    };
  } else {
    return {
      ...state,
      [field]: value,
    };
  }
};

const setAddress = {
  doorNo: '',
  street: '',
  city: '',
  state: '',
  pinCode: '',
}

export const handleGetOrganisationDetailsById = async ({ getOrgDetails, orgId, setInitValues, setSelectedImage, setSelectedhrImage }: any) => {
  try {
    const response: any = await getOrgDetails('', { organisationId: 704 ?? orgId })
    setSelectedImage(response?.data?.data?.logo)
    setSelectedhrImage(response?.data?.data?.hrSignature)
    const initialValuesSet = {
      organisationId: response?.data?.data?.organisationId ?? '',
      name: response?.data?.data?.name ?? '',
      adminEmailId: response?.data?.data?.adminEmailId ?? '',
      accountsEmailId: response?.data?.data?.accountsEmailId ?? '',
      hrEmailId: response?.data?.data?.hrEmailId ?? '',
      noticePeriod: response?.data?.data?.notifyingPeriod ?? '',
      ceoMailId: response?.data?.data?.ceoMailId ?? '',
      emailId: response?.data?.data?.emailId ?? '',
      organisationType: {
        organisationTypeId: response?.data?.data?.organisationType?.organisationTypeId ?? ''
      },
      reviewMonth: response?.data?.data?.reviewMonth ?? '',
      fiscalYearStartDate: response?.data?.data?.fiscalYearStartDate ?? '',
      domainName: response?.data?.data?.domainName ?? '',
      mobileNumber: response?.data?.data?.mobileNumber ?? '',
      employeeDto: {
        presentAddress: response?.data?.data?.presentAddress ?? setAddress,
        permanentAddress: response?.data?.data?.permanentAddress ?? setAddress,
      }
    }
    setInitValues(initialValuesSet)
  } catch (err: any) {
    triggerNotification('error', '', err?.response?.data?.status?.message ?? ErrorMessages.somethingWentWrong, 'topRight');
  }
}

export const initialStateEnterprise: any = {
  name: '',
  adminEmailId: '',
  accountsEmailId: '',
  hrDeptId: '',
  hrName: '',
  hrEmailId: '',
  noticePeriod: '',
  ceoMailId: '',
  emailId: '',
  organisationType: {
    organisationTypeId: '',
  },
  reviewMonth: '',
  fiscalYearStartDate: '',
  domainName: '',
  mobileNumber: '',
  employeeDto: {
    presentAddress: {
      doorNo: '',
      street: '',
      city: '',
      state: '',
      pinCode: '',
    },
    permanentAddress: {
      doorNo: '',
      street: '',
      city: '',
      state: '',
      pinCode: '',
    },
  },
}

export const enterpriseSchema = Yup.object().shape({
  name: Yup.string().required('Organisation Name is required'),
  adminEmailId: Yup.string().required('Admin Mail Id is required').email('Invalid email format'),
  accountsEmailId: Yup.string().required('Finance Mail Id is required').email('Invalid email format'),
  hrEmailId: Yup.string().required('HR Mail Id is required').email('Invalid email format'),
  emailId: Yup.string().required('Organisation Email Id is required').email('Invalid email format'),
  noticePeriod: Yup.string().required('Notice Period is required'),
  ceoMailId: Yup.string().required('CEO Mail Id is required').email('Invalid email format'),
  organisationType: Yup.object().shape({
    organisationTypeId: Yup.string().required('Industry Type is required'),
  }),
  reviewMonth: Yup.string().required('Review Month is required'),
  fiscalYearStartDate: Yup.string().required('Financial Year Start Date is required'),
  domainName: Yup.string().required('Domain Name is required'),
  mobileNumber: Yup.string()
    .required('Phone Number is required')
    .matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
  employeeDto: Yup.object().shape({
    presentAddress: addressSchema,
    permanentAddress: addressSchema,
  }),
});

export const noticePeriodList = [{ value: 30 }, { value: 60 }, { value: 90 }, { value: 120 }, { value: 150 }]
export const reviewMonthList = [{ value: 3 }, { value: 6 }, { value: 9 }, { value: 12 }]