import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
interface InputProps {
    type: string;
    id: string;
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    placeholder?: string;
    label: string;
    control: any;
    hasError: boolean;
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(({ type, id, name, onChange, required, placeholder, label, control, hasError }, ref) => {
    const inputClasses = `${hasError ? 'text-red-600 border-solid border-red-400' : 'text-gray-900 border-gray-300 dark:border-gray-500'} block py-3 px-0 text-sm bg-transparent appearance-none dark:focus:border-blue-500 focus:outline-none peer w-full`;
    const labelClasses = `${hasError ? 'text-red-500' : 'text-gray-500 dark:text-gray-400'
        } absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 input-custom`;
    return (
        <div className='relative'>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <>
                        <input type={type} id={id} className={inputClasses} placeholder={placeholder ?? ' '} {...field} ref={ref} />
                        <label className={labelClasses}>
                            {label}
                        </label>
                    </>
                )}
            />
        </div>
    );
});
Input.displayName = 'Input';

export default Input;
