import React, { useState, useEffect } from 'react'
import FloatLabel from '../../../../../../components/FloatLabel'
import { Input } from 'antd'
import { useSelector } from 'react-redux';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { createRequest, updateRequet } from './AddEditSalaryApiCalls';
import { addEditEmployeeContribution, addEditEmployerContribution, addEditFixedEmployer, addEditFixedSalary, addEditSalaryParitulars } from './AddEditCalculations';
import Loader from '../../../../../../components/Loader/Loader';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const AddSalaryComponent = (props: any) => {
  const { historyView, isEdit, grossSalary, employeeSalaryId, empTypeId, IndianCurrencyFormat, date, employeeId, salaryParticularsProp, employeeContributionProp, employerContributionProp, costToCompany, netAmount, fixedSalaryParticularsProp, fixedEmployerContributionProp, handleCloseModal, employeeSalaryGeTApi, isRevised } = props;
  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [fixedSalaryParticulars, setFixedSalaryParticulars] = useState<any>([]);
  const [fixedEmployerContribution, setFixedEmployerContribution] = useState<any>([]);
  const [concatSalaryParticulars, setConcatSalaryParticulars] = useState<any>([]);
  const [concatEmployerContribution, setConcatEmployerContribution] = useState<any>([]);
  const [grossSalaryMonthly, setGrossSalaryMonthly] = useState<any>(0);
  const [grossSalaryYearly, setGrossSalaryYearly] = useState<any>(0);
  const [ctcAmountMonthly, setCtcAmountMonthly] = useState<number>(0);
  const [ctcAmountYearly, setCtcAmountYearly] = useState<number>(0);
  const [netAmountMonthly, setNetAmountMonthly] = useState<number>(0);
  const [netAmountAnnual, setNetAmountAnnual] = useState<number>(0);
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const internLables = useGetApiRequests('employeeType', 'GET');
  const regularLables = useGetApiRequests('salaryLabelList', "GET");
  const createSalaryApi = useGetApiRequests('createEmployeeSalary', 'POST');
  const editSalaryApi = useGetApiRequests('updateEmployeeSalary', 'PUT');

  const checkValueGreaterThanZero = (value: any) => {
    if (Number(value) > 0) {
      return Number(value);
    }
    return 0;
  }
  const indianCurrencyChanges = (numberValue: any) => {
    return Number(numberValue).toLocaleString('en-IN');
  }
  const getRegularSideLables = () => {
    const params: any = {
      organisationId: orgId,
    };
    setLoader(true)
    regularLables('', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        const salaryParticularsData = addEditSalaryParitulars(apiResponse)
        const employerContributionData = addEditEmployerContribution(apiResponse)
        const tempFixedSalaryParticulars = addEditFixedSalary(apiResponse)
        const tempFixedEmployerContribution = addEditFixedEmployer(apiResponse)
        const tempEmployeeContribution = addEditEmployeeContribution(apiResponse)

        setSalaryParticulars(salaryParticularsData);
        setFixedSalaryParticulars(tempFixedSalaryParticulars);
        const concatenatedSalaryArray = [...salaryParticularsData, ...tempFixedSalaryParticulars];
        setConcatSalaryParticulars(concatenatedSalaryArray);

        setEmployerContribution(employerContributionData);
        setFixedEmployerContribution(tempFixedEmployerContribution);

        const concatenatedEmployer = [...employerContributionData, ...tempFixedEmployerContribution];
        setConcatEmployerContribution(concatenatedEmployer);

        setEmployeeContribution(tempEmployeeContribution)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };
  const getInternSideLables = () => {
    const params: any = {
      id: empTypeId,
    };
    setLoader(true)
    internLables('', '', params)
      .then((response: any) => {
        const emoyeeTypeSalaryComponentMap = response?.data?.data?.emoyeeTypeSalaryComponentMap;
        const mappedSalaryComponent = emoyeeTypeSalaryComponentMap.map((item: any) => item.salaryComponent);
        const salaryParticularsDataIntern = addEditSalaryParitulars(mappedSalaryComponent)
        const employerContributionDataIntern = addEditEmployerContribution(mappedSalaryComponent)
        const tempFixedSalaryParticularsIntern = addEditFixedSalary(mappedSalaryComponent)
        const tempFixedEmployerContributionIntern = addEditFixedEmployer(mappedSalaryComponent)
        const tempEmployeeContributionIntern = addEditEmployeeContribution(mappedSalaryComponent)
        setSalaryParticulars(salaryParticularsDataIntern);
        setFixedSalaryParticulars(tempFixedSalaryParticularsIntern);
        const concatenatedSalaryArray = [...salaryParticularsDataIntern, ...tempFixedSalaryParticularsIntern];
        setConcatSalaryParticulars(concatenatedSalaryArray);

        setEmployerContribution(employerContributionDataIntern);
        setFixedEmployerContribution(tempFixedEmployerContributionIntern)

        const concatenatedEmployer = [...employerContributionDataIntern, ...tempFixedEmployerContributionIntern];
        setConcatEmployerContribution(concatenatedEmployer);

        setEmployeeContribution(tempEmployeeContributionIntern)
        setLoader(false)

      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };
  useEffect(() => {
    if (isEdit) {
      setLoader(true);
      setGrossSalaryMonthly(grossSalary?.grossAmountMonthly);
      setGrossSalaryYearly(grossSalary?.grossAmountAnnual);
      setCtcAmountMonthly(costToCompany?.ctcMonthly);
      setCtcAmountYearly(costToCompany?.ctcYearly);
      setNetAmountMonthly(netAmount?.monthly);
      setNetAmountAnnual(netAmount?.yearly);
      //setting employee contribution
      setEmployeeContribution(employeeContributionProp)

      //setting salary Particulars of the employee
      const salaryParticular = salaryParticularsProp;

      const fixedSalary = fixedSalaryParticularsProp;
      const fixedSalaryIds = new Set(fixedSalary.map((item: any) => item.salaryComponentId));
      const filteredSalaryParticular = salaryParticular.filter((item: any) => !fixedSalaryIds.has(item.salaryComponentId));
      const mergerdSalary = [...filteredSalaryParticular, ...fixedSalaryParticularsProp];
      setFixedSalaryParticulars(fixedSalaryParticularsProp);
      setSalaryParticulars(filteredSalaryParticular);
      setConcatSalaryParticulars(mergerdSalary);

      //setting employer contribution
      const contributionOfEmployer = employerContributionProp;
      const fixedEmpContribution = fixedEmployerContributionProp;
      setEmployerContribution(contributionOfEmployer)

      const fixedEmployeerSalary = new Set(fixedEmpContribution.map((item: any) => item.salaryComponentId));
      const filteredEmployer = contributionOfEmployer.filter((item: any) => !fixedEmployeerSalary.has(item.salaryComponentId));
      if (filteredEmployer.length === 0) {
        setFixedEmployerContribution(fixedEmpContribution);
        setConcatEmployerContribution(fixedEmpContribution);
      } else {
        setFixedEmployerContribution(fixedEmpContribution);
        const conCatEmployer = [...filteredEmployer, ...fixedEmpContribution]
        setConcatEmployerContribution(conCatEmployer);
      }
      setLoader(false);
    } else if (empTypeId === '2') {
      getInternSideLables();
    } else if (empTypeId === '1') {
      getRegularSideLables();
    }

  }, []);

  const changeGross = (value: string, type: string) => {
    const inputValue = value.trim();
    if (/^\d*$/.test(inputValue) || inputValue === '') {
      const numericValue = inputValue === '' ? 0 : parseInt(inputValue);
      let grossMonth: number = 0;
      let grossYear: number = 0;

      if (type === 'monthly') {
        setGrossSalaryMonthly(numericValue);
        setGrossSalaryYearly(numericValue * 12)
        grossMonth = numericValue;
        grossYear = numericValue * 12;
      } else {
        setGrossSalaryYearly(numericValue);
        setGrossSalaryMonthly(numericValue / 12);
        grossMonth = numericValue / 12;
        grossYear = numericValue;
      }
      calculateSalaryParticulars(grossMonth, grossYear);
    }
  }

  function calculateSalaryParticulars(grossMonth: any, grossYear?: any) {
    if (orgId === 563) {
      calculateSalaryParticularsForKrissco(grossMonth, grossYear);
    }
    else {
      calculateSalaryParticularsForCNW(grossMonth, grossYear);
    }
  }
  const calculateSalaryParticularsForKrissco = (grossMonth: any, grossYear?: any) => {
    let tempFixedSalaryParticularsTotal: number = 0;
    let tempDAMonthly: number = 0;
    let tempBasicMonthly: number = 0;

    const updatedSalaryParticulars = salaryParticulars.map((item: any) => {
      item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      const monthValue = ((grossMonth - tempFixedSalaryParticularsTotal) * item.percent * 2) / (100 * 3);
      item.monthly = (checkValueGreaterThanZero(monthValue));
      tempBasicMonthly = item.monthly;
      item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      return item;
    });

    setSalaryParticulars(updatedSalaryParticulars);

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'DA') {
        const monthValue = tempBasicMonthly / 2;
        item.monthly = (checkValueGreaterThanZero(monthValue));
        tempDAMonthly = item.monthly;
        item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      }
    });

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'HRA') {
        const monthValue = grossMonth - tempBasicMonthly - tempDAMonthly;
        item.monthly = (checkValueGreaterThanZero(monthValue));
        item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      }
    });

    calculateCTC(grossMonth, grossYear);
  };
  const calculateSalaryParticularsForCNW = (grossMonth: any, grossYear?: any) => {
    let updatedSalaryParticulars;
    let medicalAllowanceAmount = 0;
    let conveyanceAmount = 0;
    salaryParticulars.forEach((item: any) => {
      switch (item.name) {
        case "Medical Allowance":
          medicalAllowanceAmount = item.amount;
          break;
        case "Conveyance":
          conveyanceAmount = item.amount;
          break;
        default:
          break;
      }
    });

    let tempFixedSalaryParticularsTotal: number = 0;
    let basicSalaryMonthly: number = 0;

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'LTA') {
        if (Number(grossMonth) > 36803) {
          tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
        }
      }
      else {
        tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
      }
    });

    if (empTypeId === '2' && isEdit) {
      updatedSalaryParticulars = salaryParticulars.map((item: any) => {
        let monthValue = ((Number(grossMonth) - conveyanceAmount - medicalAllowanceAmount) * (Number(item.percent))) / 100;
        if (item.name === 'Basic Salary') {
          basicSalaryMonthly = Number(monthValue);
        } else if (item.name === 'Conveyance') {
          monthValue = 1600;
        } else if (item.name === 'Medical Allowance') {
          monthValue = 1250;
        }

        return {
          ...item,
          monthly: (item.name === 'Conveyance') ? 1600 : (item.name === 'Medical Allowance') ? 1250 : (checkValueGreaterThanZero(monthValue)),
          yearly: (item.name === 'Conveyance') ? 1600 * 12 : (item.name === 'Medical Allowance') ? 1250 * 12 : (checkValueGreaterThanZero(monthValue) * 12),
        };

      });
    } else {
      updatedSalaryParticulars = salaryParticulars.map((item: any) => {
        let monthValue = ((Number(grossMonth) - tempFixedSalaryParticularsTotal) * (Number(item.percent))) / 100;
        if (item.name === 'Basic Salary') {
          basicSalaryMonthly = Number(monthValue);
        }

        return {
          ...item,
          monthly: (checkValueGreaterThanZero(monthValue)),
          yearly: (checkValueGreaterThanZero(monthValue) * 12),
        };

      });
    }
    // setSalaryParticulars(updatedSalaryParticulars);
    const concatenatedSalaryArray = [...updatedSalaryParticulars, ...fixedSalaryParticulars];
    setConcatSalaryParticulars(concatenatedSalaryArray);
    fixedEmployerContribution.forEach((item: any) => {
      if (item.name === 'Gratuity') {
        item.yearly = ((checkValueGreaterThanZero(basicSalaryMonthly) * 15) / 26);
      }
    });
    calculateCTC(grossMonth, grossYear);
  };

  const calculateCTC = (grossMonth: any, grossYear: any) => {
    const tempEmployerContribution = [...employerContribution];
    const fixedEmployerContributionTemp = [...fixedEmployerContribution]
    let tempMonthlyCTC: number = grossMonth;
    tempEmployerContribution.forEach((item: any) => {
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          item.monthly = ((checkValueGreaterThanZero(grossMonth) * 3.25) / 100);
          item.yearly = (Number(((checkValueGreaterThanZero(grossMonth)) * 3.25) / 100) * 12);
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    setCtcAmountMonthly((checkValueGreaterThanZero(tempMonthlyCTC)));

    let tempYearlyCTC: number = (tempMonthlyCTC) * 12;

    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.monthly === 0) {
        tempYearlyCTC += item.yearly;
      }
    });
    setCtcAmountYearly((tempYearlyCTC));
    const employerConCat = [...tempEmployerContribution, ...fixedEmployerContributionTemp]
    setConcatEmployerContribution(employerConCat);
    calculateEmployeeContribution(grossMonth, grossYear)
  };

  const calculateEmployeeContribution = (grossMonth: any, grossYear: any) => {
    let tempEmployeeContributionMonthlyTotal: number = 0;
    let tempEmployeeContributionYearlyTotal: number = 0;
    const tempEmployeeContribution = [...employeeContribution]

    tempEmployeeContribution.forEach((item: any) => {
      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          let tempMonthly = (grossMonth * 0.75) / 100;
          item.monthly = (checkValueGreaterThanZero(tempMonthly));
          item.yearly = (checkValueGreaterThanZero(item.monthly * 12));
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }

      tempEmployeeContributionMonthlyTotal = tempEmployeeContributionMonthlyTotal + Number(item.monthly);
      tempEmployeeContributionYearlyTotal = tempEmployeeContributionYearlyTotal + Number(item.yearly);
    });
    setEmployeeContribution(tempEmployeeContribution)
    let tempNetAmountMonthly = grossMonth - tempEmployeeContributionMonthlyTotal;
    let tempNetAmountAnnual = grossYear - tempEmployeeContributionYearlyTotal;

    setNetAmountMonthly((checkValueGreaterThanZero(tempNetAmountMonthly)));
    setNetAmountAnnual((checkValueGreaterThanZero(tempNetAmountAnnual)));
  };

  const determineRequestMethod = () => (isEdit ? 'PUT' : 'POST');

  const createPayload = () => {
    setLoader(true);
    const employeeSalaryDetails: any = []; // Defining employeeSalaryDetails

    return {
      ctc: Math.round(ctcAmountYearly),
      employee: { employeeId: parseInt(employeeId) },
      employeeSalaryDetails,
      packageType: 'yearly',
      status: 'Draft',
      appraisalEffectiveFrom: isRevised || isEdit ? date : null,
    };
  };

  const handleSave = () => {
    if (grossSalaryMonthly > 0 && grossSalaryYearly > 0) {
      setLoader(true);
      const requestMethod: string = determineRequestMethod();
      let requestPayload = createPayload();
      let allList = [...concatSalaryParticulars, ...concatEmployerContribution, ...employeeContribution];
      let tempList: any = [];

      allList.forEach((item: any) => {
        if (item.name !== 'LTA' || (item.name === 'LTA' && grossSalaryMonthly > 36803)) {
          tempList.push({
            "monthly": (Number(item.monthly)),
            "salaryComponentId": item.salaryComponentId,
            "yearly": (Number(item.yearly)),

          },)
        }
        else {
          tempList.push({
            "monthly": 0,
            "salaryComponentId": item.salaryComponentId,
            "yearly": 0,
          },
            // {"appraisalEffectiveFrom":revised}
          )
        }
      })
      if (tempList) {
        let salaryDetails = tempList.filter((elm: any) => {
          if (elm.yearly >= 0) {
            return elm;
          }
        });
        if (salaryDetails.length !== 0) {
          requestPayload.employeeSalaryDetails = salaryDetails;
          if (requestMethod === 'PUT') {
            updateRequet(requestPayload, handleCloseModal, employeeSalaryGeTApi, employeeSalaryId, editSalaryApi, setLoader);
          } else {
            createRequest(requestPayload, handleCloseModal, employeeSalaryGeTApi, createSalaryApi, setLoader);

          }
        }
      }
    } else {
      triggerNotification('warning', '', ErrorMessages?.grossSalaryRequire, 'topRight');
    }

  };
  return (
    <>
      <div className="salary_prticulars-container px-4 ">
        <div className="salary_prticulars-list overflow-y-auto">
          <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
            <div className="particulars">
              <p className='_header mb-5'>Salary Particulars</p>
              <ul>
                {concatSalaryParticulars
                  .filter((item: any) => !(item.salaryComponentId === 132 && item.name === 'LTA' && grossSalaryMonthly <= 36803))
                  .map((item: any) => (
                    <li key={item?.salaryComponentId}>{item?.name}</li>
                  ))}
              </ul>
              <p className='salary-gross'>Gross Salary</p>
            </div>
            <div className="monthly_details">
              <p className='_monthly'>Monthly </p>
              <ul>
                {concatSalaryParticulars
                  .filter((item: any) => !(item.salaryComponentId === 132 && item.name === 'LTA' && grossSalaryMonthly <= 36803))
                  .map((item: any) => (
                    <li key={item?.salaryComponentId}>
                      &#8377;{item?.monthly !== '' ? IndianCurrencyFormat(item?.monthly) : 0}
                    </li>
                  ))}
              </ul>
              {!historyView ? (
                <div className='form-item basicForm-fields' >
                  <FloatLabel name='salary'><span className='mr-1'>&#8377;</span>
                    <Input className='float-control' value={grossSalaryMonthly} onChange={(e) => { changeGross(e.target.value, "monthly") }} />
                  </FloatLabel>
                </div>) : (
                <div className='form-item basicForm-fields' >
                  <p className='font-bold'>{indianCurrencyChanges(grossSalaryMonthly)}</p>
                </div>
              )}
            </div>
            <div className="annual_details">
              <p className='_annual'>Annual </p>
              <ul>
                {concatSalaryParticulars
                  .filter((item: any) => !(item.salaryComponentId === 132 && item.name === 'LTA' && grossSalaryMonthly <= 36803))
                  .map((item: any) => (
                    <li key={item?.salaryComponentId}>
                      &#8377;{item?.yearly !== '' ? IndianCurrencyFormat(item?.yearly) : 0}
                    </li>
                  ))}
              </ul>
              {!historyView ? (
                <div className='form-item basicForm-fields' >
                  <FloatLabel name='salary'><span className='mr-1'>&#8377;</span>
                    <Input className='float-control' value={parseFloat(grossSalaryYearly.toFixed(2))} onChange={(e) => { changeGross(e.target.value, "yearly") }} />
                  </FloatLabel>
                </div>) : (
                <div className='form-item basicForm-fields' >
                  <p className='font-bold'>{indianCurrencyChanges(grossSalaryYearly)}</p>
                </div>
              )}
            </div>
          </div>

          <div className='_header mb-5'>Employer Contribution</div>
          <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
            <div className="particulars">
              <ul>
                {concatEmployerContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>{item?.name}</li>
                ))}
              </ul>

              <p className='salary-gross'>Cost To Company</p>

            </div>
            <div className="particulars">


              <ul>

                {concatEmployerContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>&#8377;{IndianCurrencyFormat(item?.monthly)}</li>
                ))}
              </ul>
              <p className='salary-gross'>&#8377; {IndianCurrencyFormat(ctcAmountMonthly)}</p>
            </div>
            <div className="particulars">
              <ul>

                {concatEmployerContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>&#8377;{IndianCurrencyFormat(item?.yearly)}</li>
                ))}
              </ul>
              <p className='salary-gross' >&#8377;  {IndianCurrencyFormat(Math.round(ctcAmountYearly))} </p>
            </div>
          </div>
          <div className='_header mb-5'>Employee Contribution</div>
          <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
            <div className="particulars">
              <ul>
                {employeeContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>{item?.name}</li>
                ))}
              </ul>
              <p className='salary-gross'>Net Salary</p>
            </div>
            <div className="particulars">
              <ul>
                {employeeContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>&#8377;{IndianCurrencyFormat(item?.monthly)}</li>
                ))}

              </ul>
              <p className='salary-gross'>&#8377; {IndianCurrencyFormat(netAmountMonthly)}</p>
            </div>
            <div className="particulars">
              <ul>
                {employeeContribution.map((item: any) => (
                  <li key={item?.salaryComponentId}>&#8377;{IndianCurrencyFormat(item?.yearly)}</li>
                ))}
              </ul>
              <p className='salary-gross' >&#8377; {IndianCurrencyFormat(netAmountAnnual)}</p>
            </div>
          </div>
        </div>
      </div>
      {!historyView && (
        <div className="flex justify-end mr-10">
          <button className='addButton' type='submit' onClick={handleSave}>Submit </button>
        </div>)}
      {loader && <ModalLoader />}
    </>
  )
}

export default AddSalaryComponent
