import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import AddNewChapterInCourseSide from "../assessment/chapter/AddNewChapterInCourseSide";
import QuestionAllocation from "../assessment/chapter/QuestionAllocation";
import UploadBulkQuestions from "../assessment/chapter/UploadBulkQuestions";
import { setCurrentTab } from "../../../redux/slices/courseSlice";
import MultiChoiceQuestions from "../assessment/chapter/multichoice-questions/MultiChoiceQuestions";
import { setAssessmentAssignTab } from "../../../redux/slices/currentCourseDetailSlics";
import AssignChapter from "../assessment/chapter/AssignChapter";

const { TabPane } = Tabs;

const EditChapterAndAssessment = ({ handle }: any) => {
  const dispatch = useDispatch()
  const reduxToken = useSelector((state: any) => state?.courseSlice.courseTab);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const [activeTabKey, setActiveTabKey] = useState('1');

  const handleTabChange = (key: string) => {
    dispatch(setAssessmentAssignTab())
    dispatch(setCurrentTab(6))
    setActiveTabKey(key);
  };

  const handleBackParent = () => {
    setActiveTabKey('1')
  }

  const items = [
    {
      key: '1',
      label: newChapterFlow ? 'Add Chapter' : 'Edit Chapter',
      children: reduxToken === 3 ? <AssignChapter /> : <AddNewChapterInCourseSide handleTabChange={handleTabChange} />,
    },
    {
      key: '2',
      label: 'Assessment',
      children: reduxToken === 9 ? <UploadBulkQuestions /> : reduxToken === 10 ? <MultiChoiceQuestions />
        : <QuestionAllocation handle={handle} handleBackParent={handleBackParent} />,
    },
  ]

  return (
    <div className="w-full edit-chapter-content">
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        {items.map(item => (
          <TabPane key={item.key} tab={item.label}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default EditChapterAndAssessment