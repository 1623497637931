import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ErrorMessages, debounce, triggerNotification, useGetApiRequests, useSelector } from '../../../common/CommonExport';
import DepartmentRender from './DepartmentRender';
import { AppContextData } from '../OrganisationsInterfaces';
import { PaginationProps } from 'antd';
import Loader from '../../../components/Loader/Loader';

export const DepartmentContext = createContext<AppContextData>({} as AppContextData);

const Organization: React.FC = () => {
    const [departments, setDepartments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const [count, setCount] = useState<number>();
    const [loader, setLoader] = useState(false);

    const departmentApi = useGetApiRequests('department', 'GET');

    const onSearch = debounce((searchTerm: string) => {
        departmentGetApi(searchTerm);
    }, 300);

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, size) => {
        setCurrentPage(1);
        setPageSize(size);
    };
    const departmentGetApi = (searchTerm = '') => {
        if (searchTerm === '') {
            setLoader(true);
        } else {
            setLoader(false)
        }
        const start: number = (currentPage - 1 === 0) ? 0 : currentPage - 1;
        const params: any = {
            start: start,
            limit: pageSize,
            organisationId: orgId,
            isActive: true,
        };
        if (searchTerm !== '') {
            params.search = searchTerm
        }
        departmentApi('', params)
            .then((response: any) => {
                const reformedDepartments = response?.data?.data.map((item: any, index: number) => ({
                    description: item.description,
                    email: item.leadEmail,
                    hod: item.headOfDepartment.firstName + ' ' + item.headOfDepartment.lastName,
                    hodId: item.headOfDepartment.employeeId,
                    department: item.name,
                    key: item.departmentId,
                }));
                setDepartments(reformedDepartments);
                setCount(response?.data?.totalResults)
                setLoader(false);
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false);

            });
    };
    useEffect(() => {
        const departmentTag: any = document.getElementsByClassName("ant-table-content")
        departmentTag[0].scrollTop = 0
        departmentGetApi();
    }, [currentPage, pageSize])

    const contextValue = useMemo(() => {
        return {
            departments,
            departmentGetApi,
            onSearch,
            onShowSizeChange,
            currentPage,
            pageSize,
            count,
            setCurrentPage
        };
    }, [departments]);


    return (
        <div>
            <DepartmentContext.Provider value={contextValue}>
                <DepartmentRender />
            </DepartmentContext.Provider>
            {loader && <Loader />}
        </div>
    );
};
export default Organization;
