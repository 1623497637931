import { Yup } from '../../../../common/CommonExport';
import { setImagePreviewRemove } from '../../../../redux/slices/updateProfileSlice';

const generateSelectFieldSchema = (label: any) => Yup.string().required(`${label} is required`);

export const generateYupSchemas = (fieldsArray: any) => {
    const schemas: any = {};
    fieldsArray.forEach((field: any) => {
        const { pdfFormField }: any = field;
        schemas[pdfFormField] = generateSelectFieldSchema(pdfFormField);
    });
    return Yup.object().shape(schemas);
};

export const generateInitialValues = (fieldsArray: any) => {
    const initialValues: any = {};
    fieldsArray.forEach((field: any) => {
        initialValues[field.pdfFormField] = field?.dbField ?? '';
    });
    return initialValues;
};

export const clearStates = (dispatch: any, setMediaData: any, setInitialValues: any, setCertificateFields: any, setValidationSchema: any, setCertificateName: any, setCurrentCertificateURL: any, setCurrentCertificateId: any, setPreName: any) => {
    dispatch(setImagePreviewRemove());
    setMediaData('');
    setInitialValues({});
    setCertificateFields([]);
    setValidationSchema(null);
    setCertificateName('');
    setCurrentCertificateURL('');
    setCurrentCertificateId('');
    setPreName('');
};
