import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  employeeList: any;
}

const initialState: AuthState = {
  employeeList: [],
};

const employeeListSlice = createSlice({
  name: 'employeeList',
  initialState,
  reducers: {
    setEmployeeList: (state, action: PayloadAction<any>) => {
      state.employeeList = action.payload;
    },
    clearEmployeeList: state => {
      state.employeeList = [];
    },
  },
});

export const { setEmployeeList, clearEmployeeList } = employeeListSlice.actions;
export default employeeListSlice.reducer;
