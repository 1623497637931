import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  leaveList: any;
}

const initialState: AuthState = {
  leaveList: [],
};

const leaveListSlice = createSlice({
  name: 'leaveList',
  initialState,
  reducers: {
    setLeaveList: (state, action: PayloadAction<any>) => {
      state.leaveList = action.payload;
    },
    clearLeaveList: state => {
      state.leaveList = [];
    },
  },
});

export const { setLeaveList, clearLeaveList } = leaveListSlice.actions;
export default leaveListSlice.reducer;
