import React from 'react'
import { Steps } from 'antd';
import ChapterDescription from './ChapterDescription';

const EmployeeProgressLearningPath = ({ stepperData }: any) => {
  return (
    <div className='pl-8 pr-2 py-6 employee-progress_stepper'>
      <p className='text-primary  mb-3 rounded-md font-rubik font-bold' >List of Chapter</p>
      <div className=' overflow-y-auto max-h-[55vh]'>
        {stepperData && stepperData.length > 0 ? (
          <Steps
            direction="vertical"
            current={1}
            items={stepperData && stepperData.map((step: any, index: any) => ({
              title: (
                <p className={`text-[#393939] text-[18px] font-semibold ${step?.employeeQuizStatus === 'NS' || step?.employeeQuizStatus === 'YS' ? 'text-[#949494]' : ''}`}>{`Chapter ${index + 1}: ${step.chapterName}`}</p>
              ),
              description: (
                <ChapterDescription
                  datas={step}
                />
              ),
              className: `${(step.employeeQuizStatus === "YS" || step.employeeQuizStatus === "NS") ? "bg_yet-start" :
                (step.employeeQuizStatus === "CO") ? "bg_completed" :
                  (step.employeeQuizStatus === "IP") ? "bg_yellow" : ''}`,
            }))}
          />
        ) : (
          <p>No Chapters found</p>
        )}
      </div>
    </div>

  )
}
export default EmployeeProgressLearningPath

