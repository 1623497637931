import { Modal } from 'antd'
import React, { useState } from 'react'
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';

export default function LeaveRequestConfirmation(props: any) {
  const { closeConfirmationModal, setCloseConfirmationModal, type, setType, approvalObject, getLeavePolicyApi } = props;
  const [open, setOpen] = useState(closeConfirmationModal)
  const leavePloicyApprovalApi = useGetApiRequests('updateLeaveRequest', 'PUT');
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setType('');
    setCloseConfirmationModal(false);
    setOpen(false);
  }
  const handleLeaveRequest = () => {
    setLoader(true)
    const payload: object = {
      status: type === 'approve' ? 'approved' : 'declined',
      employeeLeaveRequestId: approvalObject?.key,
      startDate: approvalObject?.from,
      endDate: approvalObject?.to,
      duration: approvalObject?.duration,
      reason: approvalObject?.reason
    }
    const params: object = {
      employeeLeaveRequestId: approvalObject.key,
    }
    leavePloicyApprovalApi(payload, {}, params)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        return getLeavePolicyApi(); // Trigger getLeavePolicyApi
      })
      .then((res: any) => {
        setLoader(false);
        setType('');
        setCloseConfirmationModal(false);
        setOpen(false);
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.detail, 'topRight');
        setLoader(false);
        setType('');
        setCloseConfirmationModal(false);
        setOpen(false);
      })
  }
  return (
    <div>
      <Modal className='close-confirmation-popup w-[545px]' centered open={open} onCancel={handleClose} footer={null} maskClosable={false}>
        <div className="'appraisal-increment mt-4">
          <p className='font-medium'>Do you want to {type} this request?</p>
          <div className='flex justify-end salary_add-btn mt-7'>
            <button className='addButton secondary mr-4' onClick={handleClose}>Cancel</button>
            <button type='button' className='addButton' onClick={handleLeaveRequest}>{type === 'approve' ? 'Approve' : 'Decline'}</button>
          </div>
        </div>
      </Modal>
      {loader && <Loader />}
    </div>
  )
}
