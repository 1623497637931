import React from 'react'
import { ErrorMessage } from 'formik'
import { Input, Select } from 'antd'
import { selectMultipleOption } from '../../../../Organization/TableColumn'
import UploadImages from './UploadImages'

const MultipleQuestions = ({ values, setFieldValue, mediaData, setMediaData, previewImage }: any) => {
  return (
    <>
      <div className="flex">
        <div className="w-3/5 flex flex-col">
          <div className="addcourse-field pt-5">
            <p>Question</p>
            <Input.TextArea className='bg-[#F5F5F5]'
              name="question"
              value={values.question}
              placeholder="Question"
              onChange={(e: any) => setFieldValue('question', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="question" component="div" className="error-message" />
          </div>
          <div className="addcourse-field pt-5">
            <p>Answer explanation (optional)</p>
            <Input.TextArea
              name="explanation"
              value={values.explanation}
              placeholder="Explanation"
              onChange={(e: any) => setFieldValue('explanation', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="explanation" component="div" data-testid="err-message" className="error-message" />
          </div>
          <div className="w-full flex pt-5">
            <div className="chapter-field w-1/2">
              <p>Option 01</p>
              <Input
                type="text"
                name="option1"
                value={values.option1}
                onChange={(e) => setFieldValue('option1', e.target.value)}
              />
              <ErrorMessage name="option1" component="div" data-testid="err-message" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field pl-4 w-full">
                <p>Option 02</p>
                <Input
                  data-testid="option2Input"
                  type="text"
                  name="option2"
                  value={values.option2}
                  onChange={(e) => setFieldValue('option2', e.target.value)}
                />
                <ErrorMessage name="option2" component="div" data-testid="err-message" className="error-message" />
              </div>
            </div>
          </div>
          <div className="w-full flex pt-5">
            <div className="chapter-field w-1/2">
              <p>Option 03</p>
              <Input
                data-testid="option3Input"
                type="text"
                name="option3"
                value={values.option3}
                onChange={(e) => setFieldValue('option3', e.target.value)}
              />
              <ErrorMessage name="option3" component="div" data-testid="err-message" className="error-message" />
            </div>
            <div className='w-1/2'>
              <div className="chapter-field pl-4 w-full">
                <p>Option 04</p>
                <Input
                  data-testid="option4Input"
                  type="text"
                  name="option4"
                  value={values.option4}
                  onChange={(e) => setFieldValue('option4', e.target.value)}
                />
                <ErrorMessage name="option4" component="div" data-testid="err-message" className="error-message" />
              </div>
            </div>
          </div>
        </div>
        <div className='w-2/5 pt-5 pl-8 upload-error-tooltip multi-ques'>
          <label className='addcourse-lable pl-2' htmlFor="firstName">Question Image</label>
          <UploadImages setMediaData={setMediaData} mediaData={mediaData} previewImage={previewImage} />
          <div className="chapter-field mb-5">
            <p>Set correct answer</p>
            <Select
              data-testid="selectInput"
              className="chapter-field-grey w-1/2 " value={values.correctAnswer}
              onChange={(value) => setFieldValue('correctAnswer', value)}>
              {selectMultipleOption?.map((item: any) => (
                <Select.Option key={item?.value} value={item?.value}>
                  {item?.text}
                </Select.Option>
              ))}
            </Select>
            <ErrorMessage name="correctAnswer" component="div" data-testid="err-message" className="error-message" />
          </div>
        </div>
      </div>
    </>
  )
}

export default MultipleQuestions