import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import EditOrganisation from '../Organization/EditOrganisation/EditOrgnization';
import CreateOrganisationEnterprise from './CreateOrganisationEnterprise';

const CreateOrganisation = (props: any) => {
  const { isopen, handelClose } = props;
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(isopen)
  }, [isopen])

  const handleCloseModal = () => {
    setOpen(false)
    handelClose()
  }

  return (
    <Modal className='addleaveapolicy' title="Create Organisation" centered open={open} onCancel={() => handleCloseModal()} footer={null} maskClosable={false}>
      <CreateOrganisationEnterprise handelClose={handelClose} />
    </Modal>
  )
}

export default CreateOrganisation
