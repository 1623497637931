import React, { useEffect, useState } from "react";
import { Modal, DatePicker, DatePickerProps } from "antd";
import FloatLabel from "../../../../../../components/FloatLabel";
import dayjs from "dayjs";
import { ErrorMessages } from "../../../../../../constants/messagesAndErrors";

const CancelBonusDate = (props: any) => {
  const minDate = new Date(props.createOnDate);
  const [open, setOpen] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState('')
  const [values, setValues] = useState<any>('');

  useEffect(() => {
    setOpen(props?.open);
  }, []);

  const handleClose = () => {
    setOpen(false);
    props?.handleCloseBonus();
  };

  const handleCloseWithDate = () => {
    if (values !== '') {
      setErrMsg('')
      props?.handleConfirmBonus(props?.currentEmpBonusDetail?.detail, 'cancelled', values)
      handleClose()
    } else {
      setErrMsg(ErrorMessages?.cancellationDate)
    }
  }
  const disabledDate = (current: any) => {
    // Disable dates before the minimum date
    return current && current < minDate.setHours(0, 0, 0, 0);
  };
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setErrMsg('')
    setValues(dateString);
  }

  return (
    <Modal
      title='Cancel Bonus'
      centered
      open={open}
      footer={null}
      maskClosable={false}
      onCancel={() => handleClose()}>
      <div className="pt-4">
        <FloatLabel label='Cancellation Date *' name='canceldate' value={values && dayjs(values)}>
          <DatePicker disabledDate={disabledDate} placeholder='' onChange={onChange} value={values ? dayjs(values) : null} className='float-control' />
          {errMsg && <div className="error-message">{errMsg}</div>}
        </FloatLabel>
      </div>
      <div className='w-full flex justify-end mt-8'>
        <button className=' w-[150px] addButton text-white' onClick={() => handleCloseWithDate()}>{'Save'}</button>
      </div>
    </Modal>
  )
}

export default CancelBonusDate