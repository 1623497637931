import React, { useState } from 'react'
import "./Announcements.scss"
import Loader from '../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { Input, Tooltip } from 'antd'
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import CreateAnnouncement from './CreateAnnouncement'

const Announcements = () => {
  const [loader, setLoader] = useState<any>(false)
  const navigate = useNavigate();
  const [sendMail, setSendMail] = useState<boolean>(false)
  const announcementData = [
    {
      title: "Announcement title",
      createon: "5-02-2023",
      status: "Sent",
      id: 1
    },
    {
      title: "Announcement title",
      createon: "5-02-2023",
      status: "Scheduled",
      id: 2

    },

  ]
  const handleRoute = () => {
    navigate('/home/dashboard');
  }
  const onSearch = (e: any) => {
  }
  const onChange: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const handelCancel = () => {
    setSendMail(false)
  }

  const handelPopup = () => {
    setSendMail(true)
  }
  return (
    <div className='page-container bg-customBg w-full active_ flex '>
      <div className="w-full">
        <div className='full-width-container announcement mt-5 active-history'>
          <div className='pb-3' >
            <ul className='flex'>
              <li className='text-[#878787] text-[16px] cursor-pointer' onClick={handleRoute} >Dashboard /</li>
              <li className='text-[16px] pl-1'>Announcement</li>
            </ul>
          </div>
          <div className=' bg-white p-5 w-full h-full __announcement'>
            <div className='birth-anniversary__tab flex justify-between relative '>
              <div className='card-title justify-start flex'>
                <h4>Announcement</h4>
              </div>
              <div className='flex justify-end relative top-[-9px]' >
                <div className='search-field pr-[10px]'>
                  <div className="search-filter mr-4">
                    <Input
                      placeholder="Search"
                      className="search"
                      onChange={(e: any) => onSearch(e.target.value)}
                    />
                    <span className="material-symbols-outlined"> search </span>
                  </div>
                </div>
                <>
                  <Tooltip placement="bottom" title={'Create New Announcement'}>
                    <button className='w-10 h-10 mr-3  bg-primary rounded-full flex items-center justify-center' onClick={handelPopup} >
                      <span className="material-symbols-outlined text-white">
                        add
                      </span></button>
                  </Tooltip>
                </>
              </div>
            </div>
            <div className='h-[95%] overflow-y-auto pr-1 mt-2'>
              {announcementData && announcementData.map((elm: any, index: number) => (
                <div className="flex w-full  h-[90px] mt-5 " key={elm.id}>
                  <Checkbox className='mr-5 ' onChange={onChange} />
                  <div className="announcement_container w-full px-5 py-4 bg-[#F5F5F5] rounded-lg">
                    <h3 className='text-[#2F2F2F] font-medium font-rubik text-[17px] pb-2'>{elm.title}</h3>
                    <div className="flex">
                      <p className='text-[15px] font-rubik text-[#949494] __date pr-2' >Creating on: {elm.createon}</p>
                      <p className='text-[15px] font-rubik text-[#949494] pl-2 ' >Status: <span className={elm.status == "Sent" ? 'text-[#D34A7C]' : 'text-[#434343]'}>{elm.status}</span></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {sendMail && <CreateAnnouncement open={sendMail} handelCancel={handelCancel} />}

          </div>
        </div>
      </div>
    </div>

  )
}

export default Announcements