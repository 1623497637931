import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';

const LearningPathListOfCourses = (props: any) => {
  const { searchTerm, setswapQuestion, mainArray } = props;
  const unassignedCourses = useSelector((state: any) => state?.courseList?.unassignedCourses);
  const recentlyAddedCourses = useSelector((state: any) => state?.courseList?.recentlyAddedCourses);
  const frequentlyAssignedCourses = useSelector((state: any) => state?.courseList?.frequentlyAssignedCourses
  );
  const [courseList, setCourseList] = useState<any>([]);

  const reformingFunction = () => {
    const totalCourseList = [...unassignedCourses, ...recentlyAddedCourses, ...frequentlyAssignedCourses];
    const extractedCourses = mainArray.reduce((accumulator: any, currentValue: any) => {
      accumulator.push(...currentValue.learningPathLevelCourse);
      return accumulator;
    }, []);
    const reformedCourseList = totalCourseList.map((item: any) => ({
      value: item?.courseID,
      label: item?.courseName,
      name: item?.courseName,
      courseId: item?.courseID,
      checked: extractedCourses.some(
        (extracted: any) => extracted?.course?.courseId === item?.courseID
      ),
      ...item
    }))

    setCourseList(reformedCourseList)
    if (searchTerm !== undefined) {
      const searchWord = searchTerm.toLowerCase();
      const filteredData = reformedCourseList.filter((record: any) => record.label.toLowerCase().includes(searchWord));
      setCourseList(filteredData)
    }
  }
  useEffect(() => {
    reformingFunction()
  }, [searchTerm, mainArray])

  const handleSelectCourseList = (item: any, index: any) => {

    const updatedCourseList = [...courseList];
    updatedCourseList[index].checked = !updatedCourseList[index].checked;
    const newCheckedCourses = updatedCourseList.filter((course) => course?.checked)
    setCourseList(updatedCourseList)
    setswapQuestion(newCheckedCourses)
  };

  return (
    <div>
      <p className='text-[22px] font-medium text-[#D34A7C] pb-5'>List of Course</p>
      <div className='LearningList max-h-[60vh] overflow-y-auto'>
        {courseList && courseList.length > 0 ? courseList.map((item: any, index: any) => (
          <div key={item?.courseId} className='pointer-events-none'>
            <ul>
              <li>
                <Checkbox checked={item?.checked} onChange={() => handleSelectCourseList(item, index)}></Checkbox>
                {item?.courseName}

              </li>
            </ul>
            <br />
          </div>
        )) : (
          <div>No Courses found</div>
        )}
      </div>
    </div>
  )
}



export default LearningPathListOfCourses;