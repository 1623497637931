import React, { useState } from 'react'
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { Select } from 'antd';
import { CSVLink } from 'react-csv';
import Pdf from "../../../../assets/images/file-pdf.svg"
import ImageUpload from '../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload';
import { uploadQuestionTypes } from '../../../Organization/TableColumn';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { questionTypeSchema } from '../../../BasicForm/empInfoFormSchema';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import Loader from '../../../../components/Loader/Loader';
import { csvData } from './DummyCSVFileDownload';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const UploadBulkQuestions = () => {
  const dispatch = useDispatch()
  const uploadBulkQuestions = useGetApiRequests('uploadBulkQuestions', 'POST');
  const loggedInUserId = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const questionType = { questionType: '' }
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const finalAssessmentInCourse = useSelector((state: any) => state?.currentCourse?.finalAssessmentInCourse);
  const [csvCheck, setCsvCheck] = useState<any>(true)
  const [showError, setShowError] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)

  const handleImageDelete = (file: File) => {
    setCsvCheck(true)
    setMediaData('')
  };

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('questionBankCsv', file);
    setMediaData(formData);
    setCsvCheck(false)
    setShowError(false)
  };

  const handleClose = () => {
    if (!flowDirection)
      dispatch(setCurrentTab(4))
    else if (flowDirection) {
      if (!finalAssessmentInCourse)
        dispatch(setCurrentTab(6))
      else if (finalAssessmentInCourse)
        dispatch(setCurrentTab(8))
    }
  }
  const handlePostData = async (values: any) => {
    setLoader(true)
    try {
      const queryParams = { questionType: values?.questionType, loggedInUserId: loggedInUserId?.userId }
      const response: any = await uploadBulkQuestions(mediaData, queryParams)
      handleClose()
      setLoader(false)
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }
  const onSubmit = (values: any) => {
    if (csvCheck) setShowError(true)
    else handlePostData(values)
  }

  return (
    <div className="chapter-upload-bulk question w-1/2 min-h[49vh]">
      {loader && <ModalLoader />}
      <Formik
        initialValues={questionType}
        validationSchema={questionTypeSchema}
        onSubmit={onSubmit}
        values={questionType}
        enableReinitialize={true}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <p className='text-[#737373] text-[18px] pl-2'>Select Question Category to Upload</p>
            <div className="chapter-field mb-5">
              <Field as={Select} className='float-control' name='questionType'
                value={values?.questionType} onChange={(value: any) => { setFieldValue('questionType', value) }}>
                {uploadQuestionTypes?.map((item: any) => (
                  <Select.Option className='w-full' key={item?.value} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name='questionType' component="div" className="error-message" />
            </div>
            <div className='form-item'>
              <div className=' chapter-document bulk_upload'>
                <label className='addcourse-lable pl-2 text-[#737373] ' htmlFor="firstName">Add Bulk Questions</label>
                <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 10}
                  allowedTypes={['text/csv']} />
                {(csvCheck && showError) && <p className="error-message">Please upload .CSV file</p>}
              </div>
            </div>
            <CSVLink className='flex text-left mt-2 text-[#D55382] text-[16px]' data={csvData}><img src={Pdf} alt="" className='mr-2' /> Click to download sample file</CSVLink>
            <div className="footer pb-5 _upload">
              <div className="btnGroup flex justify-end">
                <button className='addButton mr-4 primary px-5   cancelElms' type="button" onClick={() => handleClose()} >Back</button>
                <button className='addButton mr-[1.6rem] primary px-5 confirmElms' type="submit"  >Save & Next</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UploadBulkQuestions
