import React from 'react'
import { Field, ErrorMessage } from 'formik';

const AddCourse = ({ values, setFieldValue, setCourseName }: any) => {
  return (
    <div className='col-span-3  w-3/5'>
      <div className="addcourse-field mb-5">
        <label className='addcourse-lable pl-2' htmlFor="courseDTO.name">Course Name</label>
        <Field type="text" name="courseDTO.name" placeholder="Enter Course Name" className='float-control' value={values?.courseDTO?.name}
          onChange={(e: any) => {
            setFieldValue('courseDTO.name', e.target.value)
            setCourseName(e.target.value)
          }} />
        <ErrorMessage name="courseDTO.name" component="div" className="error-message" />
      </div>
      <div className="addcourse-field">
        <label className='addcourse-lable pl-2' htmlFor="courseDTO.description">Description</label>
        <br />
        <Field id="description" name="courseDTO.description" as="textarea"
          placeholder="Enter your description" value={values?.courseDTO?.description} />
        <ErrorMessage name="courseDTO.description" component="div" className="error-message" />
      </div>
    </div>
  )
}

export default AddCourse
