import React, { useRef } from 'react'
import Header from '../client-onboarding/home/Header';
import Banner from '../client-onboarding/home/Banner';
import KeyFeatures from '../client-onboarding/home/KeyFeatures';
import Footer from '../client-onboarding/footer/Footer';

const PrivacyPolicy = () => {
  const contactFormRef = useRef<any>(null);

  const scrollToContactForm = () => {
    contactFormRef?.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="client overflow-hidden">
      <div className="client-banner relative">
        <div className="client-container w-[90%] privacyPolicy">
          <Header />
          <h2 className=' text-white'>How We Use Your Information</h2>
          <div className='privacyPolicy-container'>
            <ul>
              <li>Provide and maintain the functionality of the App</li>
              <li>Process your leave requests and manage leave records within your organization.</li>
              <li>Communicate with you regarding your leave requests, updates to the App, or other relevant information.</li>
              <li>Improve and optimize the App's performance and user experience.</li>
              <li>Ensure compliance with applicable laws, regulations, and organizational policies.</li>

            </ul>
          </div>
          <h2 className=' text-white'>Sharing Your Information</h2>
          <p>We may share your personal information with third parties in the following circumstances:</p>
          <p><strong>Service Providers</strong>: We may engage third-party service providers to assist with the operation of the App or perform services on our behalf, such as hosting, data analysis, and customer support.</p>
          <p><strong>Legal Compliance</strong>: We may disclose your information when required by law, subpoena, or other legal process, or if we have a good faith belief that such disclosure is necessary to protect our rights, investigate fraud, or comply with a judicial proceeding, court order, or legal request.</p>
          <p><strong>Business Transfers</strong>: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</p>
          <h2 className='mt-6 text-white'>Data Security</h2>
          <p>We take reasonable measures to protect the security of your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <h2 className=' text-white'>Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for any updates or changes.
          </p>
          <h2 className=' text-white'>Contact Us</h2>
          <p>If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at
            No.61, Chamiers House, Chamiers Road, Raja Annamalai Puram, Chennai - 600 028, Tamil Nadu, India.
          </p>
          <p>By using the App, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
        </div>
      </div>


      <div className="client-container w-[80%]">
        <Footer />
      </div>
    </div>
  )
}

export default PrivacyPolicy
