import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ChartOptions from './Charthelper';
import { useDispatch } from 'react-redux';
import { setTableFilter } from '../../../redux/slices/tableFilterSlice';
import { retrieveData, storeData } from '../../../storage/Storage';

interface ChatType {
    series: number[];
    options: ChartOptions
}

const EmployeeTypeChart = (props: any) => {
    const { dashboardData } = props;
    const dispatch = useDispatch();
    const { probationEmployeesCount, confirmedEmployeesCount, appraisalDueCount } = dashboardData;
    const data = [{ type: 'Confirmed' }, { type: 'Appraisal Due' }, { type: 'Probation' }];
    const setCurrentState = (config: any) => {
        let count = config.w.config.labels[config.dataPointIndex]
        let status = retrieveData("count", false)
        if (count === status) {
            storeData('count', "", false);
            dispatch(setTableFilter("All Employees"))
        }
        else {
            storeData('count', count, false);
            dispatch(setTableFilter(config.w.config.labels[config.dataPointIndex]))
        }
    }
    const employeeStatus: ChatType = {
        series: [confirmedEmployeesCount, appraisalDueCount, probationEmployeesCount],
        options: {
            labels: data.map(row => row.type),
            colors: ['#2C5BA7', '#0EBDE0', '#EFBF03', '#F05726'],
            chart: {
                width: 380,
                type: 'donut',
                events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                        // dispatch(setTableFilter(config.w.config.labels[config.dataPointIndex]))

                        setCurrentState(config)
                    }
                }
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '67%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                fontSize: '10px',
                                label: 'Total Employees',
                            },
                        },
                    },
                    animate: false
                },
            },
            stroke: {
                width: 0,
            },
            dataLabels: {
                enabled: true,
            },
            // fill: {
            //     type: 'gradient',
            // },
            legend: {
                formatter: function (val: any, opts: any) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
                },
                show: false,
            },
            responsive: [
                {
                    breakpoint: 767,
                    options: {
                        chart: {
                            width: 200,
                        },
                    },
                },
            ],
        },
    }

    return (
        <div className='relative'>
            <div>
                <ReactApexChart options={employeeStatus.options} series={employeeStatus.series} type='donut' className='apex-chart' />
            </div>
            <div className='employee-chart_shadow absolute'></div>
        </div>
    );
};

export default EmployeeTypeChart;