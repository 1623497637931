import { Modal } from 'antd'
import React, { useState } from 'react'

export default function CloseModalForm(props: any) {
  const { closeConfirmationModal, handleCloseModal, setCloseConfirmationModal } = props;
  const [open, setOpen] = useState(closeConfirmationModal)

  const handleClose = () => {
    setOpen(false);
    setCloseConfirmationModal(false);
  }
  return (
    <div>
      <Modal className='close-confirmation-popup w-[545px]' data-testid="modal-1" centered open={open} onCancel={handleClose} footer={null} maskClosable={false}>
        <div className="appraisal-increment mt-4">
          <p>Are you sure you want to close this tab? Any unsaved changes will be lost.</p>
          <div className='flex justify-end salary_add-btn mt-7'>
            <button className='addButton secondary mr-4' onClick={handleClose}>Cancel</button>
            <button type='submit' className='addButton' onClick={handleCloseModal}>Proceed</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
