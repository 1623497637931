import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import PageTitle from '../../../components/title/pageTitle/PageTitle';
import { retrieveData, storeData } from '../../../storage/Storage';
import { useGetApiRequests } from '../../../common/CommonExport';
import dayjs from 'dayjs';
import DynamicTable from '../../../components/CommonTable/DynamicTable';
import Loader from '../../../components/Loader/Loader';
interface DataType {
    name: string;
    startDate: any;
    endDate: string;
    state: string;
    key: string;
    boardId: any;
    id: any;

}
const ProjectBoard = () => {
    const projectName = retrieveData("projectName")
    const initialData: DataType[] = [];
    const [tableData, setTableData] = useState(initialData);
    const [loader, setLoader] = useState<any>(false)
    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <p onClick={() => handelNavigate(record)}>{text}</p>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
        },
    ];

    const projectsList = useGetApiRequests('projectsList', 'GET');
    const navigate = useNavigate();

    const handelNavigate = (record: any) => {
        storeData('boardId', record, false)
        storeData("boardType", '', false)
        navigate('/home/project-list/project-board-details');
    };
    const [apidata, setapi] = useState<any>([])
    useEffect(() => {
        getBoardData()
    }, [])
    const getBoardData = () => {
        setLoader(true)
        const jiraProjectId = retrieveData('jiraProjectId', false);

        const pathParams = {
            "instance-information-id": 1,
        };
        const queryParams = {
            "instance-information-id": jiraProjectId,
            "board": "board",
        };
        projectsList({}, pathParams, queryParams)
            .then((response: any) => {
                setTimeout(() => {
                    setLoader(false)
                }, 2000)
                setapi(response?.data?.data.reverse())
                response?.data?.data.map((elm: any) => {
                    elm.boardSprintList.forEach((sprint: any, index: any) => {
                        initialData.push({
                            id: sprint.id,
                            boardId: elm.boardId,
                            key: index,
                            name: sprint.name,
                            startDate: sprint.startDate ? dayjs(sprint.startDate).format('MM/DD/YY') : "-",
                            endDate: sprint.endDate ? dayjs(sprint.endDate).format('MM/DD/YY') : "-",
                            state: sprint.state,
                        });
                    });

                })
                setTableData(initialData)
            })
            .catch((err) => {
                setLoader(false)
                console.error(err);
            });
    };
    const handelNavigat = (value: any) => {
        console.log(value);
        if (value.boardType == "kanban") {
            storeData('boardId', value, false)
            storeData("boardType", value.boardType, false)
            navigate('/home/project-list/project-board-details');
        }
    }
    return (
        <div className='page-container bg-customBg'>
            {loader && <Loader />}
            <div className="full-width-container pt-5">
                <div className='bg-white tab-container project-page '>
                    <div className="bredcum-container pt-5 ">
                        <ul className="breadcrumb breadcrumb--classic">
                            <li className='pl-0'>
                                <a href="#" className="!flex items-center">
                                    Dashboard
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate(-1)}>
                                    Projects
                                </a>
                            </li>
                            <li className="active">
                                <a>
                                    {projectName}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <PageTitle title="Projects" />
                    <div className="projects-list">
                        <div className="pb-3">
                            {/* <p data-testid="paraEl">{apidata[0]?.boardName}</p> */}

                            {apidata?.map((elm: any, index: number) => (
                                <Collapse key={`${elm?.boardName}_${index + 1}`} onChange={() => { handelNavigat(elm) }}
                                    items={[{
                                        key: index,
                                        label: elm.boardName,
                                        children: (
                                            <div >
                                                {elm.boardSprintList.length !== 0 && (
                                                    <DynamicTable columns={columns} data={tableData} />
                                                )}
                                            </div>
                                        ),
                                    }]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectBoard;
