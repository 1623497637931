import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import AddChapterHome from "../assessment/chapter/AddChapterHome";
import { setFlowDirection } from "../../../redux/slices/currentCourseDetailSlics";
import { useDispatch } from "react-redux";

const EditCourseDetails = (props: any) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    dispatch(setFlowDirection(true))
    setIsOpen(open);
  }, [open]);

  return (
    <Modal title="Edit Course" className='addcourse add-new add-course' centered open={isOpen} footer={null} onCancel={() => handleClose()} maskClosable={false}>
      <AddChapterHome handleClose={handleClose} />
    </Modal>
  )
}

export default EditCourseDetails 