import React, { useEffect, useState } from 'react'
import RegularSalary from './RegularSalaryParticulars';
import { useGetApiRequests } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import { useSelector } from 'react-redux';
export default function RegularEmployee(props: any) {
  const { activeTabKey } = props;
  const employeeSalaryMaster = useGetApiRequests('salaryMaster', 'GET');
  const internSalaryMaster = useGetApiRequests('getInternSalary', 'GET');
  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const isSalaryUpdate: any = useSelector((state: any) => state?.salaryUpdateReducer);
  const orgId = useSelector((state: any) => state?.organisationId?.id);

  useEffect(() => {
    if (activeTabKey == 2) {
      getInternSalary()
    }
    else {
      employeeSalary()
    }
  }, [props, isSalaryUpdate])

  const employeeSalary = () => {
    setLoader(true);
    const param: any = { organisationId: orgId };
    employeeSalaryMaster('', param)
      .then((response: any) => {
        const getData = response?.data?.data;
        if (getData) {
          const groupedData: any = {
            "Salary Particulars": [],
            "Employer Contribution": [],
            "Employee Contribution": [],
          };

          getData.forEach((obj: any) => {
            const groupName = obj.groupName?.trim();
            if (groupName in groupedData) {
              groupedData[groupName].push(obj);
            }
          });
          let obj = groupedData["Salary Particulars"].map((item: any) => {
            const type = item.percent !== 0 ? ' %' : ' ';
            return { ...item, type };
          })
          let obj1 = groupedData["Employee Contribution"].map((item: any) => {
            const type = item.percent !== 0 ? ' %' : ' ';
            return { ...item, type };
          })
          let obj2 = groupedData["Employer Contribution"].map((item: any) => {
            const type = item.percent !== 0 ? ' %' : ' ';
            return { ...item, type };
          })

          setSalaryParticulars(obj);
          setEmployeeContribution(obj1);
          setEmployerContribution(obj2);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  const getInternSalary = () => {
    const param = { intern: "2" };
    setLoader(true);

    internSalaryMaster('', {}, param)
      .then((response: any) => {
        const getData = response?.data?.data?.emoyeeTypeSalaryComponentMap;
        if (getData) {
          const groupedData: any = {
            "Salary Particulars": [],
            "Employer Contribution": [],
            "Employee Contribution": [],
          };

          getData.forEach((obj: any) => {
            const salaryComponent = obj.salaryComponent;
            if (salaryComponent?.groupName) {
              const groupName = salaryComponent.groupName.trim();
              if (groupName in groupedData) {
                groupedData[groupName].push(obj);
              }
            }
          });

          let obj = groupedData["Salary Particulars"].map((item: any) => {
            const type = item.amount !== 0 ? ' P' : 'A';
            return { ...item, type };
          })


          setSalaryParticulars(groupedData["Salary Particulars"]);
          setEmployeeContribution(groupedData["Employee Contribution"]);
          setEmployerContribution(groupedData["Employer Contribution"]);
          setLoader(false);

        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <div className='salaryStructure '>
      {loader && <Loader />}

      <div className='grid md:grid-cols-3 md:gap-10 gap-5'>
        {salaryParticulars.length > 0 && !loader ?
          <>
            <RegularSalary salaryStructure={salaryParticulars} />
            <RegularSalary salaryStructure={employerContribution} />
            <RegularSalary salaryStructure={employeeContribution} /></> : null
        }
      </div>
    </div >
  )
}
