import { TOGGLE_SIDE_BAR } from './headerType';
export const initialState: any = [];

export const dashboardSideBarReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    if (type === TOGGLE_SIDE_BAR) {
        return {
            data,
        };
    } else {
        return state;
    }
};
