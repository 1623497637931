import React from 'react';
import { Modal } from 'antd';
import DesignationEditor from './DesignationEditor/DesignationEditor';
import { useForm } from 'react-hook-form';

export default function ViewTextArea(props: any) {
    const { reset, setValue } = useForm();
    const handleCloseModal = () => {
        props.handleClose();
        reset({ roles: '' });
    };
    return (
        <div>
            <Modal data-testid="modalEl" title='Roles and Responsibilites' centered open={props.open} onCancel={handleCloseModal} footer={false} maskClosable={false}>
                <div>
                    <DesignationEditor setValue={setValue} editRecord={props?.editRecord} editModal={props?.editModal} />
                </div>
            </Modal>
        </div>
    );
}
