import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    eventData: object;
    particularEmployeeDetails: object;
}

const initialState: AuthState = {
    eventData: {},
    particularEmployeeDetails: {},
};

const empEventSlice = createSlice({
    name: 'eventData',
    initialState,
    reducers: {
        setEventData: (state, action: PayloadAction<object>) => {
            state.eventData = action.payload;
        },
        clearEventData: state => {
            state.eventData = {};
        },
        setParticularEmployeeData: (state, action: PayloadAction<object>) => {
            state.particularEmployeeDetails = action.payload;
        },
        clearParticularEmployeeData: state => {
            state.particularEmployeeDetails = {};
        },
    },
});

export const { setEventData, clearEventData, setParticularEmployeeData, clearParticularEmployeeData } = empEventSlice.actions;
export default empEventSlice.reducer;
