import React from 'react';
import { Checkbox, Input } from 'antd';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { useDispatch } from 'react-redux';
import { setCurrentQuestion } from '../../../../redux/slices/currentCourseDetailSlics';
import { questionCurrentTab } from '../../../../redux/slices/multipleQuestions';

const AssignChapterAndCourse = ({ nameValue, keyValue, onSearch, leftSide, leftSideSelected, setLeftSideSelected,
  rightSide, rightSideSelected, setRightSideSelected, setRightSide, setLeftSide }: any) => {
  const dispatch = useDispatch()

  const onChange = (selectedChapter: any, setSelection: React.Dispatch<React.SetStateAction<any[]>>, side: any) => {
    setSelection((prevSelected: any[]) => {
      const isSelected = prevSelected.some((chapter) => chapter?.[keyValue] === selectedChapter?.[keyValue]);
      if (isSelected) {
        return prevSelected.filter((chapter) => chapter?.[keyValue] !== selectedChapter?.[keyValue]);
      } else {
        const chapterToAdd = side.find((chapter: any) => chapter?.[keyValue] === selectedChapter?.[keyValue]);
        if (chapterToAdd) {
          return [...prevSelected, chapterToAdd];
        }
        return prevSelected;
      }
    });
  };

  const moveSelected = (type: any) => {
    if (type === 'right') {
      const selected = leftSideSelected
      setLeftSideSelected([])
      const storeRightSide: any = [...rightSide, ...selected]
      setRightSide(storeRightSide.reverse());
      const updatedLeftSide = leftSide.filter((leftChapter: any) => {
        return !selected.some((rightChapter: any) => rightChapter?.[keyValue] === leftChapter?.[keyValue]);
      });
      setLeftSide(updatedLeftSide)
    } else if (type === 'allRight') {
      setLeftSideSelected([])
      const selected = leftSide
      const storeRightSide = [...rightSide, ...selected]
      setRightSide(storeRightSide.reverse());
      setLeftSide([])
    } else if (type === 'left') {

      const selected = rightSideSelected
      const storeLeftSide: any = [...leftSide, ...selected]
      setRightSideSelected([])
      setLeftSide(storeLeftSide.reverse());
      const updatedRightSide = rightSide.filter((rightChapter: any) => {
        return !selected.some((leftChapter: any) => leftChapter?.[keyValue] === rightChapter?.[keyValue]);
      });
      setRightSide(updatedRightSide)
    } else if (type === 'allLeft') {
      setRightSideSelected([])
      const selected = rightSide
      const storeLeftSide: any = [...leftSide, ...selected]
      setLeftSide(storeLeftSide.reverse());
      setRightSide([])
    }
  }

  const handleQuizEdit = (item: any) => {
    if (item?.questionType === 'FIB') dispatch(questionCurrentTab(3))
    else if (item?.questionType === 'MCQ') dispatch(questionCurrentTab(1))
    else if (item?.questionType === 'TF') dispatch(questionCurrentTab(2))
    else if (item?.questionType === 'OQ') dispatch(questionCurrentTab(4))
    else if (item?.questionType === 'MFP') dispatch(questionCurrentTab(6))
    else if (item?.questionType === 'AOI') dispatch(questionCurrentTab(5))
    dispatch(setCurrentQuestion(item))
    dispatch(setCurrentTab(10))
  }

  return (
    <div className="chapter-list flex flex-wrap w-full h-full">
      <div className="chapter-list-item">
        <div className="chapter-search">
          <div className="search-filter search-assessment mr-4 pb-3">
            <Input
              placeholder="Search"
              className="search"
              onChange={e => onSearch(e.target.value)}
            />
            <span className="material-symbols-outlined w-3"> search </span>
          </div>
        </div>
        <div className="chapter-items overflow-y-auto overflow-x-hidden ">
          <ul>
            {leftSide?.map((item: any, index: any) => (
              <li key={item?.[keyValue]} className='chapter-lists'>
                <Checkbox
                  checked={leftSideSelected?.some((chapter: any) => chapter?.[keyValue] === item?.[keyValue])}
                  onClick={() => onChange(item, setLeftSideSelected, leftSide)}>
                  <span onClick={() => nameValue === 'question' && handleQuizEdit(item)}>{item?.[nameValue]}</span>
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="chapter-list-swap flex justify-center items-center flex-col">
        <span onClick={() => moveSelected('right')} className="material-symbols-outlined chapter-list-swap-single cursor-pointer ">
          chevron_right
        </span>
        <span onClick={() => moveSelected('allRight')} className="material-symbols-outlined double cursor-pointer">
          keyboard_double_arrow_right
        </span>
        <span onClick={() => moveSelected('left')} className="material-symbols-outlined chapter-list-swap-single cursor-pointer">
          chevron_left
        </span>
        <span onClick={() => moveSelected('allLeft')} className="material-symbols-outlined double cursor-pointer">
          keyboard_double_arrow_left
        </span>
      </div>
      <div className="chapter-list-items  overflow-y-auto  -questions">
        <p className='mb-6 chapter-title'>
          Selected {nameValue === 'question' ? 'Questions' : 'Chapters'} ({rightSide?.length})
        </p>
        <div className="chapter-items overflow-y-auto ">
          <ul>
            {rightSide?.map((item: any, index: any) => (
              <li key={item?.[keyValue]} className='chapter-lists' >
                <Checkbox
                  checked={rightSideSelected?.some((chapter: any) => chapter?.[keyValue] === item?.[keyValue])}
                  onChange={() => onChange(item, setRightSideSelected, rightSide)}>
                  {item?.[nameValue]}
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div >
  )
}

export default AssignChapterAndCourse