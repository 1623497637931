import { useDispatch } from "react-redux";
import { useGetApiRequests, useNavigate } from "../../common/CommonExport";
import { setPaymentDetails } from "../../redux/slices/razorPyamentDetailSlice";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const useRazorpay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createOrderDetails = useGetApiRequests('paymentOrderDetail', 'POST');
  const paymentSave = useGetApiRequests('paymentSave', 'PUT');

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpay = async (planAmount: any, type: any) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const payload = { amount: planAmount, currency: "INR" }
    const result: any = await createOrderDetails(payload);

    if (!result) {
      // alert("Server error. Are you online?");
      return;
    }

    const { amount, id: order_id, currency } = result?.data?.data;

    const options = {
      key: 'rzp_test_hUpqnyfThoyrcK',
      amount: amount.toString(),
      currency: currency,
      order_id: order_id,
      description: "Test Transaction",
      handler: async function (response: any) {
        const data = {
          // orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        const result: any = await paymentSave(data);
        if (result?.data?.status?.success === "Success") {
          dispatch(setPaymentDetails(data));
          const params: any = {
            Business: { key: 'business' },
            Enterprise: { key: 'enterprise' },
            Free: { key: 'free' },
          };
          const urlWithParams = `/signup?${new URLSearchParams(params[type]).toString()}`;
          navigate(urlWithParams);
        }
      },
      theme: {
        color: '#d23b7d'
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return { displayRazorpay };
};

export default useRazorpay;