import React from 'react'
import { Input, Select } from 'antd'
import { ErrorMessage } from 'formik'
import { selectTrueOrFalse } from '../../../../Organization/TableColumn'
import UploadImages from './UploadImages'

const TrueOrFalseQuestions = ({ values, setFieldValue, mediaData, setMediaData, previewImage }: any) => {
  return (
    <>
      <div className="flex w-full">

        <div className="w-3/5">
          <div className="addcourse-field pt-5">
            <p>Question</p>
            <Input.TextArea
              name="question"
              value={values.question}
              placeholder="Question"
              onChange={(e: any) => setFieldValue('question', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="question" component="div" className="error-message" />
          </div>
          <div className="addcourse-field pt-5">
            <p>Answer explanation (optional)</p>
            <Input.TextArea
              name="explanation"
              value={values.explanation}
              placeholder="Explanation"
              onChange={(e: any) => setFieldValue('explanation', e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <ErrorMessage name="explanation" component="div" className="error-message" />
          </div>
          <div className="chapter-field mb-5 pt-5">
            <p>Set correct answer</p>
            <Select
              className="chapter-field-grey rounded-md  w-1/2" value={values.correctAnswer}
              onChange={(value) => setFieldValue('correctAnswer', value)}>
              {selectTrueOrFalse?.map((item: any) => (
                <Select.Option key={item?.value} value={item?.value}>
                  {item?.text}
                </Select.Option>
              ))}
            </Select>
            <ErrorMessage name="correctAnswer" component="div" className="error-message" />
          </div>
        </div>
        <div className='w-2/5 pt-5 pl-8 upload-error-tooltip multi-ques'>
          <label className='addcourse-lable pl-2' htmlFor="firstName">Question Image</label>
          <UploadImages setMediaData={setMediaData} mediaData={mediaData} previewImage={previewImage} />
        </div>
      </div>
    </>
  )
}

export default TrueOrFalseQuestions
