import React, { useEffect, useState } from 'react'
import "./Signup.scss"
import signup from "../../../assets/images/client-onboard/signup-bg.png"
import logo from "../../../assets/images/logo.png"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validationSchema, initialState } from './validatinSchema';
import { ErrorMessages, retrieveData, storeData, triggerNotification, useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import ModalLoader from '../../../components/Loader/ModalLoader';
import { useSelector } from 'react-redux';

const Signup = () => {
  const navigate = useNavigate()
  const verifyDoaminName = useGetApiRequests('verifyDomainName', 'GET');
  const razorPayDetail = useSelector((state: any) => state?.razorPayDetail?.paymentDetail);
  const signupApi = useGetApiRequests('signup', 'POST');
  const [initialValues, setInitialValues]: any = useState(initialState);
  const [subscriptionType, setSubscriptionType] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [verifyDomainName, setVerifyDomainName] = useState<boolean>(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams: any = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    setSubscriptionType(queryParams?.key)
  }, [])

  const handleVerifyDomain = async (values: any) => {
    setLoader(true)
    const queryParam = { subDomainName: values?.organisationDto?.domainName }
    try {
      const res: any = await verifyDoaminName('', queryParam)
      if (res?.data?.status?.message !== 'Domain does not exist') {
        setVerifyDomainName(false)
      } else if (res?.data?.status?.message === 'Domain does not exist') {
        handleSignUp(values)
        setVerifyDomainName(true)
      }
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.status?.message ?? ErrorMessages.somethingWentWrong, 'topRight');
    }
  }

  const handleSignUp = (values: any) => {
    signupApi(values)
      .then((response: any) => {
        storeData("newOrganizationData", response?.data?.data, true)
        navigate(`/signup/verification?email=${values?.organisationDto?.emailId}`)
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
      });
  }

  const handelSaveRevise = (values: any) => {
    delete initialValues.emailId
    delete initialValues.domainName
    delete initialValues.mobileNumber
    delete initialValues.organisationDto.name
    delete initialValues.organisationDto.password
    delete initialValues.organisationDto.designation
    initialValues.subscriptionId = subscriptionType;
    initialValues.subscriptionPeriod = 'annual';
    initialValues.subscriptionPlanId = 1
    if (subscriptionType !== 'free') {
      initialValues.razorpayOrderId = razorPayDetail?.razorpayOrderId;
      initialValues.razorpayPaymentId = razorPayDetail?.razorpayPaymentId;
      initialValues.razorpaySignature = razorPayDetail?.razorpaySignature
    }

    handleVerifyDomain(initialValues)
  }

  const handleOnchange = (event: any, setFieldValue: any, name: any) => {
    const input: any = event.target.value;
    setFieldValue(name, input)
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      [name]: input,
      organisationDto: {
        ...prevValues.organisationDto,
        [name]: input,
      },
    }));
  };
  return (
    <div className="signup-container flex relative">
      {loader && <ModalLoader />}
      <div className="  absolute signup-header">
        <p className='text-[#ffffff] font-rubik text-[30px] flex items-center'><img src={logo} alt="logo " />All-Aboard </p>
      </div>
      <div className="w-full flex">
        <div className="w-[52%] flex justify-center items-end h-full">
          <img src={signup} alt="" className='py-8 px-4' />
        </div>
        <div className="w-[48%] pl-[150px]  flex justify-center items-center signup-form flex-col">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handelSaveRevise}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  <p className='text-left font-rubik text-[25px] font-semibold text-[#000] pb-12'>Signup</p>
                  <div className='w-full'>
                    <div className='contact_form  pb-6'>
                      <label> Name*</label>
                      <Field
                        type='text'
                        name='name'
                        value={values.name}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'name') }}
                        placeholder="Enter Name"
                      />
                      <ErrorMessage name='name' component='div' className='error-message absolute' />
                    </div>
                    <div className='contact_form  pb-6'>
                      <label >Company Email id*</label>
                      <Field
                        type='text'
                        name='emailId'
                        value={values.emailId}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'emailId') }}
                        placeholder="Enter Mail"
                      />
                      <ErrorMessage name='emailId' component='div' className='error-message absolute' />
                    </div>
                    <div className='contact_form  pb-6'>
                      <label >Password*</label>
                      <Field
                        type='password'
                        name='password'
                        value={values.password}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'password') }}
                        placeholder="******"
                      />
                      <ErrorMessage name='password' component='div' className='error-message absolute' />
                    </div>    <div className='  pb-6 contact_form'>
                      <label >Domain*</label>
                      <Field
                        type='text'
                        name='domainName'
                        value={values.domainName}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'domainName') }}
                        placeholder="Enter Domain"
                      />
                      <ErrorMessage name='domainName' component='div' className='error-message absolute' />
                    </div>
                    <div className='  pb-6 contact_form '>
                      <label >Designation</label>
                      <Field
                        type='text'
                        name='designation'
                        value={values.designation}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'designation') }}
                        placeholder="Enter Designation"
                      />
                      <ErrorMessage name='designation' component='div' className='error-message absolute' />
                    </div>
                    <div className='  pb-6 contact_form'>
                      <label >Phone*</label>
                      <Field
                        type='text'
                        name='mobileNumber'
                        value={values.mobileNumber}
                        onChange={(e: any) => { handleOnchange(e, setFieldValue, 'mobileNumber') }}
                        placeholder="00000-00000"
                        maxLength={10}
                      />
                      <ErrorMessage name='mobileNumber' component='div' className='error-message absolute' />
                    </div>
                  </div>
                  <div className='flex justify-center  salary_add-btn'>
                    <button type='submit' className='addButton w-[400px] '>
                      Sign Up
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Signup
