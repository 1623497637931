import { triggerNotification } from '../../../../common/CommonExport';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';

export const getChapterListByCourseId = async (setLoader: any, currentCourseId: any, chapterListByCourseId: any, setAllChapter: any, currentAssessmentTab: any, setChapterList: any) => {
    setLoader(true);
    try {
        const pathParams = { id: currentCourseId };
        const res: any = await chapterListByCourseId('', {}, pathParams);
        setAllChapter(res?.data?.data);
        let reformedEmployeeData: any = [];
        if (currentAssessmentTab === '1') {
            reformedEmployeeData = (res?.data?.data || [])
                .filter((item: any) => item?.chapter !== null)
                .map((item: any, index: any) => ({
                    key: index + 1,
                    chapterId: item?.chapter?.chapterId,
                    chaptername: item?.chapter?.name,
                    chapterContent: item?.chapter?.chapterZipName,
                    quiz: item?.quiz,
                    displaySequence: item?.displaySequence,
                    courseName: item?.course?.name,
                    courseChapterAssignmentId: item?.courseChapterAssignmentId,
                }));
        } else if (currentAssessmentTab === '2') {
            reformedEmployeeData = (res?.data?.data || [])
                .filter((item: any) => item?.chapter === null)
                .map((item: any, index: any) => ({
                    key: index + 1,
                    chapterId: item?.chapter?.chapterId,
                    chaptername: item?.chapter?.name,
                    chapterContent: item?.chapter?.chapterZipName,
                    quiz: item?.quiz,
                    displaySequence: item?.displaySequence,
                    courseName: item?.course?.name,
                    courseChapterAssignmentId: item?.courseChapterAssignmentId,
                }));
        }
        setChapterList(reformedEmployeeData);
        setLoader(false);
    } catch (err: any) {
        triggerNotification('error', '', err?.response?.data?.data, 'topRight');
        setLoader(false);
    }
};

export const handleSkip = (setChapterList: any, handleTabChange: any, dispatch: any) => {
    setChapterList([]);
    handleTabChange('1');
    dispatch(setCurrentTab(11));
};
