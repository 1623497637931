import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import EditOrganisation from './EditOrgnization';

const Organisation = () => {
  return (
    <>
      <div className='page-container bg-customBg'>
        <div className="full-width-container edit-organization pt-4">
          <div className='edit-organization_header mb-7'>
            <h1 className=' organization_title flex'>
              <a href='/home/organisation'>  <span className='organization_back-arrow pr-4'><ArrowLeftOutlined />  </span> </a>
              Edit Organisation
            </h1>
          </div>
          <div className='bg-white tab-container  w-full organisation_edit-form py-5 '>
            <EditOrganisation />
          </div>
        </div>
      </div>
    </>
  )
}

export default Organisation
