import React, { useEffect, useState } from 'react'
import { Avatar, Modal, Table } from 'antd'
import "./EmployeeAttendance.scss"

const LeaveEmployees = (props: any) => {
  const { open, handelCancel, leaveList } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [datas, setDatas] = useState([])

  const formatDatesLeaveList = (inputDate: any) => {
    const date = new Date(inputDate);
    const day = ("0" + date.getDate()).slice(-2);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const getTheLeaveType = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  useEffect(() => {
    const reformedLeaveListData: any = leaveList.map((item: any) => ({
      name: item?.employeeName,
      leavetype: item?.leaveType,
      startdate: formatDatesLeaveList(item?.startDate),
      enddate: formatDatesLeaveList(item?.endDate),
      duration: item?.durationOfLeave
    }))
    setDatas(reformedLeaveListData)
    setModalOpen(open)
  }, [modalOpen])
  const handelClose = () => {
    handelCancel()
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any, index: any) => {
        return (
          <div className='employee-image '>
            <Avatar style={{ color: '#fff', fontWeight: 600 }} className={`${"list-" + record.leavetype}`}>{getTheLeaveType(text)}</Avatar>
            <span className='ml-2 text-[#737373] font-rubik font-medium'>{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Leave type',
      dataIndex: 'leavetype',
      key: 'leavetype',
    },
    {
      title: 'Start date',
      dataIndex: 'startdate',
      key: 'startdate',
    },
    {
      title: 'End date ',
      dataIndex: 'enddate',
      key: 'enddate',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration  ',
    },
  ]

  return (
    <Modal title="Who's on leave" className='leave-employee' centered open={modalOpen} footer={null} maskClosable={false} onCancel={handelClose}>
      <Table columns={columns} dataSource={datas} pagination={false} />
    </Modal>
  )
}

export default LeaveEmployees
