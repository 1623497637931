import React from "react";
import FloatLabel from "../../components/FloatLabel";
import { DatePicker, Input, Select } from "antd";
import { ErrorMessage } from "formik";
import dayjs from "dayjs";
import { noticePeriodList, reviewMonthList } from "../Organization/EditOrganisation/EditOrganisationHelper";


const BasicOrganisationDetails = ({ values, setFieldValue, industryTypes, handleVerifyDomain }: any) => {
  return (
    <div className='grid grid-cols-3 gap-4 '>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Organisation Name *' name='name' value={values?.name}>
          <Input
            type="text"
            className='float-control'
            name="name"
            value={values?.name}
            onChange={(e) => setFieldValue('name', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="name" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Admin Email Id *' name='adminEmailId' value={values?.adminEmailId}>
          <Input
            type="text"
            className='float-control'
            name="adminEmailId"
            value={values?.adminEmailId}
            onChange={(e) => setFieldValue('adminEmailId', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="adminEmailId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Finance Email Id *' name='accountsEmailId' value={values?.accountsEmailId}>
          <Input
            type="text"
            className='float-control'
            name="accountsEmailId"
            value={values?.accountsEmailId}
            onChange={(e) => setFieldValue('accountsEmailId', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="accountsEmailId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='HR Email Id *' name='hrEmailId' value={values?.hrEmailId}>
          <Input
            type="text"
            className='float-control'
            name="hrEmailId"
            value={values?.hrEmailId}
            onChange={(e) => setFieldValue('hrEmailId', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="hrEmailId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Organisation Email Id *' name='emailId' value={values?.emailId}>
          <Input
            type="text"
            className='float-control'
            name="emailId"
            value={values?.emailId}
            onChange={(e) => setFieldValue('emailId', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="emailId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Notice Period *' name='noticePeriod' value={values?.noticePeriod}>
          <Select
            className='float-control' value={values?.noticePeriod}
            onChange={(value) => setFieldValue('noticePeriod', value)}>
            {noticePeriodList?.map((item: any) => (
              <Select.Option key={item?.value} value={item?.value}>
                {item?.value} days
              </Select.Option>
            ))}
          </Select>
        </FloatLabel>
        <ErrorMessage name="noticePeriod" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='CEO *' name='ceoMailId' value={values?.ceoMailId}>
          <Input
            type="text"
            className='float-control'
            name="ceoMailId"
            value={values?.ceoMailId}
            onChange={(e) => setFieldValue('ceoMailId', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="ceoMailId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Industry Type *' name='organisationType.organisationTypeId' value={values?.organisationType?.organisationTypeId}>
          <Select
            className='float-control' value={values?.organisationType?.organisationTypeId}
            onChange={(value: any) => setFieldValue('organisationType.organisationTypeId', value)}>
            {industryTypes?.map((item: any) => (
              <Select.Option key={item?.organisationTypeId} value={item?.organisationTypeId}>
                {item?.organisationTypeName}
              </Select.Option>
            ))}
          </Select>
        </FloatLabel>
        <ErrorMessage name="organisationType.organisationTypeId" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Review Month *' name='reviewMonth' value={values?.reviewMonth}>
          <Select
            className='float-control' value={values?.reviewMonth}
            onChange={(value) => setFieldValue('reviewMonth', value)}>
            {reviewMonthList?.map((item: any) => (
              <Select.Option key={item?.value} value={item?.value}>
                {item?.value} months
              </Select.Option>
            ))}
          </Select>
        </FloatLabel>
        <ErrorMessage name="reviewMonth" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Fiscal Year Start Date *' name='fiscalYearStartDate' value={values?.fiscalYearStartDate ? dayjs(values?.fiscalYearStartDate) : null}>
          <DatePicker
            placeholder=''
            className='float-control'
            name="fiscalYearStartDate"
            value={values?.fiscalYearStartDate ? dayjs(values?.fiscalYearStartDate) : null}
            onChange={(date, dateString) => setFieldValue('fiscalYearStartDate', dateString)}
          />
        </FloatLabel>
        <ErrorMessage name="fiscalYearStartDate" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Phone Number *' name='mobileNumber' value={values?.mobileNumber}>
          <Input
            type="text"
            maxLength={10}
            className='float-control'
            name="mobileNumber"
            value={values?.mobileNumber}
            onChange={(e) => setFieldValue('mobileNumber', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="mobileNumber" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <FloatLabel label='Domain Name *' name='domainName' value={values?.domainName}>
          <Input
            type="text"
            className='float-control'
            name="domainName"
            value={values?.domainName}
            onChange={(e) => setFieldValue('domainName', e.target.value)}
          />
        </FloatLabel>
        <ErrorMessage name="domainName" component="div" className="error-message pt-3" />
      </div>
      <div className='relative z-0 mt-5  form-item'>
        <button onClick={() => handleVerifyDomain(values?.domainName, setFieldValue)} disabled={!values?.domainName} type="button" className="green-button-to-verify">Verify</button>
      </div>
    </div>
  )
}

export default BasicOrganisationDetails