import React, { useEffect, useState } from 'react'
import { Button, Table, Select } from 'antd'
import { ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../../../../../components/Loader/Loader'
import DocumentPDFView from '../documents/documentPDFView'
import { setCertificateGeneratedReload } from '../../../../../../redux/slices/updateProfileSlice'

const NoDueApproval = ({ noDueDetails }: any) => {
  const reasonsList = useGetApiRequests('getResignationReasons', 'GET');
  const editNoDues = useGetApiRequests('editDeprovisionList', 'PUT');
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const userId = useSelector((state: any) => state?.userId?.id)
  const [loader, setLoader] = useState(true)
  const [dueData, setDueData] = useState<any>([]);
  const [certificateData, setCertificateData] = useState([])
  const [hilighted, setHilighted] = useState<boolean>(false)
  const [dropDownList, setDropDownList] = useState<any>([])
  const [feedbackFormSubmit, setFeedbackFormSubmit] = useState(false)
  const [codes, setCodes] = useState([])
  const [documentView, setDocumentView] = useState<any>(false)
  const [currentPdfDetails, setCurrentPdfDetails] = useState<any>({})
  useEffect(() => {
    setLoader(true);
    setFeedbackFormSubmit(noDueDetails?.isEmployeeExitInterviewFormSubmit)
    const newArray = noDueDetails?.employeeDeprovisionKVDto?.map((item: any) => ({
      department: item.inchargeDescription,
      incharge: `${item.inchargeEmployee.firstName} ${item.inchargeEmployee.lastName}`,
      roleName: item?.inchargeDescription,
      inchargeEmployeeId: item?.inchargeEmployee.employeeId,
      employeeDeprovisionId: item?.employeeDeprovisionId,
      status: item?.status?.name,
      statusId: item?.status?.statusId
    }));

    const certificates: any = noDueDetails?.templateCategoryDto?.map((item: any) => {
      const matchingDocument = noDueDetails?.employeeDocument?.find((doc: any) => doc?.document?.folderName === item?.name);
      return {
        name: item?.name,
        code: item?.code,
        documentPath: matchingDocument ? matchingDocument?.documentPath : null,
        status: 'Pending',
        incharge: matchingDocument ? 'Sent' : 'Not Sent'
      };
    });

    const codesFromApi = noDueDetails?.templateCategoryDto?.map((item: any) => item?.code)
    setCodes(codesFromApi)
    setCertificateData(certificates)
    setDueData(newArray)
    getReasons();
    setLoader(false)

  }, [noDueDetails])

  const getReasons = async () => {
    setLoader(true)
    const queryParams: any = {
      statusCategory: 'Deprovision'
    }
    await reasonsList('', queryParams).then((res: any) => {
      const list = res?.data?.data.filter((item: any) => [36, 37].includes(item.statusId))
      const reformedReasonList = list.map((item: any) => ({
        value: item?.statusId,
        label: item?.name
      }))
      setDropDownList(reformedReasonList);
      setLoader(false)

    })
      .catch(() => {
        setLoader(false)
      });
  }
  const handleChange = (record: any, value: any, index: any) => {
    const shallowNoDueArray: any = [...dueData];
    const pathParams = {
      id: `${record?.employeeDeprovisionId}/approval`
    }
    const queryParams: any = {
      employeeId: retrieveData("employeeId", true),
      inchargeEmployeeId: record?.inchargeEmployeeId,
      statusId: value,
      roleName: record?.roleName.toString()
    }

    editNoDues('', queryParams, pathParams).then((res: any) => {
      shallowNoDueArray[index].status = value ? 'Approved' : 'Pending'
      shallowNoDueArray[index].statusId = value;
      setDueData(shallowNoDueArray)
      setHilighted(true);
    })
      .catch((err: any) => triggerNotification('error', '', err?.response?.data?.message, 'topRight'))
  };
  const handleViewDocument = (details: any) => {
    setCurrentPdfDetails(details)
    setDocumentView(true)
  }
  const handleGenerate = () => {
    const allApproved = dueData?.every((item: any) => item.status === 'Approved');
    if (allApproved) {
      setLoader(true);
      const filteredCodes = codes?.filter(code => code !== "EXIF");
      const queryString = filteredCodes?.map((code: any) => `templateCodeStringList=${code}`).join('&');
      const baseUrl: string = process.env.REACT_APP_BASEURL + `/hrm/employee/${id}/deprovision/document?userId=${userId}&` + queryString
      axios.post(baseUrl)
        .then((res: any) => {
          if (res.status === 200) {
            setFeedbackFormSubmit(false)
            setLoader(false)
            dispatch(setCertificateGeneratedReload(true))
            triggerNotification('success', '', res?.data?.status?.message, 'topRight')
          }
        })
        .catch((err: any) => {
          triggerNotification('error', '', err?.response?.data?.message, 'topRight')
          setLoader(false)
        })
    } else {
      triggerNotification('error', '', ErrorMessages?.deprovisionGenerate, 'topRight')
    }
  }

  const columns = [
    {
      title: 'Departments',
      dataIndex: 'department',
      key: 'department',
      width: "40%"
    },
    {
      title: 'Person InCharge',
      dataIndex: 'incharge',
      key: 'incharge',
      width: "30%"

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: "20%",
      render: (text: any, record: any, index: any) => (
        <div className='cursor-pointer'>
          <div className='justify-center _status items-center py-2 pl-4' >
            <Select
              className={hilighted ? "hilighted" : ""}
              style={{ width: 120 }}
              value={record?.status}
              onChange={(value) => handleChange(record, value, index)}
              suffixIcon={<span className="material-symbols-outlined"> expand_more</span>}
              options={dropDownList}>
            </Select>
          </div>
        </div>
      ),
    },
  ];

  const columnsDocument = [
    {
      title: 'Document',
      dataIndex: 'name',
      key: 'name',
      width: "45%"
    },
    {
      title: 'Status',
      dataIndex: 'incharge',
      key: 'incharge',
      width: "35%"

    },

    {
      title: 'Perview',
      dataIndex: 'status',
      key: 'status',
      width: "10%",
      render: (text: any, record: any) => (
        <div className={`cursor-pointer deprovision_visiblity_parent${record?.incharge}`}>
          <div onClick={() => handleViewDocument(record)} className='justify-center items-center py-2 pl-4' >
            <span className={`material-symbols-outlined deprovision_visiblity${record?.incharge}`}>
              visibility
            </span>
          </div>
        </div>),

    },
  ];
  return (
    <div className="flex w-full justify-between pt-10  asset-collection">
      <div className="w-[45%]">
        <h3 className='text-[#D34A7C] text-[15px] font-semibold pb-[10px]'>NO DUE APPROVAL</h3>
        {loader && <Loader />}
        <Table dataSource={dueData} columns={columns} pagination={false} />
      </div>
      <div className="w-[45%]  asset-collection ">
        <div className="flex w-full justify-between">
          <h3 className='text-[#D34A7C] text-[15px] font-semibold'>DOCUMENTS</h3>
          <Button type="primary"
            disabled={!(dueData?.every((item: any) => item?.status === 'Approved') === true && feedbackFormSubmit === true)} onClick={handleGenerate}>Generate</Button>
        </div>
        <Table dataSource={certificateData} columns={columnsDocument} pagination={false} />
      </div>
      {documentView && <DocumentPDFView open={documentView} setDocumentView={setDocumentView} currentPdfDetails={currentPdfDetails} certificate={true} mainPage={false} />}

    </div>
  )
}

export default NoDueApproval