import React from 'react'
import { Field, Form, Formik } from 'formik'
import { DatePicker, Input, Select } from 'antd'
import RadioButton from '../../../../../../components/RadioButton/RadioButton'

const DeprovisionFormMail = () => {
  const initialValues = {
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: false,
    probationExtension: ''
  };
  const handleClose = (): any => {
    return null
  };
  const questions = [" Why are you leaving the company?",
    " Was the job realistically presented to you when you were hired?",
    " Was your salary satisfactory for the job you were performing?",
    "How do you feel about the perks and benefits offered by the company?",
    "Do you think your current supervisor was fair and reasonable?",
    "How would you rate the training you received for your position? ",
    "Did you feel your contributions were appreciated by your supervisor and others?",
    "How were the working conditions and your working environment? ",
    "Do you feel you were fairly treated while with the company? If not, can you elaborate?",
    "Do you have any suggestions for improvement of the company or your department in particular? ",
    "Are there any other comments you would like to make?"
  ]
  const onTextChange = (e: any) => {

  }
  const handelOnchange = (e: any) => {

  }
  const martialStatus = [
    { label: 'Resignation', value: false },
    { label: 'Retirement', value: true },
    { label: 'Layoff', value: true },
    { label: 'others', value: true },
  ];
  const handleType = (e: any, val: any) => {

  }
  return (
    <>
      <div className="salary-date review-modal email-template">
        <Formik
          initialValues={initialValues}
          onSubmit={handleClose}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="w-full">
                {
                  questions.map((elm: any, index: number) => (
                    <div className='flex mt-8 w-full form-item'>
                      <div className="deprovision-form w-full ">
                        <label className='text-[#1F3C66] text-[20px] font-rubik font-medium '>{`${index + 1} . ${elm}`}</label>
                        <Field
                          className='outline-none w-full bg-[#F5F5F5] px-[10px] rounded-md pt-1 mt-4'
                          as='textarea'
                          rows={3}
                          style={{ resize: 'none' }}
                          placeholder="Enter Comment"
                          name='appraiserComments'
                          onChange={(e: any) => { onTextChange(e) }}
                          value={""}
                        />
                      </div>
                    </div>
                  ))
                }
                <div className="flex w-full mt-16 __date">
                  <div className="w-1/2 flex flex-col">
                    <label className='text-[#595959] text-[18px] font-medium' htmlFor="">Interview Conducted By:</label>
                    <Input
                      type="text"
                      className='bg-[#F5F5F5] rounded-md  border-0 w-1/2 mt-4'
                      name="employeeDto.permanentAddress.street"
                      value={""}
                      onChange={(e) => { handelOnchange(e) }}
                    />
                  </div>
                  <div className="w-1/2 flex justify-end">
                    <div className="w-1/2 flex flex-col justify-end">

                      <label className='text-[#595959] text-[18px] font-medium' htmlFor="">Date</label>

                      <DatePicker
                        className='bg-[#F5F5F5] rounded-md  border-0 w-[100%] mt-4'
                        placeholder='DD/MM/YYY'
                        name="Date"
                        suffixIcon={null}
                        value={null}
                        onChange={(e) => { handelOnchange(e) }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col pt-16 __radio">
                  <label className='text-[#595959] text-[18px] font-medium mb-3' htmlFor="">Type of Exit:</label>

                  <Field
                    name="employeeDto.isMarried"
                    render={({ field, form }: any) => (
                      <RadioButton
                        {...field}
                        options={martialStatus}
                        onChange={(newValue: any) => {
                          handleType(newValue, values)
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex w-full justify-center items-center pt-20 pb-16 ">
                <button className='form-submit-btn text-white font-medium' type="submit">Submit Application</button>
              </div>

            </Form>
          )}
        </Formik>
      </div></>
  )
}

export default DeprovisionFormMail