import React, { useEffect, useState } from 'react'
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { salaryUpdate } from '../../redux/salary-master/salaryAction';

const SalaryDeleteModal = (props: any) => {
  const { open, editParticulars, handleClosed } = props;
  const saveSalaryMaster = useGetApiRequests('deleteSalaryMaster', 'DELETE');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch()
  const handelDelete = () => {

    const queryParams = {
      "param": editParticulars.salaryComponentId,
    };
    saveSalaryMaster({}, {}, queryParams).then((response: any) => {
      if (response) {
        dispatch(salaryUpdate(true))
        triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        handleClosed()
      }
    })
      .catch((err: any) => {
        console.error(err);
        handleClosed()
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  }
  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const handelClose = () => {
    setModalOpen(false)
    handleClosed()
  }
  return (
    <Modal title='Delete' className='salaryStructure-modal' centered open={modalOpen} footer={null} onCancel={handelClose} maskClosable={false}>

      <div className='flex justify-end mr-5 salary_add-btn'>
        <p>Are you sure you want to delete this {editParticulars?.name} in {editParticulars?.groupName}?</p>
        <button className='addButton' onClick={handelDelete}>
          Delete
        </button>
      </div>
    </Modal>
  )
}

export default SalaryDeleteModal
