import React, { useEffect, useState } from 'react'
import FloatLabel from '../../../../../../../components/FloatLabel'
import { ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../../common/CommonExport'
import { useLocation } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ModalLoader from '../../../../../../../components/Loader/ModalLoader';

const validationSchema = Yup.object().shape({
  newCtc: Yup.number().required('New Ctc is required').typeError('The new CTC should not contain alphabets'),
  appraiserComments: Yup.string().required('Appraiser Comment is required'),
});

const EmailChangeCtc = (props: any) => {
  const { setChangeEmailCtcModal, questionObjectEmail, changeEmailCtcModal, incrementEligibleEmail } = props;
  const { search } = useLocation();
  const getCtcApis = useGetApiRequests('getEmployeeCtc', 'GET');
  const createReview = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const employeeId: any = new URLSearchParams(search).get("employeeId")
  const employeeApiKey: any = new URLSearchParams(search).get('employeeApiKey');
  const employeeReviewId: any = new URLSearchParams(search).get('employeeReviewId');
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues]: any = useState({
    currentCtc: 0,
    newCtc: '',
    appraiserComments: '',
  });
  useEffect(() => {
    getEmployeeCtc();
  }, []);
  const getEmployeeCtc = () => {
    const params: any = {
      id: parseInt(employeeId), end: 'employeecurrentctc'
    };
    getCtcApis('', '', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        setInitialValues({ currentCtc: parseInt(apiResponse) })
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handleNewCtc = (event: any, setFieldValue: any, name: any) => {
    const input: any = event.target.value;
    if (name === 'newCtc') {
      if (input === 0 || input.trim() === '0') {
        triggerNotification('warning', '', ErrorMessages.newCtc, 'topRight');
      } else {
        setFieldValue('newCtc', input)
      }
    } else {
      setFieldValue('appraiserComments', input)
    }
  };
  const handelSaveReviseCtc = (values: any) => {
    let finalSendingPayloadEmail: any = {};
    let emailFinalPayload: any = {}
    let questionOptionEmailPayload: any = [];
    setLoader(true);
    let questionKeysEmail: any = Object.keys(questionObjectEmail);
    questionKeysEmail.forEach((item: any) => {
      let editedKey = item.split("_").join("").toLowerCase();
      if (editedKey === "appraiserfeedback") {
        questionObjectEmail &&
          Object.keys(questionObjectEmail[item]).forEach((data: any) => {
            questionOptionEmailPayload.push({
              otherText: questionObjectEmail[item][data].answer,
              questionaire: {
                questionaireId: questionObjectEmail[item][data].questionaireId,
              },
            });
          });
      } else if (editedKey !== 'appraiserfeedback') {
        questionObjectEmail?.[item]?.forEach((data: any) => {
          questionOptionEmailPayload.push({
            performanceRatingScale: {
              performanceRatingScaleId: data?.range,
            },
            otherText: data?.answer,
            questionaire: {
              questionaireId: data?.questionaireId,
            },
          });
        });

      }
    });
    finalSendingPayloadEmail.isEligibleForIncrement = incrementEligibleEmail;
    finalSendingPayloadEmail.employeeReviewId = employeeReviewId;
    finalSendingPayloadEmail.remarks = values.appraiserComments;
    finalSendingPayloadEmail.newCtc = parseInt(values.newCtc)
    finalSendingPayloadEmail.currentCtc = parseInt(initialValues.currentCtc)
    finalSendingPayloadEmail.newCtcEffectiveDate = retrieveData('salaryRevised', true)
    finalSendingPayloadEmail.employeeReviewDetails = questionOptionEmailPayload;
    finalSendingPayloadEmail.employee = {
      employeeId: parseInt(employeeId)
    }
    finalSendingPayloadEmail.type = retrieveData('reviewtype', true)
    emailFinalPayload.employeeReview = finalSendingPayloadEmail;
    const queryParams: any = {
      employeeReviewAndDetailsDtoJson: emailFinalPayload,
      employeeApiKey: employeeApiKey,
    }
    createReview(queryParams).then((res: any) => {
      setLoader(false)
      triggerNotification('success', '', res?.data?.status?.message, 'topRight');
      setChangeEmailCtcModal(false);
      setTimeout(() => {
        window.close();
      }, 3000);
    })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  };

  const handelClose = () => {
    setChangeEmailCtcModal(!changeEmailCtcModal)
  };

  return (
    <div className='appraisal-increment'>
      <p className='general-assessment-subtitle py-4'>Salary Increment</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handelSaveReviseCtc(values);
          resetForm();
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form onSubmit={handelSaveReviseCtc}>
              <div className=' mt-4 w-full flex justify-between form-item'>
                <div className=' w-1/3 form-item basicForm-fields mb-6' style={{ pointerEvents: 'none' }}>
                  <FloatLabel label='Current Ctc' name='currentCtc' value={String(initialValues.currentCtc)}>
                    <Field
                      type='text'
                      className='float-control'
                      name='currentCtc'
                      value={initialValues.currentCtc}

                    />
                    <ErrorMessage name='currentCtc' component='div' className='error-message' />
                    {initialValues.currentCtc !== 0 ? "" :
                      <p className='error-message'>Still salary is not updated </p>
                    }
                  </FloatLabel>
                </div>
                <div className=' w-1/3 form-item basicForm-fields mb-6'>

                  <FloatLabel label='New Ctc' name='newCtc' value={values.newCtc}>
                    <Field
                      type='text'
                      className='float-control'
                      name='newCtc'
                      onChange={(e: any) => { handleNewCtc(e, setFieldValue, 'newCtc') }}

                    />
                    <ErrorMessage name='newCtc' component='div' className='error-message' />
                  </FloatLabel>
                </div>
              </div>
              <div className='grid grid-cols-1 gap-x-12 mb-12 w-full form-item'>
                <Field
                  as='textarea'
                  rows={3}
                  style={{ resize: 'none' }}
                  placeholder='Enter your comments'
                  name='appraiserComments'
                  onChange={(e: any) => { handleNewCtc(e, setFieldValue, 'appraiserComments') }}
                />
                <ErrorMessage name='appraiserComments' component='div' className='error-message' />
              </div>
              <div className='flex justify-start mr-5 salary_add-btn'>
                <button className='addButton secondary mr-4' onClick={handelClose}>
                  Back
                </button>
                <button type='button' className='addButton' onClick={handelSaveReviseCtc}>
                  Submit
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
      {loader && <ModalLoader />}
    </div>
  );
}

export default EmailChangeCtc
