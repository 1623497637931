import { ErrorMessages, triggerNotification } from '../../../../../../common/CommonExport';

export const createRequest = async (requestPayload: any, handleCloseModal: any, employeeSalaryGeTApi: any, createSalaryApi: any, setLoader?: any) => {
    try {
        setLoader(true);
        const res = await createSalaryApi(requestPayload);
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        handleCloseModal();
        setLoader(false);
        employeeSalaryGeTApi();
    } catch (err) {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        setLoader(false);
    }
};

export const updateRequet = async (requestPayload: any, handleCloseModal: any, employeeSalaryGeTApi: any, employeeSalaryId: any, editSalaryApi: any, setLoader?: any) => {
    try {
        setLoader(true);
        requestPayload['employeeSalaryId'] = employeeSalaryId;
        const pathParams: any = {
            id: employeeSalaryId,
        };

        const res = await editSalaryApi(requestPayload, {}, pathParams);
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        handleCloseModal();
        setLoader(false);
        employeeSalaryGeTApi();
    } catch (err) {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        setLoader(false);
    }
};
