import React, { useEffect, useState } from 'react'
import { Modal, Form } from 'antd';
import { Field, Formik } from 'formik';
import ImageUploadElms from '../Dashboard/AddEmployeeModal/imageUpload/ImageUploadElms';
import './LearningManagementTab.scss';
import AddCertificateDB from './CourseDetails/AddCertificateDB';

const AddCertificate = (props: any) => {
  const { open, handleClosed } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handelPopup = () => {
    setIsOpen(false);
    handleClosed();
  }
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const handleImageDelete = (file: File) => setMediaData('');
  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    setMediaData(formData);
    console.log(mediaData);

  };
  const handelAddCertificate = () => {
    setIsAdd(true)
  }
  return (
    <>
      <Modal title="Add Certificate" className={isAdd ? 'chapter-upload-bulk add-certificate ' : 'chapter-upload-bulk w-3/4'} centered open={isOpen} footer={null} onCancel={handelPopup}>
        <div className='w-full mt-4'  >
          <div className={isAdd ? 'hidden' : 'w-full mt-4'}>
            <Formik
              initialValues={{
                firstName: '',
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              <Form>
                <div className="chapter-field mb-5">
                  <label className='text-[#737373] text-[18px] mb-2 pl-2 font-rubik' htmlFor=""> Certificate Name</label>
                  <Field type="text" name="name" placeholder="Enter Certificate Name" className=' border-0 bg-[#F5F5F5] py-2 px-2 rounded-md' />
                </div>
                <div className='form-item mb-11'>
                  <div className=' chapter-document bulk_upload'>
                    <label className='addcourse-lable pl-2 text-[#737373] text-[18px] font-rubik ' htmlFor="firstName">Upload Certificate</label>
                    <ImageUploadElms onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 1}
                      allowedTypes={['image/jpeg', 'image/png', 'application/pdf']} />
                  </div>
                  <div className="flex justify-end mt-2">
                    <p className='text-[#D23B7D] font-rubik cursor-pointer' onClick={handelAddCertificate}>Form Fields Mapping</p>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button className='addButton popup  primary px-5 p-0  '  >Save </button>
                </div>
              </Form>
            </Formik>
          </div>
          {isAdd &&
            <div className="w-full ">
              <AddCertificateDB />
            </div>
          }
        </div>
      </Modal>
    </>
  )
}

export default AddCertificate