import React, { useEffect, useState } from 'react'
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import DocumentPDFView from '../../employee/employee-details/employee-profile/layout/documents/documentPDFView';
import { Switch } from 'antd';
import { setCertificateLoad } from '../../../redux/slices/updateProfileSlice';
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal';

export default function CompletionCertificate() {
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL
  const dispatch = useDispatch()
  const getCertificateMappedToOrganisation = useGetApiRequests('mapCertificateToCourse', 'GET');
  const organisationCertificate = useGetApiRequests('getAllCertificate', 'GET');
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const certificateReLoad = useSelector((state: any) => state?.profileReload?.certificateLoad);
  const deleteCertificate = useGetApiRequests('deleteCertificateToCourse', 'DELETE');
  const organisationId = useSelector((state: any) => state?.organisationId?.id);
  const mapCertificateToCourse = useGetApiRequests('mapCertificateToCourse', 'POST');
  const [loader, setLoader] = useState<any>(false)
  const [documentView, setDocumentView] = useState<boolean>(false)
  const [currentUrl, setCurrentUrl] = useState<any>('')
  const [currentPdfDetails, setCurrentPdfDetails] = useState<any>({})
  const [isCertificate, setIsCertificate] = useState<boolean>(false)
  const [allCertificates, setAllCertificates] = useState<any>()
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const deleteMessage = 'Course Is in Publish Mode Can not able to edit'
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);

  const getAllOrganisationCertificate = async (certiType: any) => {
    setLoader(true)
    try {
      const response: any = await organisationCertificate('', { organisationId })
      setAllCertificates(response?.data?.data)
      setLoader(false)
    } catch (err: any) {
      setLoader(false)
    }
  }

  const getOrganisationCertificate = async (certiType: any) => {
    setLoader(true)
    try {
      const pathParam = { id: currentCourseId }
      const response: any = await getCertificateMappedToOrganisation('', {}, pathParam)
      const filteredCertificate = response?.data?.data?.find((item: any) => { return item?.certificate?.certificateType === certiType })
      if (filteredCertificate) setIsCertificate(true)
      setCurrentUrl(`${baseUrl}${filteredCertificate?.certificate?.certificatePath}`)
      setCurrentPdfDetails({ documentPath: filteredCertificate?.certificate?.certificatePath })
      setLoader(false)
    } catch (err: any) {
      setCurrentUrl(``)
      setLoader(false)
    }
  }

  useEffect(() => {
    getAllOrganisationCertificate('COURSE_CO')
  }, [])

  useEffect(() => {
    getOrganisationCertificate('COURSE_CO')
  }, [certificateReLoad])

  const handleOnClick = () => {
    if (currentUrl?.length !== 0) setDocumentView(true)
  }

  const handleCloseDelete = () => setDeletePopUp(false);

  const handleDeleteSelected = () => {
    handleCloseDelete()
  };

  const handleAssignCertificate = async (certificateId: any) => {
    const payload = { course: { courseId: currentCourseId }, certificate: { certificateId: certificateId } }
    try {
      const response: any = await mapCertificateToCourse(payload)
      setIsCertificate(true)
      setLoader(false)
      dispatch(setCertificateLoad())
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }

  const handleDeleteCertificate = async (certificateId: any) => {
    const queryParams = { courseId: currentCourseId, certificateId }
    try {
      const response: any = await deleteCertificate('', queryParams)
      setIsCertificate(false)
      setLoader(false)
      dispatch(setCertificateLoad())
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.detail, 'topRight');
    }
  }

  const handleRemoveOrAddCertificate = (event: any) => {
    if (employeeDetail?.courseStatus === 'publish') {
      setDeletePopUp(true)
    } else if (employeeDetail?.courseStatus !== 'publish') {
      const filteredCertificate = allCertificates?.find((item: any) => { return item?.certificateType === 'COURSE_CO' })
      if (event) handleAssignCertificate(filteredCertificate?.certificateId)
      else if (!event) handleDeleteCertificate(filteredCertificate?.certificateId)
    }
  }

  return (
    <div className='courseDetails-cc p-4 mt-5'>
      {loader && <Loader />}
      <div className='flex justify-between mt-4 relative w-full'>
        <div>
          <h4 className='text-primary text-xl font-medium'>Completion certificate</h4>
        </div>
        <div>  <Switch checked={isCertificate} onChange={(event) => handleRemoveOrAddCertificate(event)} /></div>
      </div>
      <div className='flex mt-7 relative w-full'>
        {currentUrl?.length !== 0 && (
          <div className='flex justify-between w-full'>
            <p className='text-[#737373]'>Course Certificate</p>
            <div><p onClick={handleOnClick} className=' cursor-pointer text-primary underline'>View</p></div>
          </div>
        )}
      </div>
      {deletePopUp && <DeleteConfirmationModal contentChange={true} open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
      {documentView && <DocumentPDFView open={documentView} setDocumentView={setDocumentView} currentPdfDetails={currentPdfDetails} certificate={true} mainPage={false} />}
      {/* <DocumentPDFView currentPdfDetails={currentPdfDetails} certificate={true} mainPage={true} /> */}
    </div>
  )
}
